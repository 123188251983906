import {Icon} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import * as React from "react";

import {formatCategoryItems} from "../../../models/format-category-item";
import {formatRevisionName} from "../../../models/format-revision-name";
import {Link} from "../../../routes";
import {useActiveMediaStore} from "../../../stores/active-media-store";
import {MomentFormats} from "../../../utils/moment-util";
import {EmptySafeLink} from "../../molecules/empty-safe-link/empty-safe-link";
import {HeadingDetailItem} from "../../molecules/heading-detail-list/heading-detail-item";
import {HeadingDetailList} from "../../molecules/heading-detail-list/heading-detail-list";
import {HeadingLine} from "../../molecules/heading-line/heading-line";
import {DocumentListItem} from "./document-list-item";

const DocumentRevisionLineDiv = styled.div`
    margin-top: 4px;
`;

export const DocumentListItemView: React.FC<{
    documentItem: DocumentListItem;
    isBlock: boolean;
}> = observer(({documentItem, isBlock}) => {
    const activeMediaState = useActiveMediaStore((state) => state.activeMediaInfo);
    return (
        <div key={documentItem.id.value}>
            <HeadingLine
                icon={<Icon icon="saved" />}
                primary={
                    <div>
                        <EmptySafeLink
                            route={`/admin/[mediaId]/documents/[documentId]`}
                            params={{
                                mediaId: activeMediaState.mediaId,
                                documentId: documentItem.id.value,
                            }}
                        >
                            {documentItem.title}
                        </EmptySafeLink>
                    </div>
                }
                secondary={
                    <HeadingDetailList>
                        <HeadingDetailItem label="ID">{documentItem.id.value}</HeadingDetailItem>
                        <HeadingDetailItem label="スラッグ">
                            {documentItem.slug ? documentItem.slug : "(未設定)"}
                        </HeadingDetailItem>
                        {!isBlock && (
                            <HeadingDetailItem label="カテゴリ">
                                {formatCategoryItems(documentItem.categories)}
                            </HeadingDetailItem>
                        )}
                        <HeadingDetailItem label="修正日">
                            {documentItem.modifiedAt.format(MomentFormats.date)}
                        </HeadingDetailItem>
                        <HeadingDetailItem>
                            {documentItem.archived ? "アーカイブ済" : "未アーカイブ"}
                        </HeadingDetailItem>
                    </HeadingDetailList>
                }
            />
            <div style={{marginLeft: "16px"}}>
                {documentItem.collapsedDocumentRevisions.map((r) => (
                    <DocumentRevisionLineDiv key={r.id.value}>
                        <HeadingLine
                            icon={<Icon icon="document" />}
                            primary={
                                <EmptySafeLink
                                    route={`/admin/[mediaId]/revisions/[documentRevisionId]`}
                                    params={{
                                        mediaId: activeMediaState.mediaId,
                                        documentRevisionId: r.id.value,
                                    }}
                                >
                                    {formatRevisionName(r.revisionName, r.revisionType)}
                                </EmptySafeLink>
                            }
                            secondary={
                                <HeadingDetailList>
                                    <HeadingDetailItem label="ID">{r.id.value}</HeadingDetailItem>
                                    <HeadingDetailItem label="担当者">
                                        {r.userNames.join(", ")}
                                    </HeadingDetailItem>
                                    <HeadingDetailItem label="更新日">
                                        {r.updatedAt.format(MomentFormats.date)}
                                    </HeadingDetailItem>
                                </HeadingDetailList>
                            }
                        />
                    </DocumentRevisionLineDiv>
                ))}
                <div style={{marginLeft: "5px", marginBottom: "5px"}}>
                    <Link
                        route={`/admin/[mediaId]/revisions`}
                        params={{
                            mediaId: activeMediaState.mediaId,
                        }}
                    >
                        <a style={{fontSize: "12px", color: "inherit"}}>下書き一覧...</a>
                    </Link>
                </div>
            </div>
        </div>
    );
});
