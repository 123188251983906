import {Tab, Tabs} from "@blueprintjs/core";
import * as React from "react";

import {AomNodeDifference} from "../../../api/values/aom-node-difference";
import {IAquaEditor} from "../../../editor/aqua-editor";
import {SourceDiff} from "../source-diff/source-diff";
import {VisualDiff} from "../visual-diff/visual-diff";

export interface IVisualSourceDiffSetProps {
    aquaEditor: IAquaEditor;
    differences: AomNodeDifference[];
}

export const VisualSourceDiffSet: React.FC<IVisualSourceDiffSetProps> = ({
    aquaEditor,
    differences,
}) => (
    <Tabs id="contentTabs" renderActiveTabPanelOnly={true}>
        <Tab
            id="visualDiffTab"
            title="ビジュアル"
            panel={<VisualDiff differences={differences} aquaEditor={aquaEditor} />}
        />

        <Tab
            id="sourceDiffTab"
            title="ソース"
            panel={<SourceDiff differences={differences} aquaEditor={aquaEditor} />}
        />
    </Tabs>
);
