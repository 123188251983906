import {JsonObject, JsonProperty} from "json2typescript";

import {BigIntId} from "../../utils/big-int-id";
import {BigIntIdConverter, JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("IdOnly")
export class IdOnly {
    @JsonProperty("id", BigIntIdConverter)
    public id: BigIntId = JsonPropertyEmpty;
}
