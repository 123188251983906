import {$E, NodeObject, NodeType} from "@syginc/aomjs";

export function checkDiffAddOrRemoveNodeExists(nodeObject: NodeObject): boolean {
    const diffType = nodeObject?.meta?.diffType;
    if (diffType === "ADD" || diffType === "REMOVE") {
        return true;
    }

    if ("child" in nodeObject) {
        return nodeObject?.child?.some((c) => checkDiffAddOrRemoveNodeExists(c)) || false;
    }

    return false;
}

function getDiffData(meta?: {[key: string]: any}, isText?: boolean) {
    if (!meta?.diffType) {
        return undefined;
    }

    return {
        "data-aom-diff-type": meta?.diffType,
        "data-aom-diff-distance": meta?.diffDistance,
        ...(isText ? {"data-aom-diff-text": ""} : {}),
    };
}

function isSkipNode(nodeObject: NodeObject) {
    return nodeObject.node === NodeType.Text && nodeObject.text?.trim()?.length === 0;
}

export function processDiffMeta(nodeObject: NodeObject): NodeObject {
    let newChild;
    if ("child" in nodeObject) {
        newChild = nodeObject.child?.filter((c) => !isSkipNode(c))?.map((c) => processDiffMeta(c));
    }

    const diffData = getDiffData(nodeObject?.meta, nodeObject?.node === NodeType.Text);
    if (nodeObject.node === NodeType.Document || nodeObject.node === NodeType.DocumentFragment) {
        return {...nodeObject, child: newChild};
    } else if (nodeObject.node === NodeType.Element) {
        return {
            ...nodeObject,
            data: {...diffData, ...nodeObject.data},
            child: newChild,
        };
    } else if (nodeObject.node === NodeType.Text && diffData) {
        // TODO: use a special aom element for meta
        return $E("article-span", {data: diffData}, nodeObject.text);
    } else {
        return nodeObject;
    }
}
