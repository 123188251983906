import {JsonObject, JsonProperty} from "json2typescript";
import * as moment from "moment-timezone";

import {DateTimeConverter, JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("DocumentRevisionPublishInput")
export class DocumentRevisionPublishInput {
    @JsonProperty("appliedAt", DateTimeConverter)
    public appliedAt: moment.Moment = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentRevisionPublishInput>) {
        Object.assign(this, args);
    }
}
