import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";
import {DocumentPreviewOutput} from "./document-preview-output";

@JsonObject("DocumentPreviewListOutput")
export class DocumentPreviewListOutput {
    @JsonProperty("previews", [DocumentPreviewOutput])
    public previews: DocumentPreviewOutput[] = JsonPropertyEmpty;
}
