import {Button} from "@blueprintjs/core";
import styled from "@emotion/styled";
import React from "react";

import {SubmitGroup} from "../submit-group/submit-group";
import {SearchPanelItem} from "./search-panel-item";

const SearchPanelDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

interface ISearchPanelProps {
    label: React.ReactNode;
    children:
        | React.ReactElement<typeof SearchPanelItem>
        | Array<React.ReactElement<typeof SearchPanelItem>>;
    onSearch: () => Promise<any>;
}

export const SearchPanel = (props: ISearchPanelProps) => (
    <SubmitGroup onSubmit={props.onSearch}>
        <SearchPanelDiv>
            {props.children}
            <SearchPanelItem>
                <Button onClick={props.onSearch}>{props.label}</Button>
            </SearchPanelItem>
        </SearchPanelDiv>
    </SubmitGroup>
);
