import {Button, ControlGroup, FormGroup, H5, InputGroup, Tooltip} from "@blueprintjs/core";
import {TimePrecision} from "@blueprintjs/datetime";
import {observer} from "mobx-react";
import * as React from "react";

import {getSitePreviewUrl} from "../../../models/format-site-url";
import {MomentDateInput} from "../../molecules/moment-date-input/moment-date-input";
import ProgressPanel from "../../molecules/progress-panel/progress-panel";
import {PreviewPanelStore} from "./preview-panel-store";

interface IPreviewPanelProps {
    store: PreviewPanelStore;
    siteUrl: string;
}

@observer
export class PreviewPanel extends React.Component<IPreviewPanelProps> {
    public render() {
        return (
            <div style={{padding: "10px", minWidth: "540px"}}>
                <ProgressPanel inProgress={this.props.store.isInProgress}>
                    <H5>共有</H5>

                    <FormGroup label="URL">
                        <ControlGroup fill={true}>
                            <InputGroup
                                value={
                                    this.props.store.previewKey
                                        ? getSitePreviewUrl(
                                              this.props.siteUrl,
                                              this.props.store.previewKey,
                                          )
                                        : "未設定"
                                }
                                readOnly={true}
                                disabled={!this.props.store.isKeyAvailable}
                                style={{backgroundColor: "rgba(206,217,224,0.5)"}}
                            />
                            <Button
                                title={
                                    this.props.store.isKeyAvailable
                                        ? "URLを開く"
                                        : "URLを作成して開く"
                                }
                                icon="document-open"
                                className="bp3-fixed"
                                onClick={() =>
                                    this.props.store.createAndOpenUrl(this.props.siteUrl)
                                }
                            />
                        </ControlGroup>
                    </FormGroup>

                    <FormGroup label="有効期限">
                        <MomentDateInput
                            popoverProps={{usePortal: false}} // 親PopoverのcanOutsideClickClose判定用
                            timePrecision={TimePrecision.MINUTE}
                            onChange={(d) => this.props.store.setExpiredAt(d)}
                            value={
                                this.props.store.expiredAt && this.props.store.expiredAt.toDate()
                            }
                        />
                    </FormGroup>

                    <div>
                        {this.props.store.isKeyAvailable && (
                            <Tooltip content="無効化すると現在の共有URLは復元できません">
                                <Button
                                    intent="danger"
                                    style={{marginRight: "10px"}}
                                    onClick={this.props.store.discard}
                                >
                                    無効化
                                </Button>
                            </Tooltip>
                        )}

                        <Button
                            intent="primary"
                            onClick={this.props.store.save}
                            disabled={!this.props.store.canSave}
                        >
                            {this.props.store.isKeyAvailable ? "更新" : "作成"}
                        </Button>
                    </div>
                </ProgressPanel>
            </div>
        );
    }
}
