import {Icon} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";

import {formatRevisionName} from "../../../models/format-revision-name";
import {formatRevisionNumber} from "../../../models/format-revision-number";
import {Link} from "../../../routes";
import {REVISION_LIST_DISPLAY_LENGTH, RevisionListPanelStore} from "./revision-list-panel-store";

function getMediaId(): string {
    const mediaInfoList = sessionStorage.getItem("mediaInfoList");
    if (mediaInfoList) {
        try {
            const parsedList = JSON.parse(mediaInfoList);
            if (parsedList && parsedList.state && parsedList.state.activeMediaInfo) {
                return parsedList.state.activeMediaInfo.mediaId || "none";
            }
        } catch (e) {
            console.error("Failed to parse mediaInfoList from sessionStorage", e);
        }
    }
    return "none";
}

@observer
export class RevisionListPanel extends React.Component<{
    isWorking: boolean;
    store: RevisionListPanelStore;
}> {
    public render() {
        const mediaId = getMediaId();

        return (
            <ul style={{listStyleType: "none", paddingLeft: 0, marginBottom: "5px"}}>
                {this.props.store.revisions
                    .filter(
                        (r) =>
                            (this.props.isWorking && !r.appliedAt) ||
                            (!this.props.isWorking && r.appliedAt),
                    )
                    .slice(0, REVISION_LIST_DISPLAY_LENGTH)
                    .map((r) => (
                        <li key={r.id.value}>
                            <div style={{fontSize: "14px", lineHeight: "1.6"}}>
                                <Icon icon="document" />
                                <Link
                                    route={`/admin/${mediaId}/revisions/[documentRevisionId]`}
                                    params={{documentRevisionId: r.id.value}}
                                >
                                    <a>{formatRevisionName(r.revisionName, r.revisionType)}</a>
                                </Link>
                                <span style={{fontSize: "12px", marginLeft: "10px"}}>
                                    {r.revisionNumber &&
                                        formatRevisionNumber(r.revisionNumber, r.appliedAt)}
                                </span>
                            </div>
                        </li>
                    ))}
                <Link
                    route={`/admin/${mediaId}/revisions`}
                    params={{
                        documentId: this.props.store.documentId.value,
                        isWorking: this.props.isWorking ? "true" : "false",
                    }}
                >
                    <a style={{fontSize: "14px"}}>一覧...</a>
                </Link>
            </ul>
        );
    }
}
