import {JsonObject, JsonProperty} from "json2typescript";

import {BigIntId} from "../../utils/big-int-id";
import {BigIntIdConverter, JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("DocumentPreviewListGetInput")
export class DocumentPreviewListGetInput {
    @JsonProperty("mediaId", String, true)
    public mediaId: string = JsonPropertyEmpty;

    @JsonProperty("documentRevisionId", BigIntIdConverter, true)
    public documentRevisionId: BigIntId = JsonPropertyEmpty;

    @JsonProperty("previewKey", String, true)
    public previewKey: string = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentPreviewListGetInput>) {
        Object.assign(this, args);
    }
}
