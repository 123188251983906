import {Button, Icon} from "@blueprintjs/core";
import styled from "@emotion/styled";
import * as React from "react";

const ListEditorItemPanelDiv = styled.div`
    display: flex;
`;

const ListEditorItemPanelContentDiv = styled.div`
    flex-grow: 1;
`;

const ListEditorItemPanelButtonsDiv = styled.div`
    display: flex;

    > * + * {
        margin-left: 8px;
    }
`;

export interface IListEditorItemPanelProps {
    onEdit?: () => void;
    onRemove?: () => void;
    onAdd?: () => void;
    onApply?: () => void;
}

export const ListEditorItemPanel: React.FC<IListEditorItemPanelProps> = ({
    onAdd,
    onApply,
    onEdit,
    onRemove,
    children,
}) => (
    <ListEditorItemPanelDiv>
        <ListEditorItemPanelContentDiv>{children}</ListEditorItemPanelContentDiv>
        <ListEditorItemPanelButtonsDiv>
            {onAdd && (
                <Button onClick={onAdd}>
                    <Icon icon="plus" />
                </Button>
            )}
            {onApply && (
                <Button onClick={onApply}>
                    <Icon icon="key-enter" />
                </Button>
            )}
            {onEdit && (
                <Button onClick={onEdit}>
                    <Icon icon="edit" />
                </Button>
            )}
            {onRemove && (
                <Button onClick={onRemove}>
                    <Icon icon="cross" />
                </Button>
            )}
        </ListEditorItemPanelButtonsDiv>
    </ListEditorItemPanelDiv>
);
