import {PageRouter} from "../utils/route-util";
import {AuthenticationStore} from "./authentication-store";
import {ToastStore} from "./toast-store";

export class Stores {
    public authenticationStore = new AuthenticationStore();
    public toastStore: ToastStore;

    constructor(pageRouter: PageRouter) {
        this.toastStore = new ToastStore(pageRouter);
    }
}
