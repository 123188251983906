import {ValidationFunction} from "../validation-function";
import {ValidationResultItem} from "../validation-result-item";

export const validateLength: ValidationFunction = (min?: number, max?: number) => {
    return (s) => {
        const items = [];
        const length = s.length;

        if (min !== undefined && length < min) {
            items.push(
                new ValidationResultItem("length.min", `${min}文字以上である必要があります`),
            );
        }

        if (max !== undefined && length > max) {
            items.push(
                new ValidationResultItem("length.max", `${max}文字以下である必要があります`),
            );
        }

        return items;
    };
};
