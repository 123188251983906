import {Button, Colors, MenuItem, Spinner} from "@blueprintjs/core";
import {IItemRendererProps, ItemListPredicate, ItemRenderer, Select} from "@blueprintjs/select";
import {ClassNames} from "@emotion/react";
import * as React from "react";
import {useEffect} from "react";

import {IUserItem, useUserListCache} from "../user-list-cache/user-list-cache";

export interface ISelectUserItem {
    name: string;
}

type LooseUserItem = ISelectUserItem | null;

function createKey(item: LooseUserItem) {
    return item ? item.name : "";
}

const UserSelect = Select.ofType<LooseUserItem>();

function createItemListPredicate(emptyOption?: boolean): ItemListPredicate<LooseUserItem> {
    return (query: string, items: LooseUserItem[]) => {
        const arr: LooseUserItem[] = [];

        if (emptyOption && !query) {
            arr.push(null);
        }

        return arr.concat(
            items.filter((item) => !query || (item && item.name.indexOf(query) >= 0)),
        );
    };
}

function createItemRenderer(placeholder?: string): ItemRenderer<LooseUserItem> {
    return (item, props) => (item ? renderItem(item, props) : renderEmptyItem(props, placeholder));
}

function renderEmptyItem(props: IItemRendererProps, placeholder?: string) {
    return (
        <MenuItem
            active={props.modifiers.active}
            key={createKey(null)}
            text={renderItemContent(null, placeholder)}
            onClick={props.handleClick}
        />
    );
}

function renderItem(item: ISelectUserItem, props: IItemRendererProps) {
    return (
        <MenuItem
            active={props.modifiers.active}
            key={createKey(item)}
            text={
                <>
                    <span style={{marginLeft: "1em"}} />
                    {renderItemContent(item)}
                </>
            }
            onClick={props.handleClick}
        />
    );
}

function renderItemContent(item: LooseUserItem, placeholder?: string) {
    return item ? (
        <>{item.name}</>
    ) : (
        <span style={{color: Colors.GRAY3}}>{placeholder || "未選択"}</span>
    );
}

interface IUserSelectorProps {
    value: string | null;
    allowNotOnList?: boolean;
    emptyOption?: boolean;
    placeholder?: string;
    onChange: (item: LooseUserItem) => void;
}

function generateItems(value: string | null, users: IUserItem[], allowNotOnList?: boolean) {
    const items = [...users];

    // TODO: 無効のユーザーを選択できるUIを作ればallowNotOnListはいらない
    if (allowNotOnList && value && !users.find((x) => x.name === value)) {
        items.push({name: value});
        items.sort((a, b) => a.name.localeCompare(b.name));
    }

    return items;
}

export const UserSelector: React.FC<IUserSelectorProps> = (props) => {
    const userListCache = useUserListCache();

    useEffect(() => {
        userListCache.load();
    }, [userListCache]);

    if (!userListCache.enabledUsers) {
        return <Spinner size={Spinner.SIZE_SMALL} />;
    }

    const handleChange = (item: LooseUserItem) => {
        props.onChange(item);
    };

    const items = generateItems(props.value, userListCache.enabledUsers, props.allowNotOnList);
    const selectedItem = items.find((x) => x.name === props.value) || null;

    return (
        <ClassNames>
            {({css}) => (
                <UserSelect
                    items={items}
                    itemListPredicate={createItemListPredicate(props.emptyOption)}
                    itemRenderer={createItemRenderer(props.placeholder)}
                    noResults={<MenuItem disabled={true} text="No Results." />}
                    onItemSelect={handleChange}
                    popoverProps={{
                        modifiers: {arrow: {enabled: false}},
                        popoverClassName: css`
                            padding: 5px;
                            background-color: white;

                            .bp3-menu {
                                max-height: 50vh;
                                max-width: 330px;
                                overflow: auto;
                            }
                        `,
                    }}
                    resetOnClose={true}
                    resetOnSelect={true}
                    resetOnQuery={false}
                >
                    <Button rightIcon="caret-down">
                        {renderItemContent(selectedItem, props.placeholder)}
                    </Button>
                </UserSelect>
            )}
        </ClassNames>
    );
};
