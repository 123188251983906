import {Button} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import * as React from "react";

import {DocumentType} from "../../../api/values/document-type";
import {useActiveMediaStore} from "../../../stores/active-media-store";
import {SubmitGroup} from "../../molecules/submit-group/submit-group";
import {ValidatedInputText} from "../../molecules/validated-input-text/validated-input-text";
import {DocumentAddStore} from "./document-add-store";

const DocumentAddContentFormBodyDiv = styled.div`
    display: flex;
    align-items: start;
`;

const StyledValidatedInputText = styled(ValidatedInputText)`
    flex-grow: 1;
`;

export const DocumentAddDocumentForm: React.FC<{
    mediaId: string;
    store: DocumentAddStore;
    documentType: DocumentType;
    onCreated: () => Promise<unknown>;
}> = observer(({store, documentType, onCreated}) => {
    const activeMediaId = useActiveMediaStore((state) => state.activeMediaInfo.mediaId);
    return (
        <SubmitGroup
            canSubmit={store.canCreate}
            onSubmit={() => store.create(documentType, activeMediaId, onCreated)}
        >
            <DocumentAddContentFormBodyDiv>
                <StyledValidatedInputText
                    value={store.title}
                    validationResult={store.titleValidationResult}
                    onChangeValue={(s) => store.setTitle(s)}
                    placeholder="タイトル"
                    autoFocus={true}
                />

                <Button
                    disabled={!store.canCreate}
                    onClick={() => store.create(documentType, activeMediaId, onCreated)}
                >
                    追加
                </Button>
            </DocumentAddContentFormBodyDiv>
        </SubmitGroup>
    );
});
