import {Classes} from "@blueprintjs/core";
import * as React from "react";
import {ReactNode} from "react";

interface DrawerFooterProps {
    actions?: ReactNode;
}

export const DrawerFooter: React.FC<DrawerFooterProps> = ({actions, children}) => (
    <div className={Classes.DRAWER_FOOTER}>
        {children}
        {actions && <div className={Classes.DIALOG_FOOTER_ACTIONS}>{actions}</div>}
    </div>
);
