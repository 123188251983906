import {DeviceType, NodeObject} from "@syginc/aomjs";
import js_beautify from "js-beautify";

import {IAquaEditor} from "../editor/aqua-editor";
import {processDiffMeta} from "./aom-diff-meta-util";

export async function generateDiffSourceHtml(
    aquaEditor: IAquaEditor,
    node: NodeObject,
): Promise<string> {
    const fragment = document.createDocumentFragment();
    const element = document.createElement("div");
    fragment.append(element);

    const processedNode = processDiffMeta(node);
    element.innerHTML = aquaEditor.aomRenderer.renderToHtml(processedNode, {
        deviceType: DeviceType.Source,
    });

    const diffElements = element.querySelectorAll("[data-aom-diff-type]");
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < diffElements.length; i++) {
        const diffElement = diffElements[i] as HTMLElement;

        const type = diffElement.getAttribute("data-aom-diff-type");
        const distance = diffElement.getAttribute("data-aom-diff-distance");

        const startComment = document.createComment(
            `START DIFF: ${type},${distance ? distance : ""}`,
        );
        const endComment = document.createComment("END DIFF");
        const parentElement = diffElement.parentNode! as HTMLElement;
        parentElement.insertBefore(startComment, diffElement);
        parentElement.insertBefore(endComment, diffElement.nextSibling);

        if (diffElement.hasAttribute("data-aom-diff-text")) {
            const child = diffElement.childNodes[0];
            diffElement.removeChild(child);
            parentElement.replaceChild(child, diffElement);
        } else {
            diffElement.removeAttribute("data-aom-diff-type");
            diffElement.removeAttribute("data-aom-diff-distance");
        }
    }

    const renderedHtml = element.innerHTML;
    aquaEditor.aomRenderer.unmountRenderedDomElement(element);

    const beautifiedHtml = js_beautify.html(renderedHtml, {preserve_newlines: false});

    const textNode = document.createTextNode(beautifiedHtml);
    const dummy = document.createElement("p");
    dummy.appendChild(textNode);
    const escapedHtml = dummy.innerHTML;

    const html = escapedHtml
        .replace(
            /&lt;!--START DIFF: (\w+),(\d*)--&gt;\s*/gm,
            (all, g1, g2) => `<span data-aom-diff-type="${g1}" data-aom-diff-distance="${g2}">`,
        )
        .replace(/\s*&lt;!--END DIFF--&gt;/gm, `</span>`);

    return html;
}
