import styled from "@emotion/styled";

export const UploadableImageDiv = styled.div`
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const UploadableImageImg = styled.img`
    display: block;

    max-width: 100%;
    max-height: 100%;
`;

export const UploadableImagePlaceholderDiv = styled.div`
    display: block;
    background-color: #bbb;

    width: 100%;
    height: 100%;
`;

export const UploadableImageIconDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);

    &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.4);
    }
`;
