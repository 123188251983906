import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";
import {DocumentType} from "./document-type";

@JsonObject("DocumentAddInput")
export class DocumentAddInput {
    @JsonProperty("mediaId", String, true)
    public mediaId: string = JsonPropertyEmpty;

    @JsonProperty()
    public documentType: DocumentType = JsonPropertyEmpty;

    @JsonProperty()
    public title: string = JsonPropertyEmpty;

    @JsonProperty()
    public slug: string = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentAddInput>) {
        Object.assign(this, args);
    }
}
