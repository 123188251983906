import {ProgressBar, Spinner} from "@blueprintjs/core";
import * as React from "react";

export interface IProgressPanelProps {
    inProgress: boolean;
    progressMax?: number;
    progressCurrent?: number;
}

export default class ProgressPanel extends React.PureComponent<IProgressPanelProps, {}> {
    public render() {
        if (!this.props.inProgress) {
            return this.props.children;
        }

        if (this.props.progressMax === undefined) {
            return <Spinner />;
        }

        return (
            <ProgressBar
                value={
                    this.props.progressMax === 0
                        ? undefined
                        : (this.props.progressCurrent || 0) / this.props.progressMax
                }
            />
        );
    }
}
