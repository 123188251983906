import styled from "@emotion/styled";
import * as React from "react";

import {IListEditorItemProps} from "./list-editor";

const ListEditorItemLi = styled.li`
    margin-top: -1px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(16, 22, 26, 0.15);
    padding: 8px;
    list-style-type: none;
    background-color: white;

    &:first-of-type {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    &:last-of-type {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
`;

const ListEditorItemLiDraggable = styled(ListEditorItemLi)`
    user-select: none;
    cursor: grab;
`;

export interface IListEditorItemElementProps<T> {
    itemProps: IListEditorItemProps<T>;
    itemComponentFactory: (item: IListEditorItemProps<T>) => JSX.Element;
}

export function ListEditorItemElement<T>(props: IListEditorItemElementProps<T>) {
    const isDraggable = props.itemProps.item.data !== null;
    const Li = isDraggable ? ListEditorItemLiDraggable : ListEditorItemLi;

    return <Li>{props.itemComponentFactory(props.itemProps)}</Li>;
}
