import {useContext} from "react";

import {StoresContext} from "./stores-context";

export function useStores() {
    const stores = useContext(StoresContext);
    if (!stores) {
        throw new Error("Stores is not provided");
    }

    return stores;
}

export function useAuthenticationStore() {
    return useStores().authenticationStore;
}

export function useToastStore() {
    return useStores().toastStore;
}
