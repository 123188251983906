import {Button, Card, Icon} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import * as React from "react";

import {Link} from "../../../routes";
import {BooleanSelect} from "../../molecules/boolean-select/boolean-select";
import {BorderedList} from "../../molecules/bordered-list/bordered-list";
import {EmptySafeLink} from "../../molecules/empty-safe-link/empty-safe-link";
import {HeadingDetailItem} from "../../molecules/heading-detail-list/heading-detail-item";
import {HeadingDetailList} from "../../molecules/heading-detail-list/heading-detail-list";
import {HeadingLine} from "../../molecules/heading-line/heading-line";
import {Pager} from "../../molecules/pager/pager";
import {SearchPanel} from "../../molecules/search-panel/search-panel";
import {SearchPanelItem} from "../../molecules/search-panel/search-panel-item";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {UserListPageStore} from "./user-list-page-store";

const UserListNameLink = styled(EmptySafeLink)`
    font-size: 16px;
    font-weight: bold;
`;

@observer
export class UserListPage extends React.Component<{pageStore: UserListPageStore}, {}> {
    public render() {
        return (
            <AppLayout
                breadcrumb={<AdminBreadcrumb factory={(builder) => builder.userList().build()} />}
                buttons={
                    <Link route={`/admin/${this.props.pageStore.mediaId}/users/new`}>
                        <Button icon="plus" text="ユーザー追加" />
                    </Link>
                }
            >
                <Card>
                    <SearchPanel label="絞り込み" onSearch={this.props.pageStore.search}>
                        <SearchPanelItem label="アカウント状態">
                            <BooleanSelect
                                undefinedLabel="指定なし"
                                trueLabel="有効"
                                falseLabel="無効"
                                value={this.props.pageStore.enabled}
                                onChangeValue={this.props.pageStore.setEnabled}
                            />
                        </SearchPanelItem>
                    </SearchPanel>
                </Card>

                <BorderedList>
                    {this.props.pageStore.users.map((user) => (
                        <HeadingLine
                            key={user.id.value}
                            icon={<Icon icon="user" />}
                            primary={
                                <UserListNameLink
                                    route="/admin/[mediaId]/users/[id]"
                                    params={{
                                        mediaId: this.props.pageStore.mediaId,
                                        id: user.id.toString(),
                                    }}
                                >
                                    {user.name}
                                </UserListNameLink>
                            }
                            secondary={
                                <HeadingDetailList>
                                    <HeadingDetailItem label="ID">
                                        {user.id.value}
                                    </HeadingDetailItem>
                                    <HeadingDetailItem>{user.role.displayName}</HeadingDetailItem>
                                    <HeadingDetailItem>
                                        {user.enabled ? "有効" : "無効"}
                                    </HeadingDetailItem>
                                </HeadingDetailList>
                            }
                        />
                    ))}
                </BorderedList>

                <Pager
                    previousExists={this.props.pageStore.previousPageExists}
                    nextExists={this.props.pageStore.nextPageExists}
                    onPreviousClicked={this.props.pageStore.goToPreviousPage}
                    onNextClicked={this.props.pageStore.goToNextPage}
                />
            </AppLayout>
        );
    }
}
