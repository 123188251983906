import styled from "@emotion/styled";
import {observer} from "mobx-react";
import * as React from "react";
import {useLocation} from "react-router";

import {BreadcrumbBar} from "../../molecules/breadcrumb-bar/breadcrumb-bar";
import {GlobalToaster} from "../../organisms/global-toaster/global-toaster";
import {SideMenu} from "../../organisms/side-menu/side-menu";
import SideNavBar from "../../organisms/side-nav-bar/side-nav-bar";
import {BasicLayout} from "../basic-layout/basic-layout";

interface IAppLayoutProps {
    breadcrumb?: React.ReactNode;
    buttons?: React.ReactNode;
}

const SideNav = styled.aside`
    height: 100vh;
    position: sticky;
    top: 0;
`;

const MainContent = styled.main`
    width: -webkit-fill-available;
`;

const MainDiv = styled.div`
    max-width: 1200px;
    margin: 0 auto;
`;

const MainContentDiv = styled.div`
    margin: 10px;
`;
export const ConditionalSideNav = () => {
    const location = useLocation();
    switch (location.pathname) {
        case "/admin/media-list":
            return null;
        case "/admin/media-create":
            return null;
        case "/admin/media-create/success":
            return null;
        case "/admin/profile":
            return null;
        case "/admin/password-change":
            return null;
        default:
            return <SideMenu />;
    }
};
export const AppLayout: React.FC<IAppLayoutProps> = observer(({breadcrumb, buttons, children}) => {
    return (
        <div
            style={{
                display: "flex",
            }}
        >
            <SideNav>
                <SideNavBar />
            </SideNav>
            <ConditionalSideNav />
            <MainContent>
                <BasicLayout>
                    <MainDiv>
                        <MainContentDiv>
                            <BreadcrumbBar breadcrumb={breadcrumb} buttons={buttons} />
                            <GlobalToaster />
                            {children}
                        </MainContentDiv>
                    </MainDiv>
                </BasicLayout>
            </MainContent>
        </div>
    );
});
