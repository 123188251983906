import {DeviceType} from "@syginc/aomjs";
import * as React from "react";

import {AomNodeDifference} from "../../../api/values/aom-node-difference";
import {IAquaEditor} from "../../../editor/aqua-editor";
import {AomContentContainer} from "../../molecules/aom-content-container/aom-content-container";
import {DiffGlobalStyle} from "../../molecules/diff-global-style/diff-global-style";
import {SiteStyledFrame} from "../../molecules/site-styled-frame/site-styled-frame";
import {VisualDiffSide} from "./visual-diff-side";

export interface IVisualDiffProps {
    differences: AomNodeDifference[];
    aquaEditor: IAquaEditor;
}

export const VisualDiff: React.FC<IVisualDiffProps> = ({differences, aquaEditor}) => (
    <SiteStyledFrame aquaEditor={aquaEditor}>
        <DiffGlobalStyle />

        <div style={{backgroundColor: "#fcfcfc"}}>
            <AomContentContainer aquaEditor={aquaEditor} deviceType={DeviceType.Mobile}>
                <div
                    style={{
                        display: "grid",
                        justifyContent: "center",
                        columnGap: "20px",
                    }}
                >
                    {differences.map((op, index) => (
                        <div
                            key={`${index}-left`}
                            style={{
                                gridRow: `${index + 1}`,
                                gridColumn: "1",
                            }}
                        >
                            <VisualDiffSide node={op.leftNode} aquaEditor={aquaEditor} />
                        </div>
                    ))}
                    {differences.map((op, index) => (
                        <div
                            key={`${index}-right`}
                            style={{
                                gridRow: `${index + 1}`,
                                gridColumn: "2",
                            }}
                        >
                            <VisualDiffSide node={op.rightNode} aquaEditor={aquaEditor} />
                        </div>
                    ))}
                </div>
            </AomContentContainer>
        </div>
    </SiteStyledFrame>
);
