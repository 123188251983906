import * as React from "react";

interface ISubmitGroupProps {
    canSubmit?: boolean;
    onSubmit: () => Promise<void>;
}

export class SubmitGroup extends React.PureComponent<ISubmitGroupProps, {}> {
    public async handleSubmit() {
        if (this.props.canSubmit === undefined || this.props.canSubmit) {
            await this.props.onSubmit();
        }
    }

    public render() {
        return (
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    this.handleSubmit();
                }}
            >
                {this.props.children}
            </form>
        );
    }
}
