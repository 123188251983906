import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {PageRouter} from "../utils/route-util";

export interface PageRouterContextValues {
    pageRouter: PageRouter;
}

export const PageRouterContext = React.createContext<PageRouterContextValues | undefined>(
    undefined,
);

export const PageRouterProvider: React.FC = ({children}) => {
    const [pageRouter, setPageRouter] = useState<PageRouter | null>(null);

    // navigate will be active after first useEffect()
    // https://github.com/remix-run/react-router/blob/fd1a20b15d03b3726a882b6d76235b356715b59c/packages/react-router/index.tsx#L531
    // navigate is updated when routing changes
    // https://github.com/remix-run/react-router/blob/fd1a20b15d03b3726a882b6d76235b356715b59c/packages/react-router/index.tsx#L557
    const navigate = useNavigate();
    useEffect(() => {
        if (!pageRouter) {
            setPageRouter(new PageRouter(navigate));
        } else {
            pageRouter.setNavigate(navigate);
        }
    }, [navigate, pageRouter]);

    if (!pageRouter) {
        return <></>;
    }

    return <PageRouterContext.Provider value={{pageRouter}}>{children}</PageRouterContext.Provider>;
};

export function usePageRouter() {
    const values = useContext(PageRouterContext);

    if (!values) {
        throw new Error("PageRouterContext is not provided");
    }

    return values.pageRouter;
}
