import {Intent} from "@blueprintjs/core";
import {action, computed, makeObservable, observable} from "mobx";

import {HttpError} from "../api/http-error";
import {QuickEditEntry} from "../components/organisms/publish-drawer/quick-edit-entry";
import {BigIntId} from "../utils/big-int-id";
import {PageRouter} from "../utils/route-util";

interface IHttpErrorDetail {
    intent: Intent;
    message: string;
}

export enum SimpleToast {
    SAVED = "SAVED",
    DELETED = "DELETED",
    FINISHED_PUBLISH_SETTING = "FINISHED_PUBLISH_SETTING",
    FINISHED_ARCHIVED_SETTING = "FINISHED_ARCHIVED_SETTING",
    CANNOT_OPEN_WINDOW = "CANNOT_OPEN_WINDOW",
    SCHEDULED_REVISION_EXISTS = "SCHEDULED_REVISION_EXISTS",
    COMPLETED = "COMPLETED",
}

interface ISimpleToastDetail {
    intent: Intent;
    text: string;
}

export interface IEditLeaveData {
    mediaId: string;
    documentId?: BigIntId;
    documentRevisionId?: BigIntId;
    quickEditEntry: QuickEditEntry;
}

export class ToastStore {
    @observable
    public httpError: HttpError | null = null;

    @observable
    public simpleToast: SimpleToast | null = null;

    @observable
    public isEditLeaveShown: boolean = false;

    public editLeaveData: IEditLeaveData | null = null;

    @observable
    public isKeepFirstRouteChange: boolean = false;

    public pageRouter: PageRouter;

    constructor(pageRouter: PageRouter) {
        this.pageRouter = pageRouter;
        makeObservable(this);
    }

    @action.bound
    public routeChanged() {
        if (this.isKeepFirstRouteChange) {
            this.isKeepFirstRouteChange = false;
            return;
        }

        this.setHttpError(null);
        this.setSimpleToast(null);
    }

    @action.bound
    public setHttpError(httpError: HttpError | null) {
        this.httpError = httpError;
    }

    @computed
    public get httpErrorDetail(): IHttpErrorDetail | null {
        if (!this.httpError) {
            return null;
        }

        if (this.httpError.userMessage) {
            return {
                intent: Intent.WARNING,
                message: this.httpError.userMessage,
            };
        } else {
            return {
                intent: Intent.DANGER,
                message: "エラーが発生しました。リロードしてください。",
            };
        }
    }

    @action.bound
    public setEditLeaveData(editLeaveData: IEditLeaveData | null) {
        this.isEditLeaveShown = !!editLeaveData;
        this.editLeaveData = editLeaveData;
    }

    @action.bound
    public hideEditLeave() {
        this.isEditLeaveShown = false;
    }

    @action.bound
    public backToEditLeave() {
        const editLeaveData = this.editLeaveData;
        if (!editLeaveData) {
            return;
        }

        if (editLeaveData.documentId) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.pageRouter.pushRoute("/admin/[mediaId]/documents/[documentId]", {
                mediaId: editLeaveData.mediaId,
                documentId: editLeaveData.documentId.value,
                editLeave: "true",
            });
        } else {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.pageRouter.pushRoute("/admin/[mediaId]/revisions/[documentRevisionId]", {
                mediaId: editLeaveData.mediaId,
                documentRevisionId: editLeaveData.documentRevisionId!.value,
                editLeave: "true",
            });
        }
    }

    @action.bound
    public setSimpleToast(simpleToast: SimpleToast | null) {
        this.simpleToast = simpleToast;
    }

    @computed
    public get simpleToastDetail(): ISimpleToastDetail | null {
        if (!this.simpleToast) {
            return null;
        }

        switch (this.simpleToast) {
            case SimpleToast.SAVED:
                return {
                    intent: Intent.SUCCESS,
                    text: "保存しました。",
                };
            case SimpleToast.DELETED:
                return {
                    intent: Intent.SUCCESS,
                    text: "削除しました。",
                };
            case SimpleToast.FINISHED_PUBLISH_SETTING:
                return {
                    intent: Intent.SUCCESS,
                    text: "公開設定が完了しました。",
                };
            case SimpleToast.FINISHED_ARCHIVED_SETTING:
                return {
                    intent: Intent.SUCCESS,
                    text: "アーカイブ状態を変更しました。",
                };
            case SimpleToast.CANNOT_OPEN_WINDOW:
                return {
                    intent: Intent.WARNING,
                    text: "新しいウィンドウが開けませんでした。",
                };
            case SimpleToast.SCHEDULED_REVISION_EXISTS:
                return {
                    intent: Intent.WARNING,
                    text: "予約公開が存在するため、公開できません。",
                };
            case SimpleToast.COMPLETED:
                return {
                    intent: Intent.SUCCESS,
                    text: "完了しました。",
                };
        }
    }

    @action.bound
    public keepFirstRouteChange() {
        this.isKeepFirstRouteChange = true;
    }
}
