import {action, makeObservable, observable} from "mobx";

import {AdminApi} from "../../../api/api";
import {Stores} from "../../../stores";
import {SimpleToast} from "../../../stores/toast-store";
import {BigIntId} from "../../../utils/big-int-id";
import {PageRouter} from "../../../utils/route-util";

interface UnpublishDocumentStoreOptions {
    adminApi: AdminApi;
    stores: Stores;
    pageRouter: PageRouter;
}

export class UnpublishDocumentStore {
    @observable
    public mediaId: string = "";

    @observable
    public documentId: BigIntId = new BigIntId("0");

    @observable
    public isOpen = false;

    private adminApi: AdminApi;
    private stores: Stores;
    private pageRouter: PageRouter;

    constructor(options: UnpublishDocumentStoreOptions) {
        this.adminApi = options.adminApi;
        this.stores = options.stores;
        this.pageRouter = options.pageRouter;

        makeObservable(this);
    }

    @action.bound
    public async open(mediaId: string, documentId: BigIntId) {
        this.isOpen = true;
        this.documentId = documentId;
        this.mediaId = mediaId;
    }

    @action.bound
    public async close() {
        this.isOpen = false;
    }

    @action.bound
    public async unpublish() {
        await this.adminApi.postDocumentUnpublish({mediaId: this.mediaId}, {id: this.documentId});

        this.stores.toastStore.setSimpleToast(SimpleToast.FINISHED_PUBLISH_SETTING);
        this.stores.toastStore.keepFirstRouteChange();

        this.pageRouter.pushRoute("/admin/[mediaId]/documents/[documentId]", {
            mediaId: this.mediaId,
            documentId: this.documentId.value,
        });
    }
}
