import * as EmailValidator from "email-validator";

import {createValidator} from "../validate";
import {ValidationResultItem} from "../validation-result-item";

export const emailValidator = createValidator((value: string) =>
    EmailValidator.validate(value)
        ? []
        : [new ValidationResultItem("email.pattern", "正しいメールアドレスを入力してください")],
);
