import {Icon} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";

import {BorderedList} from "../../molecules/bordered-list/bordered-list";
import {EmptySafeLink} from "../../molecules/empty-safe-link/empty-safe-link";
import {HeadingLine} from "../../molecules/heading-line/heading-line";
import {PopoverCreateButton} from "../../molecules/popover-create-button/popover-create-button";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {DocumentGroupListPageStore} from "./document-group-list-page-store";

@observer
export class DocumentGroupListPage extends React.Component<
    {
        pageStore: DocumentGroupListPageStore;
    },
    {}
> {
    public render() {
        return (
            <AppLayout
                breadcrumb={
                    <AdminBreadcrumb factory={(builder) => builder.documentGroupList().build()} />
                }
                buttons={
                    <PopoverCreateButton
                        label="ドキュメントグループ追加"
                        placeholder="新しい識別子"
                        closeAfterCreate={false}
                        onCreate={this.props.pageStore.createDocumentGroup}
                    />
                }
            >
                <BorderedList>
                    {this.props.pageStore.items.map((item) => (
                        <HeadingLine
                            key={item.key}
                            icon={<Icon icon="applications" />}
                            primary={
                                <EmptySafeLink
                                    route="/admin/[mediaId]/document-groups/[key]"
                                    params={{
                                        mediaId: this.props.pageStore.mediaId,
                                        key: item.key,
                                    }}
                                >
                                    {item.title}
                                </EmptySafeLink>
                            }
                        />
                    ))}
                </BorderedList>
            </AppLayout>
        );
    }
}
