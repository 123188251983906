import {Button, FormGroup, InputGroup, Intent, Position, Toaster} from "@blueprintjs/core";
import React, {useCallback, useState} from "react";

import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import {AppLayout} from "../../templates/app-layout/app-layout";

const toaster = Toaster.create({
    position: Position.TOP,
});

export const PasswordChangePage: React.FC = () => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handlePasswordChange = useCallback(() => {
        if (newPassword !== confirmPassword) {
            toaster.show({message: "新しいパスワードが一致しません", intent: Intent.DANGER});
            return;
        }
        // パスワード変更のロジックを追加
        toaster.show({message: "パスワードが変更されました", intent: Intent.SUCCESS});
    }, [newPassword, confirmPassword]);

    return (
        <AppLayout
            breadcrumb={<AdminBreadcrumb factory={(builder) => builder.passwordCheng().build()} />}
        >
            <FormGroup label="現在のパスワード" labelFor="current-password">
                <InputGroup
                    id="current-password"
                    placeholder="表示を入力してください"
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                />
            </FormGroup>
            <FormGroup label="新しいパスワード" labelFor="new-password">
                <InputGroup
                    id="new-password"
                    placeholder="新しいパスワードを入力してください"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
            </FormGroup>
            <FormGroup label="新しいパスワード（確認）" labelFor="confirm-password">
                <InputGroup
                    id="confirm-password"
                    placeholder="新しいパスワードを再入力してください"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
            </FormGroup>
            <Button intent="primary" text="変更する" onClick={handlePasswordChange} />
        </AppLayout>
    );
};
