import {Button, Callout, FormGroup, H3, Intent} from "@blueprintjs/core";
import {observer} from "mobx-react";
import React from "react";

import ProgressPanel from "../../molecules/progress-panel/progress-panel";
import {SubmitGroup} from "../../molecules/submit-group/submit-group";
import {ValidatedInputText} from "../../molecules/validated-input-text/validated-input-text";
import {GuestLayout} from "../../templates/guest-layout/guest-layout";
import {PasswordResetPageStore} from "./password-reset-page-store";

export const PasswordResetPage: React.FC<{pageStore: PasswordResetPageStore}> = observer(
    ({pageStore}) => {
        return (
            <GuestLayout>
                <div style={{width: "400px", margin: "100px auto"}}>
                    <div>
                        {pageStore.requestFailed && (
                            <Callout intent={Intent.DANGER}>
                                パスワードの変更に失敗しました。
                            </Callout>
                        )}

                        {pageStore.connectionFailed && (
                            <Callout intent={Intent.DANGER}>
                                サーバーへの接続に失敗しました。
                            </Callout>
                        )}
                    </div>

                    <H3>パスワードリセット</H3>
                    <ProgressPanel inProgress={pageStore.isInProgress}>
                        <SubmitGroup onSubmit={pageStore.tryReset}>
                            <FormGroup label="新しいパスワード" style={{marginBottom: "20px"}}>
                                <ValidatedInputText
                                    type="password"
                                    value={pageStore.newPassword}
                                    validationResult={pageStore.newPasswordValidationResult}
                                    onChangeValue={pageStore.setNewPassword}
                                />
                            </FormGroup>
                            <FormGroup
                                label="新しいパスワードの確認"
                                style={{marginBottom: "20px"}}
                            >
                                {/* TODO: パスワードの不一致を赤文字にしたい */}
                                <ValidatedInputText
                                    type="password"
                                    helperText={
                                        pageStore.isPasswordMatch
                                            ? ""
                                            : "パスワードが一致していません。"
                                    }
                                    value={pageStore.confirmPassword}
                                    validationResult={pageStore.confirmPasswordValidationResult}
                                    onChangeValue={pageStore.setConfirmPassword}
                                />
                            </FormGroup>
                            <Button
                                type="submit"
                                disabled={!pageStore.canTryingReset}
                                fill={true}
                                onClick={pageStore.tryReset}
                            >
                                パスワードを変更する
                            </Button>
                        </SubmitGroup>
                    </ProgressPanel>
                </div>
            </GuestLayout>
        );
    },
);
