import {runInAction} from "mobx";

import {ValidationExamineFunction, Validator} from "./validation-function";
import {ValidationResult} from "./validation-result";
import {ValidationResultItem} from "./validation-result-item";

export function createValidator(...functions: ValidationExamineFunction[]): Validator {
    return async (s) => {
        const items: ValidationResultItem[] = [];
        for (const f of functions) {
            const result = await Promise.resolve(f(s));
            result.forEach((r) => items.push(r));
        }
        const validationResult = new ValidationResult();
        runInAction(() => {
            validationResult.items = items;
        });
        return validationResult;
    };
}
