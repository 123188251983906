import styled from "@emotion/styled";
import * as React from "react";

const BasicContainerDiv = styled.div`
    background-color: #f8f8fa;
    min-height: 100vh;
    overflow: hidden; // stop collapsing margin
`;

export const BasicLayout: React.FC = (props) => (
    <BasicContainerDiv>{props.children}</BasicContainerDiv>
);
