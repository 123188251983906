import {action, makeObservable, observable, runInAction} from "mobx";

import {AdminApi} from "../../../api/api";
import {DocumentGroupListInput} from "../../../api/values/document-group-list-input";
import {DocumentGroupOutput} from "../../../api/values/document-group-output";
import {Stores} from "../../../stores";
import {Permission} from "../../../stores/authentication-store";
import {PageStoreConstructorOptions} from "../../../utils/page-store";
import {PageRouter} from "../../../utils/route-util";

/* eslint-disable max-classes-per-file */
class DocumentGroupItem {
    public key: string = "";

    public title: string = "";

    public constructor(args?: Partial<DocumentGroupItem>) {
        Object.assign(this, args);
    }
}

export class DocumentGroupListPageStore {
    @observable
    public items: DocumentGroupItem[] = [];
    private adminApi: AdminApi;
    private stores: Stores;
    private pageRouter: PageRouter;

    @observable
    public mediaId: string = "";

    constructor(options: PageStoreConstructorOptions) {
        makeObservable(this);

        this.adminApi = options.adminApi;
        this.stores = options.stores;
        this.pageRouter = options.pageRouter;
    }

    public async initialize({query}: any = {}) {
        this.stores.authenticationStore.checkAnyPermissionExists(Permission.EDIT_GENERAL_RESOURCES);

        this.setMediaId(query.mediaId || "");

        await this.load();
    }

    @action.bound
    public setMediaId(s: string) {
        this.mediaId = s;
    }

    @action.bound
    public async load() {
        const result = await this.adminApi.getDocumentGroups(
            new DocumentGroupListInput({
                mediaId: this.mediaId,
            }),
        );

        runInAction(() => {
            this.items = result.documentGroups.map(
                (item) =>
                    new DocumentGroupItem({
                        key: item.key,
                        title: item.title,
                    }),
            );
        });
    }

    @action.bound
    public async createDocumentGroup(key: string) {
        if (this.items.map((item) => item.key).includes(key)) {
            alert(`"${key}" は既に使われています`);
            return;
        }

        await this.adminApi.putDocumentGroup(
            {mediaId: this.mediaId},
            {key},
            new DocumentGroupOutput({
                key,
                slugs: [],
                title: key,
            }),
        );

        this.pageRouter.pushRoute("/admin/[mediaId]/document-groups/[key]", {
            mediaId: this.mediaId,
            key,
        });
    }
}
