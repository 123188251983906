import styled from "@emotion/styled";
import * as React from "react";

interface IBorderedListProps {
    children: React.ReactElement<any> | Array<React.ReactElement<any>>;
}

const BorderedListUl = styled.ul`
    list-style-type: none;
    padding-left: 0;
`;

const BorderedListLi = styled.li`
    margin-bottom: 5px;
    background-color: white;
    border: #ddd solid 1px;
    border-radius: 3px;
`;

// TODO: rename component name: currently this is not bordered
export const BorderedList = (props: IBorderedListProps) => (
    <BorderedListUl>
        {React.Children.map(props.children, (elem) => (
            <BorderedListLi>{elem}</BorderedListLi>
        ))}
    </BorderedListUl>
);
