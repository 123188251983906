import axios from "axios";
import {JsonConvert} from "json2typescript";
import {action, makeObservable, observable, runInAction} from "mobx";

// import moment from "moment";
import {AdminApi} from "../../../api/api";
import {HttpError} from "../../../api/http-error";
import {CategoryGroupImportInput} from "../../../api/values/category-group-import-input";
// import {DocumentGroupInput} from "../../../api/values/document-group-input";
// import {DocumentGroupListInput} from "../../../api/values/document-group-list-input";
// import {DocumentGroupOutput} from "../../../api/values/document-group-output";
// import {
//     DocumentImportInput,
//     DocumentImportRevisionInput,
// } from "../../../api/values/document-import-input";
import {DocumentRevisionGetInput} from "../../../api/values/document-revision-get-input";
import {DocumentRevisionSearchInput} from "../../../api/values/document-revision-search-input";
import {DocumentSearchInput} from "../../../api/values/document-search-input";
import {DocumentType} from "../../../api/values/document-type";
import {UserImportInput} from "../../../api/values/user-import-input";
import {IAquaEditor} from "../../../editor/aqua-editor";
import {Stores} from "../../../stores";
import {Permission} from "../../../stores/authentication-store";
import {SimpleToast} from "../../../stores/toast-store";
import {downloadBlob} from "../../../utils/download-link-util";
import {getServerUrl} from "../../../utils/global-config";
import {getHttpRequestHeaders} from "../../../utils/http-request-headers";
import {PageStoreConstructorOptions} from "../../../utils/page-store";
import {doWithPaging} from "../../../utils/page-util";
// import {renderSourceHtml} from "../../../utils/render-html";
import {saveHtmlCache} from "../../../utils/save-html-cache";
import {withInProgress} from "../../../utils/with-in-progress";
// import {ImportDocument, ImportDocumentList} from "./import-document-list";
// import {ImportGroup, ImportGroupList} from "./import-group-list";

async function readJsonText<T extends object>(blob: Blob, classReference: new () => T): Promise<T> {
    const jsonConvert = new JsonConvert();

    try {
        const text = await blob.text();
        const jsonObj = JSON.parse(text);
        return jsonConvert.deserializeObject(jsonObj, classReference);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Failed to parse JSON: " + e);
        throw e;
    }
}

/* eslint-disable no-console */
export class ImportPageStore {
    @observable
    public mediaId: string = "";

    @observable
    public isInProgress = false;

    @observable
    public progressMax = 0;

    @observable
    public progressCurrent = 0;

    @observable
    public importFreshOnly = false;

    private adminApi: AdminApi;
    private stores: Stores;

    constructor(options: PageStoreConstructorOptions) {
        this.adminApi = options.adminApi;
        this.stores = options.stores;

        makeObservable(this);
    }

    public async initialize() {
        this.stores.authenticationStore.checkAnyPermissionExists(Permission.MANAGE_SYSTEM);
    }

    // @action.bound
    // public async importDocuments(aquaEditor: IAquaEditor, blob: Blob, mediaId: string) {
    //     this.isInProgress = true;
    //     this.resetProgress();
    //     this.mediaId = mediaId;

    //     const baseDate = moment().add(-30, "days");

    //     const obj = await readJsonText(blob, ImportDocumentList);
    //     const progressMax = obj.documents.length;
    //     const documents = obj.documents;
    //     let i = 0;
    //     for (const x of documents) {
    //         console.log(`Start processing '${x.slug}'`);
    //         this.setProgress(i, progressMax);

    //         // TODO: Support slug empty (keep unpublished)
    //         if (!x.slug) {
    //             continue;
    //         }

    //         if (this.importFreshOnly) {
    //             const hasFreshRevision = x.revisions.some(
    //                 (x) => x.property.modifiedAt && moment(x.property.modifiedAt).isAfter(baseDate),
    //             );

    //             if (!hasFreshRevision) {
    //                 continue;
    //             }
    //         }

    //         await this.importDocument(aquaEditor, x);
    //         i++;
    //     }

    //     runInAction(() => {
    //         this.isInProgress = false;
    //     });

    //     console.log("Import documents finished.");
    //     this.stores.toastStore.setSimpleToast(SimpleToast.SAVED);
    // }

    // @action.bound
    // public async importDocument(aquaEditor: IAquaEditor, importDoc: ImportDocument) {
    //     const revisions = [];
    //     for (const importRev of importDoc.revisions) {
    //         const node = JSON.parse(importRev.content);

    //         let renderedHtml;
    //         try {
    //             renderedHtml = await renderSourceHtml(aquaEditor, node);
    //         } catch (e) {
    //             console.error("Failed to parse html: " + e);
    //             return;
    //         }

    //         const revision = new DocumentImportRevisionInput({
    //             applied: importRev.applied,
    //             content: JSON.stringify(node),
    //             contentHtml: renderedHtml,
    //             property: importRev.property,
    //             revisionName: importRev.revisionName,
    //             users: importRev.users,
    //         });

    //         revisions.push(revision);
    //     }

    //     const input = new DocumentImportInput({
    //         documentType: importDoc.documentType,
    //         slug: importDoc.slug,

    //         revisions,
    //     });

    //     await this.adminApi.postImportDocument(input);
    // }

    // @action.bound
    // public async exportDocuments() {
    //     this.downloadExportFile("documents.json", "/api/member/export/documents");
    // }

    // @action.bound
    // public async importGroups(blob: Blob) {
    //     this.resetProgress();

    //     const result = await readJsonText(blob, ImportGroupList);
    //     const progressMax = result.groups.length;
    //     await withInProgress(
    //         (b) => (this.isInProgress = b),
    //         async () => {
    //             let cur = 0;
    //             for (const group of result.groups) {
    //                 this.setProgress(cur, progressMax);

    //                 await this.adminApi.putDocumentGroup(
    //                     {mediaId: this.mediaId},
    //                     {key: group.name},
    //                     new DocumentGroupOutput({
    //                         key: group.name,
    //                         slugs: group.slugs,
    //                         title: group.title,
    //                     }),
    //                 );

    //                 cur++;
    //             }
    //         },
    //     );

    //     this.stores.toastStore.setSimpleToast(SimpleToast.SAVED);
    // }

    // @action.bound
    // public async exportGroups() {
    //     this.resetProgress();

    //     const documentGroupResult = await this.adminApi.getDocumentGroups(
    //         new DocumentGroupListInput({
    //             mediaId: this.mediaId,
    //         }),
    //     );
    //     const progressMax = documentGroupResult.documentGroups.length;
    //     const importGroups: ImportGroup[] = [];

    //     await withInProgress(
    //         (b) => (this.isInProgress = b),
    //         async () => {
    //             let cur = 0;
    //             for (const groupItem of documentGroupResult.documentGroups) {
    //                 this.setProgress(cur, progressMax);

    //                 const group = await this.adminApi.getDocumentGroup(
    //                     {key: groupItem.key},
    //                     new DocumentGroupInput({mediaId: this.mediaId}),
    //                 );

    //                 const importGroup = new ImportGroup({
    //                     name: group.key,
    //                     title: group.title,
    //                     slugs: group.slugs,
    //                 });
    //                 importGroups.push(importGroup);

    //                 cur++;
    //             }
    //         },
    //     );

    //     const resultObject = new ImportGroupList({groups: importGroups});
    //     const blob = new Blob([JSON.stringify(resultObject)], {type: "application/json"});
    //     downloadBlob("document-groups.json", blob);
    // }

    @action.bound
    public async importUsers(blob: Blob) {
        this.resetProgress();

        await withInProgress(
            (b) => (this.isInProgress = b),
            async () => {
                // TODO: JSON読み込み専用のクラスを使う
                const result = await readJsonText(blob, UserImportInput);
                await this.adminApi.postImportUsers({mediaId: this.mediaId}, result);

                runInAction(() => {
                    this.isInProgress = false;
                });

                console.log("Import users finished.");
                this.stores.toastStore.setSimpleToast(SimpleToast.SAVED);
            },
        );
    }

    @action.bound
    public async exportUsers() {
        this.downloadExportFile("users.json", "/api/member/export/users");
    }

    @action.bound
    public async updateHTML(aquaEditor: IAquaEditor, mediaId: string) {
        this.isInProgress = true;
        this.resetProgress();

        let totalCount = 0;
        let cur = 0;
        await doWithPaging(
            new DocumentRevisionSearchInput({
                mediaId: mediaId,
                measureTotal: true,
            }),
            (x) => this.adminApi.getDocumentRevisions(x),
            async (result) => {
                if (result.totalCount) {
                    totalCount = result.totalCount;
                }

                for (const item of result.documentRevisions) {
                    this.setProgress(cur, totalCount);

                    console.log(`Updating ${item.id}`);
                    const revision = await this.adminApi.getDocumentRevision(
                        {id: item.id},
                        new DocumentRevisionGetInput({
                            mediaId: mediaId,
                        }),
                    );
                    const content = await this.adminApi.getDocumentContent(
                        {id: revision.documentContentId},
                        new DocumentRevisionGetInput({
                            mediaId: mediaId,
                        }),
                    );
                    await saveHtmlCache(
                        aquaEditor,
                        revision.id,
                        JSON.parse(content.content),
                        this.adminApi,
                        mediaId,
                    );
                    cur++;
                }
            },
        );

        runInAction(() => {
            this.isInProgress = false;
        });
    }

    @action.bound
    public async updateSearchCache(mediaId: string) {
        this.isInProgress = true;
        this.resetProgress();
        this.setMediaId(mediaId);

        let totalCount = 0;
        let cur = 0;
        await doWithPaging(
            new DocumentSearchInput({
                mediaId: this.mediaId,
                measureTotal: true,
                documentType: DocumentType.ARTICLE,
            }),
            (x) => this.adminApi.getDocuments(x),
            async (result) => {
                if (result.totalCount) {
                    totalCount = result.totalCount;
                }

                for (const item of result.documents) {
                    this.setProgress(cur, totalCount);

                    console.log(`Updating ${item.id}`);
                    await this.adminApi.postDocumentUpdateCache(
                        {mediaId: this.mediaId},
                        {id: item.id},
                    );
                    cur++;
                }
            },
        );

        runInAction(() => {
            this.isInProgress = false;
        });
    }

    @action.bound
    public async importCategoryGroups(mediaId: string, blob: Blob) {
        this.resetProgress();
        this.setMediaId(mediaId);
        await withInProgress(
            (b) => (this.isInProgress = b),
            async () => {
                const result = await readJsonText(blob, CategoryGroupImportInput);
                await this.adminApi.postImportCategoryGroup(
                    {mediaId: this.mediaId},
                    new CategoryGroupImportInput({
                        categoryGroups: result.categoryGroups,
                    }),
                );

                runInAction(() => {
                    this.isInProgress = false;
                });

                console.log("Import categories finished.");
                this.stores.toastStore.setSimpleToast(SimpleToast.SAVED);
            },
        );
    }

    @action.bound
    public async exportCategoryGroups(mediaId: string) {
        this.setMediaId(mediaId);
        this.downloadExportFile(
            "category-grouping.json",
            "/api/v1/admin/member/export/category-groups",
        );
    }

    @action.bound
    public async setMediaId(mediaId: string) {
        this.mediaId = mediaId;
    }

    @action.bound
    private setProgress(cur: number, max: number) {
        this.progressCurrent = cur;
        this.progressMax = max;
    }

    @action.bound
    private resetProgress() {
        this.setProgress(0, 0);
    }

    @action.bound
    public async downloadExportFile(filename: string, path: string) {
        const queryPath = path + "?mediaId=" + this.mediaId;
        try {
            const accessToken = await this.stores.authenticationStore.getOrRefreshToken();
            const headers = getHttpRequestHeaders({accessToken});

            const response = await axios.get(getServerUrl() + queryPath, {
                headers,
                withCredentials: true, // credentials: 'include' 相当の設定
            });

            const blob = new Blob([response.data], {type: "application/json"});
            downloadBlob(filename, blob);
        } catch (error) {
            if (error instanceof HttpError) {
                this.stores.toastStore.setHttpError(error);
            }
            throw error;
        }
    }

    @action.bound
    public setImportFreshOnly(b: boolean) {
        this.importFreshOnly = b;
    }
}
