import {action, computed, makeObservable, observable, runInAction} from "mobx";
import moment from "moment-timezone";

import {AdminApi} from "../../../api/api";
import {DocumentContainsPr} from "../../../api/values/document-contains-pr";
import {DocumentPropertyInputOutput} from "../../../api/values/document-property-input-output";
import {DocumentProtectionStatus} from "../../../api/values/document-protection-status";
import {DocumentPublishScope} from "../../../api/values/document-publish-scope";
import {RelatedResourceType} from "../../../api/values/related-resource-type";
import {
    getCategoryMap,
    ICategoryItem,
    mapRelatedResourcesToCategories,
} from "../../../utils/category-util";

export class PropertyStore {
    @observable
    public protectionStatus: DocumentProtectionStatus = DocumentProtectionStatus.HIDDEN;

    @observable
    public publishScope: DocumentPublishScope = DocumentPublishScope.GET;

    @observable
    public modifiedAt: moment.Moment = moment();

    @observable
    public mediaId: string = "";

    @observable
    public title = "";

    @observable
    public htmlTitle = "";

    @observable
    public htmlDescription: string = "";

    @observable
    public htmlKeywords: string = "";

    @observable
    public htmlRobots: string = "";

    @observable
    public htmlScript: string = "";

    @observable
    public imageUri: string = "";

    @observable
    public imageAuthorityUri: string = "";

    @observable
    public imageAuthorityTitle: string = "";

    @observable
    public personName: string = "";

    @observable
    public personRole: string = "";

    @observable
    public personImageUri: string = "";

    @observable
    public personSlug: string = "";

    @observable
    public containsPr: DocumentContainsPr = DocumentContainsPr.CONTAINS;

    @observable
    public categories: ICategoryItem[] = [];

    private adminApi: AdminApi;

    constructor(options: {adminApi: AdminApi}) {
        this.adminApi = options.adminApi;
        makeObservable(this);
    }

    @computed
    public get categorySlugsJoined() {
        return this.categories.map((x) => x.slug).join(", ");
    }

    @observable
    public relatedDocumentSlugs: string[] = [];

    @computed
    public get relatedDocumentSlugsJoined() {
        return this.relatedDocumentSlugs.join(", ");
    }

    @action.bound
    public async setFromProperty(mediaId: string, property: DocumentPropertyInputOutput) {
        this.mediaId = mediaId;
        this.title = property.title;
        this.htmlTitle = property.htmlTitle;
        this.htmlDescription = property.htmlDescription;
        this.htmlKeywords = property.htmlKeywords;
        this.htmlRobots = property.htmlRobots;
        this.htmlScript = property.htmlScript;
        this.protectionStatus = property.protectionStatus;
        this.publishScope = property.publishScope;
        this.modifiedAt = property.modifiedAt;
        this.imageUri = property.imageUri;
        this.imageAuthorityTitle = property.imageAuthorityTitle;
        this.imageAuthorityUri = property.imageAuthorityUri;
        this.personName = property.personName;
        this.personRole = property.personRole;
        this.personImageUri = property.personImageUri;
        this.personSlug = property.personSlug;
        this.containsPr = property.containsPr;

        const categoryMap = await getCategoryMap(this.adminApi, this.mediaId);
        runInAction(() => {
            this.categories = mapRelatedResourcesToCategories(
                categoryMap,
                property.relatedResources,
            );
            this.relatedDocumentSlugs = property.relatedResources
                .filter((x) => x.resourceType === RelatedResourceType.DOCUMENT)
                .map((x) => x.slug);
        });
    }
}
