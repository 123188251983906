import {Button, FormGroup, InputGroup} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";

import {handleChangeString} from "../../../utils/handle-change";
import {LabeledCard} from "../../molecules/labeled-card/labeled-card";
import ProgressPanel from "../../molecules/progress-panel/progress-panel";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {MigrationPageStore} from "./migration-page-store";

@observer
export class MigrationPage extends React.Component<{pageStore: MigrationPageStore}> {
    public render() {
        return (
            <AppLayout>
                <ProgressPanel inProgress={this.props.pageStore.isInProgress}>
                    <LabeledCard label="プロパティの置換">
                        <div style={{display: "flex"}}>
                            <FormGroup label="対象のプロパティ名" style={{marginRight: "20px"}}>
                                <InputGroup
                                    value={this.props.pageStore.key}
                                    onChange={handleChangeString(this.props.pageStore.setKey)}
                                    style={{width: "15em"}}
                                />
                            </FormGroup>

                            <FormGroup label="検索する文字列" style={{marginRight: "20px"}}>
                                <InputGroup
                                    value={this.props.pageStore.search}
                                    onChange={handleChangeString(this.props.pageStore.setSearch)}
                                    style={{width: "15em"}}
                                />
                            </FormGroup>

                            <FormGroup label="置換後の文字列" style={{marginRight: "20px"}}>
                                <InputGroup
                                    value={this.props.pageStore.replacement}
                                    onChange={handleChangeString(
                                        this.props.pageStore.setReplacement,
                                    )}
                                    style={{width: "15em"}}
                                />
                            </FormGroup>
                        </div>

                        <Button
                            onClick={this.props.pageStore.replacePropertyValue}
                            disabled={!this.props.pageStore.canReplacePropertyValue}
                        >
                            置換
                        </Button>
                    </LabeledCard>
                </ProgressPanel>
            </AppLayout>
        );
    }
}
