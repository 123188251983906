import {makeObservable, observable} from "mobx";

import {DocumentPropertyInputOutput} from "../../../api/values/document-property-input-output";
import {DocumentRevisionUserInputOutput} from "../../../api/values/document-revision-user-input-output";
import {BigIntId} from "../../../utils/big-int-id";

export class QuickEditEntry {
    @observable
    public quickEditId: string = "";

    @observable
    public documentId: BigIntId = new BigIntId("0");

    // TODO: use normal object
    @observable
    public property: DocumentPropertyInputOutput = new DocumentPropertyInputOutput();

    @observable
    public nodeJson: string = "";

    @observable
    public renderedHtml: string = "";

    @observable
    public users: DocumentRevisionUserInputOutput[] = [];

    constructor() {
        makeObservable(this);
    }
}
