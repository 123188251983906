import {JsonObject, JsonProperty} from "json2typescript";
import moment from "moment-timezone";

import {DateTimeConverter, JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("DocumentPreviewUpdateInput")
export class DocumentPreviewUpdateInput {
    @JsonProperty("expiredAt", DateTimeConverter)
    public expiredAt: moment.Moment = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentPreviewUpdateInput>) {
        Object.assign(this, args);
    }
}
