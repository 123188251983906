import {action, computed, makeObservable, observable} from "mobx";

import {AdminApi} from "../../../api/api";
import {DocumentGetInput} from "../../../api/values/document-get-input";
import {DocumentGetOutput} from "../../../api/values/document-get-output";
import {DocumentUpdateInput} from "../../../api/values/document-update-input";
import {BigIntId} from "../../../utils/big-int-id";
import {withInProgress} from "../../../utils/with-in-progress";
import {SlugEditInputStore} from "../slug-edit-input/slug-edit-input-store";

interface DocumentSettingStoreOptions {
    adminApi: AdminApi;
}

export class DocumentSettingStore {
    @observable
    public mediaId: string = "";

    @observable
    public documentId: BigIntId = new BigIntId("0");

    @observable
    public documentRevisionId: BigIntId = new BigIntId("0");

    @observable
    public isOpen = false;

    @observable
    public isSaving = false;

    public slugEditInputStore: SlugEditInputStore;

    private adminApi: AdminApi;

    constructor(options: DocumentSettingStoreOptions, mediaId: string) {
        this.adminApi = options.adminApi;
        this.mediaId = mediaId;
        this.slugEditInputStore = new SlugEditInputStore({adminApi: options.adminApi}, mediaId);

        makeObservable(this);
    }

    @computed
    public get slug() {
        return this.slugEditInputStore.slug;
    }

    @action.bound
    public async open(mediaId: string, documentId: BigIntId) {
        this.isOpen = true;
        await this.loadDocument(mediaId, documentId);
    }

    @action.bound
    public async close() {
        this.isOpen = false;
    }

    @action.bound
    public async loadDocument(mediaId: string, documentId: BigIntId) {
        this.documentId = documentId;

        this.setMediaId(mediaId);

        const document = await this.adminApi.getDocument(
            {id: documentId},
            new DocumentGetInput({mediaId: this.mediaId}),
        );
        await this.setFromDocument(document);
    }

    @action.bound
    public async setFromDocument(document: DocumentGetOutput) {
        this.documentId = document.id;
        await this.slugEditInputStore.setSlug(document.slug || "");
        await this.slugEditInputStore.setMediaId(this.mediaId);
    }

    @computed
    public get canSave() {
        return this.slugEditInputStore.validated;
    }

    @action.bound
    public async save() {
        const slug = this.slugEditInputStore.editingSlug;

        await withInProgress(
            (b) => (this.isSaving = b),
            async () => {
                await this.adminApi.putDocument(
                    {mediaId: this.mediaId},
                    {id: this.documentId},
                    new DocumentUpdateInput({slug}),
                );
                // TODO: レスポンスチェック
            },
        );

        await this.slugEditInputStore.setSlug(slug);
        await this.close();
    }

    @action.bound
    private setDocumentRevisionId(documentRevisionId: BigIntId) {
        this.documentRevisionId = documentRevisionId;
    }

    @action.bound
    public setMediaId(mediaId: string) {
        this.mediaId = mediaId;
    }
}
