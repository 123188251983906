import {HTMLSelect, Spinner} from "@blueprintjs/core";
import * as React from "react";
import {useEffect} from "react";

import {deprecatedCategoryGroup} from "../../../utils/category-util";
import {handleChangeString} from "../../../utils/handle-change";
import {useCategoryCache} from "../category-cache/category-cache";

interface ICategoryGroupSelectorProps {
    slug: string | null;
    emptyOption?: boolean;
    onChange: (slug: string | null) => void;
}

export const CategoryGroupSelector: React.FC<ICategoryGroupSelectorProps> = (props) => {
    const categoryCache = useCategoryCache();
    useEffect(() => {
        categoryCache.load();
    }, [categoryCache]);

    if (!categoryCache.categoryGroups) {
        return <Spinner size={Spinner.SIZE_SMALL} />;
    }

    return (
        <HTMLSelect
            value={props.slug || ""}
            onChange={handleChangeString((s) => props.onChange(s === "" ? null : s))}
        >
            <option value="">指定なし</option>
            {categoryCache.categoryGroups.map((x) => (
                <option key={x.groupSlug} value={x.groupSlug}>
                    {x.groupLabel}
                </option>
            ))}
            <option value={deprecatedCategoryGroup}>(廃止されたカテゴリグループ)</option>
        </HTMLSelect>
    );
};
