import {HTMLSelect, InputGroup} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";

import {DocumentProtectionStatus} from "../../../api/values/document-protection-status";
import {DocumentType} from "../../../api/values/document-type";
import {handleChangeString} from "../../../utils/handle-change";
import {SearchPanel} from "../../molecules/search-panel/search-panel";
import {SearchPanelItem} from "../../molecules/search-panel/search-panel-item";
import {CategoryGroupSelector} from "../../organisms/category-group-selector/category-group-selector";
import {ArchivedFilterType, DocumentListPageStore} from "./document-list-page-store";

// TODO: Create DocumentSearchStore
export const DocumentSearchPanel: React.FC<{pageStore: DocumentListPageStore}> = observer(
    ({pageStore}) => (
        <SearchPanel label="絞り込み" onSearch={pageStore.search}>
            <SearchPanelItem label="タイトル">
                <InputGroup
                    value={pageStore.searchTitle || ""}
                    onChange={handleChangeString((s) => pageStore.setSearchTitle(s))}
                />
            </SearchPanelItem>
            <SearchPanelItem label="本文">
                <InputGroup
                    value={pageStore.searchContentHtml}
                    onChange={handleChangeString((s) => pageStore.setSearchContentHtml(s))}
                />
            </SearchPanelItem>
            <SearchPanelItem label="公開状態">
                <HTMLSelect
                    value={pageStore.searchProtectionStatus}
                    onChange={handleChangeString((s) => pageStore.setSearchProtectionStatus(s))}
                >
                    <option value="">指定なし</option>
                    <option value={DocumentProtectionStatus.VISIBLE}>公開</option>
                    <option value={DocumentProtectionStatus.HIDDEN}>非公開</option>
                </HTMLSelect>
            </SearchPanelItem>
            <SearchPanelItem label="アーカイブ">
                <HTMLSelect
                    value={pageStore.searchArchived}
                    onChange={handleChangeString((s) => pageStore.setSearchArchived(s))}
                >
                    <option value="">未アーカイブのみ</option>
                    <option value={ArchivedFilterType.ALL}>アーカイブを含む</option>
                    <option value={ArchivedFilterType.ONLY}>アーカイブのみ</option>
                </HTMLSelect>
            </SearchPanelItem>
            <>
                {pageStore.documentType === DocumentType.ARTICLE && (
                    <SearchPanelItem label="カテゴリグループ">
                        <CategoryGroupSelector
                            slug={pageStore.searchCategoryGroupSlug || null}
                            onChange={(s) => pageStore.setSearchCategoryGroupSlug(s)}
                        />
                    </SearchPanelItem>
                )}
            </>
        </SearchPanel>
    ),
);
