import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("PasswordResetRequestInput")
export class PasswordResetRequestInput {
    @JsonProperty("email", String, true)
    public email: string = JsonPropertyEmpty;

    @JsonProperty("template", String, true)
    public template: string = JsonPropertyEmpty;

    public constructor(args?: Partial<PasswordResetRequestInput>) {
        Object.assign(this, args);
    }
}
