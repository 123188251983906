import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("DocumentGroupListInput")
export class DocumentGroupListInput {
    @JsonProperty("mediaId", String, true)
    public mediaId?: string = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentGroupListInput>) {
        Object.assign(this, args);
    }
}
