import {Button, FormGroup} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";

import {DocumentType} from "../../../api/values/document-type";
import {useActiveMediaStore} from "../../../stores/active-media-store";
import {ValidatedInputText} from "../../molecules/validated-input-text/validated-input-text";
import {SlugEditInput} from "../slug-edit-input/slug-edit-input";
import {DocumentAddStore} from "./document-add-store";

export const DocumentAddGeneralForm: React.FC<{
    store: DocumentAddStore;
    documentType: DocumentType;
    onCreated: () => Promise<unknown>;
}> = observer(({store, documentType, onCreated}) => {
    const activeMediaId = useActiveMediaStore((state) => state.activeMediaInfo.mediaId);
    return (
        <>
            <FormGroup label="タイトル">
                <ValidatedInputText
                    value={store.title}
                    validationResult={store.titleValidationResult}
                    onChangeValue={(s) => store.setTitle(s)}
                    autoFocus={true}
                />
            </FormGroup>

            <FormGroup label="スラッグ">
                <SlugEditInput store={store.slugEditInputStore} />
            </FormGroup>

            <Button
                disabled={!store.canCreate}
                onClick={() => store.create(documentType, activeMediaId, onCreated)}
            >
                追加
            </Button>
        </>
    );
});
