import {Card} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";

import {DocumentType} from "../../../api/values/document-type";
import {BorderedList} from "../../molecules/bordered-list/bordered-list";
import {Pager} from "../../molecules/pager/pager";
import ProgressPanel from "../../molecules/progress-panel/progress-panel";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {DocumentListButtons} from "./document-list-buttons";
import {DocumentListItemView} from "./document-list-item-view";
import {DocumentListPageStore} from "./document-list-page-store";
import {DocumentSearchPanel} from "./document-search-panel";

export const DocumentListPage: React.FC<{
    pageStore: DocumentListPageStore;
}> = observer(({pageStore}) => {
    const isBlock = pageStore.documentType === DocumentType.BLOCK;

    // TODO: 記事・ブロック以外のドキュメントを作るときにブロックをまとめるか検討する
    return (
        <AppLayout
            breadcrumb={
                <AdminBreadcrumb
                    factory={(builder) =>
                        builder.documentListByType(pageStore.documentType).build()
                    }
                />
            }
            buttons={<DocumentListButtons pageStore={pageStore} />}
        >
            <Card>
                <DocumentSearchPanel pageStore={pageStore} />
            </Card>

            <ProgressPanel inProgress={pageStore.isLoading}>
                <BorderedList>
                    {pageStore.documents.map((x) => (
                        <DocumentListItemView key={x.id.value} documentItem={x} isBlock={isBlock} />
                    ))}
                </BorderedList>
            </ProgressPanel>

            <Pager
                previousExists={pageStore.previousPageExists}
                nextExists={pageStore.nextPageExists}
                onPreviousClicked={pageStore.showPreviousPage}
                onNextClicked={pageStore.showNextPage}
            />
        </AppLayout>
    );
});
