import {IDateFormatProps} from "@blueprintjs/datetime";
import moment from "moment-timezone";

export const localTimeZone = "Asia/Tokyo";

export function toJst(time: moment.Moment): moment.Moment {
    return time.tz(localTimeZone);
}

export function getMomentFormatter(format: string): IDateFormatProps {
    return {
        formatDate: (date) => moment(date).tz(localTimeZone).format(format),
        parseDate: (str) => moment(str, format).tz(localTimeZone).toDate(),
        placeholder: format,
    };
}

export const MomentFormats = {
    date: "YYYY-MM-DD",
    datetimeMinute: "YYYY-MM-DD HH:mm",
};
