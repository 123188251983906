import {NavigateFunction} from "react-router";

export interface IRouteParams {
    [k: string]: string | number;
}

export function convertRouteToUrl(route: string, params?: IRouteParams): string {
    const restParams = new URLSearchParams();

    let replaced = route;
    for (const [key, value] of Object.entries(params || {})) {
        if (value === undefined) {
            continue;
        }
        const replaceKey = `[${key}]`;
        const newReplaced = replaced.replace(replaceKey, value.toString());
        if (replaced === newReplaced) {
            restParams.set(key, value.toString());
        }
        replaced = newReplaced;
    }

    const search = restParams.toString();
    return search ? `${replaced}?${restParams}` : replaced;
}

export class PageRouter {
    private navigate: NavigateFunction;

    constructor(initialNavigate: NavigateFunction) {
        this.navigate = initialNavigate;
    }

    public setNavigate(navigate: NavigateFunction) {
        this.navigate = navigate;
    }

    public pushRoute(route: string, params?: IRouteParams) {
        this.pushOrReplaceRoute(false, route, params);
    }

    public replaceRoute(route: string, params?: IRouteParams) {
        this.pushOrReplaceRoute(true, route, params);
    }

    public replaceRouteFromUrl(url: string) {
        this.navigate(url, {replace: true});
    }

    private pushOrReplaceRoute(isReplace: boolean, route: string, params?: IRouteParams) {
        const url = convertRouteToUrl(route, params);
        const replaceOpts = isReplace ? {replace: true} : {};
        this.navigate(url, replaceOpts);
    }
}
