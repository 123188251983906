import {css} from "@emotion/react";
import {Global} from "@emotion/react";
import * as React from "react";

export const DiffGlobalStyle: React.FC = () => (
    <Global
        styles={css`
            [data-aom-diff-type="ADD"] {
                background-color: #e6ffed;
            }

            [data-aom-diff-type="REMOVE"] {
                background-color: #ffeef0;
            }
        `}
    />
);
