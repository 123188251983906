import * as React from "react";
import {useEffect} from "react";

import {useAuthenticationStore} from "../../../stores/store-hooks";
import {usePageRouter} from "../../page-router-context";

export const LogoutPage: React.FC = () => {
    const authenticationStore = useAuthenticationStore();
    const pageRouter = usePageRouter();

    useEffect(() => {
        authenticationStore.discardToken();
        pageRouter.pushRoute("/admin/login");
    }, [authenticationStore, pageRouter]);

    return null;
};
