import {Callout, Intent} from "@blueprintjs/core";
import React from "react";

import {ResourceForbiddenError} from "../../../errors/resource-forbidden-error";
import {useAuthenticationStore} from "../../../stores/store-hooks";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {BasicLayout} from "../../templates/basic-layout/basic-layout";

export interface ErrorPageProps {
    error: Error;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({error}) => {
    const authenticationStore = useAuthenticationStore();
    const ErrorPageLayout = authenticationStore.isAuthenticated ? AppLayout : BasicLayout;
    const calloutMessage =
        error instanceof ResourceForbiddenError
            ? "リソースが見つかりません。URLあるいは権限を確認してください"
            : "ページの読み込み中にエラーが発生しました。リロードしてください。";
    return (
        <ErrorPageLayout>
            <Callout intent={Intent.DANGER}>{calloutMessage}</Callout>
        </ErrorPageLayout>
    );
};
