import {action, makeObservable, observable} from "mobx";

import {AdminApi} from "../../../api/api";
import {Stores} from "../../../stores";
import {SimpleToast} from "../../../stores/toast-store";
import {BigIntId} from "../../../utils/big-int-id";
import {optimizeDocumentAndForgot} from "../../../utils/document-optimize-util";
import {PageStoreConstructorOptions} from "../../../utils/page-store";
import {PageRouter} from "../../../utils/route-util";

export class DeleteDocumentRevisionStore {
    @observable
    public mediaId: string = "";

    @observable
    public documentRevisionId: BigIntId = new BigIntId("0");

    @observable
    public documentId: BigIntId = new BigIntId("0");

    @observable
    public isOpen = false;

    private adminApi: AdminApi;
    private stores: Stores;
    private pageRouter: PageRouter;

    constructor(options: PageStoreConstructorOptions) {
        this.adminApi = options.adminApi;
        this.stores = options.stores;
        this.pageRouter = options.pageRouter;

        makeObservable(this);
    }

    @action.bound
    public async open(mediaId: string, documentId: BigIntId, documentRevisionId: BigIntId) {
        this.isOpen = true;
        this.documentId = documentId;
        this.documentRevisionId = documentRevisionId;
        this.mediaId = mediaId;
    }

    @action.bound
    public async close() {
        this.isOpen = false;
    }

    @action.bound
    public async delete() {
        await this.adminApi.deleteDocumentRevision(
            {mediaId: this.mediaId},
            {id: this.documentRevisionId},
        );

        optimizeDocumentAndForgot(this.mediaId, this.documentId, this.adminApi);

        this.stores.toastStore.setSimpleToast(SimpleToast.DELETED);
        this.stores.toastStore.keepFirstRouteChange();

        await this.pageRouter.pushRoute("/admin/[mediaId]/documents/[documentId]", {
            mediaId: this.mediaId,
            documentId: this.documentId.value,
        });
    }
}
