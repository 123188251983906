import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("DocumentRevisionUpdateHtmlInput")
export class DocumentRevisionUpdateHtmlInput {
    @JsonProperty()
    public html: string = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentRevisionUpdateHtmlInput>) {
        Object.assign(this, args);
    }
}
