import {DeviceType, NodeObject} from "@syginc/aomjs";
import * as React from "react";
import {useEffect, useRef, useState} from "react";

import {IAquaEditor} from "../../../editor/aqua-editor";

export interface IAomRendererProps {
    node: NodeObject;
    deviceType: DeviceType;
    aquaEditor: IAquaEditor;
}

export const AomRenderer: React.FC<IAomRendererProps> = ({node, deviceType, aquaEditor}) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const divRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const div = divRef.current;
        if (!div) {
            return;
        }
        try {
            aquaEditor.aomRenderer.mountToDomElement(node, {deviceType}, div);
            return () => {
                aquaEditor.aomRenderer.unmountRenderedDomElement(div);
            };
        } catch (e) {
            const error = e as Error;
            setErrorMessage(error.message);
        }
    }, [aquaEditor, deviceType, node]);

    // TODO: throwして上位のコンポーネントでエラーを表示する
    return (
        <>
            {errorMessage && <div style={{color: "red"}}>ERROR: {errorMessage}</div>}
            <div ref={divRef} />
        </>
    );
};
