import {Button, FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import React, {useCallback, useEffect, useReducer} from "react";

import {useStores} from "../../../stores/store-hooks";
import {getAuthInfo} from "../../../utils/auth-util";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import {AppLayout} from "../../templates/app-layout/app-layout";

type State = {
    displayName: string;
    email: string;
    errors: {
        displayName?: string;
        email?: string;
    };
};

type Action =
    | {type: "SET_DISPLAY_NAME"; payload: string}
    | {type: "SET_EMAIL"; payload: string}
    | {type: "SET_ERRORS"; payload: {displayName?: string; email?: string}};

const initialState: State = {
    displayName: "",
    email: "",
    errors: {},
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "SET_DISPLAY_NAME":
            return {...state, displayName: action.payload};
        case "SET_EMAIL":
            return {...state, email: action.payload};
        case "SET_ERRORS":
            return {...state, errors: action.payload};
        default:
            return state;
    }
};

export const ProfilePage: React.FC = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {authenticationStore, toastStore} = useStores();
    const userId = getAuthInfo()?.id;

    useEffect(() => {
        if (userId) {
            // このAPIはPh1.1対象のため、コメントアウトしています。
            // また、APIのエンドポイントが間違っているため、修正が必要です。
            // adminApiClient({authenticationStore, toastStore}).then((client) => {
            //     client
            //         .get(`/api/member/users/${userId}`)
            //         .then((response) => {
            //             dispatch({type: "SET_DISPLAY_NAME", payload: response.data.name});
            //             dispatch({type: "SET_EMAIL", payload: response.data.email});
            //         })
            //         .catch((error) => {
            //             console.error("Error fetching user data", error);
            //         });
            // });
        }
    }, [userId, authenticationStore, toastStore]);

    const handleDisplayNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({type: "SET_DISPLAY_NAME", payload: event.target.value});
    }, []);

    const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({type: "SET_EMAIL", payload: event.target.value});
    }, []);

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = useCallback(() => {
        const newErrors: {displayName?: string; email?: string} = {};

        if (!state.displayName) {
            newErrors.displayName = "表示名を入力してください。";
        }

        if (!state.email) {
            newErrors.email = "メールアドレスを入力してください。";
        } else if (!validateEmail(state.email)) {
            newErrors.email = "有効なメールアドレスを入力してください。";
        }

        dispatch({type: "SET_ERRORS", payload: newErrors});

        if (Object.keys(newErrors).length === 0 && userId) {
            // このAPIはPh1.1対象のため、コメントアウトしています。
            // また、APIのエンドポイントが間違っているため、修正が必要です。
            // adminApiClient({authenticationStore, toastStore}).then((client) => {
            //     client
            //         .patch(`/api/member/users/${userId}`, {
            //             displayName: state.displayName,
            //             email: state.email,
            //         })
            //         .then((response) => {
            //             console.log("User data updated successfully", response.data);
            //         })
            //         .catch((error) => {
            //             console.error("Error updating user data", error);
            //         });
            // });
        }
    }, [state.displayName, state.email, userId, authenticationStore, toastStore]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <AppLayout
            breadcrumb={<AdminBreadcrumb factory={(builder) => builder.profile().build()} />}
        >
            <div>
                <FormGroup
                    label="表示名"
                    labelFor="display-name-input"
                    helperText={state.errors.displayName}
                    intent={state.errors.displayName ? Intent.DANGER : Intent.NONE}
                >
                    <InputGroup
                        id="display-name-input"
                        placeholder="入力フォーム"
                        value={state.displayName}
                        onChange={handleDisplayNameChange}
                        intent={state.errors.displayName ? Intent.DANGER : Intent.NONE}
                    />
                </FormGroup>
                <FormGroup
                    label="メールアドレス"
                    labelFor="email-input"
                    helperText={state.errors.email}
                    intent={state.errors.email ? Intent.DANGER : Intent.NONE}
                >
                    <InputGroup
                        id="email-input"
                        placeholder="入力フォーム"
                        value={state.email}
                        onChange={handleEmailChange}
                        intent={state.errors.email ? Intent.DANGER : Intent.NONE}
                    />
                </FormGroup>
                <Button intent="primary" onClick={handleSubmit}>
                    変更する
                </Button>
            </div>
        </AppLayout>
    );
};
