import {JsonObject, JsonProperty} from "json2typescript";

import {BigIntId} from "../../utils/big-int-id";
import {BigIntIdConverter, JsonPropertyEmpty} from "../../utils/json-util";
import {DocumentRevisionUserInputOutput} from "./document-revision-user-input-output";

@JsonObject("DocumentRevisionAddInput")
export class DocumentRevisionAddInput {
    @JsonProperty("referenceDocumentRevisionId", BigIntIdConverter)
    public referenceDocumentRevisionId: BigIntId = JsonPropertyEmpty;

    @JsonProperty("baseDocumentRevisionId", BigIntIdConverter, true)
    public baseDocumentRevisionId?: BigIntId = undefined;

    @JsonProperty()
    public revisionName: string = JsonPropertyEmpty;

    @JsonProperty("users", [DocumentRevisionUserInputOutput])
    public users: DocumentRevisionUserInputOutput[] = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentRevisionAddInput>) {
        Object.assign(this, args);
    }
}
