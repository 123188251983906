import {HttpError} from "./http-error";

export class ApiError extends HttpError {
    public statusCode: number;
    public statusText: string;
    public code: string;

    constructor(message = "", userMessage = "", statusCode = 0, statusText = "", code = "") {
        super(message, userMessage);
        this.statusCode = statusCode;
        this.statusText = statusText;
        this.code = code;
    }
}
