import {action, computed, makeObservable, observable, runInAction} from "mobx";
import moment from "moment";

import {AdminApi} from "../../../api/api";
import {DocumentPreviewAddInput} from "../../../api/values/document-preview-add-input";
import {DocumentPreviewListGetInput} from "../../../api/values/document-preview-list-get-input";
import {DocumentPreviewUpdateInput} from "../../../api/values/document-preview-update-input";
import {getSitePreviewUrl} from "../../../models/format-site-url";
import {Stores} from "../../../stores";
import {SimpleToast} from "../../../stores/toast-store";
import {BigIntId} from "../../../utils/big-int-id";
import {openNewWindow} from "../../../utils/window-util";
import {withInProgress} from "../../../utils/with-in-progress";

interface PreviewPanelStoreOptions {
    adminApi: AdminApi;
    stores: Stores;
}

export class PreviewPanelStore {
    @observable
    public mediaId: string = "";

    @observable
    public documentRevisionId: BigIntId = new BigIntId("0");

    @observable
    public previewId: BigIntId | null = null;

    @observable
    public expiredAt: moment.Moment | null = null;

    @observable
    public previewKey: string | null = null;

    @observable
    public isOpen: boolean = false;

    @observable
    public isInProgress: boolean = false;

    private adminApi: AdminApi;
    private stores: Stores;

    constructor(options: PreviewPanelStoreOptions) {
        this.adminApi = options.adminApi;
        this.stores = options.stores;

        makeObservable(this);
    }

    @computed
    public get isKeyAvailable(): boolean {
        return !!this.previewKey;
    }

    @computed
    public get canSave() {
        return !!this.expiredAt;
    }

    @action.bound
    public async openOrClose(isOpen: boolean, mediaId: string, documentRevisionId: BigIntId) {
        this.isOpen = isOpen;
        if (isOpen) {
            await withInProgress(
                (b) => (this.isInProgress = b),
                async () => {
                    await this.loadFromDocumentRevision(mediaId, documentRevisionId);
                },
            );
        }
    }

    @action.bound
    public async loadFromDocumentRevision(mediaId: string, documentRevisionId: BigIntId) {
        this.mediaId = mediaId;
        this.documentRevisionId = documentRevisionId;

        const result = await this.adminApi.getDocumentPreviews(
            new DocumentPreviewListGetInput({
                mediaId: this.mediaId,
                documentRevisionId,
            }),
        );
        const previews = result.previews;

        if (previews.length === 0) {
            runInAction(() => {
                this.previewId = null;
                this.expiredAt = moment().add(7, "days").startOf("day");
                this.previewKey = null;
            });
            return;
        }

        const preview = previews[0];
        runInAction(() => {
            this.previewId = preview.id;
            this.expiredAt = preview.expiredAt;
            this.previewKey = preview.previewKey;
        });
    }

    @action.bound
    public setExpiredAt(date: Date) {
        this.expiredAt = moment(date);
    }

    @action.bound
    public async createAndOpenUrl(siteUrl: string) {
        if (!this.isKeyAvailable) {
            await this.save();
        }

        try {
            await openNewWindow(async () => {
                return getSitePreviewUrl(siteUrl, this.previewKey!);
            });
        } catch (e) {
            this.stores.toastStore.setSimpleToast(SimpleToast.CANNOT_OPEN_WINDOW);
        }
    }

    @action.bound
    public async discard() {
        await withInProgress(
            (b) => (this.isInProgress = b),
            async () => {
                await this.adminApi.deleteDocumentPreview(
                    {mediaId: this.mediaId},
                    {id: this.previewId!},
                );
            },
        );
        await this.loadFromDocumentRevision(this.mediaId, this.documentRevisionId);
    }

    @action.bound
    public async save() {
        await withInProgress(
            (b) => (this.isInProgress = b),
            async () => {
                await this.saveInner();
            },
        );
    }

    private async saveInner() {
        if (this.isKeyAvailable) {
            await this.adminApi.updateDocumentPreview(
                {mediaId: this.mediaId},
                {id: this.previewId!},
                new DocumentPreviewUpdateInput({expiredAt: this.expiredAt!}),
            );
        } else {
            await this.adminApi.createDocumentPreview(
                {mediaId: this.mediaId},
                new DocumentPreviewAddInput({
                    documentRevisionId: this.documentRevisionId,
                    expiredAt: this.expiredAt!,
                }),
            );
        }
        await this.loadFromDocumentRevision(this.mediaId, this.documentRevisionId);
    }
}
