import {observer} from "mobx-react";
import * as React from "react";

import {ValidatedInputText} from "../../molecules/validated-input-text/validated-input-text";
import {SlugEditInputStore} from "./slug-edit-input-store";

function slugHelperText(store: SlugEditInputStore) {
    if (store.isSlugConflicted) {
        return "すでに利用されています";
    } else {
        return undefined;
    }
}

@observer
export class SlugEditInput extends React.Component<{store: SlugEditInputStore}> {
    public render() {
        return (
            <ValidatedInputText
                value={this.props.store.editingSlug}
                validationResult={this.props.store.slugValidationResult}
                validationInProgress={this.props.store.isSlugChecking}
                onChangeValue={this.props.store.setEditingSlug}
                helperText={slugHelperText(this.props.store)}
            />
        );
    }
}
