import {JsonObject, JsonProperty} from "json2typescript";

import {BigIntId} from "../../utils/big-int-id";
import {BigIntIdConverter, JsonPropertyEmpty} from "../../utils/json-util";
import {DocumentPropertyInputOutput} from "./document-property-input-output";
import {DocumentRevisionUserInputOutput} from "./document-revision-user-input-output";

@JsonObject("DocumentQuickPublishInput")
export class DocumentQuickPublishInput {
    @JsonProperty("baseDocumentContentId", BigIntIdConverter)
    public baseDocumentContentId: BigIntId = JsonPropertyEmpty;

    @JsonProperty()
    public content: string = JsonPropertyEmpty;

    @JsonProperty()
    public contentHtml: string = JsonPropertyEmpty;

    @JsonProperty()
    public property: DocumentPropertyInputOutput = JsonPropertyEmpty;

    @JsonProperty()
    public users: DocumentRevisionUserInputOutput[] = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentQuickPublishInput>) {
        Object.assign(this, args);
    }
}
