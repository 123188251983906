import {action, computed, makeObservable, observable} from "mobx";

import {AdminApi} from "../../../api/api";
import {DocumentSearchInput} from "../../../api/values/document-search-input";
import {conflictChecker} from "../../../utils/conflict-checker-util";
import {validateLength} from "../../../utils/validation/functions/length";
import {validateSlug} from "../../../utils/validation/functions/slug";
import {createValidator} from "../../../utils/validation/validate";
import {ValidationResultEmpty} from "../../../utils/validation/validation-result";

export class SlugEditInputStore {
    @observable
    public mediaId: string = "";

    @observable
    public slug: string = "";

    @observable
    public editingSlug: string = "";

    @observable
    public slugValidationResult = ValidationResultEmpty;

    @observable
    public isSlugConflicted: boolean = false;

    @observable
    public isSlugChecking: boolean = false;

    private adminApi: AdminApi;

    constructor(options: {adminApi: AdminApi}, mediaId: string) {
        this.adminApi = options.adminApi;
        this.mediaId = mediaId;

        makeObservable(this);
    }

    private checkConflictSlug = conflictChecker(
        (b) => (this.isSlugChecking = b),
        (b) => (this.isSlugConflicted = b),
        async (currentValue) => {
            const input = new DocumentSearchInput();
            input.mediaId = this.mediaId;
            input.slug = currentValue;
            const result = await this.adminApi.getDocuments(input);
            return result.documents.length > 0;
        },
    );

    @computed
    public get validated() {
        return this.slugValidationResult.isEmpty && !this.isSlugConflicted && !this.isSlugChecking;
    }

    private slugValidator = createValidator(validateLength(1, 50), validateSlug());

    @action.bound
    public setMediaId(mediaId: string) {
        this.mediaId = mediaId;
    }

    @action.bound
    public setSlug(s: string) {
        this.slug = s;
        this.editingSlug = s;
    }

    @action.bound
    public async setEditingSlug(s: string) {
        this.editingSlug = s;

        (await this.slugValidator(s)).run((v) => (this.slugValidationResult = v));
        if (this.slugValidationResult.isEmpty) {
            this.checkSlug();
        }
    }

    @action.bound
    private checkSlug() {
        this.checkConflictSlug(this.slug, this.editingSlug);
    }
}
