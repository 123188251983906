import * as React from "react";
import {createContext, useCallback, useContext, useState} from "react";
import {useParams} from "react-router-dom";

import {AdminApi} from "../../../api/api";
import {useAdminApi} from "../../../api/api-context";
import {UserSearchInput} from "../../../api/values/user-search-input";
import {getWithPaging} from "../../../utils/page-util";

export interface IUserItem {
    name: string;
}

export interface UserListCacheContextValue {
    enabledUsers?: IUserItem[];
    load(): void;
}

const UserListCacheContext = createContext<UserListCacheContextValue | undefined>(undefined);

async function getEnabledUsers(
    adminApi: AdminApi,
    mediaId: string | undefined,
): Promise<IUserItem[]> {
    const users = await getWithPaging(
        new UserSearchInput({mediaId: mediaId, enabled: true}),
        (x) => adminApi.getUsers(x),
        (x) => x.users,
    );

    users.sort((a, b) => a.name.localeCompare(b.name));

    return users.map((x) => ({name: x.name}));
}

export const UserListCacheProvider: React.FC = ({children}) => {
    const params = useParams();
    const [enabledUsers, setEnabledUsers] = useState<IUserItem[] | undefined>(undefined);
    const adminApi = useAdminApi();
    const load = useCallback(() => {
        const mediaId = params.mediaId;
        if (enabledUsers) {
            return;
        }

        getEnabledUsers(adminApi, mediaId)
            .then((x) => setEnabledUsers(x))
            .catch((x) => console.error(x));
    }, [enabledUsers, adminApi, params]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <UserListCacheContext.Provider
            value={{
                enabledUsers,
                load,
            }}
        >
            {children}
        </UserListCacheContext.Provider>
    );
};

export function useUserListCache() {
    const value = useContext(UserListCacheContext);
    if (!value) {
        throw new Error("UserListCacheProvider is not provided");
    }
    return value;
}
