export function debounce<T>(
    intervalMs: number,
    func: (obj: T) => void | Promise<void>,
): (obj: T) => void {
    let timer: any;

    function inner(obj: T) {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            func(obj);
            timer = null;
        }, intervalMs);
    }

    return inner;
}
