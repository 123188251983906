import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";
import {DocumentGroupListItemOutput} from "./document-group-list-item-output";

@JsonObject("DocumentGroupListOutput")
export class DocumentGroupListOutput {
    @JsonProperty()
    public documentGroups: DocumentGroupListItemOutput[] = JsonPropertyEmpty;
}
