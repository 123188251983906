import {action, computed, makeObservable, observable, runInAction} from "mobx";

import {ApiError} from "../../../api/api-error";
import {Stores} from "../../../stores";
import {SimpleToast} from "../../../stores/toast-store";
import {passwordReset} from "../../../utils/password-reset-util";
import {PageRouter} from "../../../utils/route-util";
import {passwordValidator} from "../../../utils/validation/rules/password-validator";
import {ValidationResultEmpty} from "../../../utils/validation/validation-result";
import {withInProgress} from "../../../utils/with-in-progress";

interface PasswordResetPageStoreOptions {
    stores: Stores;
    pageRouter: PageRouter;
}

export class PasswordResetPageStore {
    @observable
    public newPassword: string = "";

    @observable
    public newPasswordValidationResult = ValidationResultEmpty;

    @observable
    public confirmPassword: string = "";

    @observable
    public confirmPasswordValidationResult = ValidationResultEmpty;

    @observable
    public token: string = "";

    @observable
    public isInProgress: boolean = false;

    @observable
    public requestFailed: boolean = false;

    @observable
    public connectionFailed: boolean = false;

    private stores: Stores;
    private pageRouter: PageRouter;

    constructor(options: PasswordResetPageStoreOptions) {
        this.stores = options.stores;
        this.pageRouter = options.pageRouter;

        makeObservable(this);
    }

    @computed
    public get canTryingReset() {
        if (
            !this.newPasswordValidationResult.isEmpty ||
            !this.confirmPasswordValidationResult.isEmpty
        ) {
            return false;
        }

        // TODO: textのvalidationに入力必須が欲しい
        if (!this.newPassword && !this.confirmPassword) {
            return false;
        }

        return this.isPasswordMatch;
    }

    @computed
    public get isPasswordMatch() {
        return this.newPassword === this.confirmPassword;
    }

    public async initialize({query}: any = {}) {
        const token = query && query.token;

        if (!token) {
            throw new Error("token is not set");
        }

        runInAction(() => {
            this.token = token;
        });
    }

    @action.bound
    public async setNewPassword(s: string) {
        this.newPassword = s;
        (await passwordValidator(s)).run((v) => (this.newPasswordValidationResult = v));
    }

    @action.bound
    public async setConfirmPassword(s: string) {
        this.confirmPassword = s;
        (await passwordValidator(s)).run((v) => (this.confirmPasswordValidationResult = v));
    }

    @action.bound
    public async tryReset() {
        await withInProgress(
            (b) => (this.isInProgress = b),
            async () => {
                try {
                    await passwordReset(this.token, this.newPassword);
                    runInAction(() => {
                        this.newPassword = "";
                        this.confirmPassword = "";
                        this.token = "";
                    });
                    this.stores.toastStore.setSimpleToast(SimpleToast.SAVED);
                    this.stores.toastStore.keepFirstRouteChange();
                    this.pageRouter.pushRoute("/admin/login");
                } catch (e) {
                    if (e instanceof ApiError && e.statusCode === 400) {
                        runInAction(() => {
                            this.connectionFailed = false;
                            this.requestFailed = true;
                        });
                    } else {
                        runInAction(() => {
                            this.connectionFailed = true;
                            this.requestFailed = false;
                        });
                        // eslint-disable-next-line no-console
                        console.error(e);
                    }
                }
            },
        );
    }
}
