import moment from "moment";

import {MomentFormats} from "../utils/moment-util";

export function formatRevisionNumber(
    revisionNumber: number | null,
    appliedAt: moment.Moment | null,
) {
    return revisionNumber
        ? `R${revisionNumber} (${appliedAt!.format(MomentFormats.datetimeMinute)})`
        : "(未公開)";
}
