import {HttpError} from "./http-error";

export class NetworkFailureError extends HttpError {
    constructor() {
        super(
            "NetworkFailureError",
            "ネットワークエラーが発生しました。通信環境を確認してください。",
        );
    }
}
