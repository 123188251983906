import styled from "@emotion/styled";
import * as React from "react";

interface IBreadcrumbBarProps {
    breadcrumb?: React.ReactNode;
    buttons?: React.ReactNode;
}

const BreadcrumbBarDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const BreadcrumbBarItemDiv = styled.div`
    margin-bottom: 10px;
`;

const BreadcrumbBarBreadcrumbsDiv = styled(BreadcrumbBarItemDiv)``;

const BreadcrumbBarButtonsDiv = styled(BreadcrumbBarItemDiv)`
    margin-left: auto;
    margin-top: 5px; // align text top to breadcrumb
`;

export const BreadcrumbBar = (props: IBreadcrumbBarProps) => (
    <BreadcrumbBarDiv>
        <BreadcrumbBarBreadcrumbsDiv>{props.breadcrumb}</BreadcrumbBarBreadcrumbsDiv>

        <BreadcrumbBarButtonsDiv>{props.buttons && props.buttons}</BreadcrumbBarButtonsDiv>
    </BreadcrumbBarDiv>
);
