import styled from "@emotion/styled";
import {observer} from "mobx-react";
import * as React from "react";
import {useParams} from "react-router-dom";

import {DocumentType} from "../../../api/values/document-type";
import {PopoverButton} from "../../molecules/popover-button/popover-button";
import {DocumentAddDocumentForm} from "./document-add-document-form";
import {DocumentAddGeneralForm} from "./document-add-general-form";
import {DocumentAddStore} from "./document-add-store";

const DocumentAddButtonContentDiv = styled.div`
    padding: 10px;
    width: 300px;
`;

interface IDocumentAddButtonProps {
    mediaId: string;
    store: DocumentAddStore;
    documentType: DocumentType;
    onCreated: () => Promise<unknown>;
}

const getLabel = (documentType: DocumentType) => {
    switch (documentType) {
        case DocumentType.ARTICLE:
            return "ドキュメント追加";
        case DocumentType.BLOCK:
            return "ブロック追加";
        case DocumentType.PERSON:
            return "人物追加";
    }
};

export const DocumentAddButton: React.FC<IDocumentAddButtonProps> = observer((props) => {
    const isArticle = props.documentType === DocumentType.ARTICLE;
    const params = useParams();

    return (
        <PopoverButton
            label={getLabel(props.documentType)}
            isLoading={props.store.isCreating}
            isOpen={props.store.isOpen}
            onInteraction={(nextOpenState) =>
                props.store.onInteraction(nextOpenState, params.mediaId)
            }
        >
            <DocumentAddButtonContentDiv>
                {isArticle ? (
                    <DocumentAddDocumentForm {...props} />
                ) : (
                    <DocumentAddGeneralForm {...props} />
                )}
            </DocumentAddButtonContentDiv>
        </PopoverButton>
    );
});
