import {UL} from "@blueprintjs/core";
import * as React from "react";

import {useAuthenticationStore} from "../../../stores/store-hooks";
import {UserListEditor} from "../user-list-editor/user-list-editor";

interface IDocumentRevisionUsersProps {
    names: string[];
    onChange: (values: string[]) => void;
}

export const DocumentRevisionUsers: React.FC<IDocumentRevisionUsersProps> = ({names, onChange}) => {
    const authenticationStore = useAuthenticationStore();

    if (authenticationStore.hasManageDocumentsPermission) {
        return <UserListEditor names={names} onChange={onChange} />;
    }

    return (
        <UL>
            {names.map((x, i) => (
                <li key={i}>{x}</li>
            ))}
        </UL>
    );
};
