import {JsonObject, JsonProperty} from "json2typescript";

import {BigIntId} from "../../utils/big-int-id";
import {BigIntIdConverter, JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("DocumentQuickPublishOutput")
export class DocumentQuickPublishOutput {
    @JsonProperty("documentId", BigIntIdConverter)
    public documentId: BigIntId = JsonPropertyEmpty;

    @JsonProperty("documentRevisionId", BigIntIdConverter)
    public documentRevisionId: BigIntId = JsonPropertyEmpty;
}
