import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";
import {AomNodeDifference} from "./aom-node-difference";

@JsonObject("AomDiffOutput")
export class AomDiffOutput {
    @JsonProperty()
    public differences: AomNodeDifference[] = JsonPropertyEmpty;
}
