import {action, computed, makeObservable, observable, runInAction} from "mobx";

import {ApiError} from "../../../api/api-error";
import {
    IPageStore,
    IPageStoreInitOptions,
    PageStoreConstructorOptions,
} from "../../../utils/page-store";
import {requestPasswordReset} from "../../../utils/password-reset-util";
import {withInProgress} from "../../../utils/with-in-progress";

export class PasswordResetRequestPageStore implements IPageStore {
    @observable
    public email: string = "";

    @observable
    public isInProgress: boolean = false;

    @observable
    public requestFailed: boolean = false;

    @observable
    public connectionFailed: boolean = false;

    @observable
    public requested: boolean = false;

    constructor(options?: PageStoreConstructorOptions) {
        makeObservable(this);
    }

    @computed
    public get canReset() {
        // TODO: check email format
        return 0 < this.email.length;
    }

    @action.bound
    public setEmail(s: string) {
        this.email = s;
    }

    @action.bound
    public async sendResetRequest() {
        this.connectionFailed = false;
        this.requested = false;

        await withInProgress(
            (b) => (this.isInProgress = b),
            async () => {
                try {
                    await requestPasswordReset(this.email);
                    runInAction(() => {
                        this.requested = true;
                        this.email = "";
                    });
                } catch (e) {
                    if (e instanceof ApiError && e.statusCode === 400) {
                        runInAction(() => {
                            this.requestFailed = true;
                        });
                    } else {
                        runInAction(() => {
                            this.connectionFailed = true;
                        });
                        // eslint-disable-next-line no-console
                        console.error(e);
                    }
                }
            },
        );
    }

    @action.bound
    public async initialize(params: IPageStoreInitOptions): Promise<void> {
        // 初期化ロジックをここに追加
        console.log("Page initialized with params:", params);
    }
}
