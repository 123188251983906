import {action, computed, makeObservable, observable} from "mobx";

import {DocumentPropertyInputOutput} from "../../../api/values/document-property-input-output";
import {validateLength} from "../../../utils/validation/functions/length";
import {createValidator} from "../../../utils/validation/validate";
import {ValidationResultEmpty} from "../../../utils/validation/validation-result";

export class ArticleHtmlPropertiesStore {
    @observable
    public htmlTitle = "";

    @observable
    public htmlTitleValidationResult = ValidationResultEmpty;

    @observable
    public htmlDescription: string = "";

    @observable
    public htmlDescriptionValidationResult = ValidationResultEmpty;

    @observable
    public htmlKeywords: string = "";

    @observable
    public htmlKeywordsValidationResult = ValidationResultEmpty;

    @observable
    public htmlScript: string = "";

    @observable
    public htmlScriptValidationResult = ValidationResultEmpty;

    @observable
    public robotsNoIndex: boolean = false;

    @observable
    public robotsNoFollow: boolean = false;

    @observable
    public isDirty: boolean = false;
    private htmlTitleValidator = createValidator(validateLength(0, 50));

    private htmlDescriptionValidator = createValidator(validateLength(0, 160));

    private htmlKeywordsValidator = createValidator(validateLength(0, 30));

    private htmlScriptValidator = createValidator(validateLength(0, 8192));

    constructor() {
        makeObservable(this);
    }

    @computed
    public get propertyInputFields(): Partial<DocumentPropertyInputOutput> {
        const htmlRobots = [
            this.robotsNoIndex ? "noindex" : null,
            this.robotsNoFollow ? "nofollow" : null,
        ]
            .filter(Boolean)
            .join(",");

        return {
            htmlDescription: this.htmlDescription,
            htmlKeywords: this.htmlKeywords,
            htmlRobots,
            htmlScript: this.htmlScript,
            htmlTitle: this.htmlTitle,
        };
    }

    @action.bound
    public setFromProperty(property: DocumentPropertyInputOutput) {
        const wordSet = new Set<string>();
        property.htmlRobots
            .split(/,\s*/)
            .map((s) => s.toLowerCase())
            .forEach((s) => wordSet.add(s));
        this.robotsNoIndex = wordSet.has("noindex");
        this.robotsNoFollow = wordSet.has("nofollow");

        this.htmlTitle = property.htmlTitle;
        this.htmlKeywords = property.htmlKeywords;
        this.htmlDescription = property.htmlDescription;
        this.htmlScript = property.htmlScript;

        this.isDirty = false;
    }

    @action.bound
    public setNoIndex(b: boolean) {
        this.robotsNoIndex = b;
        this.setDirty();
    }

    @action.bound
    public setNoFollow(b: boolean) {
        this.robotsNoFollow = b;
        this.setDirty();
    }

    @action.bound
    public async setHtmlTitle(text: string) {
        this.htmlTitle = text;
        (await this.htmlTitleValidator(text)).run((v) => (this.htmlTitleValidationResult = v));
        this.setDirty();
    }

    @action.bound
    public async setHtmlDescription(text: string) {
        this.htmlDescription = text;
        (await this.htmlDescriptionValidator(text)).run(
            (v) => (this.htmlDescriptionValidationResult = v),
        );
        this.setDirty();
    }

    @action.bound
    public async setHtmlKeywords(text: string) {
        this.htmlKeywords = text;
        (await this.htmlKeywordsValidator(text)).run(
            (v) => (this.htmlKeywordsValidationResult = v),
        );
        this.setDirty();
    }

    @action.bound
    public async setHtmlScript(text: string) {
        this.htmlScript = text;
        (await this.htmlScriptValidator(text)).run((v) => (this.htmlScriptValidationResult = v));
        this.setDirty();
    }

    @computed
    public get validationsAllEmpty() {
        return (
            this.htmlTitleValidationResult.isEmpty &&
            this.htmlDescriptionValidationResult.isEmpty &&
            this.htmlKeywordsValidationResult.isEmpty
        );
    }

    @action.bound
    public setDirty() {
        this.isDirty = true;
    }
}
