export interface IPageRequest {
    page?: number;
    pageSize?: number;
}

export interface IPageResponse {
    nextPageExists: boolean;
}

export async function doWithPaging<TInput extends IPageRequest, TOutput extends IPageResponse>(
    initialInput: TInput,
    getFunc: (input: TInput) => Promise<TOutput>,
    callback: (output: TOutput) => Promise<any>,
) {
    let nextPageExists = true;
    const input = initialInput;
    for (let page = 1; nextPageExists; page++) {
        // TODO: jsonconvertのせいでimmutableにできない
        input.page = page;
        if (!input.pageSize) {
            input.pageSize = 1000;
        }
        const output = await getFunc(input);
        await callback(output);
        nextPageExists = output.nextPageExists;
    }
}

export async function getWithPaging<TInput extends IPageRequest, TOutput extends IPageResponse, R>(
    initialInput: TInput,
    getFunc: (input: TInput) => Promise<TOutput>,
    collectFunc: (output: TOutput) => R[],
): Promise<R[]> {
    const result: R[] = [];
    await doWithPaging(initialInput, getFunc, async (output) => {
        collectFunc(output).forEach((x) => result.push(x));
    });
    return result;
}
