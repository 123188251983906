import {action, makeObservable} from "mobx";

import {AdminApi} from "../../../api/api";
import {DocumentPropertyInputOutput} from "../../../api/values/document-property-input-output";
import {PropertyStore} from "./property-store";

export class PropertyDiffStore {
    public baseProperty: PropertyStore;
    public property: PropertyStore;

    private adminApi: AdminApi;

    constructor(options: {adminApi: AdminApi}) {
        this.adminApi = options.adminApi;
        this.baseProperty = new PropertyStore({adminApi: this.adminApi});
        this.property = new PropertyStore({adminApi: this.adminApi});
        makeObservable(this);
    }

    @action.bound
    public async setProperties(
        mediaId: string,
        baseProperty: DocumentPropertyInputOutput,
        property: DocumentPropertyInputOutput,
    ) {
        await this.baseProperty.setFromProperty(mediaId, baseProperty);
        await this.property.setFromProperty(mediaId, property);
    }
}
