/* eslint-disable max-len */
import {action, computed, makeObservable, observable} from "mobx";

import {AdminApi} from "../../../api/api";
import {DocumentContentReplacePropertyValueInput} from "../../../api/values/document-content-replace-property-value-input";
import {Stores} from "../../../stores";
import {Permission} from "../../../stores/authentication-store";
import {withInProgress} from "../../../utils/with-in-progress";

/* eslint-enable */

interface MigrationPageStoreOptions {
    adminApi: AdminApi;
    stores: Stores;
}

export class MigrationPageStore {
    @observable
    public isInProgress: boolean = false;

    @observable
    public key: string = "";

    @observable
    public search: string = "";

    @observable
    public replacement: string = "";

    private adminApi: AdminApi;
    private stores: Stores;

    constructor(options: MigrationPageStoreOptions) {
        this.adminApi = options.adminApi;
        this.stores = options.stores;

        makeObservable(this);
    }

    @computed
    public get canReplacePropertyValue() {
        return this.key.length > 0 && this.search.length > 0 && this.replacement.length > 0;
    }

    public async initialize() {
        this.stores.authenticationStore.checkAnyPermissionExists(Permission.MANAGE_SYSTEM);
    }

    @action.bound
    public setKey(s: string) {
        this.key = s.trim();
    }

    @action.bound
    public setSearch(s: string) {
        this.search = s.trim();
    }

    @action.bound
    public setReplacement(s: string) {
        this.replacement = s.trim();
    }

    @action.bound
    public async replacePropertyValue() {
        await withInProgress(
            (b) => (this.isInProgress = b),
            async () => {
                const input = new DocumentContentReplacePropertyValueInput({
                    key: this.key,
                    replacement: this.replacement,
                    search: this.search,
                });

                const output = await this.adminApi.postDocumentContentReplacePropertyValue(input);

                // eslint-disable-next-line no-console
                console.log(output);
            },
        );
    }
}
