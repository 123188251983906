import {Button, FormGroup, HTMLSelect} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";

import {UserRole} from "../../../api/values/user-role-type";
import {SubmitGroup} from "../../molecules/submit-group/submit-group";
import {ValidatedInputText} from "../../molecules/validated-input-text/validated-input-text";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {UserEditPageStore} from "./user-edit-page-store";

function nameHelperText(pageStore: UserEditPageStore) {
    if (pageStore.isNameConflicted) {
        return "すでに利用されています";
    } else {
        return undefined;
    }
}

@observer
export class UserEditPage extends React.Component<
    {
        pageStore: UserEditPageStore;
    },
    {}
> {
    public render() {
        return (
            <AppLayout
                breadcrumb={
                    <AdminBreadcrumb
                        factory={(builder) =>
                            builder
                                .userList()
                                .custom(
                                    this.props.pageStore.isNew
                                        ? "ユーザー追加"
                                        : "ユーザー情報変更",
                                )
                                .build()
                        }
                    />
                }
            >
                <div style={{marginTop: "20px", width: "400px"}}>
                    <SubmitGroup
                        canSubmit={this.props.pageStore.canSave}
                        onSubmit={this.props.pageStore.save}
                    >
                        <ValidatedInputText
                            label="ユーザー名"
                            type="text"
                            value={this.props.pageStore.editingName}
                            validationResult={this.props.pageStore.nameValidationResult}
                            onChangeValue={this.props.pageStore.setName}
                            validationInProgress={this.props.pageStore.isNameValidationInProgress}
                            helperText={nameHelperText(this.props.pageStore)}
                            disabled={!this.props.pageStore.canEdit}
                        />

                        <ValidatedInputText
                            label="メールアドレス"
                            type="text"
                            placeholder="aqua@example.com"
                            value={this.props.pageStore.email}
                            validationResult={this.props.pageStore.emailValidationResult}
                            onChangeValue={this.props.pageStore.setEmail}
                            disabled={!this.props.pageStore.canEdit}
                        />

                        {this.props.pageStore.isNew && (
                            <ValidatedInputText
                                label="パスワード"
                                type="password"
                                value={this.props.pageStore.password}
                                validationResult={this.props.pageStore.passwordValidationResult}
                                onChangeValue={this.props.pageStore.setPassword}
                            />
                        )}

                        <FormGroup label="ロール">
                            <HTMLSelect
                                value={this.props.pageStore.role.name}
                                onChange={(evt) =>
                                    this.props.pageStore.setRole(
                                        UserRole.fromName(evt.target.value) || UserRole.WRITER,
                                    )
                                }
                                disabled={!this.props.pageStore.canEdit}
                            >
                                {this.props.pageStore.availableRoles.map((role) => (
                                    <option key={role.name} value={role.name}>
                                        {role.displayName}
                                    </option>
                                ))}
                            </HTMLSelect>
                        </FormGroup>

                        {!this.props.pageStore.isNew && (
                            <FormGroup label="アカウント状態">
                                <HTMLSelect
                                    value={this.props.pageStore.enabled ? "true" : "false"}
                                    onChange={(evt) =>
                                        this.props.pageStore.setEnabled(evt.target.value === "true")
                                    }
                                    disabled={!this.props.pageStore.canEdit}
                                >
                                    <option value="true">有効</option>
                                    <option value="false">無効</option>
                                </HTMLSelect>
                            </FormGroup>
                        )}

                        <div style={{marginBottom: "20px"}} />

                        <Button
                            type="submit"
                            text={this.props.pageStore.isNew ? "追加" : "変更"}
                            disabled={
                                !this.props.pageStore.canSave || !this.props.pageStore.canEdit
                            }
                            loading={this.props.pageStore.isSavingInProgress}
                            fill={true}
                        />
                    </SubmitGroup>
                </div>
            </AppLayout>
        );
    }
}
