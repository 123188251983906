import {runInAction} from "mobx";

export async function withInProgress<T>(
    setInProgress: (inProgress: boolean) => void,
    func: () => Promise<T>,
) {
    runInAction(() => {
        setInProgress(true);
    });

    try {
        return await func();
    } finally {
        runInAction(() => {
            setInProgress(false);
        });
    }
}
