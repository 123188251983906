import {action, makeObservable, observable, runInAction} from "mobx";

import {AdminApi} from "../../../api/api";
import {DocumentGroupInput} from "../../../api/values/document-group-input";
import {DocumentGroupOutput} from "../../../api/values/document-group-output";
import {Stores} from "../../../stores";
import {Permission} from "../../../stores/authentication-store";
import {SimpleToast} from "../../../stores/toast-store";
import {PageStoreConstructorOptions} from "../../../utils/page-store";

export class DocumentGroupEditPageStore {
    @observable
    public mediaId: string = "";

    @observable
    public key: string = "";

    @observable
    public title: string = "";

    @observable
    public initialTitle: string = "";

    @observable
    public slugs: string[] = [];

    @observable
    public canSave: boolean = false;

    @observable
    public isSaving: boolean = false;

    @observable
    public lackedSlugs: string[] = [];

    private adminApi: AdminApi;
    private stores: Stores;

    constructor(options: PageStoreConstructorOptions) {
        makeObservable(this);

        this.adminApi = options.adminApi;
        this.stores = options.stores;
    }

    public async initialize({query}: any = {}) {
        this.stores.authenticationStore.checkAnyPermissionExists(Permission.EDIT_GENERAL_RESOURCES);

        const key = query && query.key;
        if (!key) {
            throw new Error("key is not set");
        }

        this.setMediaId(query.mediaId || "");

        await this.refresh(key);
    }

    @observable
    public setMediaId(s: string) {
        this.mediaId = s;
    }

    @action.bound
    public setTitle(s: string) {
        this.title = s;
    }

    @action.bound
    public setSlugs(arr: string[]) {
        this.slugs = arr;
    }

    @action.bound
    public async refresh(key: string) {
        this.key = key;
        await this.load();
    }

    @action.bound
    public async saveAndRefresh() {
        this.isSaving = true;
        const slugsBefore = this.slugs.slice(0);

        await this.save();
        await this.load();

        runInAction(() => {
            this.lackedSlugs = slugsBefore.filter((slug) => !this.slugs.includes(slug));
            this.isSaving = false;
        });

        this.stores.toastStore.setSimpleToast(SimpleToast.SAVED);
    }

    private async load() {
        try {
            const output = await this.adminApi.getDocumentGroup(
                {key: this.key},
                new DocumentGroupInput({mediaId: this.mediaId}),
            );
            runInAction(() => {
                this.title = output.title;
                this.initialTitle = output.title;
                this.slugs = output.slugs;
                this.canSave = true;
            });
        } catch (e) {
            // eslint-disable-next-line
            console.error(e);
            alert(e); // TODO: error message
        }
    }

    private async save() {
        try {
            await this.adminApi.putDocumentGroup(
                {mediaId: this.mediaId},
                {key: this.key},
                new DocumentGroupOutput({
                    key: this.key,
                    slugs: this.slugs,
                    title: this.title,
                }),
            );
        } catch (e) {
            // eslint-disable-next-line
            console.error(e);
            alert("保存できませんでした。" + e); // TODO: error message
        }
    }
}
