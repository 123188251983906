import styled from "@emotion/styled";
import * as React from "react";

import {HeadingDetailItemProps} from "./heading-detail-item";

const HeadingDetailListUl = styled.ul`
    list-style-type: none;
    padding-left: 0;
`;

type HeadingDetailListChild = React.ReactElement<HeadingDetailItemProps> | null | false;

interface HeadingDetailListProps {
    children: HeadingDetailListChild | HeadingDetailListChild[];
}

export const HeadingDetailList: React.FC<HeadingDetailListProps> = ({children}) => (
    <HeadingDetailListUl>{children}</HeadingDetailListUl>
);
