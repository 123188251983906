import {Button, Callout, FormGroup, H3, InputGroup, Intent} from "@blueprintjs/core";
import {observer} from "mobx-react";
import React from "react";

import {handleChangeString} from "../../../utils/handle-change";
import ProgressPanel from "../../molecules/progress-panel/progress-panel";
import {SubmitGroup} from "../../molecules/submit-group/submit-group";
import {GuestLayout} from "../../templates/guest-layout/guest-layout";
import {PasswordResetRequestPageStore} from "./password-reset-request-page-store";

export const PasswordResetRequestPage: React.FC<{
    pageStore: PasswordResetRequestPageStore;
}> = observer(({pageStore}) => {
    return (
        <GuestLayout>
            <div style={{width: "400px", margin: "100px auto"}}>
                <div>
                    {pageStore.requested && (
                        <Callout intent={Intent.SUCCESS}>メールを送信しました。</Callout>
                    )}

                    {pageStore.requestFailed && (
                        <Callout intent={Intent.DANGER}>メールの送信に失敗しました。</Callout>
                    )}

                    {pageStore.connectionFailed && (
                        <Callout intent={Intent.DANGER}>サーバーへの接続に失敗しました。</Callout>
                    )}
                </div>

                <H3>パスワードリセット</H3>
                <ProgressPanel inProgress={pageStore.isInProgress}>
                    <SubmitGroup onSubmit={pageStore.sendResetRequest}>
                        <FormGroup label="メールアドレス" style={{marginBottom: "20px"}}>
                            <InputGroup
                                value={pageStore.email}
                                onChange={handleChangeString((s) => pageStore.setEmail(s))}
                            />
                        </FormGroup>

                        <Button
                            type="submit"
                            disabled={!pageStore.canReset}
                            fill={true}
                            onClick={pageStore.sendResetRequest}
                        >
                            リセット
                        </Button>
                    </SubmitGroup>
                </ProgressPanel>
            </div>
        </GuestLayout>
    );
});
