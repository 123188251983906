import {ClassNames} from "@emotion/react";
import * as React from "react";
import {useCallback} from "react";
import {SortableContainer, SortEnd} from "react-sortable-hoc";

import {IListEditorContainerProps, ListEditorContainer} from "./list-editor-container";

export interface IListEditorProps<T> {
    items: Array<IListEditorItem<T>>;
    onChange: (items: Array<IListEditorItem<T>>) => void;
    itemComponentFactory: (item: IListEditorItemProps<T>) => JSX.Element;
    className?: string;
}

export interface IListEditorItemProps<T> {
    item: IListEditorItem<T>;
    order: number;
    onChange: (item: IListEditorItem<T>) => void;
    onRemove: () => void;
}

export interface IListEditorItem<T> {
    key: string;
    data: T | null;
}

function hasData<T>(item: IListEditorItem<T>): boolean {
    return item.data !== null;
}

function updateItems<T>(
    items: Array<IListEditorItem<T>>,
    onChange: (items: Array<IListEditorItem<T>>) => void,
) {
    onChange(items.filter(hasData));
}

export function ListEditor<T>(props: IListEditorProps<T>) {
    const handleChangeItem = useCallback(
        (item: IListEditorItem<T>, index: number) => {
            const arr = props.items.slice(0);
            arr.splice(index, 1, item);
            updateItems(arr, props.onChange);
        },
        [props.items, props.onChange],
    );

    const handleRemoveItem = useCallback(
        (index: number) => {
            const arr = props.items.slice(0);
            arr.splice(index, 1);
            updateItems(arr, props.onChange);
        },
        [props.items, props.onChange],
    );

    const handleSortEnd = useCallback(
        (ev: SortEnd) => {
            const arr = props.items.slice(0);
            const it = arr.splice(ev.oldIndex, 1)[0];
            arr.splice(ev.newIndex, 0, it);
            updateItems(arr, props.onChange);
        },
        [props.items, props.onChange],
    );

    const SortableListEditorContainer =
        SortableContainer<IListEditorContainerProps<T>>(ListEditorContainer);

    return (
        <ClassNames>
            {({css}) => (
                <SortableListEditorContainer
                    items={props.items}
                    itemComponentFactory={props.itemComponentFactory}
                    lockAxis={"y"}
                    distance={10}
                    className={props.className}
                    helperClass={css`
                        border-radius: 6px !important;
                        pointer-events: auto !important;
                        cursor: grabbing !important;
                    `}
                    onChangeItem={handleChangeItem}
                    onRemoveItem={handleRemoveItem}
                    onSortEnd={handleSortEnd}
                />
            )}
        </ClassNames>
    );
}
