import {Intent, Position, Toaster} from "@blueprintjs/core";
import React, {useEffect, useState} from "react";

import {useAdminApi} from "../../../api/api-context";
import {MediaProtectionStatus} from "../../../api/values/media-protection-status";
import {MediaUpdateInput} from "../../../api/values/media-update-input";
import {useActiveMediaStore} from "../../../stores/active-media-store";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import MediaForm from "../../organisms/media-form/media-form";
import {AppLayout} from "../../templates/app-layout/app-layout";

const MediaSettingsPage: React.FC = () => {
    const adminApi = useAdminApi();
    const [mediaName, setMediaName] = useState("");
    const [mediaSummary, setMediaSummary] = useState("");
    const [mediaTitle, setMediaTitle] = useState("");
    const [mediaUrl, setMediaUrl] = useState("");
    const [mediaDescription, setMediaDescription] = useState("");
    const [faviconFileUri, setFaviconFileUri] = useState<string>("");
    const [thumbnailFileUri, setThumbnailFileUri] = useState<string>("");
    const [ogImageFileUri, setOgImageFileUri] = useState<string>("");
    const activeMediaState = useActiveMediaStore((state) => state.activeMediaInfo);

    const fetchGetMediaDetailApi = async () => {
        try {
            // const result = adminApi.getMedia({mediaId: activeMediaState.mediaId}).then((result) => {
            const result = await adminApi.getMedia({mediaId: activeMediaState.mediaId});
            if (result) {
                setMediaName(result.name);
                setMediaSummary(result.summary);
                setMediaTitle(result.title);
                setMediaUrl(result.base_url);
                setMediaDescription(result.description);
                setFaviconFileUri(result.favicon_url);
                setThumbnailFileUri(result.thumbnail_url);
                setOgImageFileUri(result.og_image_url);
            }
            // });
        } catch (e) {
            console.error("メディア情報の取得中にエラーが発生しました:", e);
        }
    };

    const fetchPutMediaDetailApi = async () => {
        try {
            const result = await adminApi.putMediaUpdate(
                {mediaId: activeMediaState.mediaId},
                new MediaUpdateInput({
                    name: mediaName,
                    summary: mediaSummary,
                    title: mediaTitle,
                    base_url: mediaUrl,
                    description: mediaDescription,
                    favicon_url: faviconFileUri,
                    thumbnail_url: thumbnailFileUri,
                    og_image_url: ogImageFileUri,
                    protection_status: MediaProtectionStatus.HIDDEN,
                }),
            );
            if (result) {
                Toaster.create({position: Position.TOP}).show({
                    message: "メディア情報が更新されました",
                    intent: Intent.SUCCESS,
                });
            }
        } catch (e) {
            console.error("メディア情報の更新にエラーが発生しました:", e);
        }
    };

    useEffect(() => {
        fetchGetMediaDetailApi();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <AppLayout
            breadcrumb={<AdminBreadcrumb factory={(builder) => builder.mediaSettings().build()} />}
        >
            <MediaForm
                mediaName={mediaName}
                setMediaName={setMediaName}
                mediaSummary={mediaSummary}
                setMediaSummary={setMediaSummary}
                mediaTitle={mediaTitle}
                setMediaTitle={setMediaTitle}
                mediaUrl={mediaUrl}
                setMediaUrl={setMediaUrl}
                mediaDescription={mediaDescription}
                setMediaDescription={setMediaDescription}
                faviconFileUri={faviconFileUri}
                setFaviconFileUri={setFaviconFileUri}
                thumbnailFileUri={thumbnailFileUri}
                setThumbnailFileUri={setThumbnailFileUri}
                ogImageFileUri={ogImageFileUri}
                setOgImageFileUri={setOgImageFileUri}
                onSubmit={fetchPutMediaDetailApi}
                buttonText="設定を変更する"
            />
        </AppLayout>
    );
};

export default MediaSettingsPage;
