import styled from "@emotion/styled";
import * as React from "react";

import {minWidthLarge} from "../../../const/breakpoints";

export const mediaLarge = `@media (min-width: ${minWidthLarge}px)`;

const DocumentEditColumnsDiv = styled.div`
    margin-top: 20px;

    ${mediaLarge} {
        display: flex;
    }
`;

const DocumentEditLeftColumnDiv = styled.div`
    ${mediaLarge} {
        flex-shrink: 0;
        width: 780px;
        margin-right: 20px;
    }
`;

const DocumentEditRightColumnDiv = styled.div`
    ${mediaLarge} {
        flex-grow: 1;
    }
`;

export const DocumentEditLeftColumn: React.FC = ({children}) => (
    <DocumentEditLeftColumnDiv>{children}</DocumentEditLeftColumnDiv>
);

export const DocumentEditRightColumn: React.FC = ({children}) => (
    <DocumentEditRightColumnDiv>{children}</DocumentEditRightColumnDiv>
);

export const DocumentEditColumns: React.FC<{children: React.ReactElement[]}> = ({children}) => (
    <DocumentEditColumnsDiv>{children}</DocumentEditColumnsDiv>
);
