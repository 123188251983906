import styled from "@emotion/styled";
import * as React from "react";

interface ImportControlListProps {
    children: React.ReactElement | React.ReactElement[];
}

const ImportControlListDiv = styled.div`
    display: flex;
    gap: 30px;
`;

export const ImportControlList: React.FC<ImportControlListProps> = ({children}) => (
    <ImportControlListDiv>{children}</ImportControlListDiv>
);
