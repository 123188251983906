import {AdminApi} from "../api/api";
import {DocumentSearchInput} from "../api/values/document-search-input";
import {DocumentType} from "../api/values/document-type";

export async function getLinkTitle(adminApi: AdminApi, urlString: string): Promise<string> {
    const url = new URL(urlString);
    const pathname = url.pathname;
    const slug = pathname.replace("/article/", "");
    if (pathname !== slug) {
        const docs = await adminApi.getDocuments(
            new DocumentSearchInput({slug, documentType: DocumentType.ARTICLE}),
        );
        if (docs.documents.length > 0) {
            return docs.documents[0].title;
        } else {
            throw new Error("Not found: " + slug);
        }
    }

    throw new Error("Invalid URL: " + urlString);
}
