import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("DocumentGroupOutput")
export class DocumentGroupOutput {
    @JsonProperty()
    public key: string = JsonPropertyEmpty;

    @JsonProperty()
    public title: string = JsonPropertyEmpty;

    @JsonProperty()
    public slugs: string[] = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentGroupOutput>) {
        Object.assign(this, args);
    }
}
