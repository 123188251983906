import {Stores} from "../stores";
import {getServerUrl} from "../utils/global-config";
import {IRequestOptions} from "../utils/http-request";
import {createApiClient} from "./api";
import {AuthError} from "./auth-error";
import {HttpError} from "./http-error";

export function createAdminApi(stores: Stores) {
    async function getRequestOptions(): Promise<IRequestOptions> {
        const accessToken = await stores.authenticationStore.getOrRefreshToken();

        return {
            accessToken,
            baseUrl: getServerUrl(),
        };
    }

    function handleError(error: any) {
        if (error instanceof AuthError) {
            // eslint-disable-next-line no-console
            console.error("AuthError occured");
            stores.authenticationStore.discardToken();
        } else if (error instanceof HttpError) {
            stores.toastStore.setHttpError(error);
        }
    }

    return createApiClient({getRequestOptions, handleError});
}
