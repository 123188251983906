import styled from "@emotion/styled";
import * as React from "react";
import {SortableElement} from "react-sortable-hoc";

import {IListEditorItem, IListEditorItemProps} from "./list-editor";
import {IListEditorItemElementProps, ListEditorItemElement} from "./list-editor-item-element";

const ListEditorUl = styled.ul`
    margin-top: 0;
    padding-left: 0;
`;

export interface IListEditorContainerProps<T> {
    items: Array<IListEditorItem<T>>;
    itemComponentFactory: (item: IListEditorItemProps<T>) => JSX.Element;
    onChangeItem: (item: IListEditorItem<T>, index: number) => void;
    onRemoveItem: (index: number) => void;
    className?: string;
}

export function ListEditorContainer<T>(props: IListEditorContainerProps<T>) {
    const SortableListEditorItemElement =
        SortableElement<IListEditorItemElementProps<T>>(ListEditorItemElement);

    return (
        <ListEditorUl className={props.className}>
            {props.items.map((item, index) => {
                const itemProps: IListEditorItemProps<T> = {
                    item,
                    order: index,
                    onChange: (x) => props.onChangeItem(x, index),
                    onRemove: () => props.onRemoveItem(index),
                };

                return (
                    <SortableListEditorItemElement
                        key={index}
                        index={index}
                        itemProps={itemProps}
                        itemComponentFactory={props.itemComponentFactory}
                    />
                );
            })}
        </ListEditorUl>
    );
}
