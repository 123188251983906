import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("ImportCategory")
export class ImportCategory {
    @JsonProperty()
    public slug: string = JsonPropertyEmpty;

    @JsonProperty()
    public label: string = JsonPropertyEmpty;

    public constructor(args?: Partial<ImportCategory>) {
        Object.assign(this, args);
    }
}

@JsonObject("ImportCategoryGroup")
export class ImportCategoryGroup {
    @JsonProperty()
    public slug: string = JsonPropertyEmpty;

    @JsonProperty()
    public label: string = JsonPropertyEmpty;

    @JsonProperty("categories", [])
    public categories: ImportCategory[] = JsonPropertyEmpty;

    public constructor(args?: Partial<ImportCategoryGroup>) {
        Object.assign(this, args);
    }
}

@JsonObject("CategoryGroupImportInput")
export class CategoryGroupImportInput {
    @JsonProperty()
    public categoryGroups: ImportCategoryGroup[] = JsonPropertyEmpty;

    public constructor(args?: Partial<CategoryGroupImportInput>) {
        Object.assign(this, args);
    }
}
