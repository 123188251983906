import {FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import React from "react";

interface FormInputGroupProps {
    label: React.ReactNode;
    labelFor: string;
    placeholder: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    helperText?: string;
    intent?: Intent;
    type?: string;
}

const FormInputGroup: React.FC<FormInputGroupProps> = ({
    label,
    labelFor,
    placeholder,
    value,
    onChange,
    onBlur,
    helperText,
    intent,
    type = "text",
}) => {
    return (
        <FormGroup
            className=".bp5-large"
            label={label}
            labelFor={labelFor}
            helperText={helperText}
            intent={intent}
        >
            <InputGroup
                style={{maxWidth: "600px"}}
                id={labelFor}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                intent={intent}
                type={type}
            />
        </FormGroup>
    );
};

export default FormInputGroup;
