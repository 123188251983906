import NProgress from "nprogress";
import * as React from "react";
import {useEffect, useState} from "react";

import {useAdminApi} from "../../../api/api-context";
import {useStores} from "../../../stores/store-hooks";
import {IPageStore} from "../../../utils/page-store";
import {usePageChangingContext} from "../../organisms/page-changing-context/page-changing-context";
import {usePageRouter} from "../../page-router-context";
import {ErrorPage} from "../../pages/error-page/error-page";
import {BasicLayout} from "../../templates/basic-layout/basic-layout";
import {PageScopeCacheProvider} from "./page-scope-cache-provider";
import {calculateNextPageState, PageConfig, PageState} from "./page-state";

export interface PageInitializerProps<T extends IPageStore> {
    pageConfig: PageConfig<T>;
}

export function PageInitializer<T extends IPageStore>(props: PageInitializerProps<T>) {
    const {pageConfig} = props;
    const [pageState, setPageState] = useState<PageState<T> | null>(null);
    const stores = useStores();
    const adminApi = useAdminApi();
    const pageRouter = usePageRouter();
    const pageChangingContext = usePageChangingContext();

    useEffect(() => {
        pageChangingContext.notify();

        NProgress.configure({showSpinner: false});
        NProgress.start();
        calculateNextPageState(pageConfig, stores, adminApi, pageRouter).then((s) => {
            setPageState(s);
            NProgress.done();
            window.scrollTo(0, 0);
        });
    }, [adminApi, pageChangingContext, pageConfig, pageRouter, stores]);

    if (!pageState) {
        return <BasicLayout />;
    }

    if (pageState.initializeError) {
        return <ErrorPage error={pageState.initializeError} />;
    }

    return (
        <PageScopeCacheProvider>
            <pageState.component pageStore={pageState.pageStore} />
        </PageScopeCacheProvider>
    );
}
