import * as React from "react";

import {TextListEditor} from "../../molecules/text-list-editor/text-list-editor";

interface ISlugListEditorProps {
    slugs: string[];
    onChange: (values: string[]) => void;
}

export class SlugListEditor extends React.Component<ISlugListEditorProps> {
    public render() {
        return (
            <TextListEditor
                values={this.props.slugs}
                onChange={this.props.onChange}
                maxLength={50}
                placeholder="スラッグ"
            />
        );
    }
}
