import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";
import {MediaProtectionStatus} from "./media-protection-status";

@JsonObject("MediaCreateInput")
export class MediaCreateInput {
    @JsonProperty("name", String)
    public name: string = JsonPropertyEmpty;

    @JsonProperty("summary", String)
    public summary: string = JsonPropertyEmpty;

    @JsonProperty("base_url", String)
    public base_url: string = JsonPropertyEmpty;

    @JsonProperty("title", String)
    public title: string = JsonPropertyEmpty;

    @JsonProperty("description", String)
    public description: string = JsonPropertyEmpty;

    @JsonProperty("og_image_url", String)
    public og_image_url: string = JsonPropertyEmpty;

    @JsonProperty("favicon_url", String)
    public favicon_url: string = JsonPropertyEmpty;

    @JsonProperty("thumbnail_url", String)
    public thumbnail_url: string = JsonPropertyEmpty;

    @JsonProperty("protection_status", String)
    public protection_status: MediaProtectionStatus = JsonPropertyEmpty;

    public constructor(args?: Partial<MediaCreateInput>) {
        Object.assign(this, args);
    }
}
