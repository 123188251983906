import {DocumentPublishScope} from "../api/values/document-publish-scope";

export function formatPublishScope(scope: DocumentPublishScope): string {
    switch (scope) {
        case DocumentPublishScope.LIST:
            return "全体";
        case DocumentPublishScope.GET:
            return "ドキュメントのみ";
    }
}
