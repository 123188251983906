import {DateInput, IDateInputProps} from "@blueprintjs/datetime";
import * as React from "react";

import {getMomentFormatter, MomentFormats} from "../../../utils/moment-util";

export class MomentDateInput extends React.Component<
    Omit<IDateInputProps, "formatDate" | "parseDate">
> {
    public render() {
        return (
            <>
                <DateInput
                    {...getMomentFormatter(MomentFormats.datetimeMinute)}
                    reverseMonthAndYearMenus={true}
                    maxDate={new Date(Date.now() + 1000 * 60 * 60 * 24 * 365)}
                    {...this.props}
                />
            </>
        );
    }
}
