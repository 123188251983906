import {Button, Colors, Menu, MenuItem, Spinner} from "@blueprintjs/core";
import {
    IItemListRendererProps,
    IItemRendererProps,
    ItemListPredicate,
    ItemListRenderer,
    ItemRenderer,
    Select,
} from "@blueprintjs/select";
import {ClassNames, css} from "@emotion/react";
import styled from "@emotion/styled";
import * as React from "react";
import {useEffect} from "react";

import {ICategoryItem} from "../../../utils/category-util";
import {useCategoryCache} from "../category-cache/category-cache";

type LooseCategoryItem = ICategoryItem | null;

function createKeyRaw(groupKey?: string, itemKey?: string) {
    return `${groupKey || ""}:${itemKey || ""}`;
}

function createKey(item: LooseCategoryItem) {
    return createKeyRaw(item ? item.groupSlug : undefined, item ? item.slug : undefined);
}

const CategorySelect = Select.ofType<LooseCategoryItem>();

function createItemListPredicate(emptyOption?: boolean): ItemListPredicate<LooseCategoryItem> {
    return (query: string, items: LooseCategoryItem[]) => {
        const arr: LooseCategoryItem[] = [];

        if (emptyOption && !query) {
            arr.push(null);
        }

        return arr.concat(
            items.filter(
                (item) =>
                    !query ||
                    (item && item.label.indexOf(query) >= 0) ||
                    (item && item.slug.indexOf(query) >= 0),
            ),
        );
    };
}

const CategorySelectorListMenu = styled(Menu)`
    max-height: 50vh;
    max-width: 330px;
    overflow: auto;
`;

const itemListRenderer: ItemListRenderer<LooseCategoryItem> = (
    props: IItemListRendererProps<LooseCategoryItem>,
) => {
    const elements: React.ReactNode[] = [];

    let previousItem: LooseCategoryItem | undefined;
    let index = 0;
    for (const item of props.filteredItems) {
        if (!item) {
            elements.push(props.renderItem(item, index++));
            previousItem = undefined;
            continue;
        }

        if (!previousItem || previousItem.groupSlug !== item.groupSlug) {
            elements.push(renderGroupItem(createKeyRaw(item.groupSlug), item.groupLabel));
            index++;
        }

        elements.push(props.renderItem(item, index++));
        previousItem = item;
    }

    if (!elements.length) {
        elements.push(renderNonIdeal());
    }

    return (
        <CategorySelectorListMenu ulRef={props.itemsParentRef}>{elements}</CategorySelectorListMenu>
    );
};

function createItemRenderer(placeholder?: string): ItemRenderer<LooseCategoryItem> {
    return (item, props) => (item ? renderItem(item, props) : renderEmptyItem(props, placeholder));
}

function renderGroupItem(key: string, label: string) {
    return (
        <MenuItem
            disabled={true}
            key={key}
            text={<strong style={{color: Colors.BLACK}}>{label}</strong>}
        />
    );
}

function renderEmptyItem(props: IItemRendererProps, placeholder?: string) {
    return (
        <MenuItem
            active={props.modifiers.active}
            key={createKey(null)}
            text={renderItemContent(null, placeholder)}
            onClick={props.handleClick}
        />
    );
}

function renderItem(item: ICategoryItem, props: IItemRendererProps) {
    return (
        <MenuItem
            active={props.modifiers.active}
            key={createKey(item)}
            text={
                <>
                    <span style={{marginLeft: "1em"}} />
                    {renderItemContent(item)}
                </>
            }
            onClick={props.handleClick}
        />
    );
}

function renderNonIdeal() {
    return <MenuItem disabled={true} key={createKey(null)} text={"No results."} />;
}

const CategoryGroupSpan = styled.span<{isBold?: boolean; isDepretated?: boolean}>`
    ${({isBold}) =>
        isBold &&
        css`
            font-weight: bold;
        `};
    ${({isDepretated}) =>
        isDepretated &&
        css`
            color: ${Colors.RED3};
        `};
`;

const CategoryGroupSlugSpan = styled.span<{isDeprecated?: boolean}>`
    margin-left: 0.2em;
    color: ${Colors.GRAY3};
    ${({isDeprecated}) =>
        isDeprecated &&
        css`
            color: ${Colors.RED3};
        `}
`;

function renderItemContent(
    item: LooseCategoryItem,
    placeholder?: string,
    isBold?: boolean,
    isDeprecated?: boolean,
) {
    return item ? (
        <CategoryGroupSpan isBold={isBold} isDepretated={isDeprecated}>
            {item.label}
            <CategoryGroupSlugSpan isDeprecated={isDeprecated}>({item.slug})</CategoryGroupSlugSpan>
        </CategoryGroupSpan>
    ) : (
        <span style={{color: Colors.GRAY3}}>{placeholder || "未選択"}</span>
    );
}

function generateItems(value: string | null, categories: ICategoryItem[], isDeprecated?: boolean) {
    const items = [...categories];

    if (value && isDeprecated) {
        items.push({
            groupLabel: "廃止されたグループ",
            groupSlug: "",
            label: "廃止されたカテゴリ",
            slug: value,
        });
    }

    return items;
}

interface ICategorySelectorProps {
    value: string | null;
    emptyOption?: boolean;
    placeholder?: string;
    onChange: (item: LooseCategoryItem) => void;
    isBold?: boolean;
}

export const CategorySelector: React.FC<ICategorySelectorProps> = (props) => {
    const categoryCache = useCategoryCache();

    useEffect(() => {
        categoryCache.load();
    }, [categoryCache]);

    if (!categoryCache.categories) {
        return <Spinner size={Spinner.SIZE_SMALL} />;
    }

    const handleChange = (item: LooseCategoryItem) => {
        props.onChange(item);
    };

    const isDeprecated =
        props.value !== null &&
        categoryCache.categories.find((x) => x.slug === props.value) === undefined;

    const items = generateItems(props.value, categoryCache.categories, isDeprecated);

    const selectedItem = items.find((x) => x.slug === props.value) || null;

    return (
        <ClassNames>
            {({css}) => (
                <CategorySelect
                    items={items}
                    itemListPredicate={createItemListPredicate(props.emptyOption)}
                    itemRenderer={createItemRenderer(props.placeholder)}
                    itemListRenderer={itemListRenderer}
                    onItemSelect={handleChange}
                    popoverProps={{
                        modifiers: {arrow: {enabled: false}},
                        popoverClassName: css`
                            padding: 5px;
                            background-color: white;
                        `,
                    }}
                    resetOnClose={true}
                    resetOnSelect={true}
                    resetOnQuery={false}
                >
                    <Button rightIcon="caret-down">
                        {renderItemContent(
                            selectedItem,
                            props.placeholder,
                            props.isBold,
                            isDeprecated,
                        )}
                    </Button>
                </CategorySelect>
            )}
        </ClassNames>
    );
};
