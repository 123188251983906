import {IAquaAdminClient, IAquaEditorConfiguration, ISiteInfo} from "@syginc/aqua-client";
import {Imagefront} from "@syginc/imagefront-client";

import {createImagefrontOption, ImagefrontTokenProvider} from "../utils/imagefront-util";
import {IAquaEditor} from "./aqua-editor";

export async function createAquaEditor(
    siteInfo: ISiteInfo,
    aquaAdminClient: IAquaAdminClient,
    aquaEditorConfig: IAquaEditorConfiguration,
    imagefrontTokenProvider: ImagefrontTokenProvider,
): Promise<IAquaEditor> {
    const {mountContentEditor, aomRenderer} = await aquaEditorConfig.setupEditor({
        aquaAdminClient,
        siteInfo,
    });

    return {
        siteUrl: siteInfo.siteUrl,
        cssUrl: siteInfo.env.editorCssUrl,
        getContentContainerClassName: aquaEditorConfig.getContentContainerClassName,
        getImagefrontClient: () =>
            new Imagefront(
                createImagefrontOption(
                    aquaEditorConfig.buildImageFilename,
                    imagefrontTokenProvider,
                ),
            ),
        mountContentEditor,
        aomRenderer,
    };
}
