import * as React from "react";
import {useContext} from "react";

import {useStores} from "../stores/store-hooks";
import {AdminApi} from "./api";
import {createAdminApi} from "./api-client";

export interface AdminApiContextValues {
    adminApi: AdminApi;
}

export const AdminApiContext = React.createContext<AdminApiContextValues | undefined>(undefined);

export const AdminApiContextProvider: React.FC = ({children}) => {
    const stores = useStores();
    const adminApi = createAdminApi(stores);

    return <AdminApiContext.Provider value={{adminApi}}>{children}</AdminApiContext.Provider>;
};

export function useAdminApi() {
    const value = useContext(AdminApiContext);
    if (!value) {
        throw new Error("AdminApiContext is not provided");
    }
    return value.adminApi;
}
