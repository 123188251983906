import moment from "moment";

import {ApiError} from "../api/api-error";
import {AuthError} from "../api/auth-error";
import {EmptyOutput} from "../api/values/empty-output";
import {TokenGrantType, TokenInput} from "../api/values/token-input";
import {TokenOutput} from "../api/values/token-output";
import {TokenRevokeInput} from "../api/values/token-revoke-input";
import {getServerUrl} from "./global-config";
import {requestObject} from "./http-request";

export interface IAuthInfo {
    username: string;
    accessToken: string;
    refreshToken: string;
    expiredAt: number;
    scope: string;
    id: string;
}

const authInfoKey = "aquaAuth";

export function getLoginUrl(location_: {pathname: string; search: string}) {
    return `/admin/login?url=${encodeURIComponent(location.pathname + location.search)}`;
}

export function getAuthInfo(): IAuthInfo | null {
    return JSON.parse(localStorage.getItem(authInfoKey) || "null");
}

export function setAuthInfo(authInfo: IAuthInfo | null) {
    localStorage.setItem(authInfoKey, JSON.stringify(authInfo));
}

export function removeAuthInfo() {
    localStorage.removeItem(authInfoKey);
}

export async function revokeRefreshToken() {
    const authInfo = getAuthInfo();
    if (authInfo === null) {
        return;
    }
    await requestObject(
        "POST",
        "/auth/token/revoke",
        EmptyOutput,
        undefined,
        new TokenRevokeInput({username: authInfo.username, refreshToken: authInfo.refreshToken}),
        {baseUrl: getServerUrl()},
    );
}

export async function tryAuthenticate(username: string, password: string): Promise<IAuthInfo> {
    return translateAuthError(async () => {
        const output = await requestObject(
            "POST",
            "/auth/token",
            TokenOutput,
            undefined,
            new TokenInput({grantType: TokenGrantType.Password, username, password}),
            {baseUrl: getServerUrl()},
        );

        return convertToken(username, output);
    });
}

export async function tryRefreshToken(authInfo: IAuthInfo): Promise<IAuthInfo> {
    return translateAuthError(async () => {
        const {username, refreshToken} = authInfo;

        const output = await requestObject(
            "POST",
            "/auth/token",
            TokenOutput,
            undefined,
            new TokenInput({grantType: TokenGrantType.RefreshToken, refreshToken}),
            {baseUrl: getServerUrl()},
        );

        return convertToken(username, output);
    });
}

function convertToken(username: string, tokenOutput: TokenOutput): IAuthInfo {
    return {
        accessToken: tokenOutput.accessToken,
        expiredAt: moment()
            .add(parseInt(tokenOutput.expiresIn, 10) / 2, "seconds")
            .valueOf(),
        refreshToken: tokenOutput.refreshToken,
        scope: tokenOutput.scope,
        username,
        id: tokenOutput.id,
    };
}

async function translateAuthError<T>(action: () => Promise<T>) {
    try {
        return await action();
    } catch (e) {
        if (e instanceof ApiError && e.statusCode === 400) {
            throw new AuthError(e.statusCode, e.statusText);
        } else {
            throw e;
        }
    }
}
