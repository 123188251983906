import {Checkbox, FormGroup} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";

import {handleChangeBoolean} from "../../../utils/handle-change";
import {LabeledCard} from "../../molecules/labeled-card/labeled-card";
import {ValidatedInputText} from "../../molecules/validated-input-text/validated-input-text";
import {ValidatedTextArea} from "../../molecules/validated-text-area/validated-text-area";
import {ArticleHtmlPropertiesStore} from "./article-html-properties-store";

@observer
export class ArticleHtmlProperties extends React.Component<{store: ArticleHtmlPropertiesStore}> {
    public render() {
        return (
            <LabeledCard label="HTML">
                <ValidatedInputText
                    label={`<title> (SEOタイトル)`}
                    helperText={
                        <div>
                            空白の時はドキュメントのタイトルになります
                            <div style={{float: "right"}}>
                                {this.props.store.htmlTitle.length}文字
                            </div>
                        </div>
                    }
                    value={this.props.store.htmlTitle}
                    validationResult={this.props.store.htmlTitleValidationResult}
                    onChangeValue={this.props.store.setHtmlTitle}
                />

                <ValidatedTextArea
                    label={`<meta name="description"> (SEOディスクリプション)`}
                    value={this.props.store.htmlDescription}
                    validationResult={this.props.store.htmlDescriptionValidationResult}
                    onChangeValue={this.props.store.setHtmlDescription}
                />

                <ValidatedInputText
                    label={`<meta name="keywords"> (SEOキーワード)`}
                    value={this.props.store.htmlKeywords}
                    validationResult={this.props.store.htmlKeywordsValidationResult}
                    onChangeValue={this.props.store.setHtmlKeywords}
                />

                <FormGroup label={`<meta name="robots">`}>
                    <Checkbox
                        checked={this.props.store.robotsNoIndex}
                        label="noindex"
                        inline={true}
                        onChange={handleChangeBoolean(this.props.store.setNoIndex)}
                    />
                    <Checkbox
                        checked={this.props.store.robotsNoFollow}
                        label="nofollow"
                        inline={true}
                        onChange={handleChangeBoolean(this.props.store.setNoFollow)}
                    />
                </FormGroup>

                <ValidatedTextArea
                    label="スクリプト"
                    value={this.props.store.htmlScript}
                    validationResult={this.props.store.htmlScriptValidationResult}
                    onChangeValue={this.props.store.setHtmlScript}
                    rows={5}
                />
            </LabeledCard>
        );
    }
}
