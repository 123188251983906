import * as React from "react";
import {useLocation, useParams} from "react-router-dom";

import {IPageStore, PageStoreConstructor} from "../utils/page-store";
import {PageInitializer} from "./molecules/page-initializer/page-initializer";
import {PageConfig} from "./molecules/page-initializer/page-state";
import {Authenticated} from "./organisms/authenticated/authenticated";

interface RenderPageProps<T extends IPageStore> {
    component: React.ComponentType<{pageStore: T}>;
    pageStore: PageStoreConstructor<T>;
    requireAuth?: boolean;
}

export function RenderPage<T extends IPageStore>({
    component,
    pageStore,
    requireAuth,
}: RenderPageProps<T>) {
    const params = useParams();
    const location = useLocation();
    const queryParams = Object.fromEntries(new URLSearchParams(location.search).entries());
    const query = {...queryParams, ...params};

    // create a different object per render
    const pageConfig: PageConfig<T> = {
        component,
        pageStoreClass: pageStore,
        query,
    };

    return (
        <Authenticated requireAuth={requireAuth ?? true}>
            <PageInitializer pageConfig={pageConfig} />
        </Authenticated>
    );
}
