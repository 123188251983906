import {IImagefrontOption} from "@syginc/imagefront-client";

import {getImagefrontUrl} from "./global-config";

export type ImagefrontTokenProvider = () => Promise<string>;

export function createImagefrontOption(
    buildImageFilename: (file: File) => string,
    tokenProvider: ImagefrontTokenProvider,
): IImagefrontOption {
    return {
        basePath: getImagefrontUrl(),
        fetch: (input: RequestInfo | URL, init?: RequestInit) => fetch(input, init),
        tokenProvider,
        buildImageFilename,
    };
}
