import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";
import {DocumentType} from "./document-type";

@JsonObject("DocumentSearchInput")
export class DocumentSearchInput {
    @JsonProperty("mediaId", String, true)
    public mediaId?: string = JsonPropertyEmpty;

    @JsonProperty("documentType", String, true)
    public documentType?: DocumentType = JsonPropertyEmpty;

    @JsonProperty("title", String, true)
    public title?: string = JsonPropertyEmpty;

    @JsonProperty("contentHtml", String, true)
    public contentHtml?: string = JsonPropertyEmpty;

    @JsonProperty("categoryGroup", String, true)
    public categoryGroup?: string = JsonPropertyEmpty;

    @JsonProperty("deprecatedCategory", Boolean, true)
    public deprecatedCategory?: boolean = JsonPropertyEmpty;

    @JsonProperty("slug", String, true)
    public slug?: string = JsonPropertyEmpty;

    @JsonProperty("protectionStatus", String, true)
    public protectionStatus?: string = JsonPropertyEmpty;

    @JsonProperty("archived", Boolean, true)
    public archived?: boolean = JsonPropertyEmpty;

    @JsonProperty("page", Number, true)
    public page?: number = JsonPropertyEmpty;

    @JsonProperty("pageSize", Number, true)
    public pageSize?: number = JsonPropertyEmpty;

    @JsonProperty("measureTotal", Boolean, true)
    public measureTotal?: boolean = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentSearchInput>) {
        Object.assign(this, args);
    }
}
