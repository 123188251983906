import {Button, ControlGroup, InputGroup, Position} from "@blueprintjs/core";
import * as React from "react";
import {useCallback, useState} from "react";

import {handleChangeString} from "../../../utils/handle-change";
import {PopoverButton} from "../popover-button/popover-button";
import {SubmitGroup} from "../submit-group/submit-group";

interface IPopoverCreateButtonProps {
    placeholder?: string;
    label: string;
    onCreate: (text: string) => Promise<void>;
    closeAfterCreate?: boolean;
    disabled?: boolean;
    position?: Position;
    large?: boolean;
}

export const PopoverCreateButton: React.FC<IPopoverCreateButtonProps> = (props) => {
    const {placeholder, label, onCreate, closeAfterCreate, disabled, position, large} = props;

    const [isCreating, setIsCreating] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [text, setText] = useState("");

    const canSubmit = useCallback(() => text.length > 0, [text]);
    const submit = useCallback(async () => {
        if (!canSubmit()) {
            return;
        }

        setIsCreating(true);
        setText("");
        await onCreate(text);
        if (closeAfterCreate ?? true) {
            setIsCreating(false);
            setIsOpen(false);
        }
    }, [canSubmit, closeAfterCreate, onCreate, text]);

    const handlePopoverInteraction = useCallback((nextOpenState: boolean) => {
        setIsOpen(nextOpenState);
    }, []);

    return (
        <PopoverButton
            label={label}
            position={position}
            disabled={disabled}
            large={large}
            isLoading={isCreating}
            isOpen={isOpen}
            onInteraction={(nextOpenState) => handlePopoverInteraction(nextOpenState)}
        >
            <div style={{padding: "10px"}}>
                <SubmitGroup onSubmit={submit}>
                    <ControlGroup>
                        <InputGroup
                            placeholder={placeholder}
                            autoFocus={true}
                            value={text}
                            onChange={handleChangeString((s) => setText(s))}
                        />
                        <Button disabled={!canSubmit} onClick={submit}>
                            追加
                        </Button>
                    </ControlGroup>
                </SubmitGroup>
            </div>
        </PopoverButton>
    );
};
