import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("TokenRevokeInput")
export class TokenRevokeInput {
    @JsonProperty("username", String, true)
    public username?: string = JsonPropertyEmpty;

    @JsonProperty("refresh_token", String, true)
    public refreshToken?: string = JsonPropertyEmpty;

    public constructor(args?: Partial<TokenRevokeInput>) {
        Object.assign(this, args);
    }
}
