import {Callout, Intent, Spinner} from "@blueprintjs/core";
import * as React from "react";

import {AquaEditorState} from "../../../editor/aqua-editor-context";

export const AquaEditorStatus: React.FC<{aquaEditorState: AquaEditorState}> = ({
    aquaEditorState,
}) => {
    if (aquaEditorState === "error") {
        return <Callout intent={Intent.DANGER}>Editorスクリプトの読み込みに失敗しました。</Callout>;
    }

    if (aquaEditorState === "loaded") {
        return <Callout intent={Intent.SUCCESS}>Editorスクリプトが読み込まれました。</Callout>;
    }

    return <Spinner />;
};
