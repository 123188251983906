import {OpenWindowError} from "../errors/open-window-error";

export async function openNewWindow(getUrl: () => Promise<string | undefined>) {
    const window1 = window.open("", "_blank");
    if (!window1) {
        throw new OpenWindowError("Cannot open a new window.");
        return;
    }

    const url = await getUrl();
    if (!url) {
        window1.close();
        return;
    }

    window1.location.href = url;
}
