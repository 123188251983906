import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("DocumentContentReplacePropertyValueInput")
export class DocumentContentReplacePropertyValueInput {
    @JsonProperty()
    public key: string = JsonPropertyEmpty;

    @JsonProperty()
    public search: string = JsonPropertyEmpty;

    @JsonProperty()
    public replacement: string = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentContentReplacePropertyValueInput>) {
        Object.assign(this, args);
    }
}
