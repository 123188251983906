import {DocumentType, DocumentTypeEnum} from "../api/values/document-type";

export function formatDocumentType(documentType: DocumentType): string {
    switch (documentType) {
        case DocumentType.ARTICLE:
            return "記事";
        case DocumentType.BLOCK:
            return "ブロック";
        case DocumentType.PERSON:
            return "人物";
    }
}

export function formatDocumentTypeEnum(documentType: DocumentTypeEnum) {
    switch (documentType) {
        case DocumentTypeEnum.ARTICLE:
            return "記事";
        case DocumentTypeEnum.BLOCK:
            return "ブロック";
        case DocumentTypeEnum.PERSON:
            return "人物";
    }
}
