import {AdminApi} from "../../../api/api";
import {Stores} from "../../../stores";
import {IPageStore, PageStoreConstructor} from "../../../utils/page-store";
import {PageRouter} from "../../../utils/route-util";

export interface PageConfig<T extends IPageStore> {
    component: React.ComponentType<{pageStore: T}>;
    pageStoreClass: PageStoreConstructor<T>;
    query: Record<string, any>;
}

export interface PageState<T> {
    component: React.ComponentType<{pageStore: T}>;
    pageStore: T;
    initializeError?: Error;
}

export async function calculateNextPageState<T extends IPageStore>(
    pageConfig: PageConfig<T>,
    stores: Stores,
    adminApi: AdminApi,
    pageRouter: PageRouter,
): Promise<PageState<T>> {
    const {component, pageStoreClass, query} = pageConfig;

    const pageStore = new pageStoreClass({stores, adminApi, pageRouter});

    if (pageStore.initialize) {
        try {
            await pageStore.initialize({query});
        } catch (e) {
            console.error(e);

            return {
                component,
                pageStore,
                initializeError: e as Error,
            };
        }
    }

    return {
        component,
        pageStore,
    };
}
