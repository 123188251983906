import React, {useEffect, useState} from "react";

import {usePageRouter} from "../components/page-router-context";
import {Stores} from "./index";

export const StoresContext = React.createContext<Stores | undefined>(undefined);

export const StoresContextProvider: React.FC = ({children}) => {
    const pageRouter = usePageRouter();
    const [stores, setStores] = useState<Stores | undefined>(undefined);

    useEffect(() => {
        setStores(new Stores(pageRouter));
    }, [pageRouter]);

    if (!stores) {
        return null;
    }

    return <StoresContext.Provider value={stores}>{children}</StoresContext.Provider>;
};
