import {JsonObject, JsonProperty} from "json2typescript";

import {BigIntId} from "../../utils/big-int-id";
import {BigIntIdConverter, JsonPropertyEmpty} from "../../utils/json-util";
import {UserRoleType} from "./user-role-type";

@JsonObject("UserOutput")
export class UserOutput {
    @JsonProperty("id", BigIntIdConverter)
    public id: BigIntId = JsonPropertyEmpty;

    @JsonProperty()
    public name: string = JsonPropertyEmpty;

    @JsonProperty()
    public email: string = JsonPropertyEmpty;

    @JsonProperty()
    public role: UserRoleType = JsonPropertyEmpty;

    @JsonProperty()
    public enabled: boolean = JsonPropertyEmpty;
}
