import {FormGroup, Intent, TextArea} from "@blueprintjs/core";
import * as React from "react";

import {handleChangeString} from "../../../utils/handle-change";
import {ValidationResult} from "../../../utils/validation/validation-result";

// TODO: support validationInProgress
interface IValidatedTextAreaProps {
    value: string;
    validationResult: ValidationResult;
    onChangeValue: (value: string) => void;
    label?: string;
    helperText?: React.ReactNode;
    rows?: number;
}

export const ValidatedTextArea: React.FC<IValidatedTextAreaProps> = (props) => (
    <FormGroup
        label={props.label}
        helperText={
            !props.validationResult.isEmpty
                ? props.validationResult.simpleMessage
                : props.helperText
        }
    >
        <TextArea
            value={props.value}
            fill={true}
            intent={props.validationResult.isEmpty ? Intent.NONE : Intent.DANGER}
            onChange={handleChangeString(props.onChangeValue)}
            rows={props.rows}
        />
    </FormGroup>
);
