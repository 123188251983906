import * as React from "react";

import {convertRouteToUrl, IRouteParams} from "../../../utils/route-util";
import {usePageRouter} from "../../page-router-context";

export interface ILinkProps {
    route: string;
    params?: IRouteParams;
    passHref?: boolean;
}

function isAnchorElement(elem: HTMLElement): elem is HTMLAnchorElement {
    return elem.nodeName === "A";
}

export const WrappingLink: React.FC<ILinkProps> = (props) => {
    const {children, route, params, passHref} = props;

    const pageRouter = usePageRouter();

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        const targetElement = e.currentTarget;

        if (
            isAnchorElement(targetElement) &&
            ((targetElement.target && targetElement.target !== "_self") ||
                e.metaKey ||
                e.ctrlKey ||
                e.shiftKey)
        ) {
            // ignore for new window
            return;
        }

        e.preventDefault();

        pageRouter.pushRoute(route, params);
    };

    const url = convertRouteToUrl(route, params);
    const child: any = React.Children.only(children);
    const childProps = {
        ...child.props,
        href: (passHref || child.type === "a") && !("href" in child.props) ? url : undefined,
        onClick: (e: React.MouseEvent<HTMLElement>) => {
            if (child.props && typeof child.props.onClick === "function") {
                child.props.onClick(e);
            }

            if (!e.defaultPrevented) {
                handleClick(e);
            }
        },
    };

    return React.cloneElement(child, childProps);
};
