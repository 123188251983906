import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("MediaCreateOutput")
export class MediaCreateOutput {
    @JsonProperty("id", String)
    public id: string = JsonPropertyEmpty;

    @JsonProperty("api_token", String)
    public api_token: string = JsonPropertyEmpty;
}
