import {HttpMethod, requestObject} from "../utils/http-request";
import {ApiOptions} from "./api-options";
/**
 * エラーハンドリングを含むリクエストオブジェクトの非同期関数。
 *
 * @template TOutput - 出力データの型。
 * @param {HttpMethod} method - HTTPメソッド。
 * @param {string} path - エンドポイントパス。
 * @param {new () => TOutput} outputClass - レスポンスデータのデシリアライズクラス。
 * @param {object} [pathVariables] - パス変数。
 * @param {object} [inputObject] - リクエストの入力データ。
 * @param {ApiOptions} apiOptions - APIオプション。
 * @param {object} [queryVariables] - クエリ変数。
 * @returns {Promise<TOutput>} - レスポンスデータのPromise。
 * @throws - リクエスト中にエラーが発生した場合にスローされます。
 */
export async function requestObjectErrorHandled<TOutput extends object>(
    method: HttpMethod,
    path: string,
    outputClass: new () => TOutput,
    pathVariables: object | undefined,
    inputObject: object | undefined,
    apiOptions: ApiOptions,
    queryVariables?: object,
) {
    try {
        return await requestObject(
            method,
            path,
            outputClass,
            pathVariables,
            inputObject,
            await apiOptions.getRequestOptions(),
            queryVariables,
        );
    } catch (error) {
        apiOptions.handleError(error);
        throw error;
    }
}
