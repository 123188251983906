import * as React from "react";

interface IUnloadWarningProps {
    enabled?: boolean;
}

export class UnloadWarning extends React.PureComponent<IUnloadWarningProps> {
    public componentDidMount() {
        window.addEventListener("beforeunload", this.handleBeforeUnload);
    }

    public componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    }

    public render() {
        return null;
    }

    private handleBeforeUnload = (e: BeforeUnloadEvent) => {
        if (this.props.enabled) {
            e.preventDefault();
            e.returnValue = "行った変更が保存されない可能性があります。";
        }
    };
}
