import {action, computed, makeObservable, observable} from "mobx";

import {AdminApi} from "../../../api/api";
import {DocumentAddInput} from "../../../api/values/document-add-input";
import {DocumentType} from "../../../api/values/document-type";
import {validateLength} from "../../../utils/validation/functions/length";
import {createValidator} from "../../../utils/validation/validate";
import {ValidationResultEmpty} from "../../../utils/validation/validation-result";
import {SlugEditInputStore} from "../slug-edit-input/slug-edit-input-store";

export class DocumentAddStore {
    @observable
    public mediaId: string = "";

    @observable
    public isCreating = false;

    @observable
    public isOpen = false;

    @observable
    public title = "";

    @observable
    public titleValidationResult = ValidationResultEmpty;

    public slugEditInputStore: SlugEditInputStore;

    private adminApi: AdminApi;

    constructor(options: {adminApi: AdminApi}, mediaId: string) {
        this.adminApi = options.adminApi;
        this.mediaId = mediaId;
        this.slugEditInputStore = new SlugEditInputStore(
            {adminApi: options.adminApi},
            this.mediaId,
        );

        makeObservable(this);
    }

    @computed
    public get canCreate() {
        return this.titleValidationResult.isEmpty;
    }

    private titleValidator = createValidator(validateLength(1, 50));

    @action.bound
    public onInteraction(nextOpenState: boolean, mediaId: string | undefined) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.setTitle(""); // start validation
        this.slugEditInputStore.setSlug("");
        this.slugEditInputStore.setMediaId(mediaId ?? "");
        this.isOpen = nextOpenState;
    }

    @action.bound
    public close() {
        this.isOpen = false;
    }

    @action.bound
    public async create(documentType: DocumentType, mediaId: string, onCreated: () => void) {
        await this.setIsCreating(true);
        this.setMediaId(mediaId);

        await this.adminApi.postDocument(
            {mediaId: this.mediaId},
            new DocumentAddInput({
                mediaId: this.mediaId,
                documentType: documentType,
                title: this.title,
                slug:
                    documentType !== DocumentType.ARTICLE
                        ? this.slugEditInputStore.editingSlug
                        : "",
            }),
        );

        await this.setIsCreating(false);
        this.close();
        onCreated();
    }

    @action.bound
    public async setTitle(s: string) {
        this.title = s;
        (await this.titleValidator(s)).run((v) => (this.titleValidationResult = v));
    }

    @action.bound
    private async setIsCreating(b: boolean) {
        this.isCreating = b;
    }

    @action.bound
    public setMediaId(mediaId: string) {
        this.mediaId = mediaId;
    }
}
