import * as React from "react";

import {ListEditorItemPanel} from "../../molecules/list-editor-item-panel/list-editor-item-panel";
import {UserSelector} from "../user-selector/user-selector";

export interface IUserListEditorItemProps {
    className?: string;
    value: string | null;
    placeholder?: string;
    onChange: (value: string | null) => void;
    onRemove: () => void;
}

export class UserListEditorItem extends React.Component<IUserListEditorItemProps> {
    public constructor(props: IUserListEditorItemProps) {
        super(props);
        this.state = {
            isEditing: !props.value,
        };
    }

    public render() {
        return (
            <ListEditorItemPanel onRemove={this.props.value ? this.props.onRemove : undefined}>
                <UserSelector
                    value={this.props.value}
                    allowNotOnList={true}
                    placeholder={this.props.placeholder}
                    onChange={(data) => this.props.onChange(data ? data.name : null)}
                />
            </ListEditorItemPanel>
        );
    }
}
