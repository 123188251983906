import {FormGroup} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import * as React from "react";

import {ConfirmDialog} from "../../molecules/confirm-dialog/confirm-dialog";
import {SubmitGroup} from "../../molecules/submit-group/submit-group";
import {ValidatedInputText} from "../../molecules/validated-input-text/validated-input-text";
import {CopyDocumentRevisionStore} from "./copy-document-revision-store";

const ContentDiv = styled.div`
    height: 5em;
`;

export const CopyDocumentRevisionDialog: React.FC<{store: CopyDocumentRevisionStore}> = observer(
    ({store}) => (
        <ConfirmDialog
            title="下書きを複製"
            buttonLabel="作成"
            isOpen={store.isOpen}
            onClose={store.close}
            canAction={store.validationsAllEmpty}
            onAction={store.createCopy}
        >
            <ContentDiv>
                <SubmitGroup
                    canSubmit={store.validationsAllEmpty}
                    onSubmit={store.createCopyAndClose}
                >
                    <FormGroup label="下書き名">
                        <ValidatedInputText
                            autoFocus={true}
                            value={store.revisionName}
                            validationResult={store.revisionNameValidationResult}
                            onChangeValue={store.setRevisionName}
                        />
                    </FormGroup>
                </SubmitGroup>
            </ContentDiv>
        </ConfirmDialog>
    ),
);
