import {IAquaEditor} from "./aqua-editor";
import {AquaEditorState, useAquaEditorContext} from "./aqua-editor-context";

interface AquaEditorHookResult {
    aquaEditorState: AquaEditorState;
    aquaEditor?: IAquaEditor;
}

export function useAquaEditor(): AquaEditorHookResult {
    const {aquaEditorData} = useAquaEditorContext();

    return {
        aquaEditor: aquaEditorData.state === "loaded" ? aquaEditorData.aquaEditor : undefined,
        aquaEditorState: aquaEditorData.state,
    };
}
