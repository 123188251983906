export function getSiteEnvUrl(siteUrl: string) {
    return `${siteUrl}/aqua/site-env`;
}

export function getSitePreviewUrl(siteUrl: string, previewKey: string) {
    return `${siteUrl}/aqua/redirect-to-site/preview-page/${previewKey}`;
}

export function getSiteDocumentUrl(siteUrl: string, slug: string) {
    return `${siteUrl}/aqua/redirect-to-site/document-page/${slug}`;
}

export function getSiteAdminLoginHookUrl(siteUrl: string) {
    return `${siteUrl}/aqua/admin-login-hook`;
}
