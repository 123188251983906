import * as React from "react";
import {createContext, useCallback, useContext, useState} from "react";
import {useParams} from "react-router-dom";

import {useAdminApi} from "../../../api/api-context";
import {ICategoryGroupItem, ICategoryItem, loadCategories} from "../../../utils/category-util";

export interface CategoryCacheContextValue {
    categories?: ICategoryItem[];
    categoryGroups?: ICategoryGroupItem[];
    load(): void;
}

const CategoryCacheContext = createContext<CategoryCacheContextValue | undefined>(undefined);

export const CategoryCacheProvider: React.FC = ({children}) => {
    const [data, setData] = useState<
        {categories: ICategoryItem[]; categoryGroups: ICategoryGroupItem[]} | undefined
    >(undefined);
    const adminApi = useAdminApi();
    const {mediaId} = useParams<{mediaId: string}>();

    const load = useCallback(() => {
        if (data) {
            return;
        }
        mediaId &&
            loadCategories(adminApi, mediaId)
                .then((x) => setData(x))
                .catch((x) => console.error(x));
    }, [data, adminApi, mediaId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CategoryCacheContext.Provider
            value={{
                ...data,
                load,
            }}
        >
            {children}
        </CategoryCacheContext.Provider>
    );
};

export function useCategoryCache() {
    const value = useContext(CategoryCacheContext);
    if (!value) {
        throw new Error("CategoryCacheContextProvider is not provided");
    }
    return value;
}
