import {BigIntId} from "../utils/big-int-id";
import {HttpMethod} from "../utils/http-request";
import {ApiOptions} from "./api-options";
import {requestObjectErrorHandled} from "./request-error-handled";
import {AomDiffInput} from "./values/aom-diff-input";
import {AomDiffOutput} from "./values/aom-diff-output";
import {CategoryGroupImportInput} from "./values/category-group-import-input";
import {CategoryGroupListInput} from "./values/category-group-list-input";
import {CategoryGroupListOutput} from "./values/category-group-list-output";
import {DocumentAddInput} from "./values/document-add-input";
import {DocumentContentDiffInput} from "./values/document-content-diff-input";
import {DocumentContentDiffOutput} from "./values/document-content-diff-output";
import {DocumentContentGetInput} from "./values/document-content-get-input";
import {DocumentContentGetOutput} from "./values/document-content-get-output";
import {DocumentContentReplacePropertyValueInput} from "./values/document-content-replace-property-value-input";
import {DocumentContentReplacePropertyValueOutput} from "./values/document-content-replace-property-value-output";
import {DocumentGetInput} from "./values/document-get-input";
import {DocumentGetOutput} from "./values/document-get-output";
import {DocumentGroupInput} from "./values/document-group-input";
import {DocumentGroupListInput} from "./values/document-group-list-input";
import {DocumentGroupListOutput} from "./values/document-group-list-output";
import {DocumentGroupOutput} from "./values/document-group-output";
import {DocumentImportInput} from "./values/document-import-input";
import {DocumentPreviewAddInput} from "./values/document-preview-add-input";
import {DocumentPreviewListGetInput} from "./values/document-preview-list-get-input";
import {DocumentPreviewListOutput} from "./values/document-preview-list-output";
import {DocumentPreviewOutput} from "./values/document-preview-output";
import {DocumentPreviewUpdateInput} from "./values/document-preview-update-input";
import {DocumentQuickPublishInput} from "./values/document-quick-publish-input";
import {DocumentQuickPublishOutput} from "./values/document-quick-publish-output";
import {DocumentRevisionAddInput} from "./values/document-revision-add-input";
import {DocumentRevisionGetInput} from "./values/document-revision-get-input";
import {DocumentRevisionGetOutput} from "./values/document-revision-get-output";
import {DocumentRevisionPublishInput} from "./values/document-revision-publish-input";
import {DocumentRevisionSearchInput} from "./values/document-revision-search-input";
import {DocumentRevisionSearchOutput} from "./values/document-revision-search-output";
import {DocumentRevisionUpdateHtmlInput} from "./values/document-revision-update-html-input";
import {DocumentRevisionUpdateInput} from "./values/document-revision-update-input";
import {DocumentSearchInput} from "./values/document-search-input";
import {DocumentSearchOutput} from "./values/document-search-output";
import {DocumentUpdateInput} from "./values/document-update-input";
import {EmptyInput} from "./values/empty-input";
import {EmptyOutput} from "./values/empty-output";
import {IdOnly} from "./values/id-only";
import {MediaCreateInput} from "./values/media-create-input";
import {MediaCreateOutput} from "./values/media-create-output";
import {MediaDeleteOutput} from "./values/media-delete-output";
import {MediaGetOutput} from "./values/media-get-output";
import {MediaListOutput} from "./values/media-list-output";
import {MediaPrivateOutput} from "./values/media-private-output";
import {MediaPublishOutput} from "./values/media-publish-output";
import {MediaUpdateInput} from "./values/media-update-input";
import {MediaUpdateOutput} from "./values/media-update-output";
import {UserAddInput} from "./values/user-add-input";
import {UserImportInput} from "./values/user-import-input";
import {UserInput} from "./values/user-input";
import {UserOutput} from "./values/user-output";
import {UserSearchInput} from "./values/user-search-input";
import {UserSearchOutput} from "./values/user-search-output";
import {UserUpdateInput} from "./values/user-update-input";

export interface IIdPathVariable {
    id: BigIntId;
}

export interface IKeyPathVariable {
    key: string;
}

export interface IMediaIdVariable {
    mediaId: string;
}

export interface MediaIdQuery {
    mediaId: string;
}

export function createApiClient(apiOptions: ApiOptions) {
    function withInput<TInput extends object, TOutput extends object>(
        method: HttpMethod,
        path: string,
        outputClass: new () => TOutput,
    ) {
        return async (input?: TInput) =>
            requestObjectErrorHandled<TOutput>(method, path, outputClass, {}, input, apiOptions);
    }

    function withQueryPath<
        TQueryVariables extends object,
        TPathVariables extends object,
        TOutput extends object
    >(method: HttpMethod, path: string, outputClass: new () => TOutput) {
        return async (queryVariables?: TQueryVariables, pathVariables?: TPathVariables) =>
            requestObjectErrorHandled<TOutput>(
                method,
                path,
                outputClass,
                pathVariables,
                undefined,
                apiOptions,
                queryVariables,
            );
    }

    function withQueryInput<
        TQueryVariables extends object,
        TInput extends object,
        TOutput extends object
    >(method: HttpMethod, path: string, outputClass: new () => TOutput) {
        return async (queryVariables?: TQueryVariables, input?: TInput) =>
            requestObjectErrorHandled<TOutput>(
                method,
                path,
                outputClass,
                {},
                input,
                apiOptions,
                queryVariables,
            );
    }

    function withQueryPathInput<
        TQueryVariables extends object,
        TPathVariables extends object,
        TInput extends object,
        TOutput extends object
    >(method: HttpMethod, path: string, outputClass: new () => TOutput) {
        return async (
            queryVariables?: TQueryVariables,
            pathVariables?: TPathVariables,
            input?: TInput,
        ) =>
            requestObjectErrorHandled<TOutput>(
                method,
                path,
                outputClass,
                pathVariables,
                input,
                apiOptions,
                queryVariables,
            );
    }

    function withPathInput<
        TPathVariables extends object,
        TInput extends object,
        TOutput extends object
    >(method: HttpMethod, path: string, outputClass: new () => TOutput) {
        return async (pathVariables?: TPathVariables, input?: TInput) =>
            requestObjectErrorHandled<TOutput>(
                method,
                path,
                outputClass,
                pathVariables,
                input,
                apiOptions,
            );
    }

    return {
        // /aom
        postAomDiff: withInput<AomDiffInput, AomDiffOutput>(
            "POST",
            "/api/v1/admin/member/aom/diff",
            AomDiffOutput,
        ),

        // /documents
        getDocuments: withInput<DocumentSearchInput, DocumentSearchOutput>(
            "GET",
            "/api/v1/admin/member/documents",
            DocumentSearchOutput,
        ),

        postDocument: withQueryInput<MediaIdQuery, DocumentAddInput, IdOnly>(
            "POST",
            "/api/v1/admin/member/documents",
            IdOnly,
        ),

        getDocument: withPathInput<IIdPathVariable, DocumentGetInput, DocumentGetOutput>(
            "GET",
            "/api/v1/admin/member/documents/:id",
            DocumentGetOutput,
        ),

        putDocument: withQueryPathInput<
            MediaIdQuery,
            IIdPathVariable,
            DocumentUpdateInput,
            EmptyOutput
        >("PUT", "/api/v1/admin/member/documents/:id", EmptyOutput),

        deleteDocument: withQueryPath<MediaIdQuery, IIdPathVariable, EmptyOutput>(
            "DELETE",
            "/api/v1/admin/member/documents/:id",
            EmptyOutput,
        ),

        postDocumentQuickPublish: withQueryPathInput<
            MediaIdQuery,
            IIdPathVariable,
            DocumentQuickPublishInput,
            DocumentQuickPublishOutput
        >("POST", "/api/v1/admin/member/documents/:id/quick-publish", DocumentQuickPublishOutput),

        postDocumentUnpublish: withQueryPath<MediaIdQuery, IIdPathVariable, EmptyOutput>(
            "POST",
            "/api/v1/admin/member/documents/:id/unpublish",
            EmptyOutput,
        ),

        postDocumentUpdateCache: withQueryPath<MediaIdQuery, IIdPathVariable, EmptyOutput>(
            "POST",
            "/api/v1/admin/member/documents/:id/update-cache",
            EmptyOutput,
        ),

        postDocumentOptimize: withQueryPath<MediaIdQuery, IIdPathVariable, EmptyOutput>(
            "POST",
            "/api/v1/admin/member/documents/:id/optimize",
            EmptyOutput,
        ),

        // /document-contents
        getDocumentContent: withPathInput<
            IIdPathVariable,
            DocumentContentGetInput,
            DocumentContentGetOutput
        >("GET", "/api/v1/admin/member/document-contents/:id", DocumentContentGetOutput),

        getDocumentContentDiff: withPathInput<
            IIdPathVariable,
            DocumentContentDiffInput,
            DocumentContentDiffOutput
        >("GET", "/api/v1/admin/member/document-contents/:id/diff", DocumentContentDiffOutput),

        postDocumentContentReplacePropertyValue: withInput<
            DocumentContentReplacePropertyValueInput,
            DocumentContentReplacePropertyValueOutput
        >(
            "POST",
            "/api/member/document-contents/replace-property-value",
            DocumentContentReplacePropertyValueOutput,
        ),

        // /document-revisions
        postDocumentRevision: withQueryInput<MediaIdQuery, DocumentRevisionAddInput, IdOnly>(
            "POST",
            "/api/v1/admin/member/document-revisions",
            IdOnly,
        ),

        getDocumentRevisions: withInput<DocumentRevisionSearchInput, DocumentRevisionSearchOutput>(
            "GET",
            "/api/v1/admin/member/document-revisions",
            DocumentRevisionSearchOutput,
        ),

        getDocumentRevision: withPathInput<
            IIdPathVariable,
            DocumentRevisionGetInput,
            DocumentRevisionGetOutput
        >("GET", "/api/v1/admin/member/document-revisions/:id", DocumentRevisionGetOutput),

        putDocumentRevision: withQueryPathInput<
            MediaIdQuery,
            IIdPathVariable,
            DocumentRevisionUpdateInput,
            EmptyOutput
        >("PUT", "/api/v1/admin/member/document-revisions/:id", EmptyOutput),

        deleteDocumentRevision: withQueryPath<MediaIdQuery, IIdPathVariable, EmptyOutput>(
            "DELETE",
            "/api/v1/admin/member/document-revisions/:id",
            EmptyOutput,
        ),

        postDocumentRevisionPublish: withQueryPathInput<
            MediaIdQuery,
            IIdPathVariable,
            DocumentRevisionPublishInput,
            EmptyOutput
        >("POST", "/api/v1/admin/member/document-revisions/:id/publish", EmptyOutput),

        postDocumentRevisionUnschedule: withQueryPath<MediaIdQuery, IIdPathVariable, EmptyOutput>(
            "POST",
            "/api/v1/admin/member/document-revisions/:id/unschedule",
            EmptyOutput,
        ),

        putDocumentRevisionHtml: withQueryPathInput<
            MediaIdQuery,
            IIdPathVariable,
            DocumentRevisionUpdateHtmlInput,
            EmptyOutput
        >("PUT", "/api/v1/admin/member/document-revisions/:id/html", EmptyOutput),

        // /document-groups
        getDocumentGroups: withInput<DocumentGroupListInput, DocumentGroupListOutput>(
            "GET",
            "/api/v1/admin/member/document-groups",
            DocumentGroupListOutput,
        ),

        getDocumentGroup: withPathInput<IKeyPathVariable, DocumentGroupInput, DocumentGroupOutput>(
            "GET",
            "/api/v1/admin/member/document-groups/:key",
            DocumentGroupOutput,
        ),

        putDocumentGroup: withQueryPathInput<
            MediaIdQuery,
            IKeyPathVariable,
            DocumentGroupOutput,
            IdOnly
        >("PUT", "/api/v1/admin/member/document-groups/:key", IdOnly),

        // /document-previews
        getDocumentPreviews: withInput<DocumentPreviewListGetInput, DocumentPreviewListOutput>(
            "GET",
            "/api/v1/admin/member/document-previews",
            DocumentPreviewListOutput,
        ),

        createDocumentPreview: withQueryInput<
            MediaIdQuery,
            DocumentPreviewAddInput,
            DocumentPreviewOutput
        >("POST", "/api/v1/admin/member/document-previews", DocumentPreviewOutput),

        updateDocumentPreview: withQueryPathInput<
            MediaIdQuery,
            IIdPathVariable,
            DocumentPreviewUpdateInput,
            DocumentPreviewOutput
        >("POST", "/api/v1/admin/member/document-previews/:id", DocumentPreviewOutput),

        deleteDocumentPreview: withQueryPath<MediaIdQuery, IIdPathVariable, EmptyOutput>(
            "DELETE",
            "/api/v1/admin/member/document-previews/:id",
            EmptyOutput,
        ),

        // /category-groups
        getCategoryGroups: withInput<CategoryGroupListInput, CategoryGroupListOutput>(
            "GET",
            "/api/v1/admin/member/category-groups",
            CategoryGroupListOutput,
        ),

        // /users
        getUsers: withInput<UserSearchInput, UserSearchOutput>(
            "GET",
            "/api/v1/admin/member/users",
            UserSearchOutput,
        ),

        getUser: withPathInput<IIdPathVariable, UserInput, UserOutput>(
            "GET",
            "/api/v1/admin/member/users/:id",
            UserOutput,
        ),

        postUser: withQueryInput<MediaIdQuery, UserAddInput, IdOnly>(
            "POST",
            "/api/v1/admin/member/users",
            IdOnly,
        ),

        patchUser: withQueryPathInput<MediaIdQuery, IIdPathVariable, UserUpdateInput, EmptyOutput>(
            "PATCH",
            "/api/v1/admin/member/users/:id",
            EmptyOutput,
        ),

        deleteUser: withQueryPath<MediaIdQuery, IIdPathVariable, EmptyOutput>(
            "DELETE",
            "/api/v1/admin/member/users/:id",
            EmptyOutput,
        ),

        // /import
        postImportDocument: withInput<DocumentImportInput, EmptyOutput>(
            "POST",
            "/api/member/import/document",
            EmptyOutput,
        ),

        postImportUsers: withQueryInput<MediaIdQuery, UserImportInput, EmptyOutput>(
            "POST",
            "/api/v1/admin/member/import/users",
            EmptyOutput,
        ),

        postImportCategoryGroup: withQueryInput<
            MediaIdQuery,
            CategoryGroupImportInput,
            EmptyOutput
        >("POST", "/api/v1/admin/member/import/category-groups", EmptyOutput),

        getMediaList: withInput<EmptyInput, MediaListOutput>(
            "GET",
            "/api/v1/admin/admin/media",
            MediaListOutput,
        ),

        postMediaCreate: withInput<MediaCreateInput, MediaCreateOutput>(
            "POST",
            "/api/v1/admin/admin/media",
            MediaCreateOutput,
        ),

        getMedia: withPathInput<IMediaIdVariable, EmptyInput, MediaGetOutput>(
            "GET",
            "/api/v1/admin/admin/media/:mediaId",
            MediaGetOutput,
        ),

        putMediaUpdate: withPathInput<IMediaIdVariable, MediaUpdateInput, MediaUpdateOutput>(
            "PUT",
            "/api/v1/admin/admin/media/:mediaId",
            MediaUpdateOutput,
        ),

        deleteMedia: withPathInput<IMediaIdVariable, EmptyInput, MediaDeleteOutput>(
            "DELETE",
            "/api/v1/admin/admin/media/:mediaId",
            MediaDeleteOutput,
        ),

        postMediaPublish: withPathInput<IMediaIdVariable, EmptyInput, MediaPublishOutput>(
            "POST",
            "/api/v1/admin/member/media/:mediaId/status/publish",
            MediaPublishOutput,
        ),

        postMediaPrivate: withPathInput<IMediaIdVariable, EmptyInput, MediaPrivateOutput>(
            "POST",
            "/api/v1/admin/member/media/:mediaId/status/private",
            MediaPrivateOutput,
        ),
    };
}

export type AdminApi = ReturnType<typeof createApiClient>;
