import {Button, IconName, MaybeElement, Popover, PopoverPosition} from "@blueprintjs/core";
import * as React from "react";

export interface IPopoverButtonProps {
    label?: string;
    icon?: IconName | MaybeElement;
    isLoading?: boolean;
    isOpen?: boolean;
    onInteraction?: (nextOpenState: boolean) => void;
    position?: PopoverPosition;
    large?: boolean;
    disabled?: boolean;
}

export class PopoverButton extends React.PureComponent<IPopoverButtonProps, {}> {
    public render() {
        return (
            <Popover
                content={<>{this.props.children}</>}
                position={this.props.position || PopoverPosition.BOTTOM_RIGHT}
                transitionDuration={0}
                isOpen={this.props.isOpen}
                onInteraction={this.props.onInteraction}
                disabled={this.props.disabled}
            >
                <Button
                    rightIcon={this.props.icon === undefined ? "caret-down" : this.props.icon}
                    text={this.props.label}
                    disabled={this.props.disabled}
                    loading={this.props.isLoading}
                    large={this.props.large}
                />
            </Popover>
        );
    }
}
