import {InputGroup} from "@blueprintjs/core";
import * as React from "react";

interface ITextListEditorInputProps {
    value: string;
    maxLength?: number;
    placeholder?: string;
    onChange?: (e: React.FormEvent<HTMLInputElement>) => any;
    onEnterKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => any;
    onEscKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => any;
    inputRef?: (ref: HTMLInputElement | null) => any;
}

export class TextListEditorInput extends React.Component<ITextListEditorInputProps> {
    public render() {
        const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
            if (e.key === "Enter" && this.props.onEnterKeyDown) {
                this.props.onEnterKeyDown(e);
            } else if (e.key === "Escape" && this.props.onEscKeyDown) {
                this.props.onEscKeyDown(e);
            }
        };

        return (
            <>
                <InputGroup
                    maxLength={this.props.maxLength}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    inputRef={this.props.inputRef}
                    onChange={this.props.onChange}
                    onKeyDown={handleKeyDown}
                />
            </>
        );
    }
}
