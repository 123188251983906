import * as React from "react";
import {RefObject, useCallback, useEffect, useRef, useState} from "react";
import * as ReactDOM from "react-dom";

interface RenderContentFunction {
    (contentDocument: Document, contentWindow: Window): React.ReactElement;
}

interface FrameContentProps {
    iframeRef: RefObject<HTMLIFrameElement>;
    renderContent: RenderContentFunction;
    onContentReady: () => void;
    onHeightChanged: (height: number) => void;
}

const FrameContent: React.FC<FrameContentProps> = (props) => {
    const {renderContent, iframeRef, onContentReady, onHeightChanged} = props;
    const contentDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const contentDiv = contentDivRef.current;
        if (!contentDiv) {
            return;
        }

        const IframeResizeObserver = iframeRef.current?.contentWindow?.ResizeObserver;
        if (!IframeResizeObserver) {
            throw new Error("ResizeObserver is not supported");
        }
        const resizeObserver = new IframeResizeObserver((entries: ResizeObserverEntry[]) => {
            onHeightChanged(entries[0].contentRect.height);
        });
        resizeObserver.observe(contentDiv);

        return () => resizeObserver.disconnect();
    }, [iframeRef, onHeightChanged]);

    useEffect(() => {
        onContentReady?.();
    }, [onContentReady]);

    return (
        <div ref={contentDivRef}>
            {renderContent(iframeRef.current!.contentDocument!, iframeRef.current!.contentWindow!)}
        </div>
    );
};

interface FrameContainerProps {
    cssUrl?: string;
    children: RenderContentFunction;
    onReady?: () => void;
}

export const FrameContainer: React.FC<FrameContainerProps> = (props) => {
    const {cssUrl, children, onReady} = props;

    const [isIFrameLoaded, setIsIFrameLoaded] = useState(false);
    const [isCssLoaded, setIsCssLoaded] = useState(false);
    const [isContentReady, setIsContentReady] = useState(false);

    const handleIframeLoaded = useCallback((e) => {
        setIsIFrameLoaded(true);
    }, []);

    const handleCssLoaded = useCallback(() => {
        setIsCssLoaded(true);
        if (isContentReady) {
            onReady?.();
        }
    }, [isContentReady, onReady]);

    const handleContentReady = useCallback(() => {
        setIsContentReady(true);
        if (isCssLoaded) {
            onReady?.();
        }
    }, [isCssLoaded, onReady]);

    const [height, setHeight] = useState(0);
    const handleHeightChanged = useCallback((height: number) => {
        setHeight(height);
    }, []);

    const iframeRef = useRef<HTMLIFrameElement>(null);

    return (
        <iframe
            ref={iframeRef}
            style={{
                display: !cssUrl || isCssLoaded ? "block" : "none",
                width: "100%",
                height: `${height}px`,
                border: "none",
            }}
            onLoad={handleIframeLoaded}
            srcDoc="<!DOCTYPE html><html><head></head><body></body></html>"
        >
            {isIFrameLoaded && [
                ReactDOM.createPortal(
                    <>
                        {cssUrl && <link rel="stylesheet" href={cssUrl} onLoad={handleCssLoaded} />}
                    </>,
                    iframeRef.current!.contentDocument!.head,
                ),
                ReactDOM.createPortal(
                    <FrameContent
                        iframeRef={iframeRef}
                        renderContent={children}
                        onContentReady={handleContentReady}
                        onHeightChanged={handleHeightChanged}
                    />,
                    iframeRef.current!.contentDocument!.body,
                ),
            ]}
        </iframe>
    );
};
