import {Button, Card, H5} from "@blueprintjs/core";
import styled from "@emotion/styled";
import React, {useEffect} from "react";

import {Link} from "../../../routes";
import {useActiveMediaStore} from "../../../stores/active-media-store";
import {useMediaListStore} from "../../../stores/media-list-store";

export interface MediaCardProps {
    mediaId: string;
    mediaImgSrc: string;
    mediaTitle: string;
    mediaUrl: string;
}

const MediaImgDiv = styled.div`
    display: flex;
    height: 150px;
    overflow: hidden;
    justify-content: center;
    border-radius: 3px;
    border: 1px solid #c5cbd3;
`;

const MediaCardImg = styled.img`
    width: 100%;
    object-fit: cover;
`;

const MediaCardDetail = styled.div`
    padding-top: 10px;
`;

const MediaCard: React.FC<MediaCardProps> = ({mediaId, mediaImgSrc, mediaTitle, mediaUrl}) => {
    const mediaInfoState = useMediaListStore((state) => state.infoList);
    const setActiveMedia = useActiveMediaStore((state) => state.setActiveMediaInfo);

    useEffect(() => {
        const mediaInfo = mediaInfoState.find((info) => info.mediaId === mediaId);
        if (mediaInfo) {
            setActiveMedia(mediaInfo);
        }
    }, [mediaId, mediaInfoState, setActiveMedia]);

    return (
        <Card style={{maxWidth: "350px"}}>
            <MediaImgDiv>
                <MediaCardImg src={mediaImgSrc} />
            </MediaImgDiv>
            <MediaCardDetail>
                <p>{mediaId}</p>
                <H5>{mediaTitle}</H5>
                <p>
                    <a href={mediaUrl} target="_blank" rel="noopener noreferrer">
                        {mediaUrl}
                    </a>
                </p>
                <Link route={`/admin/${mediaId}/documents`}>
                    <Button small={true} text="ドキュメント一覧を開く" />
                </Link>
            </MediaCardDetail>
        </Card>
    );
};

export default MediaCard;
