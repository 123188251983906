import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import {useAdminApi} from "../../../api/api-context";
import {MediaCreateInput} from "../../../api/values/media-create-input";
import {MediaProtectionStatus} from "../../../api/values/media-protection-status";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import MediaForm from "../../organisms/media-form/media-form";
import {AppLayout} from "../../templates/app-layout/app-layout";

const MediaCreatePage: React.FC = () => {
    const [mediaName, setMediaName] = useState("");
    const [mediaSummary, setMediaSummary] = useState("");
    const [mediaTitle, setMediaTitle] = useState("");
    const [mediaUrl, setMediaUrl] = useState("");
    const [mediaDescription, setMediaDescription] = useState("");
    const [faviconFileUri, setFaviconFileUri] = useState<string>("");
    const [thumbnailFileUri, setThumbnailFileUri] = useState<string>("");
    const [ogImageFileUri, setOgImageFileUri] = useState<string>("");

    const navigate = useNavigate();
    const adminApi = useAdminApi();

    const fetchPostMediaApi = async () => {
        try {
            await adminApi
                .postMediaCreate(
                    new MediaCreateInput({
                        name: mediaName,
                        summary: mediaSummary,
                        title: mediaTitle,
                        base_url: mediaUrl,
                        description: mediaDescription,
                        favicon_url: faviconFileUri,
                        thumbnail_url: thumbnailFileUri,
                        og_image_url: ogImageFileUri,
                        protection_status: MediaProtectionStatus.HIDDEN,
                    }),
                )
                .then((res) => {
                    goSuccessPage(res.id, mediaName);
                });
        } catch (e) {
            console.error("メディア作成中にエラーが発生しました:", e);
        }
    };

    const goSuccessPage = (mediaId: string, mediaName: string) => {
        navigate("/admin/media-create/success", {
            state: {
                id: mediaId,
                name: mediaName,
            },
        });
    };

    return (
        <AppLayout
            breadcrumb={<AdminBreadcrumb factory={(builder) => builder.mediaCreate().build()} />}
        >
            <MediaForm
                mediaName={mediaName}
                setMediaName={setMediaName}
                mediaSummary={mediaSummary}
                setMediaSummary={setMediaSummary}
                mediaTitle={mediaTitle}
                setMediaTitle={setMediaTitle}
                mediaUrl={mediaUrl}
                setMediaUrl={setMediaUrl}
                mediaDescription={mediaDescription}
                setMediaDescription={setMediaDescription}
                faviconFileUri={faviconFileUri}
                setFaviconFileUri={setFaviconFileUri}
                thumbnailFileUri={thumbnailFileUri}
                setThumbnailFileUri={setThumbnailFileUri}
                ogImageFileUri={ogImageFileUri}
                setOgImageFileUri={setOgImageFileUri}
                onSubmit={fetchPostMediaApi}
                buttonText="メディアを作成する"
            />
        </AppLayout>
    );
};

export default MediaCreatePage;
