import {JsonObject, JsonProperty} from "json2typescript";
import * as moment from "moment-timezone";

import {BigIntId} from "../../utils/big-int-id";
import {BigIntIdConverter, DateTimeConverter, JsonPropertyEmpty} from "../../utils/json-util";
import {DocumentPropertyInputOutput} from "./document-property-input-output";
import {DocumentRevisionType} from "./document-revision-type";
import {DocumentRevisionUserInputOutput} from "./document-revision-user-input-output";

@JsonObject("DocumentRevisionGetOutput")
export class DocumentRevisionGetOutput {
    @JsonProperty("mediaId", String)
    public mediaId: string = JsonPropertyEmpty;

    @JsonProperty("id", BigIntIdConverter)
    public id: BigIntId = JsonPropertyEmpty;

    @JsonProperty("documentId", BigIntIdConverter)
    public documentId: BigIntId = JsonPropertyEmpty;

    @JsonProperty("appliedAt", DateTimeConverter)
    public appliedAt: moment.Moment = JsonPropertyEmpty;

    @JsonProperty("documentContentId", BigIntIdConverter)
    public documentContentId: BigIntId = JsonPropertyEmpty;

    @JsonProperty("baseDocumentContentId", BigIntIdConverter, true)
    public baseDocumentContentId?: BigIntId = JsonPropertyEmpty;

    @JsonProperty()
    public revisionName: string = JsonPropertyEmpty;

    @JsonProperty()
    public revisionNumber: number = JsonPropertyEmpty;

    @JsonProperty()
    public revisionType: DocumentRevisionType = JsonPropertyEmpty;

    @JsonProperty("property", DocumentPropertyInputOutput)
    public property: DocumentPropertyInputOutput = JsonPropertyEmpty;

    @JsonProperty("baseProperty", DocumentPropertyInputOutput)
    public baseProperty: DocumentPropertyInputOutput = JsonPropertyEmpty;

    @JsonProperty()
    public users: DocumentRevisionUserInputOutput[] = JsonPropertyEmpty;

    @JsonProperty()
    public appliable = false;

    @JsonProperty()
    public unschedulable = false;

    @JsonProperty()
    public quickEditable = false;

    @JsonProperty("updatedAt", DateTimeConverter)
    public updatedAt: moment.Moment = JsonPropertyEmpty;

    @JsonProperty("updatedUserName", String, true)
    public updatedUserName?: string = JsonPropertyEmpty;

    @JsonProperty()
    public outdated = false;
}
