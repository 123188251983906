import {Button, ControlGroup, FileInput} from "@blueprintjs/core";
import * as React from "react";
import {useCallback, useState} from "react";

interface FileWithButtonProps {
    label: string;
    onAction: (file: File) => void;
}

export const FileWithButton: React.FC<FileWithButtonProps> = ({onAction, label}) => {
    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [selectedFileName, setSelectedFileName] = useState<string | undefined>(undefined);
    const onInputChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;
        if (files && files.length > 0) {
            const file = files[0];
            setSelectedFile(file);
            setSelectedFileName(file.name);
        } else {
            setSelectedFile(undefined);
            setSelectedFileName(undefined);
        }
    }, []);

    const onClick = useCallback(() => {
        if (selectedFile) {
            onAction(selectedFile);
        }
    }, [onAction, selectedFile]);

    return (
        <ControlGroup>
            <FileInput
                onInputChange={onInputChange}
                text={selectedFileName ?? "選択してください"}
                buttonText="選択"
            />
            <Button onClick={onClick} disabled={!selectedFile}>
                {label}
            </Button>
        </ControlGroup>
    );
};
