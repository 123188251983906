import {ICategoryItem} from "../utils/category-util";

export function formatCategoryItem(categoryItem: ICategoryItem) {
    if (categoryItem.groupSlug === categoryItem.slug) {
        return categoryItem.label;
    } else {
        return `${categoryItem.groupLabel}/${categoryItem.label}`;
    }
}

export function formatCategoryItems(categoryItems: ICategoryItem[]) {
    return categoryItems.map((c) => formatCategoryItem(c)).join(", ");
}
