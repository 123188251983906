import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";
import {DocumentListItemOutput} from "./document-list-item-output";

@JsonObject("DocumentSearchOutput")
export class DocumentSearchOutput {
    @JsonProperty("documents", [DocumentListItemOutput])
    public documents: DocumentListItemOutput[] = JsonPropertyEmpty;

    @JsonProperty()
    public nextPageExists: boolean = JsonPropertyEmpty;

    @JsonProperty()
    public previousPageExists: boolean = JsonPropertyEmpty;

    @JsonProperty("totalCount", Number, true)
    public totalCount?: number = JsonPropertyEmpty;
}
