import {action, computed, makeObservable, observable, runInAction} from "mobx";
import moment from "moment-timezone";

import {AdminApi} from "../../../api/api";
import {AomDiffInput} from "../../../api/values/aom-diff-input";
import {AomNodeDifference} from "../../../api/values/aom-node-difference";
import {DocumentContentDiffInput} from "../../../api/values/document-content-diff-input";
import {DocumentContentGetInput} from "../../../api/values/document-content-get-input";
import {DocumentGetInput} from "../../../api/values/document-get-input";
import {DocumentQuickPublishInput} from "../../../api/values/document-quick-publish-input";
import {DocumentRevisionGetInput} from "../../../api/values/document-revision-get-input";
import {DocumentRevisionPublishInput} from "../../../api/values/document-revision-publish-input";
import {Stores} from "../../../stores";
import {SimpleToast} from "../../../stores/toast-store";
import {BigIntId} from "../../../utils/big-int-id";
import {optimizeDocumentAndForgot} from "../../../utils/document-optimize-util";
import {PageRouter} from "../../../utils/route-util";
import {withInProgress} from "../../../utils/with-in-progress";
import {PropertyDiffStore} from "../property-diff-panel/property-diff-store";
import {QuickEditEntry} from "./quick-edit-entry";

export enum PublishingType {
    IMMEDIATE = "IMMEDIATE",
    SCHEDULED = "SCHEDULED",
}

interface PublishDrawerStoreOptions {
    adminApi: AdminApi;
    stores: Stores;
    pageRouter: PageRouter;
}

export class PublishDrawerStore {
    public propertyDiffStore: PropertyDiffStore;
    private adminApi: AdminApi;
    private stores: Stores;
    private pageRouter: PageRouter;

    constructor(options: PublishDrawerStoreOptions) {
        this.adminApi = options.adminApi;
        this.stores = options.stores;
        this.pageRouter = options.pageRouter;
        this.propertyDiffStore = new PropertyDiffStore({adminApi: this.adminApi});

        makeObservable(this);
    }

    @computed
    public get canPublish() {
        return !this.isPublishing && (!this.isOutdated || this.isOutdatedConfirmed);
    }

    @observable
    public mediaId: string = "";

    @observable
    public documentRevisionId: BigIntId = new BigIntId("0");

    @observable
    public documentId: BigIntId = new BigIntId("0");

    @observable
    public baseDocumentContentId: BigIntId = new BigIntId("0");

    @observable
    public isOutdated: boolean = false;

    @observable.shallow
    public differences: AomNodeDifference[] | null = null;

    @observable
    public editingPublishingType: PublishingType = PublishingType.IMMEDIATE;

    @observable
    public editingAppliedAt: moment.Moment = moment();

    @observable
    public quickEditEntry: QuickEditEntry | null = null;

    @observable
    public isOpen = false;

    @observable
    public isLoading = false;

    @observable
    public isPublishing = false;

    @observable
    public isOutdatedConfirmed: boolean = false;

    public onPublish?: () => void;

    @computed
    public get isQuickPublish() {
        return !!this.quickEditEntry;
    }

    @action.bound
    public async openPublish(mediaId: string, documentRevisionId: BigIntId) {
        this.setMediaId(mediaId);
        await withInProgress(
            (b) => (this.isLoading = b),
            async () => {
                this.isOpen = true;
                await this.loadDocumentRevision(documentRevisionId);
            },
        );
    }

    @action.bound
    public async openQuickPublish(mediaId: string, quickEditEntry: QuickEditEntry) {
        this.setMediaId(mediaId);
        await withInProgress(
            (b) => (this.isLoading = b),
            async () => {
                this.isOpen = true;
                await this.loadQuickEdit(quickEditEntry);
            },
        );
    }

    @action.bound
    public async close() {
        this.isOpen = false;
    }

    @action.bound
    public async publish() {
        this.isPublishing = true;
        this.setMediaId(this.mediaId);

        await this.adminApi.postDocumentRevisionPublish(
            {mediaId: this.mediaId},
            {id: this.documentRevisionId},
            new DocumentRevisionPublishInput({
                appliedAt:
                    this.editingPublishingType === PublishingType.IMMEDIATE
                        ? moment()
                        : this.editingAppliedAt,
            }),
        );
        await this.loadDocumentRevision(this.documentRevisionId);

        runInAction(() => {
            this.isPublishing = false;
        });

        await this.finishPublishing();
    }

    @action.bound
    public async quickPublish() {
        const quickEditEntry = this.quickEditEntry!;
        await withInProgress(
            (b) => (this.isPublishing = b),
            async () => {
                await this.adminApi.postDocumentQuickPublish(
                    {mediaId: this.mediaId},
                    {id: this.documentId},
                    new DocumentQuickPublishInput({
                        baseDocumentContentId: this.baseDocumentContentId,
                        content: quickEditEntry.nodeJson,
                        contentHtml: quickEditEntry.renderedHtml,
                        property: quickEditEntry.property,
                        users: quickEditEntry.users,
                    }),
                );
            },
        );

        await this.finishPublishing();
    }

    @action.bound
    public updateEditingAppliedAt(date: Date) {
        this.editingAppliedAt = moment(date);
    }

    @action.bound
    public setMediaId(mediaId: string) {
        this.mediaId = mediaId;
    }

    @action.bound
    public setPublishingType(value: string) {
        this.editingPublishingType =
            value === PublishingType.SCHEDULED
                ? PublishingType.SCHEDULED
                : PublishingType.IMMEDIATE;
    }

    @action.bound
    public setIsOutdatedConfirmed(b: boolean) {
        this.isOutdatedConfirmed = b;
    }

    @action.bound
    private async loadDocumentRevision(id: BigIntId) {
        this.documentRevisionId = id;

        const documentRevision = await this.adminApi.getDocumentRevision(
            {id: this.documentRevisionId},
            new DocumentRevisionGetInput({
                mediaId: this.mediaId,
            }),
        );
        runInAction(() => {
            this.documentRevisionId = documentRevision.id;
            this.documentId = documentRevision.documentId;
            this.isOutdated = documentRevision.outdated;
        });

        const document = await this.adminApi.getDocument(
            {id: this.documentId},
            new DocumentGetInput({mediaId: this.mediaId}),
        );
        const activeDocumentRevision = await this.adminApi.getDocumentRevision(
            {id: document.activeDocumentRevisionId},
            new DocumentRevisionGetInput({
                mediaId: this.mediaId,
            }),
        );

        await this.propertyDiffStore.setProperties(
            this.mediaId,
            activeDocumentRevision.property,
            documentRevision.property,
        );

        const contentDiff = await this.adminApi.getDocumentContentDiff(
            {id: documentRevision.documentContentId},
            new DocumentContentDiffInput({
                mediaId: this.mediaId,
                baseDocumentContentId: activeDocumentRevision.documentContentId,
            }),
        );
        runInAction(() => {
            this.differences = contentDiff.differences;
        });
    }

    @action.bound
    private async loadQuickEdit(quickEditEntry: QuickEditEntry) {
        const document = await this.adminApi.getDocument(
            {id: quickEditEntry.documentId},
            new DocumentGetInput({mediaId: this.mediaId}),
        );
        const activeDocumentRevision = await this.adminApi.getDocumentRevision(
            {id: document.activeDocumentRevisionId},
            new DocumentRevisionGetInput({
                mediaId: this.mediaId,
            }),
        );

        const baseDocumentContentId = activeDocumentRevision.documentContentId;
        const baseContent = await this.adminApi.getDocumentContent(
            {id: baseDocumentContentId},
            new DocumentContentGetInput({
                mediaId: this.mediaId,
            }),
        );
        const result = await this.adminApi.postAomDiff(
            new AomDiffInput({
                leftJson: baseContent.content,
                rightJson: quickEditEntry.nodeJson,
            }),
        );
        await this.propertyDiffStore.setProperties(
            this.mediaId,
            activeDocumentRevision.property,
            quickEditEntry.property,
        );

        runInAction(() => {
            this.baseDocumentContentId = baseDocumentContentId;
            this.quickEditEntry = quickEditEntry;
            this.documentId = quickEditEntry.documentId;
            this.differences = result.differences;
        });
    }

    private async finishPublishing() {
        optimizeDocumentAndForgot(this.mediaId, this.documentId, this.adminApi);

        if (this.onPublish) {
            this.onPublish();
        }

        this.stores.toastStore.setSimpleToast(SimpleToast.FINISHED_PUBLISH_SETTING);
        this.stores.toastStore.keepFirstRouteChange();

        this.pageRouter.pushRoute("/admin/[mediaId]/documents/[documentId]", {
            mediaId: this.mediaId,
            documentId: this.documentId.value,
        });
    }
}
