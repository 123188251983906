export enum UserRoleType {
    PARTNER = "PARTNER",
    WRITER = "WRITER",
    EDITOR = "EDITOR",
    MANAGER = "MANAGER",
    ADMIN = "ADMIN",
}

export class UserRole {
    public static readonly values: UserRole[] = [];

    public static readonly PARTNER = new UserRole(UserRoleType.PARTNER, "パートナー");
    public static readonly WRITER = new UserRole(UserRoleType.WRITER, "ライター");
    public static readonly EDITOR = new UserRole(UserRoleType.EDITOR, "編集者");
    public static readonly MANAGER = new UserRole(UserRoleType.MANAGER, "メディア管理者");
    public static readonly ADMIN = new UserRole(UserRoleType.ADMIN, "システム管理者");

    public static fromName(name?: string) {
        return this.values.find((x) => x.name === name);
    }

    private constructor(public readonly name: string, public readonly displayName: string) {
        UserRole.values.push(this);
    }

    public equals(other: UserRole) {
        return this.name === other.name;
    }

    public toString() {
        return this.name;
    }
}
