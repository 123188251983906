import {Card, HTMLSelect, Icon, InputGroup, NumericInput} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import * as React from "react";

import {DocumentType} from "../../../api/values/document-type";
import {formatCategoryItems} from "../../../models/format-category-item";
import {formatRevisionName} from "../../../models/format-revision-name";
import {formatRevisionNumber} from "../../../models/format-revision-number";
import {Link} from "../../../routes";
import {useActiveMediaStore} from "../../../stores/active-media-store";
import {useAuthenticationStore} from "../../../stores/store-hooks";
import {handleChangeString} from "../../../utils/handle-change";
import {MomentFormats} from "../../../utils/moment-util";
import {BooleanSelect} from "../../molecules/boolean-select/boolean-select";
import {BorderedList} from "../../molecules/bordered-list/bordered-list";
import {EmptySafeLink} from "../../molecules/empty-safe-link/empty-safe-link";
import {HeadingDetailItem} from "../../molecules/heading-detail-list/heading-detail-item";
import {HeadingDetailList} from "../../molecules/heading-detail-list/heading-detail-list";
import {HeadingLine} from "../../molecules/heading-line/heading-line";
import {Pager} from "../../molecules/pager/pager";
import {SearchPanel} from "../../molecules/search-panel/search-panel";
import {SearchPanelItem} from "../../molecules/search-panel/search-panel-item";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import {UserSelector} from "../../organisms/user-selector/user-selector";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {RevisionListPageStore} from "./revision-list-page-store";

const RevisionListRevisionNameSpan = styled.span`
    font-size: 16px;
    font-weight: bold;
`;

const RevisionListTitleSpan = styled.span`
    font-size: 15px;
    font-weight: normal;
`;

const RevisionListSearchUserDiv = styled.div`
    line-height: 30px;
    min-width: 80px;
`;

export const RevisionListPage: React.FC<{pageStore: RevisionListPageStore}> = observer(
    ({pageStore}) => {
        const hasReadDocumentsPermission = useAuthenticationStore().hasReadDocumentsPermission;
        const activeMediaId = useActiveMediaStore((state) => state.activeMediaInfo.mediaId);

        return (
            <AppLayout
                breadcrumb={
                    <AdminBreadcrumb
                        factory={(builder) => builder.documentRevisionList().build()}
                    />
                }
            >
                <Card>
                    <SearchPanel label="絞り込み" onSearch={pageStore.search}>
                        <SearchPanelItem label="ドキュメントタイプ">
                            <HTMLSelect
                                style={{width: "10em"}}
                                value={pageStore.documentType}
                                onChange={handleChangeString((s) => pageStore.setDocumentType(s))}
                            >
                                <option value="">指定なし</option>
                                <option value={DocumentType.ARTICLE}>記事</option>
                                <option value={DocumentType.BLOCK}>ブロック</option>
                                <option value={DocumentType.PERSON}>人物</option>
                            </HTMLSelect>
                        </SearchPanelItem>
                        <SearchPanelItem label="ドキュメントID">
                            <NumericInput
                                style={{width: "6em"}}
                                value={pageStore.searchDocumentId}
                                buttonPosition="none"
                                onValueChange={(n, s) => pageStore.setSearchDocumentId(s)}
                            />
                        </SearchPanelItem>
                        <SearchPanelItem label="下書き名">
                            <InputGroup
                                style={{width: "10em"}}
                                value={pageStore.searchRevisionName || ""}
                                onChange={handleChangeString(pageStore.setSearchRevisionName)}
                            />
                        </SearchPanelItem>
                        <SearchPanelItem label="タイトル">
                            <InputGroup
                                style={{width: "15em"}}
                                value={pageStore.searchTitle || ""}
                                onChange={handleChangeString(pageStore.setSearchTitle)}
                            />
                        </SearchPanelItem>
                        <SearchPanelItem label="担当者">
                            {hasReadDocumentsPermission ? (
                                <UserSelector
                                    value={pageStore.searchUserName}
                                    emptyOption={true}
                                    placeholder="指定なし"
                                    onChange={(c) => pageStore.setSearchUserName(c ? c.name : "")}
                                />
                            ) : (
                                <RevisionListSearchUserDiv>
                                    {pageStore.searchUserName}
                                </RevisionListSearchUserDiv>
                            )}
                        </SearchPanelItem>
                        <SearchPanelItem label="状態">
                            <BooleanSelect
                                undefinedLabel="指定なし"
                                trueLabel="作業中(未公開)"
                                falseLabel="適用済"
                                value={pageStore.searchIsWorking}
                                onChangeValue={pageStore.setSearchIsWorking}
                            />
                        </SearchPanelItem>
                    </SearchPanel>
                </Card>

                <BorderedList>
                    {pageStore.documentRevisions.map((x) => (
                        <HeadingLine
                            key={x.id.value}
                            icon={<Icon icon="document" />}
                            primary={
                                <EmptySafeLink
                                    route={`/admin/${activeMediaId}/revisions/[documentRevisionId]`}
                                    params={{documentRevisionId: x.id.value}}
                                >
                                    <RevisionListRevisionNameSpan>
                                        {formatRevisionName(x.revisionName, x.revisionType)}
                                    </RevisionListRevisionNameSpan>
                                    <RevisionListTitleSpan>
                                        &nbsp;-&nbsp;{x.title}
                                    </RevisionListTitleSpan>
                                </EmptySafeLink>
                            }
                            secondary={
                                <HeadingDetailList>
                                    <HeadingDetailItem label="ID">{x.id.value}</HeadingDetailItem>
                                    <HeadingDetailItem label="ドキュメントID">
                                        <Link
                                            route={`/admin/${activeMediaId}/documents/[documentId]`}
                                            params={{documentId: x.documentId.value}}
                                        >
                                            <a>{x.documentId.value}</a>
                                        </Link>
                                    </HeadingDetailItem>
                                    <HeadingDetailItem>
                                        {formatRevisionNumber(x.revisionNumber, x.appliedAt)}
                                    </HeadingDetailItem>
                                    <HeadingDetailItem label="カテゴリ">
                                        {formatCategoryItems(x.categories)}
                                    </HeadingDetailItem>
                                    <HeadingDetailItem label="担当者">
                                        {x.userNames.join(", ")}
                                    </HeadingDetailItem>
                                    <HeadingDetailItem label="修正日">
                                        {x.modifiedAt.format(MomentFormats.date)}
                                    </HeadingDetailItem>
                                    <HeadingDetailItem label="更新日">
                                        {x.updatedAt.format(MomentFormats.date)}
                                    </HeadingDetailItem>
                                </HeadingDetailList>
                            }
                        />
                    ))}
                </BorderedList>

                <Pager
                    previousExists={pageStore.previousPageExists}
                    nextExists={pageStore.nextPageExists}
                    onPreviousClicked={pageStore.showPreviousPage}
                    onNextClicked={pageStore.showNextPage}
                />
            </AppLayout>
        );
    },
);
