import styled from "@emotion/styled";
import * as React from "react";

import {ListEditorItemPanel} from "../list-editor-item-panel/list-editor-item-panel";
import {TextListEditorInput} from "./text-list-editor-input";

export interface ITextListEditorItemProps {
    value?: string | null;
    focus?: boolean;
    maxLength?: number;
    placeholder?: string;
    onChange: (value: string) => void;
    onRemove: () => void;
}

interface ITextListEditorItemState {
    isEditing: boolean;
    value: string;
}

const TextListEditorItemStableDiv = styled.div`
    line-height: 30px;
`;

export class TextListEditorItem extends React.Component<
    ITextListEditorItemProps,
    ITextListEditorItemState
> {
    private readonly isNewItem: boolean;
    private inputRef: HTMLInputElement | null = null;

    public constructor(props: ITextListEditorItemProps) {
        super(props);
        this.state = {
            isEditing: !props.value,
            value: props.value || "",
        };
        this.isNewItem = !props.value;
    }

    public componentDidMount() {
        if (this.props.focus && this.inputRef) {
            this.inputRef.focus();
        }
    }

    public render() {
        return this.state.isEditing || !this.props.value
            ? this.renderEditing()
            : this.renderStable();
    }

    private renderEditing() {
        const handleInputRef = (input: HTMLInputElement | null) => {
            this.inputRef = input;
        };

        const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
            this.setState({value: event.currentTarget.value});
        };

        const handleApplyInteraction = () => {
            this.props.onChange(this.state.value);
            this.setState({isEditing: false});
        };

        const handleCancelInteraction = () => {
            this.setState({
                isEditing: false,
                value: this.props.value || "",
            });
        };

        return (
            <ListEditorItemPanel
                onAdd={this.isNewItem ? handleApplyInteraction : undefined}
                onApply={!this.isNewItem ? handleApplyInteraction : undefined}
            >
                <TextListEditorInput
                    maxLength={this.props.maxLength}
                    placeholder={this.props.placeholder}
                    value={this.state.value}
                    inputRef={handleInputRef}
                    onChange={handleChange}
                    onEnterKeyDown={handleApplyInteraction}
                    onEscKeyDown={handleCancelInteraction}
                />
            </ListEditorItemPanel>
        );
    }

    private renderStable() {
        const handleClickEditButton = () => {
            this.setState({isEditing: true}, () => {
                if (this.inputRef) {
                    this.inputRef.focus();
                }
            });
        };

        return (
            <ListEditorItemPanel onEdit={handleClickEditButton} onRemove={this.props.onRemove}>
                <TextListEditorItemStableDiv>{this.props.value}</TextListEditorItemStableDiv>
            </ListEditorItemPanel>
        );
    }
}
