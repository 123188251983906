import {IBlockDefinition} from "@syginc/aqua-client";
import LRUCache from "lru-cache";

import {AdminApi} from "../../api/api";
import {DocumentSearchInput} from "../../api/values/document-search-input";
import {DocumentType} from "../../api/values/document-type";

// TODO: Share caches with other apis
const blockCacheKey = "block-definitions";
const blockCache = new LRUCache<typeof blockCacheKey, Promise<IBlockDefinition[]>>({
    maxAge: 60 * 1000,
});

async function getBlockDefinitionsFromApi(adminApi: AdminApi) {
    const blocks: IBlockDefinition[] = [];

    const url = new URL(location.href);
    const path = url.pathname.split("/");

    if (path.length >= 3 && path[1] === "admin" && path[3] === "documents") {
        let nextPageExists = true;
        for (let i = 1; i < 100 && nextPageExists; i++) {
            const result = await adminApi.getDocuments(
                new DocumentSearchInput({
                    mediaId: path[2],
                    page: i,
                    pageSize: 100,
                    documentType: DocumentType.BLOCK,
                }),
            );

            result.documents.forEach((x) =>
                blocks.push({
                    slug: x.slug,
                    title: x.title,
                }),
            );

            nextPageExists = result.nextPageExists;
        }
    }

    return blocks;
}

export async function getBlockDefinitions(adminApi: AdminApi): Promise<IBlockDefinition[]> {
    const cacheEntry = blockCache.get("block-definitions");
    if (cacheEntry) {
        return cacheEntry;
    }

    const result = getBlockDefinitionsFromApi(adminApi);
    blockCache.set("block-definitions", result);
    return result;
}
