import styled from "@emotion/styled";
import React from "react";

import {Link} from "../../../routes";

export interface SideNavIconProps {
    isActive?: boolean;
    link?: string;
    onClick?: () => void;
    children: React.ReactNode;
}

const SideNavIcon: React.FC<SideNavIconProps> = (props) => {
    const MainDiv = styled.div`
        padding: 5px;
        background-color: "unset";
    `;

    const IconButton = styled.div`
        cursor: pointer;
        padding: 0;
        border: 2px solid #c5cbd3;
        width: 50px;
        height: 50px;
        line-height: 50px;
        background-color: #ffffff;
        border-radius: 50%;
        overflow: hidden;
        &:hover {
            border: 3px solid #106ba3;
        }
    `;
    const IconBody = styled.div`
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
    `;
    return (
        <MainDiv data-testid="side-nav-icon">
            <IconButton
                onClick={props.onClick}
                style={props.isActive ? {border: "3px solid #106ba3"} : undefined}
            >
                {props.link ? (
                    <Link route={`${props.link}`} passHref={true}>
                        <IconBody>{props.children}</IconBody>
                    </Link>
                ) : (
                    <IconBody>{props.children}</IconBody>
                )}
            </IconButton>
        </MainDiv>
    );
};

export default SideNavIcon;
