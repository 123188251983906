import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";
import {CategoryGroupListItemOutput} from "./category-group-list-item-output";

@JsonObject("CategoryGroupListOutput")
export class CategoryGroupListOutput {
    @JsonProperty()
    public categoryGroups: CategoryGroupListItemOutput[] = JsonPropertyEmpty;
}
