export enum DocumentType {
    ARTICLE = "ARTICLE",
    BLOCK = "BLOCK",
    PERSON = "PERSON",
}

export enum DocumentTypeEnum {
    ARTICLE = "1",
    BLOCK = "2",
    PERSON = "3",
}
