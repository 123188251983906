import {action, computed, makeObservable, observable, runInAction} from "mobx";

import {AdminApi} from "../../../api/api";
import {UserAddInput} from "../../../api/values/user-add-input";
import {UserInput} from "../../../api/values/user-input";
import {UserRole} from "../../../api/values/user-role-type";
import {UserSearchInput} from "../../../api/values/user-search-input";
import {UserUpdateInput} from "../../../api/values/user-update-input";
import {Stores} from "../../../stores";
import {Permission} from "../../../stores/authentication-store";
import {SimpleToast} from "../../../stores/toast-store";
import {BigIntId} from "../../../utils/big-int-id";
import {conflictChecker} from "../../../utils/conflict-checker-util";
import {PageStoreConstructorOptions} from "../../../utils/page-store";
import {PageRouter} from "../../../utils/route-util";
import {emailValidator} from "../../../utils/validation/rules/email-validator";
import {passwordValidator} from "../../../utils/validation/rules/password-validator";
import {usernameValidator} from "../../../utils/validation/rules/username-validator";
import {ValidationResult, ValidationResultEmpty} from "../../../utils/validation/validation-result";
import {withInProgress} from "../../../utils/with-in-progress";

export class UserEditPageStore {
    @observable
    public isNew = true;

    @observable
    public id?: BigIntId = undefined;

    @observable
    public mediaId: string = "";

    @observable
    public name: string = "";

    @observable
    public editingName: string = "";

    @observable
    public email: string = "";

    @observable
    public password: string = "";

    @observable
    public role: UserRole = UserRole.ADMIN;

    @observable
    public enabled: boolean = false;

    @observable
    public nameValidationResult: ValidationResult = ValidationResultEmpty;

    @observable
    public emailValidationResult: ValidationResult = ValidationResultEmpty;

    @observable
    public passwordValidationResult: ValidationResult = ValidationResultEmpty;

    @observable
    public isNameValidationInProgress: boolean = false;

    @observable
    public isNameConflicted: boolean = false;

    @observable
    public isSavingInProgress: boolean = false;

    private adminApi: AdminApi;
    private stores: Stores;
    private pageRouter: PageRouter;

    constructor(options: PageStoreConstructorOptions) {
        this.adminApi = options.adminApi;
        this.stores = options.stores;
        this.pageRouter = options.pageRouter;

        makeObservable(this);
    }

    @computed
    public get canSave() {
        return (
            this.editingName.length > 0 &&
            this.email.length > 0 &&
            this.nameValidationResult.isEmpty &&
            this.emailValidationResult.isEmpty &&
            (!this.isNew || this.passwordValidationResult.isEmpty) &&
            !this.isNameValidationInProgress &&
            !this.isNameConflicted &&
            !this.isSavingInProgress
        );
    }

    @computed
    public get canEdit() {
        return (
            this.stores.authenticationStore.hasManageAdminUsersPermission ||
            !this.role.equals(UserRole.ADMIN)
        );
    }

    @computed
    public get availableRoles() {
        if (!this.canEdit) {
            return [this.role];
        }
        if (!this.stores.authenticationStore.hasManageAdminUsersPermission) {
            return UserRole.values.filter((role) => !role.equals(UserRole.ADMIN));
        } else {
            return UserRole.values;
        }
    }

    private checkConflictName = conflictChecker(
        (b) => (this.isNameValidationInProgress = b),
        (b) => (this.isNameConflicted = b),
        async (currentValue) => {
            const input = new UserSearchInput({
                mediaId: this.mediaId,
                name: currentValue,
            });
            const result = await this.adminApi.getUsers(input);
            return result.users.length > 0;
        },
    );

    public async initialize({query}: any) {
        this.stores.authenticationStore.checkAnyPermissionExists(
            Permission.MANAGE_ADMIN_USERS,
            Permission.MANAGE_USERS,
        );

        const id = query && query.id;
        if (!id) {
            throw new Error("id is not set");
        }

        this.setMediaId(query.mediaId || "");

        if (id === "new") {
            await this.clear();
        } else {
            await this.load(new BigIntId(id));
        }
    }

    @action.bound
    public async setMediaId(s: string) {
        this.mediaId = s;
    }

    @action.bound
    public async setName(s: string) {
        this.editingName = s;
        (await usernameValidator(s)).run((v) => (this.nameValidationResult = v));
        if (this.nameValidationResult.isEmpty) {
            await this.validateName();
        }
    }

    @action.bound
    public async setEmail(s: string) {
        this.email = s;
        (await emailValidator(s)).run((v) => (this.emailValidationResult = v));
    }

    @action.bound
    public async setPassword(s: string) {
        this.password = s;
        (await passwordValidator(s)).run((v) => (this.passwordValidationResult = v));
    }

    @action.bound
    public async setRole(r: UserRole) {
        this.role = r;
    }

    @action.bound
    public async setEnabled(b: boolean) {
        this.enabled = b;
    }

    @action.bound
    public async clear() {
        this.isNew = true;
        this.id = undefined;
        this.name = "";
        this.editingName = "";
        this.email = "";
        this.password = "";
        this.role = UserRole.WRITER;
        this.enabled = true;
    }

    @action.bound
    public async load(id: BigIntId) {
        const result = await this.adminApi.getUser(
            {id},
            new UserInput({
                mediaId: this.mediaId,
            }),
        );

        runInAction(() => {
            this.isNew = false;
            this.id = id;
            this.name = result.name;
            this.editingName = result.name;
            this.email = result.email;
            this.password = "";
            this.role = UserRole.fromName(result.role) || UserRole.WRITER;
            this.enabled = result.enabled;
        });
    }

    @action.bound
    public async save() {
        await withInProgress(
            (b) => (this.isSavingInProgress = b),
            async () => {
                if (this.isNew) {
                    await this.saveNew();
                } else {
                    await this.saveToExisting();
                }

                this.stores.toastStore.setSimpleToast(SimpleToast.SAVED);
                this.stores.toastStore.keepFirstRouteChange();

                this.pageRouter.pushRoute("/admin/[mediaId]/users", {
                    mediaId: this.mediaId,
                    enabled: "true",
                });
            },
        );
    }

    @action.bound
    public validateName() {
        this.checkConflictName(this.name, this.editingName);
    }

    @action.bound
    private async saveNew() {
        await this.adminApi.postUser(
            {mediaId: this.mediaId},
            new UserAddInput({
                email: this.email,
                name: this.editingName,
                password: this.password,
                role: this.role.name,
            }),
        );
    }

    @action.bound
    private async saveToExisting() {
        await this.adminApi.patchUser(
            {mediaId: this.mediaId},
            {id: this.id!},
            new UserUpdateInput({
                email: this.email,
                enabled: this.enabled,
                name: this.editingName,
                role: this.role.name,
            }),
        );
    }
}
