import {action, makeObservable, observable, runInAction} from "mobx";
import moment from "moment-timezone";

import {AdminApi} from "../../../api/api";
import {AomNodeDifference} from "../../../api/values/aom-node-difference";
import {DocumentContentDiffInput} from "../../../api/values/document-content-diff-input";
import {DocumentGetInput} from "../../../api/values/document-get-input";
import {DocumentPropertyInputOutput} from "../../../api/values/document-property-input-output";
import {DocumentRevisionAddInput} from "../../../api/values/document-revision-add-input";
import {DocumentRevisionGetInput} from "../../../api/values/document-revision-get-input";
import {DocumentRevisionUserInputOutput} from "../../../api/values/document-revision-user-input-output";
import {Stores} from "../../../stores";
import {Permission} from "../../../stores/authentication-store";
import {SimpleToast} from "../../../stores/toast-store";
import {filterNotNullOrUndefined} from "../../../utils/array-util";
import {getAuthInfo} from "../../../utils/auth-util";
import {BigIntId} from "../../../utils/big-int-id";
import {PageStoreConstructorOptions} from "../../../utils/page-store";
import {IPageStore} from "../../../utils/page-store";
import {PageRouter} from "../../../utils/route-util";
import {withInProgress} from "../../../utils/with-in-progress";
import {PropertyDiffStore} from "../../organisms/property-diff-panel/property-diff-store";

export class DocumentRevisionDetailPageStore implements IPageStore {
    @observable
    public mediaId: string = "";

    @observable
    public documentRevisionId: BigIntId = new BigIntId("0");

    @observable
    public documentId: BigIntId = new BigIntId("0");

    @observable
    public appliedAt: moment.Moment | null = null;

    @observable
    public revisionNumber: number | null = null;

    @observable
    public unschedulable = false;

    @observable.shallow
    public differences: AomNodeDifference[] | null = null;

    @observable
    public isPublishing = false;

    @observable
    public userNames: string[] = [];

    @observable
    public isSaving: boolean = false;

    public propertyDiffStore: PropertyDiffStore;
    private adminApi: AdminApi;
    private stores: Stores;
    private pageRouter: PageRouter;

    constructor(options: PageStoreConstructorOptions) {
        this.adminApi = options.adminApi;
        this.stores = options.stores;
        this.pageRouter = options.pageRouter;
        this.propertyDiffStore = new PropertyDiffStore({adminApi: this.adminApi});

        makeObservable(this);
    }

    public async initialize({query}: any) {
        if (!query || !query.documentRevisionId) {
            throw new Error("documentRevisionId is not set");
        }

        this.setMediaId(query.mediaId);

        await this.loadDocumentRevision(new BigIntId(query.documentRevisionId));

        this.stores.authenticationStore.checkAllUsersOrLimitedUserPermissionExist(
            Permission.READ_DOCUMENTS,
            Permission.READ_ASSIGNED_DOCUMENTS,
            this.userNames,
        );
    }

    @action.bound
    public async unschedule() {
        this.isPublishing = true;
        await this.adminApi.postDocumentRevisionUnschedule(
            {mediaId: this.mediaId},
            {id: this.documentRevisionId},
        );
        runInAction(() => {
            this.isPublishing = false;
        });

        this.stores.toastStore.setSimpleToast(SimpleToast.FINISHED_PUBLISH_SETTING);
        this.stores.toastStore.keepFirstRouteChange();

        this.pageRouter.pushRoute("/admin/[mediaId]/documents/[documentId]", {
            documentId: this.documentId.value,
            mediaId: this.mediaId,
        });
    }

    @action.bound
    private async loadDocumentRevision(id: BigIntId) {
        this.documentRevisionId = id;

        const documentRevision = await this.adminApi.getDocumentRevision(
            {id: this.documentRevisionId},
            new DocumentRevisionGetInput({
                mediaId: this.mediaId,
            }),
        );
        runInAction(() => {
            this.documentRevisionId = documentRevision.id;
            this.documentId = documentRevision.documentId;
            this.appliedAt = documentRevision.appliedAt ? moment(documentRevision.appliedAt) : null;
            this.revisionNumber = documentRevision.revisionNumber;
            this.unschedulable = documentRevision.unschedulable;
            this.userNames = documentRevision.users.map((u) => u.name);
        });

        const document = await this.adminApi.getDocument(
            {id: this.documentId},
            new DocumentGetInput({mediaId: this.mediaId}),
        );
        let baseDocumentContentId: BigIntId;
        let baseProperty: DocumentPropertyInputOutput;
        if (documentRevision.appliedAt) {
            baseDocumentContentId = documentRevision.baseDocumentContentId!;
            baseProperty = documentRevision.baseProperty;
        } else {
            const activeDocumentRevision = await this.adminApi.getDocumentRevision(
                {id: document.activeDocumentRevisionId},
                new DocumentRevisionGetInput({
                    mediaId: this.mediaId,
                }),
            );
            baseDocumentContentId = activeDocumentRevision.documentContentId;
            baseProperty = activeDocumentRevision.property;
        }

        await this.propertyDiffStore.setProperties(
            this.mediaId,
            baseProperty,
            documentRevision.property,
        );
        const contentDiff = await this.adminApi.getDocumentContentDiff(
            {id: documentRevision.documentContentId},
            new DocumentContentDiffInput({
                mediaId: this.mediaId,
                baseDocumentContentId,
            }),
        );
        runInAction(() => {
            this.differences = contentDiff.differences;
        });
    }

    @action.bound
    public async saveNew(revisionName: string) {
        await withInProgress(
            (b) => {
                this.isSaving = b;
            },
            () => this.save(revisionName),
        );
        this.stores.toastStore.setSimpleToast(SimpleToast.SAVED);
    }

    private async save(revisionName: string) {
        const users = filterNotNullOrUndefined([getAuthInfo()]).map(
            (x) => new DocumentRevisionUserInputOutput({name: x.username}),
        );

        const document = await this.adminApi.getDocument(
            {id: this.documentId},
            new DocumentGetInput({mediaId: this.mediaId}),
        );
        const result = await this.adminApi.postDocumentRevision(
            {mediaId: this.mediaId},
            new DocumentRevisionAddInput({
                referenceDocumentRevisionId: this.documentRevisionId,
                baseDocumentRevisionId: document.activeDocumentRevisionId,
                revisionName,
                users,
            }),
        );

        this.pageRouter.pushRoute("/admin/[mediaId]/revisions/[documentRevisionId]", {
            mediaId: this.mediaId,
            documentRevisionId: result.id.value,
        });
    }

    @action.bound
    public setMediaId(mediaId: string) {
        this.mediaId = mediaId;
    }
}
