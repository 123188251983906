export interface MediaInfoApi {
    id: string;
    name: string;
    summary: string;
    base_url: string;
    title: string;
    description: string;
    og_image_url: string;
    favicon_url: string;
    thumbnail_url: string;
    protection_status: "1" | "2";
    api_token: string;
}

export interface MediaInfoState {
    mediaId: string;
    mediaName: string;
    mediaSummary: string;
    mediaUrl: string;
    mediaTitle: string;
    mediaDescription: string;
    mediaOgImage: string;
    mediaFavicon: string;
    mediaThumbnail: string;
    mediaProtectionStatus: "1" | "2";
    mediaApiToken: string;
}

export const createMediaInfo = (mediaInfoApi: MediaInfoApi): MediaInfoState => {
    return {
        mediaId: String(mediaInfoApi.id),
        mediaName: mediaInfoApi.name,
        mediaSummary: mediaInfoApi.summary,
        mediaUrl: mediaInfoApi.base_url,
        mediaTitle: mediaInfoApi.title,
        mediaDescription: mediaInfoApi.description,
        mediaOgImage: mediaInfoApi.og_image_url,
        mediaFavicon: mediaInfoApi.favicon_url,
        mediaThumbnail: mediaInfoApi.thumbnail_url,
        mediaProtectionStatus: mediaInfoApi.protection_status,
        mediaApiToken: mediaInfoApi.api_token,
    };
};
