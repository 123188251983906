import {Button, Callout, FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";

import {handleChangeString} from "../../../utils/handle-change";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import {SlugListEditor} from "../../organisms/slug-list-editor/slug-list-editor";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {DocumentGroupEditPageStore} from "./document-group-edit-page-store";

@observer
export class DocumentGroupEditPage extends React.Component<
    {
        pageStore: DocumentGroupEditPageStore;
    },
    {}
> {
    public render() {
        return (
            <AppLayout
                breadcrumb={
                    <AdminBreadcrumb
                        factory={(builder) =>
                            builder.documentGroupList().custom("ドキュメントグループ編集").build()
                        }
                    />
                }
            >
                {this.props.pageStore.lackedSlugs.length > 0 && (
                    <Callout intent={Intent.WARNING}>
                        無効なスラッグは無視されました:{" "}
                        {this.props.pageStore.lackedSlugs.join(", ")}
                    </Callout>
                )}

                <div style={{marginTop: "20px", width: "400px"}}>
                    <FormGroup label="名称">
                        <InputGroup
                            placeholder="グループ名称"
                            value={this.props.pageStore.title}
                            onChange={handleChangeString(this.props.pageStore.setTitle)}
                        />
                    </FormGroup>

                    <FormGroup label="ドキュメント">
                        <SlugListEditor
                            slugs={this.props.pageStore.slugs}
                            onChange={this.props.pageStore.setSlugs}
                        />
                    </FormGroup>

                    <div>
                        <Button
                            onClick={this.props.pageStore.saveAndRefresh}
                            loading={this.props.pageStore.isSaving}
                            icon="floppy-disk"
                            disabled={!this.props.pageStore.canSave}
                        >
                            保存
                        </Button>
                    </div>
                </div>
            </AppLayout>
        );
    }
}
