import {configure} from "mobx";
import * as React from "react";
import {BrowserRouter} from "react-router-dom";

import {AppProvider} from "./app-provider";
import {AppRouter} from "./app-router";

export class App extends React.Component {
    public constructor(props: any, state: any) {
        super(props, state);

        configure({enforceActions: "always"});
    }

    public render() {
        return (
            <BrowserRouter>
                <AppProvider>
                    <AppRouter />
                </AppProvider>
            </BrowserRouter>
        );
    }
}
