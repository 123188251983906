const ID_REGEX = /^[0-9]{1,20}$/; // "18446744073709551615".length == 20

export class BigIntId {
    constructor(public value: string) {
        if (!ID_REGEX.test(value)) {
            throw new Error("Invalid ID string: " + value);
        }
    }

    public toString() {
        return this.value;
    }

    public toJSON() {
        return this.value;
    }
}
