import {ValidationFunction} from "../validation-function";
import {ValidationResultItem} from "../validation-result-item";

const slugPattern = /^[-_0-9a-z]*$/;

// TODO: Move to "../rules/slug-validator.ts"
// TODO: 適切に分割する？
export const validateSlug: ValidationFunction = () => {
    return (s) => {
        if (!slugPattern.test(s)) {
            return [new ValidationResultItem("slug.pattern", `a-z, 0-9, -, _のみ利用可能です`)];
        }
        return [];
    };
};
