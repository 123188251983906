import * as React from "react";

import {CategoryCacheProvider} from "../../organisms/category-cache/category-cache";
import {UserListCacheProvider} from "../../organisms/user-list-cache/user-list-cache";

export const PageScopeCacheProvider: React.FC = ({children}) => (
    <CategoryCacheProvider>
        <UserListCacheProvider>{children}</UserListCacheProvider>
    </CategoryCacheProvider>
);
