import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";
import {DocumentRevisionListItemOutput} from "./document-revision-list-item-output";

@JsonObject("DocumentRevisionSearchOutput")
export class DocumentRevisionSearchOutput {
    @JsonProperty("documentRevisions", [DocumentRevisionListItemOutput])
    public documentRevisions: DocumentRevisionListItemOutput[] = JsonPropertyEmpty;

    @JsonProperty()
    public nextPageExists: boolean = JsonPropertyEmpty;

    @JsonProperty()
    public previousPageExists: boolean = JsonPropertyEmpty;

    @JsonProperty("totalCount", Number, true)
    public totalCount?: number = JsonPropertyEmpty;
}
