import {DeviceType} from "@syginc/aomjs";
import * as React from "react";

import {IAquaEditor} from "../../../editor/aqua-editor";

interface AomContentContainerProps {
    deviceType: DeviceType;
    aquaEditor: IAquaEditor;
}

const ArticleContainer: React.FC<{aquaEditor: IAquaEditor; deviceType: DeviceType}> = ({
    aquaEditor,
    deviceType,
    children,
}) => {
    return <div className={aquaEditor.getContentContainerClassName({deviceType})}>{children}</div>;
};

export const AomContentContainer: React.FC<AomContentContainerProps> = ({
    deviceType,
    aquaEditor,
    children,
}) => {
    return (
        <ArticleContainer aquaEditor={aquaEditor} deviceType={deviceType}>
            {children}
        </ArticleContainer>
    );
};
