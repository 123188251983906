import * as React from "react";
import {v4 as uuid} from "uuid";

import {IListEditorItem, IListEditorItemProps, ListEditor} from "../list-editor/list-editor";
import {TextListEditorItem} from "./text-list-editor-item";

interface ITextListEditorProps {
    values: string[];
    maxLength?: number;
    placeholder?: string;
    onChange: (values: string[]) => void;
}

interface ITextListEditorState {
    items: Array<IListEditorItem<string>>;
}

export class TextListEditor extends React.Component<ITextListEditorProps, ITextListEditorState> {
    public static getDerivedStateFromProps(
        nextProps: ITextListEditorProps,
        prevState: ITextListEditorState,
    ) {
        const nextValues = nextProps.values.concat([""]);
        const prevValues = prevState.items.map((item) => item.data);

        if (JSON.stringify(nextValues) === JSON.stringify(prevValues)) {
            return null;
        }

        return {
            items: nextValues.map((value) => ({
                data: value,
                key: uuid(),
            })),
        };
    }

    private smartFocus: boolean = false;

    constructor(props: ITextListEditorProps) {
        super(props);
        this.state = {items: []};
    }

    public render() {
        const handleChange = (items: Array<IListEditorItem<string>>) => {
            const values = items.map((item) => item.data).filter((data) => !!data) as string[];
            this.props.onChange(values);
        };

        const handleChangeItem = (value: string, props: IListEditorItemProps<string>) => {
            this.smartFocus = true;
            props.onChange({
                data: value,
                key: props.item.key,
            });
        };

        return (
            <ListEditor
                items={this.state.items}
                onChange={handleChange}
                itemComponentFactory={(props) => {
                    let focus = false;
                    if (!props.item.data) {
                        focus = this.smartFocus;
                        this.smartFocus = false;
                    }

                    return (
                        <TextListEditorItem
                            value={props.item.data}
                            focus={focus}
                            maxLength={this.props.maxLength}
                            placeholder={this.props.placeholder}
                            onChange={(value) => handleChangeItem(value, props)}
                            onRemove={props.onRemove}
                        />
                    );
                }}
            />
        );
    }
}
