import {makeObservable, observable, runInAction} from "mobx";

export class ValidationResultItem {
    @observable
    public errorType!: string;

    @observable
    public errorMessage!: string;

    public constructor(errorType: string, errorMessage: string) {
        makeObservable(this);
        runInAction(() => {
            this.errorType = errorType;
            this.errorMessage = errorMessage;
        });
    }
}
