import {HTMLSelect} from "@blueprintjs/core";
import * as React from "react";
import {ChangeEvent} from "react";

interface IBooleanSelectProps {
    undefinedLabel?: string;
    trueLabel?: string;
    falseLabel?: string;
    value?: boolean;
    onChangeValue?: (b?: boolean) => void;
}

function booleanToString(value?: boolean): string {
    if (value === undefined) {
        return "undefined";
    } else if (value === true) {
        return "true";
    } else {
        return "false";
    }
}

function stringToBoolean(value: string): boolean | undefined {
    if (value === "true") {
        return true;
    } else if (value === "false") {
        return false;
    } else {
        return undefined;
    }
}

export class BooleanSelect extends React.PureComponent<IBooleanSelectProps> {
    private handleChangeBinded = this.handleChange.bind(this);

    public render() {
        return (
            <HTMLSelect
                value={booleanToString(this.props.value)}
                onChange={this.handleChangeBinded}
            >
                {this.props.undefinedLabel && (
                    <option value="undefined">{this.props.undefinedLabel}</option>
                )}
                {this.props.trueLabel && <option value="true">{this.props.trueLabel}</option>}
                {this.props.falseLabel && <option value="false">{this.props.falseLabel}</option>}
            </HTMLSelect>
        );
    }

    private handleChange(e: ChangeEvent<HTMLSelectElement>) {
        if (this.props.onChangeValue) {
            this.props.onChangeValue(stringToBoolean(e.target.value));
        }
    }
}
