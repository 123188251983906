import {Icon} from "@blueprintjs/core";
import styled from "@emotion/styled";
import React, {useMemo} from "react";

import {DocumentType} from "../../../api/values/document-type";
import {Link} from "../../../routes";
import {useActiveMediaStore} from "../../../stores/active-media-store";
import {useAuthenticationStore} from "../../../stores/store-hooks";
import {getSiteUrl} from "../../../utils/global-config";
import {ResponsiveAnchorButton} from "../../molecules/responsive-labeled-button/responsive-anchor-button";

/**
 * サイドメニューのメディアタイトルスタイルを定義
 */
const MediaTitle = styled.div`
    font-size: 24px;
    font-weight: "bold";
    width: 160px;
`;

/**
 * サイドメニュー全体のスタイルを定義
 */
const StyledSideMenu = styled.nav`
    display: flex;
    flex-direction: column;
    min-width: fit-content;
    align-items: flex-start;
    padding: 16px;
    gap: 40px;
    height: 100vh;
    position: sticky;
    top: 0;
`;

/**
 * メニューグループのスタイルを定義
 */
const MenuGroupe = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

/**
 * メニュータイトルのスタイルを定義
 */
const MenuTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: bold;
    margin: 0 0 6px 10px;
`;

/**
 * 外部リンク用のスタイルを定義
 */
const TargetBlank = styled.a`
    color: #182026;
    padding: 10px;
    display: inline-block;
    &:hover {
        background: rgba(167, 182, 194, 0.3);
        box-shadow: none;
        text-decoration: none;
        color: #182026;
        border-radius: 4px;
    }
`;

/**
 * サイドメニューコンポーネント
 *
 * メディアの管理や設定などのリンクを提供するためのサイドメニューを表示します。
 * ユーザーの権限に応じて表示されるメニュー項目が動的に変わります。
 *
 * @returns {JSX.Element} サイドメニューのJSX要素
 */
export const SideMenu: React.FC = () => {
    const activeMediaInfo = useActiveMediaStore((state) => state.activeMediaInfo);
    const activeMediaId = useMemo(() => activeMediaInfo.mediaId, [activeMediaInfo]);
    const activeMediaName = useMemo(() => activeMediaInfo.mediaName, [activeMediaInfo]);

    const authenticationStore = useAuthenticationStore();
    const {
        hasManageAdminUsersPermission,
        hasManageUsersPermission,
        hasEditGeneralResourcesPermission,
        hasReadDocumentsPermission,
        username,
    } = authenticationStore!;
    const usernameParams = username ? {userName: username} : undefined;

    return (
        <StyledSideMenu>
            <MediaTitle>{activeMediaName}</MediaTitle>
            <MenuGroupe>
                <MenuTitle>
                    <Icon icon="draw" />
                    <span>記事管理</span>
                </MenuTitle>
                {hasReadDocumentsPermission && (
                    <Link route={`/admin/${activeMediaId}/documents`} passHref={true}>
                        <ResponsiveAnchorButton text="ドキュメント" minimal={true} />
                    </Link>
                )}
                <Link
                    route={`/admin/${activeMediaId}/revisions`}
                    params={{isWorking: "true", ...usernameParams, type: DocumentType.ARTICLE}}
                    passHref={true}
                >
                    <ResponsiveAnchorButton text="下書き" minimal={true} />
                </Link>
                {(hasManageAdminUsersPermission ||
                    hasManageUsersPermission ||
                    hasEditGeneralResourcesPermission) && (
                    <Link route={`/admin/${activeMediaId}/document-groups`} passHref={true}>
                        <ResponsiveAnchorButton text="ドキュメントグループ" minimal={true} />
                    </Link>
                )}
                {(hasManageAdminUsersPermission ||
                    hasManageUsersPermission ||
                    hasEditGeneralResourcesPermission) && (
                    <Link
                        route={`/admin/${activeMediaId}/documents`}
                        params={{type: DocumentType.BLOCK}}
                    >
                        <ResponsiveAnchorButton text="ブロック" minimal={true} />
                    </Link>
                )}
                {(hasManageAdminUsersPermission ||
                    hasManageUsersPermission ||
                    hasEditGeneralResourcesPermission) && (
                    <Link
                        route={`/admin/${activeMediaId}/documents`}
                        params={{type: DocumentType.PERSON}}
                    >
                        <ResponsiveAnchorButton text="人物" minimal={true} />
                    </Link>
                )}
            </MenuGroupe>
            <MenuGroupe>
                <MenuTitle>
                    <Icon icon="desktop" />
                    <span>メディア設定</span>
                </MenuTitle>
                {hasEditGeneralResourcesPermission && (
                    <Link route={`/admin/${activeMediaId}/media-settings`} passHref={true}>
                        <ResponsiveAnchorButton text="基本設定" minimal={true} />
                    </Link>
                )}
                {(hasManageAdminUsersPermission ||
                    hasManageUsersPermission ||
                    hasEditGeneralResourcesPermission) && (
                    <Link route={`/admin/${activeMediaId}/users`} passHref={true}>
                        <ResponsiveAnchorButton text="アカウント管理" minimal={true} />
                    </Link>
                )}
            </MenuGroupe>
            <MenuGroupe>
                <MenuTitle>
                    <Icon icon="settings" />
                    <span>システム設定</span>
                </MenuTitle>
                {hasEditGeneralResourcesPermission && (
                    <Link route={`/admin/${activeMediaId}/media-publishing`} passHref={true}>
                        <ResponsiveAnchorButton text="メディア公開設定" minimal={true} />
                    </Link>
                )}
                {hasEditGeneralResourcesPermission && (
                    <Link route={`/admin/${activeMediaId}/media-delete`} passHref={true}>
                        <ResponsiveAnchorButton text="メディア削除" minimal={true} />
                    </Link>
                )}
            </MenuGroupe>

            <div
                style={{
                    marginTop: "auto",
                }}
            >
                <div>
                    <Icon icon="globe" />
                    <TargetBlank target="_blank" href={getSiteUrl()}>
                        メディアを表示
                    </TargetBlank>
                </div>
            </div>
        </StyledSideMenu>
    );
};
