import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("AomDiffInput")
export class AomDiffInput {
    @JsonProperty()
    public leftJson: string = JsonPropertyEmpty;

    @JsonProperty()
    public rightJson: string = JsonPropertyEmpty;

    public constructor(args?: Partial<AomDiffInput>) {
        Object.assign(this, args);
    }
}
