import {NodeObject} from "@syginc/aomjs";

import {AdminApi} from "../api/api";
import {DocumentRevisionUpdateHtmlInput} from "../api/values/document-revision-update-html-input";
import {IAquaEditor} from "../editor/aqua-editor";
import {BigIntId} from "./big-int-id";
import {renderSourceHtml} from "./render-html";

export async function saveHtmlCache(
    aquaEditor: IAquaEditor,
    documentRevisionId: BigIntId,
    aomNode: NodeObject,
    adminApi: AdminApi,
    mediaId: string,
) {
    const renderedHtml = await renderSourceHtml(aquaEditor, aomNode);

    await adminApi.putDocumentRevisionHtml(
        {mediaId: mediaId},
        {id: documentRevisionId},
        new DocumentRevisionUpdateHtmlInput({html: renderedHtml}),
    );
}
