import {observer} from "mobx-react";
import * as React from "react";

import {useAuthenticationStore} from "../../../stores/store-hooks";
import {DocumentAddButton} from "../../organisms/document-add-button/document-add-button";
import {DocumentListPageStore} from "./document-list-page-store";

export const DocumentListButtons: React.FC<{pageStore: DocumentListPageStore}> = observer(
    ({pageStore}) => {
        const authenticationStore = useAuthenticationStore();

        if (!authenticationStore.hasManageDocumentsPermission) {
            return <></>;
        }

        return (
            <DocumentAddButton
                mediaId={pageStore.mediaId}
                store={pageStore.documentAddStore}
                documentType={pageStore.documentType}
                onCreated={pageStore.search}
            />
        );
    },
);
