import {Spinner} from "@blueprintjs/core";
import createCache from "@emotion/cache";
import {CacheProvider} from "@emotion/react";
import weakMemoize from "@emotion/weak-memoize";
import * as React from "react";
import {ReactNode, useCallback, useState} from "react";

import {IAquaEditor} from "../../../editor/aqua-editor";
import {FrameContainer} from "../frame-container/frame-container";

const containerMemoizedCache = weakMemoize((container: HTMLElement) =>
    createCache({key: "css-site", container}),
);

export interface SiteStyledFrameProps {
    aquaEditor: IAquaEditor;
    children?: ReactNode;
}

export const SiteStyledFrame: React.FC<SiteStyledFrameProps> = ({aquaEditor, children}) => {
    const [isReady, setIsReady] = useState(false);
    const handleReady = useCallback(() => {
        setIsReady(true);
    }, [setIsReady]);

    return (
        <>
            {!isReady && <Spinner />}

            <FrameContainer cssUrl={aquaEditor.cssUrl} onReady={handleReady}>
                {(doc) => (
                    <CacheProvider value={containerMemoizedCache(doc.head)}>
                        {children}
                    </CacheProvider>
                )}
            </FrameContainer>
        </>
    );
};
