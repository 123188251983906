import {EmptyOutput} from "../api/values/empty-output";
import {PasswordResetInput} from "../api/values/password-reset-input";
import {PasswordResetRequestInput} from "../api/values/password-reset-request-input";
import {getServerUrl} from "./global-config";
import {requestObject} from "./http-request";

export async function requestPasswordReset(email: string) {
    // TODO: routesから取りたい
    const adminUrl = location.origin + "/admin/password-reset";
    await requestObject(
        "POST",
        "/api/password-reset",
        EmptyOutput,
        undefined,
        new PasswordResetRequestInput({
            email,
            template: `
パスワードのリセットを受け付けました。
新しいパスワードを設定するには、次の URL にアクセスしてください。

${adminUrl}/{RESET_TOKEN}

パスワードを変更しない場合や、パスワードリセットをリクエストしていない場合は、このメールを無視してください。
アカウントの安全性を維持するため、このメールは他者に転送しないでください。

セキュリティに関するお問い合わせは運営管理者までご連絡ください。

Aqua をご利用いただきありがとうございます。
- Aqua 開発スタッフ 一同
`,
        }),
        {baseUrl: getServerUrl()},
    );
}

export async function passwordReset(resetToken: string, newPassword: string) {
    await requestObject(
        "POST",
        `/api/password-reset/${resetToken}`,
        EmptyOutput,
        undefined,
        new PasswordResetInput({newPassword}),
        {baseUrl: getServerUrl()},
    );
}
