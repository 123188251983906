import styled from "@emotion/styled";
import {DeviceType, NodeObject} from "@syginc/aomjs";
import * as React from "react";
import {useEffect, useState} from "react";

import {IAquaEditor} from "../../../editor/aqua-editor";
import {checkDiffAddOrRemoveNodeExists, processDiffMeta} from "../../../utils/aom-diff-meta-util";
import {getMobilePreviewWidth} from "../../../utils/mobile-util";
import {AomRenderer} from "../../molecules/aom-renderer/aom-renderer";

interface IVisualDiffSideProps {
    node: NodeObject;
    aquaEditor: IAquaEditor;
}

const DiffWrapperDiv = styled.div<{changeExists?: boolean}>`
    padding-left: 3px;
    border-left: 3px solid ${({changeExists}) => (changeExists ? "#106ba3" : "transparent")};
`;

const DiffContentDiv = styled.div`
    width: ${getMobilePreviewWidth()};
    background-color: white;
    overflow: hidden;
`;

export const VisualDiffSide: React.FC<IVisualDiffSideProps> = ({node, aquaEditor}) => {
    const [changeExists, setChangeExists] = useState(false);
    const [processedNode, setProcessedNode] = useState<NodeObject | null>(null);

    useEffect(() => {
        setChangeExists(checkDiffAddOrRemoveNodeExists(node));
        setProcessedNode(processDiffMeta(node));
    }, [node]);

    return (
        <DiffWrapperDiv changeExists={changeExists}>
            <DiffContentDiv>
                {processedNode && (
                    <AomRenderer
                        node={processedNode}
                        deviceType={DeviceType.Mobile}
                        aquaEditor={aquaEditor}
                    />
                )}
            </DiffContentDiv>
        </DiffWrapperDiv>
    );
};
