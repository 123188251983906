import {JsonConverter, JsonCustomConvert} from "json2typescript";
import moment from "moment-timezone";

import {BigIntId} from "./big-int-id";

export const JsonPropertyEmpty = undefined as any;

/* eslint-disable max-classes-per-file */

@JsonConverter
export class DateTimeConverter implements JsonCustomConvert<moment.Moment | null> {
    public serialize(date: moment.Moment | null): any {
        return date === null ? date : date.toJSON();
    }
    public deserialize(date: string | null): moment.Moment | null {
        return date === null ? date : moment(date);
    }
}

@JsonConverter
export class BigIntIdConverter implements JsonCustomConvert<BigIntId | null> {
    public serialize(id: BigIntId | null): any {
        return id === null ? id : id.value;
    }
    public deserialize(id: string | null): BigIntId | null {
        return id === null ? id : new BigIntId(id);
    }
}

@JsonConverter
export class BigIntIdArrayConverter implements JsonCustomConvert<BigIntId[] | null> {
    public serialize(ids: BigIntId[] | null): any {
        return ids === null ? ids : ids.map((x) => x.value);
    }
    public deserialize(ids: string[] | null): BigIntId[] | null {
        return ids === null ? ids : ids.map((x) => new BigIntId(x));
    }
}
