import {Breadcrumb, Classes} from "@blueprintjs/core";
import * as React from "react";

import {Link} from "../../../routes";
import {IRouteParams} from "../../../utils/route-util";

export interface INextBreadcrumbProps {
    label: string;
    route?: string;
    params?: IRouteParams;
    current?: boolean;
}

interface INextBreadcrumbsProps {
    items: INextBreadcrumbProps[];
}

const NextBreadcrumb: React.FC<INextBreadcrumbProps> = ({label, route, params, current}) =>
    !current && route ? (
        <Link route={route} params={params}>
            {/* pass # to href to create a*/}
            <Breadcrumb href="#">{label}</Breadcrumb>
        </Link>
    ) : (
        <Breadcrumb current={current}>{label}</Breadcrumb>
    );

// Use style classes only because Breadcrumb(s) component of blueprintjs
// does not support custom Link components (We require "as" parameters).
// TODO: Use Overflowlist to collapse
export const NextBreadcrumbs: React.FC<INextBreadcrumbsProps> = ({items}) => (
    <ul className={Classes.BREADCRUMBS} data-testid="breadcrumbs">
        {items.map((x, i) => (
            <li key={i}>
                <NextBreadcrumb current={i === items.length - 1} {...x} />
            </li>
        ))}
    </ul>
);
