import {Button} from "@blueprintjs/core";
import * as React from "react";

interface IPagerProps {
    onNextClicked: () => void;
    onPreviousClicked: () => void;
    previousExists: boolean;
    nextExists: boolean;
}

export class Pager extends React.Component<IPagerProps> {
    public render() {
        return (
            <div style={{display: "flex", marginTop: "10px"}}>
                <Button
                    icon="double-chevron-left"
                    disabled={!this.props.previousExists}
                    onClick={this.props.onPreviousClicked}
                />
                <Button
                    icon="double-chevron-right"
                    disabled={!this.props.nextExists}
                    onClick={this.props.onNextClicked}
                />
            </div>
        );
    }
}
