import React, {useCallback} from "react";
import {useState} from "react";

import {PageChangingCallback, PageChangingContext} from "./page-changing-context";

export const PageChangingContextProvider: React.FC = ({children}) => {
    const [listeners] = useState(() => new Set<PageChangingCallback>());
    const notify = useCallback(() => {
        listeners.forEach((x) => x());
    }, [listeners]);

    const listen = useCallback(
        (l) => {
            listeners.add(l);
            return () => listeners.delete(l);
        },
        [listeners],
    );

    return (
        <PageChangingContext.Provider
            value={{
                notify,
                listen,
            }}
        >
            {children}
        </PageChangingContext.Provider>
    );
};
