import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("TokenOutput")
export class TokenOutput {
    @JsonProperty("access_token")
    public accessToken: string = JsonPropertyEmpty;

    @JsonProperty("refresh_token")
    public refreshToken: string = JsonPropertyEmpty;

    @JsonProperty("token_type")
    public tokenType: string = JsonPropertyEmpty;

    @JsonProperty("expires_in")
    public expiresIn: string = JsonPropertyEmpty;

    @JsonProperty("scope")
    public scope: string = JsonPropertyEmpty;

    @JsonProperty("id")
    public id: string = JsonPropertyEmpty;
}
