import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";
import {AomNodeDifference} from "./aom-node-difference";

@JsonObject("DocumentContentDiffOutput")
export class DocumentContentDiffOutput {
    @JsonProperty()
    public differences: AomNodeDifference[] = JsonPropertyEmpty;
}
