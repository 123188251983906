export function enumKeys(o: any): string[] {
    return Object.keys(o).filter((x) => isNaN(Number(x)));
}

export function enumValues(o: any): any[] {
    return enumKeys(o).map((x) => o[x]);
}

export function enumEntries(o: any): Array<[string, any]> {
    return enumKeys(o).map((x): [string, any] => [x, o[x]]);
}

export function stringToEnum<T>(o: any, value: string): T | undefined {
    return enumValues(o).find((x) => x === value) ? (value as any as T) : undefined;
}
