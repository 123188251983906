import {AnchorButton, IconName} from "@blueprintjs/core";
import * as React from "react";

export interface ResponsiveLabeledButtonProps {
    text: string;
    icon?: IconName;
    minimal: boolean;
    href?: string;
}

export const ResponsiveAnchorButton: React.FC<ResponsiveLabeledButtonProps> = (props) => {
    const {text, ...nonTextProps} = props;
    return <AnchorButton text={<div>{text}</div>} {...nonTextProps} />;
};
