import {observer} from "mobx-react";
import * as React from "react";

import {ValidationResult} from "../../../utils/validation/validation-result";
import {ValidatedInputText} from "../../molecules/validated-input-text/validated-input-text";

interface IDocumentTitleInputProps {
    value: string;
    onChangeValue: (value: string) => void;
    validationResult: ValidationResult;
}

@observer
export class DocumentTitleInput extends React.Component<IDocumentTitleInputProps> {
    public render() {
        return (
            <ValidatedInputText
                helperText={`${this.props.value.length}文字`}
                large={true}
                value={this.props.value}
                validationResult={this.props.validationResult}
                onChangeValue={this.props.onChangeValue}
                placeholder="タイトル"
                maxLength={50}
            />
        );
    }
}
