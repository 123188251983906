import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";
import {RelatedResourceType} from "./related-resource-type";

@JsonObject("DocumentPropertyRelatedResourceInputOutput")
export class DocumentPropertyRelatedResourceInputOutput {
    @JsonProperty()
    public slug: string = JsonPropertyEmpty;

    @JsonProperty()
    public resourceType: RelatedResourceType = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentPropertyRelatedResourceInputOutput>) {
        Object.assign(this, args);
    }
}
