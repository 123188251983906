import {
    getAquaEditorConfig,
    installAquaAdminClient,
    ISiteEnv,
    ISiteInfo,
    loadScript,
} from "@syginc/aqua-client";

import {AdminApi} from "../api/api";
import {getSiteEnvUrl} from "../models/format-site-url";
import {ImagefrontTokenProvider} from "../utils/imagefront-util";
import {createAquaAdminClient} from "./admin-client";
import {IAquaEditor} from "./aqua-editor";
import {createAquaEditor} from "./create-aqua-editor";

export interface AquaEditorLoadOption {
    siteUrl: string;
    adminApi: AdminApi;
    imagefrontTokenProvider: ImagefrontTokenProvider;
    user?: string;
}

export async function loadAquaEditor(option: AquaEditorLoadOption): Promise<IAquaEditor> {
    const response = await fetch(getSiteEnvUrl(option.siteUrl));
    const siteEnv: ISiteEnv = await response.json();

    await loadScript(siteEnv.editorJsUrl, false, true);

    const aquaEditorConfig = getAquaEditorConfig();

    const aquaAdminClient = createAquaAdminClient(
        option.adminApi,
        aquaEditorConfig.buildImageFilename,
        siteEnv.assetUrl as string | undefined,
        option.imagefrontTokenProvider,
    );

    // TODO: Stop using global aquaAdminClient
    installAquaAdminClient(aquaAdminClient);

    const siteInfo: ISiteInfo = {
        siteUrl: option.siteUrl,
        env: siteEnv,
        username: option.user,
    };

    return createAquaEditor(
        siteInfo,
        aquaAdminClient,
        aquaEditorConfig,
        option.imagefrontTokenProvider,
    );
}
