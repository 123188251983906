import styled from "@emotion/styled";
import {NodeObject} from "@syginc/aomjs";
import * as React from "react";
import {useEffect, useState} from "react";

import {IAquaEditor} from "../../../editor/aqua-editor";
import {generateDiffSourceHtml} from "../../../utils/diff-util";

export interface IAomSourceBlockProps {
    node: NodeObject;
    aquaEditor: IAquaEditor;
}

const AomSourceBlockCode = styled.code`
    display: block;
    margin-bottom: 0;
    margin-top: 0;
    white-space: pre-wrap;
`;

const AomSourceBlockDiv = styled.div`
    word-wrap: break-word;
`;

export const AomSourceBlock: React.FC<IAomSourceBlockProps> = ({node, aquaEditor}) => {
    const [sourceHtml, setSourceHtml] = useState<string | null>(null);

    useEffect(() => {
        if (!node) {
            setSourceHtml(null);
            return;
        }

        generateDiffSourceHtml(aquaEditor, node).then((x) => setSourceHtml(x));
    }, [aquaEditor, node]);

    if (!sourceHtml) {
        return <></>;
    }

    return (
        <AomSourceBlockDiv>
            <AomSourceBlockCode dangerouslySetInnerHTML={{__html: sourceHtml}} />
        </AomSourceBlockDiv>
    );
};
