import * as React from "react";

import {AomNodeDifference} from "../../../api/values/aom-node-difference";
import {IAquaEditor} from "../../../editor/aqua-editor";
import {AomSourceBlock} from "../../molecules/aom-source-block/aom-source-block";
import {DiffGlobalStyle} from "../../molecules/diff-global-style/diff-global-style";

export interface ISourceDiffProps {
    differences: AomNodeDifference[];
    aquaEditor: IAquaEditor;
}

export const SourceDiff: React.FC<ISourceDiffProps> = ({differences, aquaEditor}) => (
    <div
        style={{
            display: "grid",
            gridTemplateColumns: "50% 50%",
            columnGap: "20px",
        }}
    >
        <DiffGlobalStyle />

        <>
            {differences.map((op, index) => (
                <div
                    key={`${index}-left`}
                    style={{
                        gridRow: `${index + 1}`,
                        gridColumn: "1",
                    }}
                >
                    <AomSourceBlock node={op.leftNode} aquaEditor={aquaEditor} />
                </div>
            ))}
            {differences.map((op, index) => (
                <div
                    key={`${index}-right`}
                    style={{
                        gridRow: `${index + 1}`,
                        gridColumn: "2",
                    }}
                >
                    <AomSourceBlock node={op.rightNode} aquaEditor={aquaEditor} />
                </div>
            ))}
        </>
    </div>
);
