import {action, makeObservable, observable, runInAction} from "mobx";

import {AdminApi} from "../../../api/api";
import {DocumentProtectionStatus} from "../../../api/values/document-protection-status";
import {DocumentRevisionSearchInput} from "../../../api/values/document-revision-search-input";
import {DocumentSearchInput} from "../../../api/values/document-search-input";
import {DocumentType} from "../../../api/values/document-type";
import {Stores} from "../../../stores";
import {Permission} from "../../../stores/authentication-store";
import {trueToBoolean} from "../../../utils/boolean-util";
import {
    deprecatedCategoryGroup,
    getCategoryMap,
    mapRelatedResourcesToCategories,
} from "../../../utils/category-util";
import {stringToEnum} from "../../../utils/enum-util";
import {IPageStore} from "../../../utils/page-store";
import {PageStoreConstructorOptions} from "../../../utils/page-store";
import {getWithPaging} from "../../../utils/page-util";
import {PageRouter} from "../../../utils/route-util";
import {DocumentAddStore} from "../../organisms/document-add-button/document-add-store";
import {DocumentListItem} from "./document-list-item";
import {DocumentListRevisionItem} from "./document-list-revision-item";

interface IDocumentListPageSearchParams {
    mediaId: string;
    title?: string;
    contentHtml?: string;
    categoryGroup?: string;
    deprecatedCategory?: boolean;
    protectionStatus?: string;
    archived?: string;
    documentType?: string;
}

export enum ArchivedFilterType {
    ALL = "ALL",
    ONLY = "ONLY",
}

export class DocumentListPageStore implements IPageStore {
    @observable
    public mediaId: string = "";

    @observable
    public documentType: DocumentType = DocumentType.ARTICLE;

    @observable
    public documents: DocumentListItem[] = [];

    @observable
    public searchTitle?: string = "";

    @observable
    public searchContentHtml?: string = "";

    @observable
    public searchCategoryGroupSlug?: string = "";

    @observable
    public searchProtectionStatus?: string = "";

    @observable
    public searchArchived?: string = "";

    @observable
    public currentPage: number = 0;

    @observable
    public previousPageExists: boolean = false;

    @observable
    public nextPageExists: boolean = false;

    @observable
    public isLoading: boolean = false;

    public documentAddStore: DocumentAddStore;

    private adminApi: AdminApi;
    private stores: Stores;
    private pageRouter: PageRouter;

    constructor(options: PageStoreConstructorOptions) {
        this.adminApi = options.adminApi;
        this.stores = options.stores;
        this.pageRouter = options.pageRouter;

        this.documentAddStore = new DocumentAddStore({adminApi: options.adminApi}, this.mediaId);

        makeObservable(this);
    }

    public async initialize({query}: any) {
        this.stores.authenticationStore.checkAnyPermissionExists(Permission.READ_DOCUMENTS);

        const q = query || {};

        this.setMediaId(q.mediaId);

        await this.loadDocuments(q.page ? Number(q.page) : 1, {
            mediaId: q.mediaId,
            archived: q.archived,
            categoryGroup: q.categoryGroup,
            deprecatedCategory: trueToBoolean(q.deprecatedCategory),
            contentHtml: q.contentHtml,
            protectionStatus: q.protectionStatus,
            title: q.title,
            documentType: q.type,
        });
    }

    @action.bound
    public async loadDocuments(page: number, searchParams: IDocumentListPageSearchParams) {
        let archived;
        switch (stringToEnum(ArchivedFilterType, searchParams.archived || "")) {
            case ArchivedFilterType.ALL:
                archived = undefined;
                break;
            case ArchivedFilterType.ONLY:
                archived = true;
                break;
            default:
                archived = false;
        }

        const categoryMap = await getCategoryMap(this.adminApi, this.mediaId);

        const result = await this.adminApi.getDocuments(
            new DocumentSearchInput({
                mediaId: this.mediaId,
                documentType: this.getDocumentTypeFromQueryParam(searchParams.documentType),
                archived,
                categoryGroup: searchParams.categoryGroup,
                contentHtml: searchParams.contentHtml,
                deprecatedCategory: searchParams.deprecatedCategory || undefined,
                page,
                protectionStatus: searchParams.protectionStatus,
                title: searchParams.title,
            }),
        );

        const revisions = await getWithPaging(
            new DocumentRevisionSearchInput({
                mediaId: this.mediaId,
                documentIds: result.documents.map((x) => x.id),
            }),
            (x) => this.adminApi.getDocumentRevisions(x),
            (x) => x.documentRevisions,
        );

        const revisionMap = new Map<string, DocumentListRevisionItem[]>();
        revisions.forEach((x) => {
            if (x.appliedAt) {
                return;
            }

            if (!revisionMap.has(x.documentId.value)) {
                revisionMap.set(x.documentId.value, []);
            }

            runInAction(() => {
                const item = new DocumentListRevisionItem({
                    id: x.id,
                    revisionName: x.revisionName,
                    revisionType: x.revisionType,

                    updatedAt: x.updatedAt,

                    userNames: x.users.map((u) => u.name),
                });
                revisionMap.get(x.documentId.value)!.push(item);
            });
        });

        runInAction(() => {
            this.mediaId = searchParams.mediaId || "";
            this.documents = result.documents.map(
                (x) =>
                    new DocumentListItem({
                        archived: x.archived,
                        documentRevisions: revisionMap.get(x.id.value) || [],
                        id: x.id,
                        modifiedAt: x.modifiedAt,
                        slug: x.slug,
                        title: x.title,

                        categories: mapRelatedResourcesToCategories(
                            categoryMap,
                            x.relatedResources,
                        ),
                    }),
            );
            this.documentType = this.getDocumentTypeFromQueryParam(searchParams.documentType);
            this.searchTitle = searchParams.title || "";
            this.searchContentHtml = searchParams.contentHtml || "";
            this.searchCategoryGroupSlug = searchParams.deprecatedCategory
                ? deprecatedCategoryGroup
                : searchParams.categoryGroup || undefined;
            this.searchProtectionStatus = stringToEnum(
                DocumentProtectionStatus,
                searchParams.protectionStatus || "",
            );
            this.searchArchived = stringToEnum(ArchivedFilterType, searchParams.archived || "");
            this.currentPage = page;
            this.previousPageExists = result.previousPageExists;
            this.nextPageExists = result.nextPageExists;
        });
    }

    @action.bound
    public setMediaId(mediaId: string) {
        this.mediaId = mediaId;
    }

    @action.bound
    public async showNextPage() {
        return this.goToPage(this.currentPage + 1, this.getSearchParams());
    }

    @action.bound
    public async showPreviousPage() {
        return this.goToPage(this.currentPage - 1, this.getSearchParams());
    }

    @action.bound
    public setSearchTitle(s: string) {
        this.searchTitle = s;
    }

    @action.bound
    public setSearchContentHtml(s: string) {
        this.searchContentHtml = s;
    }

    @action.bound
    public setSearchCategoryGroupSlug(s: string | null) {
        this.searchCategoryGroupSlug = s || undefined;
    }

    @action.bound
    public setSearchProtectionStatus(s: string) {
        this.searchProtectionStatus = stringToEnum(DocumentProtectionStatus, s);
    }

    @action.bound
    public setSearchArchived(s: string) {
        this.searchArchived = stringToEnum(ArchivedFilterType, s);
    }

    @action.bound
    public async search() {
        this.goToPage(1, this.getSearchParams());
    }

    @action.bound
    private goToPage(page: number, searchParams: IDocumentListPageSearchParams) {
        this.isLoading = true;
        window.scrollTo(0, 0);

        this.pageRouter.pushRoute(`/admin/${searchParams.mediaId}/documents`, {
            page: page > 1 ? page.toString() : undefined,
            type: this.documentType,
            ...searchParams,
        } as any);
    }

    private getSearchParams(): IDocumentListPageSearchParams {
        return {
            mediaId: this.mediaId,
            archived: this.searchArchived || undefined,
            categoryGroup:
                this.searchCategoryGroupSlug === deprecatedCategoryGroup
                    ? undefined
                    : this.searchCategoryGroupSlug || undefined,
            contentHtml: this.searchContentHtml || undefined,
            deprecatedCategory:
                this.searchCategoryGroupSlug === deprecatedCategoryGroup ? true : undefined,
            protectionStatus: this.searchProtectionStatus || undefined,
            title: this.searchTitle || undefined,
        };
    }

    private getDocumentTypeFromQueryParam(queryParam?: string): DocumentType {
        return stringToEnum(DocumentType, queryParam || "") ?? DocumentType.ARTICLE;
    }
}
