import {DocumentRevisionType} from "../api/values/document-revision-type";

export function formatRevisionName(
    revisionName: string,
    documentRevisionType: DocumentRevisionType,
) {
    if (documentRevisionType === DocumentRevisionType.QUICK) {
        return "(クイック公開)";
    } else if (documentRevisionType === DocumentRevisionType.INITIAL) {
        return "(初期状態)";
    }

    if (!revisionName) {
        return "(無題の下書き)";
    }

    return revisionName;
}
