import styled from "@emotion/styled";
import React from "react";

const SearchPanelItemDiv = styled.div`
    margin-right: 10px;
    margin-bottom: 10px;
`;

const SearchPanelItemLabelDiv = styled.div`
    font-size: 15px;
    height: 20px;
`;

interface ISearchPanelItemProps {
    label?: React.ReactNode;
}

export const SearchPanelItem: React.FunctionComponent<ISearchPanelItemProps> = (props) => (
    <SearchPanelItemDiv>
        <SearchPanelItemLabelDiv>{props.label}</SearchPanelItemLabelDiv>
        <div>{props.children}</div>
    </SearchPanelItemDiv>
);
