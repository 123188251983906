import * as React from "react";

import {StringValueListEditor} from "../string-value-list-editor/string-value-list-editor";
import {CategoryListEditorItem} from "./category-list-editor-item";

interface ICategoryListEditorProps {
    slugs: string[];
    onChange: (values: string[]) => void;
}

export class CategoryListEditor extends React.Component<ICategoryListEditorProps> {
    public render() {
        return (
            <StringValueListEditor
                values={this.props.slugs}
                onChange={this.props.onChange}
                itemRenderer={(props) => {
                    return (
                        <CategoryListEditorItem
                            isPrimary={props.order === 0 && !!props.value}
                            value={props.value}
                            placeholder="カテゴリを追加"
                            onChange={(value) => props.onChange(value ? value.slug : null)}
                            onRemove={props.onRemove}
                        />
                    );
                }}
            />
        );
    }
}
