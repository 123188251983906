import {action, makeObservable, observable, runInAction} from "mobx";

import {AdminApi} from "../../../api/api";
import {UserRole} from "../../../api/values/user-role-type";
import {UserSearchInput} from "../../../api/values/user-search-input";
import {Stores} from "../../../stores";
import {Permission} from "../../../stores/authentication-store";
import {PageStoreConstructorOptions} from "../../../utils/page-store";
import {PageRouter} from "../../../utils/route-util";
import {UserListItem} from "./user-list-item";

interface IUserListSearchParams {
    mediaId?: string;
    enabled?: boolean;
}

export class UserListPageStore {
    @observable
    public mediaId: string = "";

    @observable
    public users: UserListItem[] = [];

    @observable
    public enabled?: boolean = false;

    @observable
    public currentPage: number = 0;

    @observable
    public previousPageExists: boolean = false;

    @observable
    public nextPageExists: boolean = false;

    private adminApi: AdminApi;
    private stores: Stores;
    private pageRouter: PageRouter;

    constructor(options: PageStoreConstructorOptions) {
        this.adminApi = options.adminApi;
        this.stores = options.stores;
        this.pageRouter = options.pageRouter;

        makeObservable(this);
    }

    public async initialize({query}: any) {
        this.stores.authenticationStore.checkAnyPermissionExists(
            Permission.MANAGE_ADMIN_USERS,
            Permission.MANAGE_USERS,
        );

        const q = query || {};

        this.setMediaId(q.mediaId || "");

        await this.loadUsers(q.page ? Number(q.page) : 1, {
            mediaId: this.mediaId,
            enabled: q.enabled === undefined ? undefined : q.enabled === "true",
        });
    }

    @action.bound
    public async setMediaId(s: string) {
        this.mediaId = s;
    }

    @action.bound
    public async setEnabled(b?: boolean) {
        this.enabled = b;
    }

    @action.bound
    public async loadUsers(page: number, searchParams: IUserListSearchParams) {
        const result = await this.adminApi.getUsers(
            new UserSearchInput({
                mediaId: searchParams.mediaId,
                enabled: searchParams.enabled,
                page,
            }),
        );

        runInAction(() => {
            this.currentPage = page;
            this.enabled = searchParams.enabled;
            this.previousPageExists = result.previousPageExists;
            this.nextPageExists = result.nextPageExists;
            this.users = result.users.map(
                (user) =>
                    new UserListItem({
                        enabled: user.enabled,
                        id: user.id,
                        name: user.name,
                        role: UserRole.fromName(user.role),
                    }),
            );
        });
    }

    @action.bound
    public async goToNextPage() {
        await this.goToPage(this.currentPage + 1, this.mediaId, this.getSearchParams());
    }

    @action.bound
    public async goToPreviousPage() {
        await this.goToPage(this.currentPage - 1, this.mediaId, this.getSearchParams());
    }

    @action.bound
    public async search() {
        await this.goToPage(1, this.mediaId, this.getSearchParams());
    }

    private getSearchParams() {
        return {
            enabled: this.enabled,
        };
    }

    private async goToPage(page: number, mediaId: string, searchParams: IUserListSearchParams) {
        this.pageRouter.pushRoute("/admin/[mediaId]/users", {
            mediaId: mediaId,
            page: page > 1 ? page.toString() : undefined,
            ...searchParams,
        } as any);
    }
}
