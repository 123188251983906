import {Card, H5} from "@blueprintjs/core";
import * as React from "react";

export const LabeledCard: React.FC<{label: React.ReactNode}> = (props) => (
    <div style={{marginBottom: "20px"}}>
        <Card>
            <H5>{props.label}</H5>

            {props.children}
        </Card>
    </div>
);
