import {Button, FormGroup} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";

import {formatDocumentTypeEnum} from "../../../models/format-document-type";
import {formatProtectionStatus} from "../../../models/format-protection-status";
import {useAuthenticationStore} from "../../../stores/store-hooks";
import {DocumentEditPageStore} from "./document-edit-page-store";

export const DocumentInfoPanel: React.FC<{pageStore: DocumentEditPageStore}> = observer(
    ({pageStore}) => {
        const authenticationStore = useAuthenticationStore();
        const hasEditGeneralResourcesPermission =
            authenticationStore.hasEditGeneralResourcesPermission;

        return (
            <>
                <FormGroup label="タイプ">
                    {formatDocumentTypeEnum(pageStore.documentType)}
                </FormGroup>

                <FormGroup label="公開状態">
                    {formatProtectionStatus(pageStore.protectionStatus)}
                </FormGroup>

                <FormGroup label="スラッグ">
                    <span>{pageStore.slug}</span>

                    {hasEditGeneralResourcesPermission && (
                        <span style={{marginLeft: "1em"}}>
                            <Button onClick={pageStore.startDocumentSetting}>変更</Button>
                        </span>
                    )}
                </FormGroup>
            </>
        );
    },
);
