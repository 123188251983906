import {action, makeObservable, observable, runInAction} from "mobx";

import {AuthError} from "../../../api/auth-error";
import {Stores} from "../../../stores";
import {aquaConnect} from "../../../utils/aqua-connect-util";
import {getSiteUrl} from "../../../utils/global-config";
import {PageRouter} from "../../../utils/route-util";
import {withInProgress} from "../../../utils/with-in-progress";

interface LoginPageStoreOptions {
    stores: Stores;
    pageRouter: PageRouter;
}

export class LoginPageStore {
    @observable
    public username: string = "";

    @observable
    public password: string = "";

    @observable
    public isInProgress: boolean = false;

    @observable
    public authFailed: boolean = false;

    @observable
    public connectionFailed: boolean = false;

    @observable
    public url: string = "";

    private stores: Stores;
    private pageRouter: PageRouter;

    constructor(options: LoginPageStoreOptions) {
        this.stores = options.stores;
        this.pageRouter = options.pageRouter;

        makeObservable(this);
    }

    public async initialize({query}: any) {
        const url = query && query.url;
        runInAction(() => {
            this.url = url || "";
        });
    }

    @action.bound
    public setUsername(s: string) {
        this.username = s.replace(/[^-_0-9a-zA-Z.]/g, ""); // TODO:
    }

    @action.bound
    public setPassword(s: string) {
        this.password = s;
    }

    @action.bound
    public async login() {
        this.authFailed = false;
        this.connectionFailed = false;

        await withInProgress(
            (b) => (this.isInProgress = b),
            async () => {
                try {
                    await this.stores.authenticationStore.login(this.username, this.password);
                    aquaConnect(getSiteUrl());
                } catch (e) {
                    if (e instanceof AuthError) {
                        runInAction(() => {
                            this.authFailed = true;
                        });
                    } else {
                        runInAction(() => {
                            this.connectionFailed = true;
                        });
                        // eslint-disable-next-line no-console
                        console.error(e);
                    }
                    return;
                }

                if (this.url) {
                    try {
                        this.pageRouter.replaceRouteFromUrl(this.url);
                        return;
                    } catch (e) {
                        // Url may be not in routes
                        // eslint-disable-next-line no-console
                        console.error(e);
                    }
                }

                this.pageRouter.replaceRoute("/admin");
            },
        );
    }
}
