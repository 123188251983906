import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

import {MediaInfoState} from "./media-list-store";

export interface ActiveMediaInfoState {
    mediaId: string;
    mediaName: string;
    mediaUrl: string;
}

const initialMediaInfo: ActiveMediaInfoState = {
    mediaId: "",
    mediaName: "",
    mediaUrl: "",
};

export interface UseActiveMediaStore {
    activeMediaInfo: ActiveMediaInfoState;
    setActiveMediaInfo: (info: MediaInfoState) => void;
}

export const useActiveMediaStore = create<UseActiveMediaStore>()(
    persist(
        (set) => ({
            activeMediaInfo: initialMediaInfo,
            setActiveMediaInfo: (info: MediaInfoState) =>
                set({
                    activeMediaInfo: {
                        mediaId: info.mediaId,
                        mediaName: info.mediaName,
                        mediaUrl: info.mediaUrl,
                    },
                }),
        }),
        {
            name: "mediaInfoList", // ローカルストレージでのキー名
            storage: createJSONStorage<ActiveMediaInfoState>(() => sessionStorage), // 使用するストレージの指定
        },
    ),
);
