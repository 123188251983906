import styled from "@emotion/styled";
import * as React from "react";

const HeadingLineDiv = styled.div`
    display: grid;
    grid-template-areas:
        "icon primary"
        ". secondary";
    grid-template-columns: auto 1fr;

    width: 100%;
    padding: 5px;
`;

const HeadingLineIconDiv = styled.div`
    grid-area: icon;

    width: 16px;
    margin-right: 2px;
`;

const HeadingLinePrimaryDiv = styled.div`
    grid-area: primary;

    font-size: 16px;
    font-weight: bold;
    color: black;
`;

const HeadingLineSecondaryDiv = styled.div`
    grid-area: secondary;

    font-size: 12px;
    color: #666;
    margin-top: 3px;
`;

interface IHeadingLineProps {
    icon?: React.ReactNode;
    primary?: React.ReactNode;
    secondary?: React.ReactNode;
}

export const HeadingLine: React.FC<IHeadingLineProps> = ({icon, primary, secondary}) => (
    <HeadingLineDiv>
        {icon && <HeadingLineIconDiv>{icon}</HeadingLineIconDiv>}
        {primary && <HeadingLinePrimaryDiv>{primary}</HeadingLinePrimaryDiv>}
        {secondary && <HeadingLineSecondaryDiv>{secondary}</HeadingLineSecondaryDiv>}
    </HeadingLineDiv>
);
