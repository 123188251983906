import {observer} from "mobx-react";
import * as React from "react";

import {useAuthenticationStore} from "../../../stores/store-hooks";
import {ButtonList} from "../../molecules/button-list/button-list";
import {PopoverCreateButton} from "../../molecules/popover-create-button/popover-create-button";
import {DocumentRevisionDetailPageStore} from "./revision-detail-page-store";

export const DocumentRevisionDetailButtons: React.FC<{
    pageStore: DocumentRevisionDetailPageStore;
}> = observer(({pageStore}) => {
    const authenticationStore = useAuthenticationStore();

    const hasManageDocumentsPermission = authenticationStore.hasManageDocumentsPermission;

    return (
        <ButtonList>
            {hasManageDocumentsPermission && (
                <PopoverCreateButton
                    label="復元用の下書きを作成"
                    placeholder="下書き名"
                    onCreate={pageStore.saveNew}
                    closeAfterCreate={false}
                />
            )}
        </ButtonList>
    );
});
