/* eslint-disable max-len */
import {NodeObject} from "@syginc/aomjs";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import moment from "moment-timezone";
import * as React from "react";

import {AdminApi} from "../../../api/api";
import {DocumentContainsPr} from "../../../api/values/document-contains-pr";
import {DocumentContentGetInput} from "../../../api/values/document-content-get-input";
import {DocumentContentGetOutput} from "../../../api/values/document-content-get-output";
import {DocumentGetInput} from "../../../api/values/document-get-input";
import {DocumentGetOutput} from "../../../api/values/document-get-output";
import {DocumentPropertyInputOutput} from "../../../api/values/document-property-input-output";
import {DocumentPropertyRelatedResourceInputOutput} from "../../../api/values/document-property-related-resource-input-output";
import {DocumentProtectionStatus} from "../../../api/values/document-protection-status";
import {DocumentPublishScope} from "../../../api/values/document-publish-scope";
import {DocumentRevisionAddInput} from "../../../api/values/document-revision-add-input";
import {DocumentRevisionGetInput} from "../../../api/values/document-revision-get-input";
import {DocumentRevisionGetOutput} from "../../../api/values/document-revision-get-output";
import {DocumentRevisionType} from "../../../api/values/document-revision-type";
import {DocumentRevisionUpdateInput} from "../../../api/values/document-revision-update-input";
import {DocumentRevisionUserInputOutput} from "../../../api/values/document-revision-user-input-output";
import {DocumentType} from "../../../api/values/document-type";
import {DocumentUpdateInput} from "../../../api/values/document-update-input";
import {RelatedResourceType} from "../../../api/values/related-resource-type";
import {getSiteDocumentUrl} from "../../../models/format-site-url";
import {Stores} from "../../../stores";
import {Permission} from "../../../stores/authentication-store";
import {SimpleToast} from "../../../stores/toast-store";
import {filterNotNullOrUndefined} from "../../../utils/array-util";
import {getAuthInfo} from "../../../utils/auth-util";
import {BigIntId} from "../../../utils/big-int-id";
import {optimizeDocument, optimizeDocumentAndForgot} from "../../../utils/document-optimize-util";
import {stringToEnum} from "../../../utils/enum-util";
import {PageStoreConstructorOptions} from "../../../utils/page-store";
import {PageRouter} from "../../../utils/route-util";
import {validateLength} from "../../../utils/validation/functions/length";
import {createValidator} from "../../../utils/validation/validate";
import {ValidationResultEmpty} from "../../../utils/validation/validation-result";
import {openNewWindow} from "../../../utils/window-util";
import {withInProgress} from "../../../utils/with-in-progress";
import {IAomEditorHandler} from "../../molecules/aom-editor/aom-editor";
import {DocumentSettingStore} from "../../organisms/document-setting-drawer/document-setting-store";
import {PublishDrawerStore} from "../../organisms/publish-drawer/publish-drawer-store";
import {QuickEditEntry} from "../../organisms/publish-drawer/quick-edit-entry";
import {ArticleHtmlPropertiesStore} from "./article-html-properties-store";
import {CopyDocumentRevisionStore} from "./copy-document-revision-store";
import {DeleteDocumentRevisionStore} from "./delete-document-revision-store";
import {DeleteDocumentStore} from "./delete-document-store";
import {PreviewPanelStore} from "./preview-panel-store";
import {RevisionListPanelStore} from "./revision-list-panel-store";
import {UnpublishDocumentStore} from "./unpublish-document-store";

/* eslint-enable */

export class DocumentEditPageStore {
    public aomEditorRef = React.createRef<IAomEditorHandler>();

    @observable
    public isFromDocument: boolean = true;

    @observable
    public isJsonMode: boolean = false;

    @observable
    public mediaId: string = "";

    @observable
    public documentRevisionId: BigIntId = new BigIntId("0");

    @observable
    public documentId: BigIntId = new BigIntId("0");

    @observable
    public documentType: any = DocumentType.ARTICLE;

    @observable
    public revisionType: DocumentRevisionType = DocumentRevisionType.NORMAL;

    @observable
    public isOutdated: boolean = false;

    @observable
    public isSaving: boolean = false;

    @observable
    public isStartPublishing: boolean = false;

    @observable
    public isCreatingPreview: boolean = false;

    @observable
    public isEditorInitialized: boolean = false;

    @observable
    public isContentDirty: boolean = false;

    @observable
    public isNonContentDirty: boolean = false;

    @observable
    public revisionName: string = "";

    @observable
    public revisionNameValidationResult = ValidationResultEmpty;

    @observable
    public title: string = "";

    @observable
    public titleValidationResult = ValidationResultEmpty;

    @observable
    public contentJson: string = "";

    @observable
    public protectionStatus: DocumentProtectionStatus = DocumentProtectionStatus.HIDDEN;

    @observable
    public publishScope: DocumentPublishScope = DocumentPublishScope.GET;

    @observable
    public appliedAt: moment.Moment | null = moment();

    @observable
    public modifiedAt: moment.Moment = moment();

    @observable
    public updatedAt: moment.Moment = moment();

    @observable
    public updatedUserName?: string = "";

    @observable
    public note: string = "";

    @observable
    public noteValidationResult = ValidationResultEmpty;

    @observable
    public featuredImageUri: string = "";

    @observable
    public featuredImageAuthorityTitle: string = "";

    @observable
    public featuredImageAuthorityTitleValidationResult = ValidationResultEmpty;

    @observable
    public featuredImageAuthorityUri: string = "";

    @observable
    public featuredImageAuthorityUriValidationResult = ValidationResultEmpty;

    @observable
    public personName: string = "";

    @observable
    public personNameValidationResult = ValidationResultEmpty;

    @observable
    public personRole: string = "";

    @observable
    public personRoleValidationResult = ValidationResultEmpty;

    @observable
    public personImageUri: string = "";

    @observable
    public personImageUriValidationResult = ValidationResultEmpty;

    @observable
    public personSlug: string = "";

    @observable
    public personSlugValidationResult = ValidationResultEmpty;

    @observable
    public containsPr: DocumentContainsPr = DocumentContainsPr.CONTAINS;

    @observable
    public relatedDocumentSlugs: string[] = [];

    @observable
    public categorySlugs: string[] = [];

    @observable
    public userNames: string[] = [];

    public publishDrawerStore: PublishDrawerStore;
    public documentSettingStore: DocumentSettingStore;
    public revisionListPanelStore: RevisionListPanelStore;
    public copyDocumentRevisionStore: CopyDocumentRevisionStore;
    public deleteDocumentRevisionStore: DeleteDocumentRevisionStore;
    public deleteDocumentStore: DeleteDocumentStore;
    public previewPanelStore: PreviewPanelStore;
    public unpublishDocumentStore: UnpublishDocumentStore;

    private adminApi: AdminApi;
    private stores: Stores;
    private pageRouter: PageRouter;

    constructor(options: PageStoreConstructorOptions) {
        this.adminApi = options.adminApi;
        this.stores = options.stores;
        this.pageRouter = options.pageRouter;

        this.publishDrawerStore = new PublishDrawerStore({
            adminApi: this.adminApi,
            stores: this.stores,
            pageRouter: this.pageRouter,
        });

        this.documentSettingStore = new DocumentSettingStore(
            {adminApi: this.adminApi},
            this.mediaId,
        );

        this.revisionListPanelStore = new RevisionListPanelStore({adminApi: this.adminApi});

        this.deleteDocumentStore = new DeleteDocumentStore({
            adminApi: this.adminApi,
            stores: this.stores,
            pageRouter: this.pageRouter,
        });

        this.previewPanelStore = new PreviewPanelStore({
            adminApi: this.adminApi,
            stores: this.stores,
        });

        this.unpublishDocumentStore = new UnpublishDocumentStore({
            adminApi: this.adminApi,
            stores: this.stores,
            pageRouter: this.pageRouter,
        });

        this.copyDocumentRevisionStore = new CopyDocumentRevisionStore({
            adminApi: this.adminApi,
            stores: this.stores,
            pageRouter: this.pageRouter,
        });

        this.deleteDocumentRevisionStore = new DeleteDocumentRevisionStore({
            adminApi: this.adminApi,
            stores: this.stores,
            pageRouter: this.pageRouter,
        });

        makeObservable(this);
    }

    @computed
    public get slug() {
        return this.documentSettingStore.slug;
    }

    @observable
    public archived: boolean = false;

    @observable
    public noSlugCalloutRequired = false;

    @computed
    public get isNoSlugCalloutShown() {
        return !this.slug && this.noSlugCalloutRequired;
    }

    @observable
    public articleHtmlPropertiesStore: ArticleHtmlPropertiesStore =
        new ArticleHtmlPropertiesStore();

    @computed
    public get isDirty() {
        return (
            this.isContentDirty || this.isNonContentDirty || this.articleHtmlPropertiesStore.isDirty
        );
    }

    @computed
    public get relatedDocumentResources() {
        return this.relatedDocumentSlugs.map(
            (slug) =>
                new DocumentPropertyRelatedResourceInputOutput({
                    resourceType: RelatedResourceType.DOCUMENT,
                    slug,
                }),
        );
    }

    @computed
    public get categories() {
        return this.categorySlugs.map(
            (slug) =>
                new DocumentPropertyRelatedResourceInputOutput({
                    resourceType: RelatedResourceType.CATEGORY,
                    slug,
                }),
        );
    }

    @computed
    public get validationsAllEmpty() {
        return (
            this.titleValidationResult.isEmpty &&
            this.articleHtmlPropertiesStore.validationsAllEmpty &&
            this.featuredImageAuthorityTitleValidationResult.isEmpty &&
            this.featuredImageAuthorityUriValidationResult.isEmpty &&
            (this.isFromDocument || this.revisionNameValidationResult.isEmpty)
        );
    }

    private revisionNameValidator = createValidator(validateLength(1, 50));

    private titleValidator = createValidator(validateLength(1, 50));

    private noteValidator = createValidator(validateLength(0, 16384));

    private featuredImageAuthorityTitleValidator = createValidator(validateLength(0, 200));

    private featuredImageAuthorityUriValidator = createValidator(validateLength(0, 2048));

    private personNameValidator = createValidator(validateLength(0, 50));

    private personRoleValidator = createValidator(validateLength(0, 50));

    private personImageUriValidator = createValidator(validateLength(0, 200));

    private personSlugValidator = createValidator(validateLength(0, 50));

    public async initialize({query}: any = {}) {
        this.setMediaId(query.mediaId);

        if (query && query.documentId) {
            await this.loadFromDocument(new BigIntId(query.documentId));
        } else if (query && query.documentRevisionId) {
            await this.loadFromDocumentRevision(new BigIntId(query.documentRevisionId));
        } else {
            throw new Error("id is not set");
        }

        this.stores.authenticationStore.checkAllUsersOrLimitedUserPermissionExist(
            Permission.READ_DOCUMENTS,
            Permission.READ_ASSIGNED_DOCUMENTS,
            this.userNames,
        );

        if (query && query.editLeave) {
            await this.loadFromEditLeave();
        }

        if (!this.isFromDocument && this.appliedAt) {
            this.pageRouter.replaceRoute("/admin/[mediaId]/revisions/[documentRevisionId]/detail", {
                mediaId: this.mediaId,
                documentRevisionId: this.documentRevisionId.value,
            });
            return;
        }
    }

    @action.bound
    public setJsonMode(b: boolean) {
        this.isJsonMode = b;
    }

    @computed
    public get canSaveExisting() {
        return (
            this.isEditorInitialized && !this.isSaving && this.isDirty && this.validationsAllEmpty
        );
    }

    @action.bound
    public async saveToExisting() {
        await withInProgress(
            (b) => {
                this.isSaving = b;
            },
            () => this.saveToExistingInner(),
        );
        this.stores.toastStore.setSimpleToast(SimpleToast.SAVED);
    }

    @computed
    public get canSaveNew() {
        return this.isEditorInitialized && !this.isSaving && this.validationsAllEmpty;
    }

    @action.bound
    public async saveNew(text: string) {
        await withInProgress(
            (b) => {
                this.isSaving = b;
            },
            () => this.saveNewInner(text),
        );
        optimizeDocumentAndForgot(this.mediaId, this.documentId, this.adminApi);
        this.stores.toastStore.setSimpleToast(SimpleToast.SAVED);
    }

    @action.bound
    public async saveFromEditor() {
        if (!this.isDirty) {
            return;
        }
        if (this.isFromDocument) {
            alert("下書きを作成してください。");
            return;
        }
        if (!this.canSaveExisting) {
            alert("保存できません。");
            return;
        }

        await this.saveToExisting();
    }

    @computed
    public get canShowPage() {
        return !this.isDirty && this.protectionStatus === DocumentProtectionStatus.VISIBLE;
    }

    @action.bound
    public async showPage(siteUrl: string) {
        try {
            await openNewWindow(async () => getSiteDocumentUrl(siteUrl, this.slug!));
        } catch (e) {
            this.stores.toastStore.setSimpleToast(SimpleToast.CANNOT_OPEN_WINDOW);
        }
    }

    @computed
    public get canDeleteDocumentRevision() {
        return !this.appliedAt;
    }

    @action.bound
    public async startDeleteDocumentRevision() {
        await this.deleteDocumentRevisionStore.open(
            this.mediaId,
            this.documentId,
            this.documentRevisionId,
        );
    }

    @action.bound
    public async startCopyDocumentRevision() {
        await this.copyDocumentRevisionStore.open(this.mediaId, this.documentRevisionId);
    }

    @action.bound
    public async startDeleteDocument() {
        await this.deleteDocumentStore.open(this.mediaId, this.documentId);
    }

    @action.bound
    public setMediaId(mediaId: string) {
        this.mediaId = mediaId;
    }

    @action.bound
    public setModifiedAt(date: Date) {
        this.modifiedAt = moment(date);
        this.setNonContentDirty();
    }

    @action.bound
    public setPublishScope(value: string) {
        const type = stringToEnum<DocumentPublishScope>(DocumentPublishScope, value);
        if (!type) {
            return;
        }
        this.publishScope = type;
        this.setNonContentDirty();
    }

    @action.bound
    public async setRevisionName(revisionName: string) {
        this.revisionName = revisionName;
        (await this.revisionNameValidator(revisionName)).run(
            (v) => (this.revisionNameValidationResult = v),
        );
        this.setNonContentDirty();
    }

    @action.bound
    public async setTitle(title: string) {
        this.title = title;
        (await this.titleValidator(title)).run((v) => (this.titleValidationResult = v));
        this.setNonContentDirty();
    }

    @action.bound
    public async setNote(text: string) {
        this.note = text;
        (await this.noteValidator(text)).run((v) => (this.noteValidationResult = v));
        this.setNonContentDirty();
    }

    @action.bound
    public setFeaturedImageUri(s: string) {
        this.featuredImageUri = s;
        this.setNonContentDirty();
    }

    @action.bound
    public async setFeaturedImageAuthorityTitle(s: string) {
        this.featuredImageAuthorityTitle = s;
        (await this.featuredImageAuthorityTitleValidator(s)).run(
            (v) => (this.featuredImageAuthorityTitleValidationResult = v),
        );
        this.setNonContentDirty();
    }

    @action.bound
    public async setFeaturedImageAuthorityUri(s: string) {
        this.featuredImageAuthorityUri = s;
        (await this.featuredImageAuthorityUriValidator(s)).run(
            (v) => (this.featuredImageAuthorityUriValidationResult = v),
        );
        this.setNonContentDirty();
    }

    @action.bound
    public async setPersonName(s: string) {
        this.personName = s;
        (await this.personNameValidator(s)).run((v) => (this.personNameValidationResult = v));
        this.setNonContentDirty();
    }

    @action.bound
    public async setPersonRole(s: string) {
        this.personRole = s;
        (await this.personRoleValidator(s)).run((v) => (this.personRoleValidationResult = v));
        this.setNonContentDirty();
    }

    @action.bound
    public async setPersonImageUri(s: string) {
        this.personImageUri = s;
        (await this.personImageUriValidator(s)).run(
            (v) => (this.personImageUriValidationResult = v),
        );
        this.setNonContentDirty();
    }

    @action.bound
    public async setPersonSlug(s: string) {
        this.personSlug = s;
        (await this.personSlugValidator(s)).run((v) => (this.personSlugValidationResult = v));
        this.setNonContentDirty();
    }

    @action.bound
    public setContainsPr(value: string) {
        const type = stringToEnum<DocumentContainsPr>(DocumentContainsPr, value);
        if (!type) {
            return;
        }
        this.containsPr = type;
        this.setNonContentDirty();
    }

    @action.bound
    public async setRelatedDocumentSlugs(slugs: string[]) {
        this.relatedDocumentSlugs = slugs;
        this.setNonContentDirty();
    }

    @action.bound
    public async setCategorySlugs(slugs: string[]) {
        this.categorySlugs = slugs;
        this.setNonContentDirty();
    }

    @action.bound
    public async setUserNames(names: string[]) {
        this.userNames = names;
        this.setNonContentDirty();
    }

    @action.bound
    public setEditorInitialized() {
        this.isEditorInitialized = true;
    }

    @action.bound
    public setContentDirty() {
        this.isContentDirty = true;
    }

    @action.bound
    public clearDirty() {
        this.isContentDirty = false;
        this.isNonContentDirty = false;
    }

    @action.bound
    public setNonContentDirty() {
        this.isNonContentDirty = true;
    }

    @action.bound
    public async startPublish() {
        const isScheduledRevisionExists = await withInProgress(
            (b) => (this.isStartPublishing = b),
            async () => {
                await this.saveToExistingInner();
                return await this.checkScheduledRevisionExists(this.documentId);
            },
        );

        if (isScheduledRevisionExists) {
            return;
        }

        if (this.showNoSlugCalloutIfNoSlug()) {
            return;
        }

        await this.publishDrawerStore.openPublish(this.mediaId, this.documentRevisionId);
    }

    @action.bound
    public async startQuickPublish() {
        const isScheduledRevisionExists = await withInProgress(
            (b) => (this.isStartPublishing = b),
            async () => {
                return await this.checkScheduledRevisionExists(this.documentId);
            },
        );

        if (isScheduledRevisionExists) {
            return;
        }

        if (this.showNoSlugCalloutIfNoSlug()) {
            return;
        }

        const {aomNode, renderedHtml} = this.getNodeAndHtml();
        if (aomNode === undefined || renderedHtml === undefined) {
            return null;
        }

        const entry = this.getQuickEditEntry(aomNode, renderedHtml);
        await this.publishDrawerStore.openQuickPublish(this.mediaId, entry);
    }

    @computed
    public get canUnpublish() {
        return this.protectionStatus === DocumentProtectionStatus.VISIBLE;
    }

    @action.bound
    public async startUnpublish() {
        await this.unpublishDocumentStore.open(this.mediaId, this.documentId);
    }

    @action.bound
    public async startDocumentSetting() {
        await this.documentSettingStore.open(this.mediaId, this.documentId);
    }

    @action.bound
    public async archive() {
        await this.adminApi.putDocument(
            {mediaId: this.mediaId},
            {id: this.documentId},
            new DocumentUpdateInput({archived: true}),
        );
        this.stores.toastStore.setSimpleToast(SimpleToast.FINISHED_ARCHIVED_SETTING);
        runInAction(() => {
            this.archived = true;
        });
    }

    @action.bound
    public async unarchive() {
        await this.adminApi.putDocument(
            {mediaId: this.mediaId},
            {id: this.documentId},
            new DocumentUpdateInput({archived: false}),
        );
        this.stores.toastStore.setSimpleToast(SimpleToast.FINISHED_ARCHIVED_SETTING);
        runInAction(() => {
            this.archived = false;
        });
    }

    @action.bound
    public async optimize() {
        await optimizeDocument(this.mediaId, this.documentId, this.adminApi);
        this.stores.toastStore.setSimpleToast(SimpleToast.COMPLETED);
    }

    // This can't be async
    @action.bound
    public leaveEditing() {
        if (!this.isDirty) {
            return;
        }

        const {aomNode, renderedHtml} = this.getNodeAndHtml();
        if (aomNode === undefined || renderedHtml === undefined) {
            // eslint-disable-next-line
            console.error("Failed to get node when leaving editor");
            return;
        }

        const quickEditEntry = this.getQuickEditEntry(aomNode, renderedHtml);
        if (this.isFromDocument) {
            this.stores.toastStore.setEditLeaveData({
                mediaId: this.mediaId,
                quickEditEntry,
                documentId: this.documentId,
            });
        } else {
            this.stores.toastStore.setEditLeaveData({
                mediaId: this.mediaId,
                quickEditEntry,
                documentRevisionId: this.documentRevisionId,
            });
        }
    }

    @action.bound
    public handlePublished() {
        // to prevent editLeave guard
        this.clearDirty();
    }

    private getQuickEditEntry(aomNode: NodeObject, renderedHtml: string) {
        const entry = new QuickEditEntry();
        const property = this.getProperty();
        const users = this.getDocumentRevisionUsers();
        runInAction(() => {
            entry.documentId = this.documentId;
            entry.nodeJson = JSON.stringify(aomNode);
            entry.renderedHtml = renderedHtml;
            entry.property = property;
            entry.users = users;
        });
        return entry;
    }

    private getNodeAndHtml(): {aomNode?: NodeObject; renderedHtml?: string} {
        const aomEditor = this.aomEditorRef.current;
        if (!aomEditor) {
            alert("エディタが初期化されていません。");
            return {};
        }

        try {
            return aomEditor.getNodeAndHtml();
        } catch (e) {
            // eslint-disable-next-line
            console.error(e);
            alert("保存できませんでした。" + e); // TODO: error message
            return {};
        }
    }

    private async getUpdateInput(revisionName: string, users: DocumentRevisionUserInputOutput[]) {
        const {aomNode, renderedHtml} = this.getNodeAndHtml();
        if (aomNode === undefined) {
            return null;
        }

        const content = JSON.stringify(aomNode);
        const input = new DocumentRevisionUpdateInput({
            content,
            contentHtml: renderedHtml,
            property: await this.getProperty(),
            revisionName,
            users,
        });

        return input;
    }

    private async saveNewInner(revisionName: string) {
        const users = filterNotNullOrUndefined([getAuthInfo()]).map(
            (x) => new DocumentRevisionUserInputOutput({name: x.username}),
        );

        const input = await this.getUpdateInput(revisionName, users);
        if (!input) {
            return;
        }

        const result = await this.adminApi.postDocumentRevision(
            {mediaId: this.mediaId},
            new DocumentRevisionAddInput({
                referenceDocumentRevisionId: this.documentRevisionId,
                baseDocumentRevisionId: this.documentRevisionId,
                revisionName,
                users,
            }),
        );

        if (this.isDirty) {
            await this.adminApi.putDocumentRevision(
                {mediaId: this.mediaId},
                {id: result.id},
                input,
            );
        }

        // clear first to prevent editLeave guard
        this.clearDirty();

        this.pageRouter.pushRoute("/admin/[mediaId]/revisions/[documentRevisionId]", {
            mediaId: this.mediaId,
            documentRevisionId: result.id.value,
        });
    }

    private async saveToExistingInner() {
        const input = await this.getUpdateInput(this.revisionName, this.getDocumentRevisionUsers());
        if (!input) {
            return;
        }

        await this.adminApi.putDocumentRevision(
            {mediaId: this.mediaId},
            {id: this.documentRevisionId},
            input,
        );

        const documentRevision = await this.adminApi.getDocumentRevision(
            {id: this.documentRevisionId},
            new DocumentRevisionGetInput({mediaId: this.mediaId}),
        );
        // relatedDocuments and users may be filtered by the server
        await this.updateFields(documentRevision);

        this.clearDirty();
    }

    @action.bound
    private getProperty() {
        return new DocumentPropertyInputOutput({
            personSlug: this.personSlug,
            personImageUri: this.personImageUri,
            personRole: this.personRole,
            personName: this.personName,
            imageAuthorityTitle: this.featuredImageAuthorityTitle,
            imageAuthorityUri: this.featuredImageAuthorityUri,
            imageUri: this.featuredImageUri,
            modifiedAt: this.modifiedAt,
            note: this.note,
            protectionStatus: this.protectionStatus,
            publishScope: this.publishScope,
            containsPr: this.containsPr,
            relatedResources: this.relatedDocumentResources.concat(this.categories),
            title: this.title,

            ...this.articleHtmlPropertiesStore.propertyInputFields,
        });
    }

    @action.bound
    private getDocumentRevisionUsers() {
        return this.userNames.map((name) => new DocumentRevisionUserInputOutput({name}));
    }

    @action.bound
    private async loadFromDocument(documentId: BigIntId) {
        this.isFromDocument = true;

        const document = await this.adminApi.getDocument(
            {id: documentId},
            new DocumentGetInput({mediaId: this.mediaId}),
        );
        await this.setFromDocument(document);

        await this.loadDocumentRevision(document.activeDocumentRevisionId);

        // clear users when editing the document
        this.updateDocumentRevisionUsers([]);
    }

    @action.bound
    private async loadFromDocumentRevision(documentRevisionId: BigIntId) {
        this.isFromDocument = false;

        await this.loadDocumentRevision(documentRevisionId);
        await this.setFromDocument(
            await this.adminApi.getDocument(
                {id: this.documentId},
                new DocumentGetInput({mediaId: this.mediaId}),
            ),
        );
    }

    @action.bound
    private async loadFromEditLeave() {
        if (!this.stores.toastStore.editLeaveData) {
            return;
        }

        const quickEditEntry = this.stores.toastStore.editLeaveData.quickEditEntry;
        if (quickEditEntry.documentId.value !== this.documentId.value) {
            return;
        }
        this.stores.toastStore.setEditLeaveData(null);

        this.setContentDirty();
        this.updateProperty(quickEditEntry.property);
        this.updateDocumentRevisionUsers(quickEditEntry.users);
        this.updateContent(quickEditEntry.nodeJson);
    }

    @action.bound
    private async setFromDocument(document: DocumentGetOutput) {
        this.documentType = document.documentType;
        this.archived = document.archived;
        await this.documentSettingStore.setFromDocument(document);
        await this.revisionListPanelStore.loadRevisions(this.mediaId, document.id);
    }

    @action.bound
    private async loadDocumentRevision(documentRevisionId: BigIntId) {
        this.documentRevisionId = documentRevisionId;

        const documentRevision = await this.adminApi.getDocumentRevision(
            {id: this.documentRevisionId},
            new DocumentRevisionGetInput({mediaId: this.mediaId}),
        );
        runInAction(() => {
            this.documentRevisionId = documentRevision.id;
            this.documentId = documentRevision.documentId;
        });
        const content = await this.adminApi.getDocumentContent(
            {id: documentRevision.documentContentId},
            new DocumentContentGetInput({
                mediaId: this.mediaId,
            }),
        );

        await this.updateFields(documentRevision, content);
    }

    @action.bound
    private async updateFields(
        documentRevision: DocumentRevisionGetOutput,
        content?: DocumentContentGetOutput,
    ) {
        this.updatedAt = documentRevision.updatedAt;
        this.updatedUserName = documentRevision.updatedUserName;
        this.revisionType = documentRevision.revisionType;
        this.revisionName = documentRevision.revisionName;
        this.isOutdated = documentRevision.outdated;
        this.appliedAt = documentRevision.appliedAt;

        this.updateProperty(documentRevision.property);
        this.updateDocumentRevisionUsers(documentRevision.users);
        if (content !== undefined) {
            this.updateContent(content.content);
        }
    }

    @action.bound
    private updateProperty(property: DocumentPropertyInputOutput) {
        this.modifiedAt = property.modifiedAt;
        this.protectionStatus = property.protectionStatus;
        this.publishScope = property.publishScope;
        this.title = property.title;
        this.articleHtmlPropertiesStore.setFromProperty(property);
        this.note = property.note;
        this.featuredImageUri = property.imageUri;
        this.featuredImageAuthorityUri = property.imageAuthorityUri;
        this.featuredImageAuthorityTitle = property.imageAuthorityTitle;
        this.personName = property.personName;
        this.personRole = property.personRole;
        this.personImageUri = property.personImageUri;
        this.personSlug = property.personSlug;
        this.containsPr = property.containsPr;
        this.relatedDocumentSlugs = property.relatedResources
            .filter((item) => item.resourceType === RelatedResourceType.DOCUMENT)
            .map((item) => item.slug);
        this.categorySlugs = property.relatedResources
            .filter((item) => item.resourceType === RelatedResourceType.CATEGORY)
            .map((item) => item.slug);
    }

    @action.bound
    private updateDocumentRevisionUsers(users: DocumentRevisionUserInputOutput[]) {
        this.userNames = users.map((user) => user.name);
    }

    @action.bound
    private updateContent(content: string) {
        this.contentJson = content;
    }

    @action.bound
    private showNoSlugCalloutIfNoSlug() {
        this.noSlugCalloutRequired = true;
        return this.isNoSlugCalloutShown;
    }

    @action.bound
    private async checkScheduledRevisionExists(documentId: BigIntId) {
        const document = await this.adminApi.getDocument(
            {id: documentId},
            new DocumentGetInput({mediaId: this.mediaId}),
        );
        const isScheduledRevisionExists =
            document.activeDocumentRevisionId.value !== document.maxDocumentRevisionId.value;

        if (isScheduledRevisionExists) {
            this.stores.toastStore.setSimpleToast(SimpleToast.SCHEDULED_REVISION_EXISTS);
        }

        return isScheduledRevisionExists;
    }
}
