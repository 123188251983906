import {Button, Callout, FormGroup, H3, InputGroup, Intent} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import React from "react";

import {Link} from "../../../routes";
import {handleChangeString} from "../../../utils/handle-change";
import ProgressPanel from "../../molecules/progress-panel/progress-panel";
import {SubmitGroup} from "../../molecules/submit-group/submit-group";
import {GuestLayout} from "../../templates/guest-layout/guest-layout";
import {LoginPageStore} from "./login-page-store";

const LoginWrapperDiv = styled.div`
    max-width: 400px;
    margin: 100px auto;
    padding: 10px;
`;

export const LoginPage: React.FC<{pageStore: LoginPageStore}> = observer(({pageStore}) => {
    return (
        <GuestLayout>
            <LoginWrapperDiv>
                <div>
                    {pageStore.authFailed && (
                        <Callout intent={Intent.DANGER}>
                            ユーザー名またはパスワードが間違っています。
                        </Callout>
                    )}

                    {pageStore.connectionFailed && (
                        <Callout intent={Intent.DANGER}>サーバーへの接続に失敗しました。</Callout>
                    )}
                </div>

                <H3>ログイン</H3>
                <ProgressPanel inProgress={pageStore.isInProgress}>
                    <SubmitGroup onSubmit={pageStore.login}>
                        <FormGroup label="ユーザー名" style={{marginBottom: "20px"}}>
                            <InputGroup
                                value={pageStore.username}
                                onChange={handleChangeString((s) => pageStore.setUsername(s))}
                            />
                        </FormGroup>

                        <FormGroup label="パスワード" style={{marginBottom: "30px"}}>
                            <InputGroup
                                type="password"
                                value={pageStore.password}
                                onChange={handleChangeString((s) => pageStore.setPassword(s))}
                            />
                            <span>
                                パスワードをお忘れの方は
                                <Link route="/admin/password-reset-request">
                                    <a>こちら</a>
                                </Link>
                            </span>
                        </FormGroup>

                        <Button type="submit" large={true} onClick={pageStore.login}>
                            ログイン
                        </Button>
                    </SubmitGroup>
                </ProgressPanel>
            </LoginWrapperDiv>
        </GuestLayout>
    );
});
