import {DocumentContainsPr} from "../api/values/document-contains-pr";

export function formatContainsPr(containsPr: DocumentContainsPr): string {
    switch (containsPr) {
        case DocumentContainsPr.CONTAINS:
            return "表示する(PR広告が含まれています)";
        case DocumentContainsPr.NOT_CONTAINS:
            return "表示しない";
    }
}
