import {action, makeObservable, observable, runInAction} from "mobx";
import moment from "moment";

import {AdminApi} from "../../../api/api";
import {DocumentRevisionSearchInput} from "../../../api/values/document-revision-search-input";
import {DocumentRevisionType} from "../../../api/values/document-revision-type";
import {BigIntId} from "../../../utils/big-int-id";

export const REVISION_LIST_DISPLAY_LENGTH = 5;

/* eslint-disable max-classes-per-file */
class RevisionListItem {
    @observable
    public id: BigIntId = new BigIntId("0");

    @observable
    public revisionName: string = "";

    @observable
    public revisionNumber: number | null = null;

    @observable
    public revisionType: DocumentRevisionType = DocumentRevisionType.NORMAL;

    @observable
    public appliedAt: moment.Moment | null = null;

    public constructor(args?: Partial<RevisionListItem>) {
        makeObservable(this);
        Object.assign(this, args);
    }
}

interface RevisionListPanelStoreOptions {
    adminApi: AdminApi;
}

export class RevisionListPanelStore {
    @observable
    public mediaId: string = "";

    @observable
    public revisions: RevisionListItem[] = [];

    @observable
    public documentId: BigIntId = new BigIntId("0");

    @observable
    public hasMore: boolean = false;

    private adminApi: AdminApi;

    constructor(options: RevisionListPanelStoreOptions) {
        this.adminApi = options.adminApi;

        makeObservable(this);
    }

    @action.bound
    public async loadRevisions(mediaId: string, documentId: BigIntId) {
        this.mediaId = mediaId;
        this.documentId = documentId;

        const result = await this.adminApi.getDocumentRevisions(
            new DocumentRevisionSearchInput({
                mediaId: this.mediaId,
                documentIds: [documentId],
                page: 1,
                pageSize: REVISION_LIST_DISPLAY_LENGTH * 2, // isWorking and not
            }),
        );

        runInAction(() => {
            this.revisions = result.documentRevisions.slice(0, REVISION_LIST_DISPLAY_LENGTH).map(
                (x) =>
                    new RevisionListItem({
                        appliedAt: x.appliedAt,
                        id: x.id,
                        revisionName: x.revisionName,
                        revisionNumber: x.revisionNumber,
                        revisionType: x.revisionType,
                    }),
            );
        });
    }
}
