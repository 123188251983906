import {observer} from "mobx-react";
import * as React from "react";
import {useEffect} from "react";

import {useAuthenticationStore} from "../../../stores/store-hooks";
import {getLoginUrl} from "../../../utils/auth-util";

interface AuthenticatedProps {
    requireAuth: boolean;
}

export const Authenticated: React.FC<AuthenticatedProps> = observer(({children, requireAuth}) => {
    const authenticationStore = useAuthenticationStore();
    const needsLogin = requireAuth && !authenticationStore.isAuthenticated;

    useEffect(() => {
        if (needsLogin) {
            // force refresh because AquaEditorLoader may keep Error state on AuthError
            location.href = getLoginUrl(location);
        }
    }, [needsLogin]);

    if (needsLogin) {
        return null;
    } else {
        return <>{children}</>;
    }
});
