import {observer} from "mobx-react";
import * as React from "react";

import {GoPageStore} from "./go-page-store";

@observer
export class GoPage extends React.Component<{pageStore: GoPageStore}> {
    public render() {
        return null;
    }
}
