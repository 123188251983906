import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";
import {DocumentPropertyInputOutput} from "./document-property-input-output";
import {DocumentRevisionUserInputOutput} from "./document-revision-user-input-output";

@JsonObject("DocumentRevisionUpdateInput")
export class DocumentRevisionUpdateInput {
    @JsonProperty()
    public content: string = JsonPropertyEmpty;

    @JsonProperty()
    public contentHtml: string = JsonPropertyEmpty;

    @JsonProperty("property", DocumentPropertyInputOutput)
    public property: DocumentPropertyInputOutput = JsonPropertyEmpty;

    @JsonProperty()
    public users: DocumentRevisionUserInputOutput[] = JsonPropertyEmpty;

    @JsonProperty()
    public revisionName: string = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentRevisionUpdateInput>) {
        Object.assign(this, args);
    }
}
