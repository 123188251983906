import * as React from "react";
import {v4 as uuid} from "uuid";

import {IListEditorItem, ListEditor} from "../../molecules/list-editor/list-editor";

interface IStringValueListEditorItemProps {
    order: number;
    onChange: (value: string | null) => void;
    onRemove: () => void;
    value: string | null;
}

interface IStringValueListEditorProps {
    values: string[];
    onChange: (values: string[]) => void;
    itemRenderer: (props: IStringValueListEditorItemProps) => JSX.Element;
}

interface IUserListEditorState {
    values: Array<IListEditorItem<string>>;
}

export class StringValueListEditor extends React.Component<
    IStringValueListEditorProps,
    IUserListEditorState
> {
    public static getDerivedStateFromProps(
        nextProps: IStringValueListEditorProps,
        prevState: IUserListEditorState,
    ) {
        const nextValues = nextProps.values;
        const prevValues = prevState.values
            .map((x) => (x.data ? x.data : undefined))
            .filter((x) => !!x);

        if (JSON.stringify(nextValues) === JSON.stringify(prevValues)) {
            return null;
        }

        return {
            values: nextProps.values.concat([""]).map((value) => {
                return {
                    data: value,
                    key: uuid(),
                };
            }),
        };
    }

    public state = {values: [{data: null, key: uuid()}]};

    public render() {
        const handleChange = (items: Array<IListEditorItem<string>>) => {
            const values = items.map((item) => item.data).filter((data) => !!data) as string[];
            this.props.onChange(values);
        };

        return (
            <ListEditor
                items={this.state.values}
                onChange={handleChange}
                itemComponentFactory={(itemProps) =>
                    this.props.itemRenderer({
                        onChange: (value) =>
                            itemProps.onChange({
                                data: value,
                                key: itemProps.item.key,
                            }),
                        onRemove: itemProps.onRemove,
                        order: itemProps.order,
                        value: itemProps.item.data,
                    })
                }
            />
        );
    }
}
