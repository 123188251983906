import {IAquaAdminClient} from "@syginc/aqua-client";

import {AdminApi} from "../api/api";
import {getLinkTitle} from "../utils/get-link-title";
import {createImagefrontOption, ImagefrontTokenProvider} from "../utils/imagefront-util";
import {getBlockDefinitions} from "./admin-client/get-block-definitions";

export function createAquaAdminClient(
    adminApi: AdminApi,
    buildImageFilename: (file: File) => string,
    assetUrl: string | undefined,
    imagefrontTokenProvider: ImagefrontTokenProvider,
): IAquaAdminClient {
    return {
        getImagefrontOption: () =>
            createImagefrontOption(buildImageFilename, imagefrontTokenProvider),
        getAssetUrl: () => assetUrl,
        getLinkTitle: (url) => getLinkTitle(adminApi, url),
        getBlockDefinitions: () => getBlockDefinitions(adminApi),
    };
}
