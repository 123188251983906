import {AdminApi} from "../api/api";
import {CategoryGroupListInput} from "../api/values/category-group-list-input";
// eslint-disable-next-line max-len
import {DocumentPropertyRelatedResourceInputOutput} from "../api/values/document-property-related-resource-input-output";
import {RelatedResourceType} from "../api/values/related-resource-type";

export const deprecatedCategoryGroup = "DEPRECATED" as const;

export interface ICategoryItem {
    groupLabel: string;
    groupSlug: string;
    label: string;
    slug: string;
}

export interface ICategoryGroupItem {
    groupLabel: string;
    groupSlug: string;
}

export async function loadCategories(
    adminApi: AdminApi,
    mediaId: string,
): Promise<{
    categories: ICategoryItem[];
    categoryGroups: ICategoryGroupItem[];
}> {
    const categoryItemList: ICategoryItem[] = [];
    const categoryGroupList: ICategoryGroupItem[] = [];

    const output = await adminApi.getCategoryGroups(
        new CategoryGroupListInput({
            mediaId: mediaId,
        }),
    );

    output.categoryGroups.forEach((categoryGroup) => {
        categoryGroupList.push({groupLabel: categoryGroup.label, groupSlug: categoryGroup.slug});
        categoryGroup.categories.forEach((category) =>
            categoryItemList.push({
                groupLabel: categoryGroup.label,
                groupSlug: categoryGroup.slug,
                label: category.label,
                slug: category.slug,
            }),
        );
    });

    return {categories: categoryItemList, categoryGroups: categoryGroupList};
}

export async function getCategoryMap(
    adminApi: AdminApi,
    mediaId: string,
): Promise<Map<string, ICategoryItem>> {
    const {categories} = await loadCategories(adminApi, mediaId);
    const map = new Map<string, ICategoryItem>();
    categories.forEach((x) => map.set(x.slug, x));
    return map;
}

export function mapRelatedResourcesToCategories(
    categoryMap: Map<string, ICategoryItem>,
    relatedResources: DocumentPropertyRelatedResourceInputOutput[],
) {
    return relatedResources
        .filter((x) => x.resourceType === RelatedResourceType.CATEGORY)
        .map(
            (x) =>
                categoryMap.get(x.slug) || {
                    slug: x.slug,
                    label: `廃止されたカテゴリ(${x.slug})`,
                    groupSlug: "",
                    groupLabel: "廃止されたカテゴリグループ",
                },
        )
        .filter((x) => x !== undefined) as ICategoryItem[];
}
