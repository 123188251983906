import {JsonObject, JsonProperty} from "json2typescript";
import moment from "moment-timezone";

import {BigIntId} from "../../utils/big-int-id";
import {BigIntIdConverter, DateTimeConverter, JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("DocumentPreviewAddInput")
export class DocumentPreviewAddInput {
    @JsonProperty("documentRevisionId", BigIntIdConverter)
    public documentRevisionId: BigIntId = JsonPropertyEmpty;

    @JsonProperty("expiredAt", DateTimeConverter)
    public expiredAt: moment.Moment = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentPreviewAddInput>) {
        Object.assign(this, args);
    }
}
