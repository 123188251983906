import {Button} from "@blueprintjs/core";
import styled from "@emotion/styled";
import React, {ReactNode} from "react";

/**
 * 中央寄せテキストのスタイルを適用する共通コンポーネント。
 * 子要素を中央に配置します。
 */
const CenteredText = styled.div`
    margin: 12px auto;
    width: 90%;
    text-align: center;
`;

/**
 * 左揃えテキストのスタイルを適用する共通コンポーネント。
 * 子要素を左に配置します。
 */
const LeftAlignedText = styled.p`
    text-align: left;
    margin: 0 5%;
`;

/**
 * ボタンを横並びにするスタイルを適用するコンポーネント。
 * 特定のボタンに対してカスタムスタイルを適用します。
 */
const DialogBoxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
        &:first-of-type {
            background-color: red;
            color: white;
            &:hover {
                background-color: #f54b4b;
            }
            &:disabled {
                background-color: lightgray;
                color: gray;
            }
        }
    }
`;

interface DialogTextBoxProps {
    /** 子要素を含むReactノード */
    children: ReactNode;
}

/**
 * ダイアログのテキストボックスをラップするコンポーネント。
 * 子要素を中央に配置し、左揃えのテキストを含むことができます。
 *
 * @param {DialogTextBoxProps} props - 子要素を持つプロパティ。
 * @returns {JSX.Element} テキストボックスをラップした要素。
 */
export const DialogTextBox = ({children}: DialogTextBoxProps): JSX.Element => (
    <CenteredText>
        <LeftAlignedText>{children}</LeftAlignedText>
    </CenteredText>
);

interface DialogBoxProps {
    /** 確認ボタンがクリックされたときのハンドラー関数 */
    onConfirm: () => void;
    /** キャンセルボタンがクリックされたときのハンドラー関数 */
    onCancel: () => void;
    /** 確認ボタンが無効かどうかを示すフラグ */
    isDisabled?: boolean;
    /** 確認ボタンに表示するテキスト */
    confirmText?: string;
    /** キャンセルボタンに表示するテキスト */
    cancelText?: string;
}

/**
 * 確認とキャンセルボタンを含むダイアログボックスコンポーネント。
 * 確認とキャンセルの操作を提供します。
 *
 * @param {DialogBoxProps} props - ダイアログボックスのプロパティ。
 * @returns {JSX.Element} 確認とキャンセルボタンを含む要素。
 */
export const DialogBox = ({
    onConfirm,
    onCancel,
    isDisabled = false,
    confirmText = "確認",
    cancelText = "キャンセル",
}: DialogBoxProps): JSX.Element => (
    <DialogBoxWrapper>
        <Button onClick={onConfirm} disabled={isDisabled}>
            {confirmText}
        </Button>
        <Button onClick={onCancel}>{cancelText}</Button>
    </DialogBoxWrapper>
);
