import {AdminApi} from "../api/api";
import {BigIntId} from "./big-int-id";

export async function optimizeDocument(mediaId: string, documentId: BigIntId, adminApi: AdminApi) {
    await adminApi.postDocumentOptimize({mediaId: mediaId}, {id: documentId});
}

// call from a client to separate a transaction and keep flexibility
export function optimizeDocumentAndForgot(
    mediaId: string,
    documentId: BigIntId,
    adminApi: AdminApi,
) {
    optimizeDocument(mediaId, documentId, adminApi).catch((e) => {
        console.error("Failed to optimize document");
        console.error(e);
    });
}
