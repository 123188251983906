import {NodeObject} from "@syginc/aomjs";
import * as React from "react";
import {forwardRef, useImperativeHandle, useRef} from "react";

import {IAquaEditor} from "../../../editor/aqua-editor";
import {renderSourceHtml} from "../../../utils/render-html";
import {AomCustomEditor} from "./aom-custom-editor";
import {IAomEditorGetNodeHandler} from "./aom-editor-handler";
import {AomJsonEditor} from "./aom-json-editor";

export interface IAomEditorProps {
    aquaEditor: IAquaEditor;

    defaultNodeJson: string;
    onReady?: () => void;
    onChange?: () => void;
    onSave?: () => void;
    documentType: any;

    jsonMode?: boolean;
}

export interface IAomEditorHandler {
    getNodeAndHtml(): {aomNode?: NodeObject; renderedHtml?: string};
}

export const AomEditor = forwardRef<IAomEditorHandler, IAomEditorProps>(function AomEditor(
    props,
    ref,
) {
    const {jsonMode, aquaEditor, ...restProps} = props;
    const editorRef = useRef<IAomEditorGetNodeHandler>(null);

    useImperativeHandle(ref, () => {
        return {
            getNodeAndHtml() {
                const editor = editorRef.current;
                if (!editor || !aquaEditor) {
                    return {};
                }
                const aomNode = editor.getNode();
                if (!aomNode) {
                    return {};
                }
                const renderedHtml = renderSourceHtml(aquaEditor, aomNode);
                return {aomNode, renderedHtml};
            },
        };
    });

    if (jsonMode) {
        return <AomJsonEditor ref={editorRef} aquaEditor={aquaEditor} {...restProps} />;
    } else {
        return <AomCustomEditor ref={editorRef} aquaEditor={aquaEditor} {...restProps} />;
    }
});
