import {observer} from "mobx-react";
import * as React from "react";
import {createContext, useContext, useEffect, useState} from "react";

import {useAdminApi} from "../api/api-context";
import {useAuthenticationStore} from "../stores/store-hooks";
import {getSiteUrl} from "../utils/global-config";
import {IAquaEditor} from "./aqua-editor";
import {loadAquaEditor} from "./load-aqua-editor";

export type AquaEditorData =
    | {state: "uninitialized"}
    | {state: "loading"}
    | {state: "loaded"; aquaEditor: IAquaEditor}
    | {state: "error"; error: Error};

export type AquaEditorState = AquaEditorData["state"];

export interface AquaEditorContextValue {
    aquaEditorData: AquaEditorData;
}

export const AquaEditorContext = createContext<AquaEditorContextValue | undefined>(undefined);

export const AquaEditorProvider: React.FC = observer(({children}) => {
    const adminApi = useAdminApi();

    const authenticationStore = useAuthenticationStore();
    const username = authenticationStore.username;

    const [aquaEditorData, setAquaEditorData] = useState<AquaEditorData>({state: "uninitialized"});

    const isInitialized = aquaEditorData.state !== "uninitialized";
    useEffect(() => {
        if (isInitialized || !username) {
            return;
        }

        const imagefrontTokenProvider = async () => {
            return (await authenticationStore.getOrRefreshToken())!;
        };

        setAquaEditorData({state: "loading"});
        loadAquaEditor({siteUrl: getSiteUrl(), adminApi, imagefrontTokenProvider, user: username})
            .then((aquaEditor) => setAquaEditorData({state: "loaded", aquaEditor}))
            .catch((error) => {
                console.error(error);
                setAquaEditorData({state: "error", error});
            });
    }, [adminApi, authenticationStore, isInitialized, username]);

    return (
        <AquaEditorContext.Provider
            value={{
                aquaEditorData,
            }}
        >
            {children}
        </AquaEditorContext.Provider>
    );
});

export function useAquaEditorContext() {
    const value = useContext(AquaEditorContext);
    if (!value) {
        throw new Error("AquaEditorProvider is not provided");
    }
    return value;
}
