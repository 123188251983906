import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("UserAddInput")
export class UserAddInput {
    @JsonProperty()
    public name: string = JsonPropertyEmpty;

    @JsonProperty()
    public email: string = JsonPropertyEmpty;

    @JsonProperty()
    public password: string = JsonPropertyEmpty;

    @JsonProperty()
    public role: string = JsonPropertyEmpty;

    public constructor(args?: Partial<UserAddInput>) {
        Object.assign(this, args);
    }
}
