import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";
import {UserListItemOutput} from "./user-list-item-output";

@JsonObject("UserSearchOutput")
export class UserSearchOutput {
    @JsonProperty("users", [UserListItemOutput])
    public users: UserListItemOutput[] = JsonPropertyEmpty;

    @JsonProperty()
    public nextPageExists: boolean = JsonPropertyEmpty;

    @JsonProperty()
    public previousPageExists: boolean = JsonPropertyEmpty;
}
