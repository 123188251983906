import {JsonObject, JsonProperty} from "json2typescript";

import {BigIntId} from "../../utils/big-int-id";
import {BigIntIdConverter, JsonPropertyEmpty} from "../../utils/json-util";
import {DocumentTypeEnum} from "./document-type";

@JsonObject("DocumentGetOutput")
export class DocumentGetOutput {
    @JsonProperty("id", BigIntIdConverter)
    public id: BigIntId = JsonPropertyEmpty;

    @JsonProperty()
    public documentType: DocumentTypeEnum = JsonPropertyEmpty;

    @JsonProperty()
    public slug: string | null = JsonPropertyEmpty;

    @JsonProperty()
    public archived: boolean = JsonPropertyEmpty;

    @JsonProperty("activeDocumentRevisionId", BigIntIdConverter)
    public activeDocumentRevisionId: BigIntId = JsonPropertyEmpty;

    @JsonProperty("maxDocumentRevisionId", BigIntIdConverter)
    public maxDocumentRevisionId: BigIntId = JsonPropertyEmpty;
}
