import {JsonObject, JsonProperty} from "json2typescript";

import {BigIntId} from "../../utils/big-int-id";
import {BigIntIdArrayConverter, JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("DocumentRevisionSearchInput")
export class DocumentRevisionSearchInput {
    @JsonProperty("mediaId", String, true)
    public mediaId: string = JsonPropertyEmpty;

    @JsonProperty("documentIds", BigIntIdArrayConverter, true)
    public documentIds?: BigIntId[] = JsonPropertyEmpty;

    @JsonProperty("revisionName", String, true)
    public revisionName?: string = JsonPropertyEmpty;

    @JsonProperty("title", String, true)
    public title?: string = JsonPropertyEmpty;

    @JsonProperty("userName", String, true)
    public userName?: string = JsonPropertyEmpty;

    @JsonProperty("isWorking", Boolean, true)
    public isWorking?: boolean = JsonPropertyEmpty;

    @JsonProperty("documentType", String, true)
    public documentType?: string = JsonPropertyEmpty;

    @JsonProperty("page", Number, true)
    public page: number = JsonPropertyEmpty;

    @JsonProperty("pageSize", Number, true)
    public pageSize: number = JsonPropertyEmpty;

    @JsonProperty("measureTotal", Boolean, true)
    public measureTotal?: boolean = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentRevisionSearchInput>) {
        Object.assign(this, args);
    }
}
