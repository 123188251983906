import {AxiosRequestConfig} from "axios";

/**
 * HTTPリクエストヘッダーのオプションを表すインターフェース。
 */
export interface HttpRequestHeadersOption {
    accessToken?: string;
}

/**
 * HTTPリクエストのヘッダーを生成する関数。
 *
 * @param {HttpRequestHeadersOption} [option] - アクセストークンなどのオプション。
 * @returns {AxiosRequestConfig["headers"]} - 生成されたHTTPリクエストヘッダー。
 */
export function getHttpRequestHeaders(
    option?: HttpRequestHeadersOption,
): AxiosRequestConfig["headers"] {
    const headers: AxiosRequestConfig["headers"] = {
        "X-Requested-With": "fetch",
        "Content-Type": "application/json",
    };

    if (option?.accessToken) {
        headers["Authorization"] = `Bearer ${option.accessToken}`;
    }

    return headers;
}
