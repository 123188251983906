import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("DocumentRevisionUserInputOutput")
export class DocumentRevisionUserInputOutput {
    @JsonProperty()
    public name: string = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentRevisionUserInputOutput>) {
        Object.assign(this, args);
    }
}
