import * as React from "react";

import {AuthenticationStore} from "../../../stores/authentication-store";
import {useAuthenticationStore} from "../../../stores/store-hooks";
import {BreadcrumbItemBuilder, breadcrumbItems} from "../../../utils/breadcrumb-util";
import {
    INextBreadcrumbProps,
    NextBreadcrumbs,
} from "../../molecules/next-breadcrumbs/next-breadcrumbs";

interface AdminBreadcrumbProps {
    factory: (
        builder: BreadcrumbItemBuilder,
        authenticationStore?: AuthenticationStore,
    ) => INextBreadcrumbProps[];
}

export const AdminBreadcrumb = ({factory}: AdminBreadcrumbProps) => {
    const authenticationStore = useAuthenticationStore();

    const builder = breadcrumbItems(authenticationStore);
    const items = factory(builder, authenticationStore);

    return <NextBreadcrumbs items={items} />;
};
