import React from "react";

import {Link} from "../../../routes";
import {ILinkProps} from "../wrapping-link/wrapping-link";

interface EmptySafeLinkProps {
    className?: string;
}

export const EmptySafeLink: React.FC<EmptySafeLinkProps & ILinkProps> = (props) => {
    let usePlaceholder = true;
    React.Children.forEach(props.children, (x) => {
        if (typeof x === "string" && x.trim().length === 0) {
            return;
        }
        usePlaceholder = false;
    });

    return (
        <Link route={props.route} params={props.params}>
            <a className={props.className}>{usePlaceholder ? "_" : props.children}</a>
        </Link>
    );
};
