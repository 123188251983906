import {Intent, Toast, Toaster} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";
import {useEffect} from "react";

import {useToastStore} from "../../../stores/store-hooks";
import {usePageChangingContext} from "../page-changing-context/page-changing-context";

export const GlobalToaster: React.FC = observer(() => {
    const pageChangingContext = usePageChangingContext();
    const toastStore = useToastStore();

    useEffect(() => {
        return pageChangingContext.listen(() => toastStore.routeChanged());
    }, [pageChangingContext, toastStore]);

    return (
        <Toaster>
            {toastStore.httpError && (
                <Toast
                    message={toastStore.httpErrorDetail!.message}
                    intent={toastStore.httpErrorDetail!.intent}
                    onDismiss={() => {
                        toastStore.setHttpError(null);
                    }}
                />
            )}

            {toastStore.isEditLeaveShown && (
                <Toast
                    message={"離れた編集画面に未保存の変更があります。"}
                    intent={Intent.WARNING}
                    action={{
                        onClick: () => {
                            toastStore.backToEditLeave();
                        },
                        text: "編集画面に戻る",
                    }}
                    onDismiss={() => {
                        toastStore.hideEditLeave();
                    }}
                    timeout={60000}
                />
            )}

            {toastStore.simpleToast && (
                <Toast
                    message={toastStore.simpleToastDetail!.text}
                    intent={toastStore.simpleToastDetail!.intent}
                    timeout={3000}
                    onDismiss={() => {
                        toastStore.setSimpleToast(null);
                    }}
                />
            )}
        </Toaster>
    );
});
