import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("TokenInput")
export class TokenInput {
    @JsonProperty("grant_type", String, false)
    public grantType: TokenGrantType = JsonPropertyEmpty;

    @JsonProperty("username", String, true)
    public username?: string = JsonPropertyEmpty;

    @JsonProperty("password", String, true)
    public password?: string = JsonPropertyEmpty;

    @JsonProperty("refresh_token", String, true)
    public refreshToken?: string = JsonPropertyEmpty;

    public constructor(args?: Partial<TokenInput>) {
        Object.assign(this, args);
    }
}

export enum TokenGrantType {
    Password = "password",
    RefreshToken = "refresh_token",
}
