import {DocumentProtectionStatus} from "../api/values/document-protection-status";

export function formatProtectionStatus(status: DocumentProtectionStatus): string {
    switch (status) {
        case DocumentProtectionStatus.HIDDEN:
            return "非公開";
        case DocumentProtectionStatus.VISIBLE:
            return "公開";
    }
}
