import "normalize.css/normalize.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "nprogress/nprogress.css";

import React from "react";
import ReactDOM from "react-dom";

import {App} from "./app";

ReactDOM.render(<App />, document.getElementById("root"));
