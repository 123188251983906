import * as React from "react";

import {StringValueListEditor} from "../string-value-list-editor/string-value-list-editor";
import {UserListEditorItem} from "./user-list-editor-item";

interface IUserListEditorProps {
    names: string[];
    onChange: (values: string[]) => void;
}

export class UserListEditor extends React.Component<IUserListEditorProps> {
    public render() {
        return (
            <StringValueListEditor
                values={this.props.names}
                onChange={this.props.onChange}
                itemRenderer={(props) => (
                    <UserListEditorItem
                        value={props.value}
                        placeholder="ユーザーを追加"
                        onChange={props.onChange}
                        onRemove={props.onRemove}
                    />
                )}
            />
        );
    }
}
