import {Button, Drawer, H5, Intent} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";

import {DrawerBody} from "../../molecules/drawer-body/drawer-body";
import {DrawerFooter} from "../../molecules/drawer-footer/drawer-footer";
import {SlugEditInput} from "../slug-edit-input/slug-edit-input";
import {DocumentSettingStore} from "./document-setting-store";

@observer
export class DocumentSettingDrawer extends React.Component<{store: DocumentSettingStore}> {
    public render() {
        return (
            <Drawer
                isOpen={this.props.store.isOpen}
                onClose={this.props.store.close}
                title={"ドキュメント設定"}
            >
                <DrawerBody>
                    <H5>スラッグ設定</H5>
                    <SlugEditInput store={this.props.store.slugEditInputStore} />
                </DrawerBody>

                <DrawerFooter
                    actions={
                        <Button
                            disabled={!this.props.store.canSave}
                            intent={Intent.PRIMARY}
                            onClick={this.props.store.save}
                        >
                            変更
                        </Button>
                    }
                />
            </Drawer>
        );
    }
}
