import {action, makeObservable} from "mobx";

import {AdminApi} from "../../../api/api";
import {DocumentPreviewListGetInput} from "../../../api/values/document-preview-list-get-input";
import {DocumentSearchInput} from "../../../api/values/document-search-input";
import {DocumentType} from "../../../api/values/document-type";
import {PageStoreConstructorOptions} from "../../../utils/page-store";
import {PageRouter} from "../../../utils/route-util";

export class GoPageStore {
    private adminApi: AdminApi;
    private pageRouter: PageRouter;
    private mediaId: string = "";

    constructor(options: PageStoreConstructorOptions) {
        makeObservable(this);

        this.adminApi = options.adminApi;
        this.pageRouter = options.pageRouter;
    }

    public async initialize({query}: any = {}) {
        if (query) {
            if (query.slug) {
                await this.forwardWithSlug(query.slug);
                return;
            }

            if (query.previewKey) {
                await this.forwardWithPreviewKey(query.previewKey);
                return;
            }
            this.setMediaId(query.mediaId);
        }

        throw new Error("Invalid go query");
    }

    @action.bound
    private async forwardWithSlug(slug: string) {
        for (const documentType of [DocumentType.ARTICLE, DocumentType.PERSON]) {
            const result = await this.adminApi.getDocuments(
                new DocumentSearchInput({
                    mediaId: this.mediaId,
                    slug,
                    documentType,
                }),
            );

            const documents = result.documents;
            if (documents.length !== 0) {
                this.pageRouter.replaceRoute("/admin/[mediaId]/documents/[documentId]", {
                    mediaId: this.mediaId,
                    documentId: documents[0].id.value,
                });
                return;
            }
        }
        this.pageRouter.replaceRoute("/admin/[mediaId]/documents", {
            mediaId: this.mediaId,
        });
    }

    @action.bound
    private async forwardWithPreviewKey(previewKey: string) {
        const result = await this.adminApi.getDocumentPreviews(
            new DocumentPreviewListGetInput({
                mediaId: this.mediaId,
                previewKey,
            }),
        );
        const previews = result.previews;

        if (previews.length === 0) {
            this.pageRouter.replaceRoute("/admin/[mediaId]/revisions", {
                mediaId: this.mediaId,
            });
            return;
        }

        this.pageRouter.replaceRoute("/admin/[mediaId]/revisions/[documentRevisionId]", {
            mediaId: this.mediaId,
            documentRevisionId: previews[0].documentRevisionId.value,
        });
    }

    @action.bound
    public async setMediaId(s: string) {
        this.mediaId = s;
    }
}
