import {Card, HTMLTable} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";

import {formatCategoryItems} from "../../../models/format-category-item";
import {formatContainsPr} from "../../../models/format-contains-pr";
import {formatPublishScope} from "../../../models/format-publish-scope";
import {MomentFormats} from "../../../utils/moment-util";
import {PropertyDiffImage} from "./property-diff-image";
import {PropertyDiffStore} from "./property-diff-store";

interface IPropertyDiffPanelProps {
    store: PropertyDiffStore;
    changeOnly?: boolean;
}

function differenceElements(props: IPropertyDiffPanelProps) {
    const {changeOnly} = props;
    const {property, baseProperty} = props.store;

    const list = [];

    if (!changeOnly || baseProperty.title !== property.title) {
        list.push(
            <tr key="title">
                <th>タイトル</th>
                <td>{baseProperty.title}</td>
                <td>{property.title}</td>
            </tr>,
        );
    }

    if (
        !changeOnly ||
        (baseProperty.modifiedAt && !baseProperty.modifiedAt.isSame(property.modifiedAt))
    ) {
        list.push(
            <tr key="modifiedAt">
                <th>修正日時（表示用）</th>
                <td>
                    {baseProperty.modifiedAt &&
                        baseProperty.modifiedAt.format(MomentFormats.datetimeMinute)}
                </td>
                <td>
                    {property.modifiedAt &&
                        property.modifiedAt.format(MomentFormats.datetimeMinute)}
                </td>
            </tr>,
        );
    }

    if (!changeOnly || baseProperty.imageUri !== property.imageUri) {
        list.push(
            <tr key="imageUri">
                <th>アイキャッチ画像</th>
                <td>
                    <PropertyDiffImage imageUri={baseProperty.imageUri} />
                </td>
                <td>
                    <PropertyDiffImage imageUri={property.imageUri} />
                </td>
            </tr>,
        );
    }

    if (!changeOnly || baseProperty.imageAuthorityTitle !== property.imageAuthorityTitle) {
        list.push(
            <tr key="imageAuthorityTitle">
                <th>
                    アイキャッチ画像
                    <br />
                    出典タイトル
                </th>
                <td>{baseProperty.imageAuthorityTitle}</td>
                <td>{property.imageAuthorityTitle}</td>
            </tr>,
        );
    }

    if (!changeOnly || baseProperty.imageAuthorityUri !== property.imageAuthorityUri) {
        list.push(
            <tr key="imageAuthorityUri">
                <th>
                    アイキャッチ画像
                    <br />
                    出典URL
                </th>
                <td>{baseProperty.imageAuthorityUri}</td>
                <td>{property.imageAuthorityUri}</td>
            </tr>,
        );
    }

    if (!changeOnly || baseProperty.personName !== property.personName) {
        list.push(
            <tr key="personName">
                <th>人物 名前</th>
                <td>{baseProperty.personName}</td>
                <td>{property.personName}</td>
            </tr>,
        );
    }

    if (!changeOnly || baseProperty.personRole !== property.personRole) {
        list.push(
            <tr key="personRole">
                <th>人物 役割</th>
                <td>{baseProperty.personRole}</td>
                <td>{property.personRole}</td>
            </tr>,
        );
    }

    if (!changeOnly || baseProperty.personImageUri !== property.personImageUri) {
        list.push(
            <tr key="personImageUri">
                <th>人物 画像</th>
                <td>
                    <PropertyDiffImage imageUri={baseProperty.personImageUri} />
                </td>
                <td>
                    <PropertyDiffImage imageUri={property.personImageUri} />
                </td>
            </tr>,
        );
    }

    if (!changeOnly || baseProperty.personSlug !== property.personSlug) {
        list.push(
            <tr key="personSlug">
                <th>人物 スラッグ</th>
                <td>{baseProperty.personSlug}</td>
                <td>{property.personSlug}</td>
            </tr>,
        );
    }

    if (!changeOnly || baseProperty.containsPr !== property.containsPr) {
        list.push(
            <tr key="containsPr">
                <th>広告表示</th>
                <td>{formatContainsPr(baseProperty.containsPr)}</td>
                <td>{formatContainsPr(property.containsPr)}</td>
            </tr>,
        );
    }

    if (!changeOnly || baseProperty.categorySlugsJoined !== property.categorySlugsJoined) {
        list.push(
            <tr key="categoryLabelsJoined">
                <th>カテゴリ</th>
                <td>{formatCategoryItems(baseProperty.categories)}</td>
                <td>{formatCategoryItems(property.categories)}</td>
            </tr>,
        );
    }

    if (
        !changeOnly ||
        baseProperty.relatedDocumentSlugsJoined !== property.relatedDocumentSlugsJoined
    ) {
        list.push(
            <tr key="relatedDocumentSlugsJoined">
                <th>関連ドキュメント</th>
                <td>{baseProperty.relatedDocumentSlugsJoined}</td>
                <td>{property.relatedDocumentSlugsJoined}</td>
            </tr>,
        );
    }

    if (!changeOnly || baseProperty.publishScope !== property.publishScope) {
        list.push(
            <tr key="publishScope">
                <th>公開範囲</th>
                <td>{formatPublishScope(baseProperty.publishScope)}</td>
                <td>{formatPublishScope(property.publishScope)}</td>
            </tr>,
        );
    }

    if (!changeOnly || baseProperty.htmlTitle !== property.htmlTitle) {
        list.push(
            <tr key="htmlTitle">
                <th>
                    &lt;title&gt;
                    <br />
                    (SEOタイトル)
                </th>
                <td>{baseProperty.htmlTitle}</td>
                <td>{property.htmlTitle}</td>
            </tr>,
        );
    }

    if (!changeOnly || baseProperty.htmlDescription !== property.htmlDescription) {
        list.push(
            <tr key="htmlDescription">
                <th>
                    &lt;meta name=&quot;description&quot;&gt;
                    <br />
                    (SEOディスクリプション)
                </th>
                <td>{baseProperty.htmlDescription}</td>
                <td>{property.htmlDescription}</td>
            </tr>,
        );
    }

    if (!changeOnly || baseProperty.htmlKeywords !== property.htmlKeywords) {
        list.push(
            <tr key="htmlKeywords">
                <th>
                    &lt;meta name=&quot;keywords&quot;&gt;
                    <br />
                    (SEOキーワード)
                </th>
                <td>{baseProperty.htmlKeywords}</td>
                <td>{property.htmlKeywords}</td>
            </tr>,
        );
    }

    if (!changeOnly || baseProperty.htmlRobots !== property.htmlRobots) {
        list.push(
            <tr key="htmlRobots">
                <th>&lt;meta name=&quot;robots&quot;&gt;</th>
                <td>{baseProperty.htmlRobots}</td>
                <td>{property.htmlRobots}</td>
            </tr>,
        );
    }

    if (!changeOnly || baseProperty.htmlScript !== property.htmlScript) {
        list.push(
            <tr key="htmlScript">
                <th>スクリプト</th>
                <td>{baseProperty.htmlScript}</td>
                <td>{property.htmlScript}</td>
            </tr>,
        );
    }

    return list;
}

@observer
export class PropertyDiffPanel extends React.Component<IPropertyDiffPanelProps, {}> {
    public render() {
        const changeList = differenceElements(this.props);

        return (
            changeList.length > 0 && (
                <div style={{marginBottom: "20px"}}>
                    <Card>
                        <HTMLTable>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>変更前</th>
                                    <th>変更後</th>
                                </tr>
                            </thead>

                            <tbody>{changeList}</tbody>
                        </HTMLTable>
                    </Card>
                </div>
            )
        );
    }
}
