import styled from "@emotion/styled";
import * as React from "react";

const ButtonListUl = styled.ul`
    list-style-type: none;
    padding-left: 0;
    margin: 0;
`;

const ButtonListLi = styled.li`
    display: inline-block;
    margin-left: 5px;
`;

export const ButtonList: React.FC = ({children}) => (
    <ButtonListUl>
        {React.Children.map(children, (x, i) => (
            <ButtonListLi key={i}>{x}</ButtonListLi>
        ))}
    </ButtonListUl>
);
