import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("UserSearchInput")
export class UserSearchInput {
    @JsonProperty("mediaId", String, true)
    public mediaId: string = JsonPropertyEmpty;

    @JsonProperty("name", String, true)
    public name: string = JsonPropertyEmpty;

    @JsonProperty("enabled", Boolean, true)
    public enabled: boolean = JsonPropertyEmpty;

    @JsonProperty("page", Number, true)
    public page: number = JsonPropertyEmpty;

    @JsonProperty("pageSize", Number, true)
    public pageSize: number = JsonPropertyEmpty;

    public constructor(args?: Partial<UserSearchInput>) {
        Object.assign(this, args);
    }
}
