import {makeObservable, observable} from "mobx";
import moment from "moment";

import {DocumentRevisionType} from "../../../api/values/document-revision-type";
import {BigIntId} from "../../../utils/big-int-id";

export class DocumentListRevisionItem {
    @observable
    public id: BigIntId = new BigIntId("0");

    @observable
    public revisionName: string = "";

    @observable
    public revisionType: DocumentRevisionType = DocumentRevisionType.NORMAL;

    @observable
    public updatedAt: moment.Moment = moment();

    @observable
    public userNames: string[] = [];

    public constructor(args?: Partial<DocumentListRevisionItem>) {
        makeObservable(this);
        Object.assign(this, args);
    }
}
