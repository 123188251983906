import {makeObservable, observable} from "mobx";

import {UserRole} from "../../../api/values/user-role-type";
import {BigIntId} from "../../../utils/big-int-id";

export class UserListItem {
    @observable
    public id: BigIntId = new BigIntId("0");

    @observable
    public name: string = "";

    @observable
    public role: UserRole = UserRole.ADMIN;

    @observable
    public enabled: boolean = false;

    public constructor(args?: Partial<UserListItem>) {
        makeObservable(this);
        Object.assign(this, args);
    }
}
