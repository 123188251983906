import * as React from "react";

import {DocumentType, DocumentTypeEnum} from "../../../api/values/document-type";
import {BigIntId} from "../../../utils/big-int-id";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";

interface DocumentEditBreadcrumb {
    documentType: DocumentType | DocumentTypeEnum;
    documentId: BigIntId;
    documentRevisionId?: BigIntId;
}

export const DocumentEditBreadcrumb: React.FC<DocumentEditBreadcrumb> = ({
    documentType,
    documentId,
    documentRevisionId,
}) => (
    <AdminBreadcrumb
        factory={(builder) => {
            builder
                .documentListByTypeEnum(documentType)
                .documentEditByTypeEnum(documentType, documentId);

            if (documentRevisionId) {
                builder.documentRevisionEdit(documentRevisionId);
            }

            return builder.build();
        }}
    />
);
