import {JsonObject, JsonProperty} from "json2typescript";

import {BigIntId} from "../../utils/big-int-id";
import {BigIntIdConverter, JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("DocumentContentDiffInput")
export class DocumentContentDiffInput {
    @JsonProperty("mediaId", String, true)
    public mediaId: string = JsonPropertyEmpty;

    @JsonProperty("baseDocumentContentId", BigIntIdConverter, true)
    public baseDocumentContentId: BigIntId = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentContentDiffInput>) {
        Object.assign(this, args);
    }
}
