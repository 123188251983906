import {FormGroup, Intent, TextArea} from "@blueprintjs/core";
import {NodeObject} from "@syginc/aomjs";
import {
    ChangeEvent,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import * as React from "react";

import {IAquaEditor} from "../../../editor/aqua-editor";
import {debounce} from "../../../utils/debounce";
import {renderSourceHtml} from "../../../utils/render-html";
import {IAomEditorGetNodeHandler} from "./aom-editor-handler";

export interface IAomJsonEditorProps {
    aquaEditor: IAquaEditor;
    defaultNodeJson: string;
    onReady?: () => void;
    onChange?: () => void;
}

export const AomJsonEditor = forwardRef<IAomEditorGetNodeHandler, IAomJsonEditorProps>(
    function AomJsonEditor(props, ref) {
        const {aquaEditor, defaultNodeJson, onReady, onChange} = props;

        const [errorMessage, setErrorMessage] = useState<string | null>(null);
        const [currentNode, setCurrentNode] = useState<NodeObject | null>(null);
        const [aomEditingJson, setAomEditingJson] = useState<string>("");
        useEffect(() => {
            const initialNode = JSON.parse(defaultNodeJson);
            setCurrentNode(initialNode);
            setAomEditingJson(JSON.stringify(initialNode, null, 2));
            onReady?.();
        }, [defaultNodeJson, onReady]);

        useImperativeHandle(ref, () => {
            return {
                getNode() {
                    return currentNode;
                },
            };
        });

        const handleTextAreaChange = useCallback(
            (e: ChangeEvent<HTMLTextAreaElement>) => {
                const debouncedChangeText = debounce(300, (v: string) => {
                    try {
                        const aomNode = JSON.parse(v);

                        // tag check
                        renderSourceHtml(aquaEditor, aomNode);

                        setCurrentNode(aomNode);
                        setErrorMessage(null);
                    } catch (e) {
                        const error = e as Error;
                        setErrorMessage(error.message);
                    }
                    onChange?.();
                });

                const value = e.target.value;
                setAomEditingJson(value);
                debouncedChangeText(value);
            },
            [aquaEditor, onChange],
        );

        const intent = errorMessage ? Intent.DANGER : Intent.NONE;
        return (
            <FormGroup intent={intent} helperText={errorMessage}>
                <TextArea
                    intent={intent}
                    fill={true}
                    rows={30}
                    onChange={handleTextAreaChange}
                    value={aomEditingJson}
                />
            </FormGroup>
        );
    },
);
