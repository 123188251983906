import {runInAction} from "mobx";

import {debounce} from "./debounce";

export function conflictChecker(
    setInProgress: (inProgress: boolean) => void,
    setIsConflicted: (isConflicted: boolean) => void,
    checkConflictFunc: (currentValue: string) => Promise<boolean>,
): (initialValue: string, currentValue: string) => void {
    const debounceFunc = debounce(1000, async (value: string) => {
        const isConflicted = await checkConflictFunc(value);
        runInAction(() => {
            setIsConflicted(isConflicted);
            setInProgress(false);
        });
    });

    return (initialValue, currentValue) => {
        if (initialValue === currentValue) {
            runInAction(() => {
                setIsConflicted(false);
            });
            return;
        }

        runInAction(() => {
            setInProgress(true);
        });

        return debounceFunc(currentValue);
    };
}
