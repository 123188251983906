import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";
import {MediaProtectionStatus} from "./media-protection-status";

@JsonObject("MediaGetOutput")
export class MediaGetOutput {
    @JsonProperty("id", String, true)
    public id: string = JsonPropertyEmpty;

    @JsonProperty("name", String, true)
    public name: string = JsonPropertyEmpty;

    @JsonProperty("summary", String, true)
    public summary: string = JsonPropertyEmpty;

    @JsonProperty("base_url", String, true)
    public base_url: string = JsonPropertyEmpty;

    @JsonProperty("title", String, true)
    public title: string = JsonPropertyEmpty;

    @JsonProperty("description", String, true)
    public description: string = JsonPropertyEmpty;

    @JsonProperty("og_image_url", String, true)
    public og_image_url: string = JsonPropertyEmpty;

    @JsonProperty("favicon_url", String, true)
    public favicon_url: string = JsonPropertyEmpty;

    @JsonProperty("thumbnail_url", String, true)
    public thumbnail_url: string = JsonPropertyEmpty;

    @JsonProperty("protection_status", String, true)
    public protection_status: MediaProtectionStatus = JsonPropertyEmpty;

    @JsonProperty("api_token", String, true)
    public api_token: string = JsonPropertyEmpty;

    public constructor(args?: Partial<MediaGetOutput>) {
        Object.assign(this, args);
    }
}
