import styled from "@emotion/styled";
import * as React from "react";

const HeadingDetailItemLi = styled.li`
    display: inline-block;
    padding-right: 0.5em;

    & + & {
        border-left: 1px solid #aaa;
        padding: 0.2em 0.5em;
    }
`;

export interface HeadingDetailItemProps {
    label?: string;
}

export const HeadingDetailItem: React.FC<HeadingDetailItemProps> = ({label, children}) => (
    <HeadingDetailItemLi>
        {label && `${label}: `}
        {children}
    </HeadingDetailItemLi>
);
