import {JsonObject, JsonProperty} from "json2typescript";
import moment from "moment-timezone";

import {BigIntId} from "../../utils/big-int-id";
import {BigIntIdConverter, DateTimeConverter, JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("DocumentPreviewOutput")
export class DocumentPreviewOutput {
    @JsonProperty("id", BigIntIdConverter)
    public id: BigIntId = JsonPropertyEmpty;

    @JsonProperty("documentRevisionId", BigIntIdConverter)
    public documentRevisionId: BigIntId = JsonPropertyEmpty;

    @JsonProperty()
    public previewKey: string = JsonPropertyEmpty;

    @JsonProperty("expiredAt", DateTimeConverter)
    public expiredAt: moment.Moment = JsonPropertyEmpty;
}
