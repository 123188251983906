import {Button, Card, H3, HTMLTable} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";

import {useAquaEditor} from "../../../editor/use-aqua-editor";
import {MomentFormats} from "../../../utils/moment-util";
import {DateTimeText} from "../../atoms/datetime-text/datetime-text";
import {AquaEditorStatus} from "../../molecules/aqua-editor-status/aqua-editor-status";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import {PropertyDiffPanel} from "../../organisms/property-diff-panel/property-diff-panel";
import {VisualSourceDiffSet} from "../../organisms/visual-source-diff-set/visual-source-diff-set";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {DocumentRevisionDetailButtons} from "./revision-detail-buttons";
import {DocumentRevisionDetailPageStore} from "./revision-detail-page-store";

export const RevisionDetailPage = observer(function RevisionDetailPage(props: {
    pageStore: DocumentRevisionDetailPageStore;
}) {
    const {aquaEditor, aquaEditorState} = useAquaEditor();

    return (
        <AppLayout
            breadcrumb={
                <AdminBreadcrumb
                    factory={(builder) =>
                        builder
                            .documentList()
                            .documentEdit(props.pageStore.documentId)
                            .custom("下書き情報")
                            .build()
                    }
                />
            }
            buttons={<DocumentRevisionDetailButtons pageStore={props.pageStore} />}
        >
            <H3>公開情報</H3>
            <HTMLTable>
                <tbody>
                    <tr>
                        <th>{props.pageStore.unschedulable ? "公開予定日時" : "公開日時"}</th>
                        <td>
                            {props.pageStore.appliedAt ? (
                                <DateTimeText
                                    time={props.pageStore.appliedAt!}
                                    format={MomentFormats.datetimeMinute}
                                />
                            ) : (
                                "なし"
                            )}
                            {props.pageStore.unschedulable && (
                                <Button onClick={() => props.pageStore.unschedule()}>
                                    公開中止
                                </Button>
                            )}
                        </td>
                    </tr>

                    <tr>
                        <th>リビジョン番号</th>
                        <td>R{props.pageStore.revisionNumber}</td>
                    </tr>
                </tbody>
            </HTMLTable>

            <H3>担当者</H3>
            <HTMLTable>
                <tbody>
                    <tr>
                        <th>担当者</th>
                        <td>{props.pageStore.userNames.join(",")}</td>
                    </tr>
                </tbody>
            </HTMLTable>

            <H3>変更内容</H3>
            <PropertyDiffPanel store={props.pageStore.propertyDiffStore} />
            {props.pageStore.differences && (
                <Card>
                    {!aquaEditor ? (
                        <AquaEditorStatus aquaEditorState={aquaEditorState} />
                    ) : (
                        <VisualSourceDiffSet
                            aquaEditor={aquaEditor}
                            differences={props.pageStore.differences}
                        />
                    )}
                </Card>
            )}
        </AppLayout>
    );
});
