import {
    Button,
    Callout,
    Card,
    Checkbox,
    Colors,
    ControlGroup,
    Drawer,
    FormGroup,
    Intent,
    Radio,
    RadioGroup,
    Spinner,
} from "@blueprintjs/core";
import {TimePrecision} from "@blueprintjs/datetime";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import * as React from "react";

import {IAquaEditor} from "../../../editor/aqua-editor";
import {handleChangeBoolean} from "../../../utils/handle-change";
import {DrawerBody} from "../../molecules/drawer-body/drawer-body";
import {DrawerFooter} from "../../molecules/drawer-footer/drawer-footer";
import {MomentDateInput} from "../../molecules/moment-date-input/moment-date-input";
import ProgressPanel from "../../molecules/progress-panel/progress-panel";
import {VisualSourceDiffSet} from "../../organisms/visual-source-diff-set/visual-source-diff-set";
import {PropertyDiffPanel} from "../property-diff-panel/property-diff-panel";
import {PublishDrawerStore, PublishingType} from "./publish-drawer-store";

function publishSetting(store: PublishDrawerStore) {
    return (
        <div style={{display: "flex", alignItems: "center", height: "35px"}}>
            <RadioGroup
                inline={true}
                onChange={(e) => store.setPublishingType(e.currentTarget.value)}
                selectedValue={store.editingPublishingType}
            >
                <Radio label="即時公開" value={PublishingType.IMMEDIATE} />
                <Radio label="予約公開" value={PublishingType.SCHEDULED} />
            </RadioGroup>
            <div>
                {store.editingPublishingType === PublishingType.SCHEDULED && (
                    <FormGroup label="公開予定日時" inline={true}>
                        <ControlGroup>
                            <MomentDateInput
                                timePrecision={TimePrecision.MINUTE}
                                onChange={(d) => store.updateEditingAppliedAt(d)}
                                value={store.editingAppliedAt.toDate()}
                            />
                        </ControlGroup>
                    </FormGroup>
                )}
            </div>
        </div>
    );
}

const PublishDrawerBodyDiv = styled.div`
    background-color: #f8f8fa;
`;

interface IPublishDrawerProps {
    aquaEditor: IAquaEditor;
    store: PublishDrawerStore;
    onPublish?: () => void;
}

@observer
export class PublishDrawer extends React.Component<IPublishDrawerProps, {}> {
    public render() {
        // TODO: update with lifecycle function
        this.props.store.onPublish = this.props.onPublish;

        return (
            <Drawer
                size="1000px"
                isOpen={this.props.store.isOpen}
                onClose={this.props.store.close}
                title={this.props.store.isQuickPublish ? "クイック公開" : "下書きを公開"}
            >
                {this.props.store.isLoading ? (
                    <DrawerBody>
                        <Spinner />
                    </DrawerBody>
                ) : (
                    <>
                        <DrawerBody>
                            <PublishDrawerBodyDiv>
                                {this.props.store.isOutdated && (
                                    <Callout intent={Intent.WARNING}>
                                        同時編集によって公開ドキュメントが更新されています。変更内容に注意してください。
                                    </Callout>
                                )}

                                <div>
                                    <PropertyDiffPanel
                                        store={this.props.store.propertyDiffStore}
                                        changeOnly={true}
                                    />

                                    <Card>
                                        {this.props.store.differences && (
                                            <VisualSourceDiffSet
                                                aquaEditor={this.props.aquaEditor}
                                                differences={this.props.store.differences}
                                            />
                                        )}
                                    </Card>
                                </div>
                            </PublishDrawerBodyDiv>
                        </DrawerBody>
                        <DrawerFooter>
                            {this.props.store.isOutdated && (
                                <Checkbox
                                    large={true}
                                    onChange={handleChangeBoolean(
                                        this.props.store.setIsOutdatedConfirmed,
                                    )}
                                    checked={this.props.store.isOutdatedConfirmed}
                                >
                                    <span style={{color: Colors.RED3}}>
                                        同時編集されていることを確認しました。
                                    </span>
                                </Checkbox>
                            )}

                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div>
                                    <ProgressPanel inProgress={this.props.store.isPublishing}>
                                        {!this.props.store.isQuickPublish &&
                                            publishSetting(this.props.store)}
                                    </ProgressPanel>
                                </div>
                                <div>
                                    {this.props.store.isQuickPublish && (
                                        <Button
                                            disabled={!this.props.store.canPublish}
                                            intent={Intent.PRIMARY}
                                            onClick={this.props.store.quickPublish}
                                        >
                                            公開
                                        </Button>
                                    )}
                                    {!this.props.store.isQuickPublish && (
                                        <Button
                                            disabled={!this.props.store.canPublish}
                                            intent={Intent.PRIMARY}
                                            onClick={this.props.store.publish}
                                        >
                                            公開
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </DrawerFooter>
                    </>
                )}
            </Drawer>
        );
    }
}
