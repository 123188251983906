import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("UserImportUser")
export class UserImportUser {
    @JsonProperty()
    public email: string = JsonPropertyEmpty;

    @JsonProperty()
    public passwordHash: string = JsonPropertyEmpty;

    @JsonProperty()
    public role: string = JsonPropertyEmpty;

    @JsonProperty()
    public username: string = JsonPropertyEmpty;

    public constructor(args?: Partial<UserImportUser>) {
        Object.assign(this, args);
    }
}

/* eslint-disable max-classes-per-file */
@JsonObject("UserImportInput")
export class UserImportInput {
    @JsonProperty()
    public users: UserImportUser[] = JsonPropertyEmpty;

    public constructor(args?: Partial<UserImportInput>) {
        Object.assign(this, args);
    }
}
