export function handleChangeString(handler: (value: string) => void) {
    return (event: React.FormEvent<HTMLElement>) =>
        handler((event.target as HTMLInputElement).value);
}

export function handleChangeNumber(handler: (value: number) => void) {
    return handleChangeString((value) => handler(+value));
}

export function handleChangeBoolean(handler: (checked: boolean) => void) {
    return (event: React.FormEvent<HTMLElement>) =>
        handler((event.target as HTMLInputElement).checked);
}
