import * as React from "react";

import {ICategoryItem} from "../../../utils/category-util";
import {ListEditorItemPanel} from "../../molecules/list-editor-item-panel/list-editor-item-panel";
import {CategorySelector} from "../category-selector/category-selector";

export interface ICategoryListEditorItemProps {
    isPrimary: boolean;
    value: string | null;
    placeholder?: string;
    onChange: (value: ICategoryItem | null) => void;
    onRemove: () => void;
}

export class CategoryListEditorItem extends React.Component<ICategoryListEditorItemProps> {
    public constructor(props: ICategoryListEditorItemProps) {
        super(props);
        this.state = {
            isEditing: !props.value,
        };
    }

    public render() {
        const handleChange = (value: ICategoryItem | null) => {
            this.props.onChange(value);
        };

        return (
            <ListEditorItemPanel onRemove={this.props.value ? this.props.onRemove : undefined}>
                <CategorySelector
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    onChange={handleChange}
                    isBold={this.props.isPrimary}
                />
            </ListEditorItemPanel>
        );
    }
}
