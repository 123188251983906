import * as React from "react";

import {GlobalToaster} from "../../organisms/global-toaster/global-toaster";
import {BasicLayout} from "../basic-layout/basic-layout";

export const GuestLayout: React.FC = (props) => (
    <BasicLayout>
        <GlobalToaster />
        {props.children}
    </BasicLayout>
);
