import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("CategoryGroupListInput")
export class CategoryGroupListInput {
    @JsonProperty("mediaId", String, true)
    public mediaId?: string = JsonPropertyEmpty;

    public constructor(args?: Partial<CategoryGroupListInput>) {
        Object.assign(this, args);
    }
}
