import * as React from "react";

interface IPropertyDiffImageProps {
    imageUri: string;
}

export class PropertyDiffImage extends React.PureComponent<IPropertyDiffImageProps> {
    public render() {
        if (this.props.imageUri) {
            return (
                <img src={this.props.imageUri} style={{maxWidth: "200px", maxHeight: "150px"}} />
            );
        } else {
            return <div>(No Image)</div>;
        }
    }
}
