import * as React from "react";
import {useEffect} from "react";

import {useAuthenticationStore} from "../../../stores/store-hooks";
import {usePageRouter} from "../../page-router-context";

export const HomePage: React.FC = () => {
    const authenticationStore = useAuthenticationStore();
    const pageRouter = usePageRouter();

    useEffect(() => {
        // tellus-adminは一律してメディア一覧画面へ遷移させる
        const nextUrl = "/admin/media-list";

        // aqua-adminの場合は、
        // ドキュメントの閲覧権限がある場合はドキュメント一覧画面へ、
        // ない場合はリビジョン一覧画面へ遷移する

        // const nextUrl = authenticationStore.hasReadDocumentsPermission
        //   ? "/admin/documents"
        //   : "/admin/revisions";

        pageRouter.pushRoute(nextUrl);
    }, [authenticationStore, pageRouter]);

    return null;
};
