import React, {useContext} from "react";

export type PageChangingCallback = () => void;
export type UnlistenPageChangingCallback = () => void;

export interface PageChangingContextValues {
    notify: () => void;
    listen: (callback: PageChangingCallback) => UnlistenPageChangingCallback;
}

export const PageChangingContext = React.createContext<PageChangingContextValues | undefined>(
    undefined,
);

export function usePageChangingContext() {
    const values = useContext(PageChangingContext);
    if (!values) {
        throw new Error("DirtyEditBackupContext is not setup");
    }
    return values;
}
