import {ValidationFunction} from "../validation-function";
import {ValidationResultItem} from "../validation-result-item";

const smallAlphabetNumberPattern = /^[0-9a-z]*$/;

export const smallAlphabetNumber: ValidationFunction = () => {
    return (s) => {
        if (!smallAlphabetNumberPattern.test(s)) {
            return [
                new ValidationResultItem("smallAlphabetNumber.pattern", `a-z, 0-9のみ利用可能です`),
            ];
        }
        return [];
    };
};
