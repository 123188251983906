import {Button, Classes, Dialog, Intent} from "@blueprintjs/core";
import * as React from "react";
import {useState} from "react";

interface IConfirmDialogProps {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    buttonLabel: React.ReactNode;
    canAction?: boolean;
    onAction: () => Promise<unknown>;
}

export const ConfirmDialog: React.FC<IConfirmDialogProps> = ({
    title,
    buttonLabel,
    isOpen,
    onClose,
    canAction,
    onAction,
    children,
}) => {
    const [isExecuting, setIsExecuting] = useState(false);

    const onClick = async () => {
        setIsExecuting(true);
        try {
            await onAction();
        } finally {
            setIsExecuting(false);
            onClose();
        }
    };

    const disabled = canAction === undefined ? false : !canAction;

    return (
        <Dialog isOpen={isOpen} onClose={onClose} title={title}>
            <div className={Classes.DIALOG_BODY}>{children}</div>

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                        intent={Intent.PRIMARY}
                        loading={isExecuting}
                        onClick={onClick}
                        disabled={disabled}
                    >
                        {buttonLabel}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};
