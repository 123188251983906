import {Callout, Intent} from "@blueprintjs/core";
import {IContentEditorHandle} from "@syginc/aqua-client";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import * as React from "react";

import {DocumentType} from "../../../api/values/document-type";
import {IAquaEditor} from "../../../editor/aqua-editor";
import {IAomEditorGetNodeHandler} from "./aom-editor-handler";

export interface IAomCustomEditorProps {
    aquaEditor: IAquaEditor;
    defaultNodeJson: string;
    onReady?: () => void;
    onChange?: () => void;
    onSave?: () => void;
    documentType: DocumentType;
}

export const AomCustomEditor = forwardRef<IAomEditorGetNodeHandler, IAomCustomEditorProps>(
    function AomCustomEditor(props, ref) {
        const {aquaEditor, defaultNodeJson, onReady, onChange, onSave, documentType} = props;

        const editorMode = documentType === DocumentType.BLOCK ? "block" : "article";

        const divRef = useRef<HTMLDivElement>(null);
        const [errorMessage, setErrorMessage] = useState<string | null>(null);
        const [editorHandle, setEditorHandle] = useState<IContentEditorHandle | null>(null);

        useEffect(() => {
            if (!divRef.current) {
                throw new Error("An editor element is not mounted.");
            }

            const initialNode = JSON.parse(defaultNodeJson);

            const handlePromise = aquaEditor.mountContentEditor({
                element: divRef.current,
                initialNode,
                onChange,
                onSave,
                editorMode,
            });

            handlePromise
                .then((x) => {
                    setEditorHandle(x);

                    if (onReady) {
                        onReady();
                    }
                })
                .catch((x) => {
                    setErrorMessage(x.message || "Failed to initialize the aom editor");
                });

            return () => {
                setEditorHandle(null);
                handlePromise.then((x) => x.unmount());
            };
        }, [aquaEditor, defaultNodeJson, editorMode, onChange, onReady, onSave]);

        useImperativeHandle(ref, () => {
            return {
                getNode() {
                    if (!editorHandle) {
                        return null;
                    }
                    return editorHandle.getNode();
                },
            };
        });

        return (
            <div>
                {errorMessage && <Callout intent={Intent.DANGER}>{errorMessage}</Callout>}
                <div style={{display: errorMessage ? "none" : "block"}} ref={divRef} />
            </div>
        );
    },
);
