import {action, makeObservable, observable, runInAction} from "mobx";
import moment from "moment";

import {AdminApi} from "../../../api/api";
import {DocumentRevisionSearchInput} from "../../../api/values/document-revision-search-input";
import {DocumentRevisionType} from "../../../api/values/document-revision-type";
import {DocumentType} from "../../../api/values/document-type";
import {Stores} from "../../../stores";
import {Permission} from "../../../stores/authentication-store";
import {BigIntId} from "../../../utils/big-int-id";
import {trueToBoolean} from "../../../utils/boolean-util";
import {
    getCategoryMap,
    ICategoryItem,
    mapRelatedResourcesToCategories,
} from "../../../utils/category-util";
import {stringToEnum} from "../../../utils/enum-util";
import {PageStoreConstructorOptions} from "../../../utils/page-store";
import {PageRouter} from "../../../utils/route-util";

interface IDocumentRevisionListSearchParams {
    mediaId: string;
    documentId?: string;
    revisionName?: string;
    title?: string;
    userName?: string;
    isWorking?: boolean;
    documentType?: string;
}

export class RevisionListItem {
    @observable
    public id: BigIntId = new BigIntId("0");

    @observable
    public documentId: BigIntId = new BigIntId("0");

    @observable
    public revisionName: string = "";

    @observable
    public revisionNumber: number | null = null;

    @observable
    public revisionType: DocumentRevisionType = DocumentRevisionType.NORMAL;

    @observable
    public appliedAt: moment.Moment | null = null;

    @observable
    public updatedAt: moment.Moment = moment();

    @observable
    public modifiedAt: moment.Moment = moment();

    @observable
    public title: string = "";

    @observable
    public categories: ICategoryItem[] = [];

    @observable
    public userNames: string[] = [];

    public constructor(args?: Partial<RevisionListItem>) {
        makeObservable(this);
        Object.assign(this, args);
    }
}

/* eslint-disable max-classes-per-file */
export class RevisionListPageStore {
    @observable
    public documentRevisions: RevisionListItem[] = [];

    @observable
    public mediaId: string = "";

    @observable
    public searchDocumentId: string = "";

    @observable
    public searchRevisionName: string = "";

    @observable
    public searchTitle: string = "";

    @observable
    public searchUserName: string = "";

    @observable
    public searchIsWorking?: boolean;

    @observable
    public currentPage: number = 0;

    @observable
    public previousPageExists: boolean = false;

    @observable
    public nextPageExists: boolean = false;

    @observable
    public documentType?: DocumentType;

    private adminApi: AdminApi;
    private stores: Stores;
    private pageRouter: PageRouter;

    constructor(options: PageStoreConstructorOptions) {
        this.adminApi = options.adminApi;
        this.stores = options.stores;
        this.pageRouter = options.pageRouter;

        makeObservable(this);
    }

    @action.bound
    public setSearchDocumentId(s: string) {
        this.searchDocumentId = s;
    }

    @action.bound
    public setSearchRevisionName(s: string) {
        this.searchRevisionName = s;
    }

    @action.bound
    public setSearchTitle(s: string) {
        this.searchTitle = s;
    }

    @action.bound
    public setSearchUserName(s: string) {
        this.searchUserName = s;
    }

    @action.bound
    public setSearchIsWorking(b?: boolean) {
        this.searchIsWorking = b;
    }

    @action.bound
    public setDocumentType(s: string) {
        this.documentType = this.getDocumentTypeFromQueryParam(s);
    }

    public async initialize({query}: any) {
        this.stores.authenticationStore.checkAnyPermissionExists(
            Permission.READ_DOCUMENTS,
            Permission.READ_ASSIGNED_DOCUMENTS,
        );

        const q = query || {};

        await this.loadDocumentRevisions(q.page ? Number(q.page) : 1, {
            mediaId: q.mediaId,
            documentId: q.documentId,
            isWorking: trueToBoolean(q.isWorking),
            revisionName: q.revisionName,
            title: q.title,
            userName: q.userName,
            documentType: q.type,
        });
    }

    @action.bound
    public async loadDocumentRevisions(
        page: number,
        searchParams: IDocumentRevisionListSearchParams,
    ) {
        const userName = this.stores.authenticationStore.hasReadDocumentsPermission
            ? searchParams.userName
            : this.stores.authenticationStore.username!;
        const categoryMap = await getCategoryMap(this.adminApi, searchParams.mediaId);

        const result = await this.adminApi.getDocumentRevisions(
            new DocumentRevisionSearchInput({
                mediaId: searchParams.mediaId,
                documentIds: searchParams.documentId
                    ? [new BigIntId(searchParams.documentId)]
                    : undefined,
                isWorking: searchParams.isWorking,
                page,
                revisionName: searchParams.revisionName,
                title: searchParams.title,
                documentType: this.getDocumentTypeFromQueryParam(searchParams.documentType),
                userName,
            }),
        );

        runInAction(() => {
            this.currentPage = page;
            this.previousPageExists = result.previousPageExists;
            this.nextPageExists = result.nextPageExists;
            this.mediaId = searchParams.mediaId;
            this.searchDocumentId = searchParams.documentId || "";
            this.searchRevisionName = searchParams.revisionName || "";
            this.searchTitle = searchParams.title || "";
            this.searchUserName = userName || "";
            this.searchIsWorking = searchParams.isWorking;
            this.documentType = this.getDocumentTypeFromQueryParam(searchParams.documentType);
            this.documentRevisions = result.documentRevisions.map(
                (x) =>
                    new RevisionListItem({
                        documentId: x.documentId,
                        id: x.id,
                        revisionName: x.revisionName,
                        revisionNumber: x.revisionNumber,
                        revisionType: x.revisionType,

                        appliedAt: x.appliedAt,
                        modifiedAt: x.modifiedAt,
                        updatedAt: x.updatedAt,

                        title: x.title,

                        categories: mapRelatedResourcesToCategories(
                            categoryMap,
                            x.relatedResources,
                        ),
                        userNames: x.users.map((u) => u.name),
                    }),
            );
        });
    }

    @action.bound
    public async showNextPage() {
        await this.goToPage(this.currentPage + 1, this.getSearchParams());
    }

    @action.bound
    public async showPreviousPage() {
        await this.goToPage(this.currentPage - 1, this.getSearchParams());
    }

    @action.bound
    public async search() {
        await this.goToPage(1, this.getSearchParams());
    }

    private getSearchParams() {
        return {
            mediaId: this.mediaId,
            documentId: this.searchDocumentId,
            isWorking: this.searchIsWorking,
            revisionName: this.searchRevisionName,
            title: this.searchTitle,
            userName: this.searchUserName,
            type: this.documentType,
        };
    }

    private async goToPage(page: number, searchParams: IDocumentRevisionListSearchParams) {
        this.pageRouter.pushRoute(`/admin/[mediaId]/revisions`, {
            page: page > 1 ? page.toString() : undefined,
            ...searchParams,
        } as any);
    }

    private getDocumentTypeFromQueryParam(queryParam?: string): DocumentType | undefined {
        return stringToEnum(DocumentType, queryParam || "");
    }
}
