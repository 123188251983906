import createCache from "@emotion/cache";
import {CacheProvider} from "@emotion/react";
import * as React from "react";

import {AdminApiContextProvider} from "../api/api-context";
import {AquaEditorProvider} from "../editor/aqua-editor-context";
import {StoresContextProvider} from "../stores/stores-context";
import {PageChangingContextProvider} from "./organisms/page-changing-context/page-changing-context-provider";
import {PageRouterProvider} from "./page-router-context";

const emotionCache = createCache({key: "css-admin"});

export function AppProvider(props: any) {
    return (
        <CacheProvider value={emotionCache}>
            <PageChangingContextProvider>
                <PageRouterProvider>
                    <StoresContextProvider>
                        <AdminApiContextProvider>
                            <AquaEditorProvider>{props.children}</AquaEditorProvider>
                        </AdminApiContextProvider>
                    </StoresContextProvider>
                </PageRouterProvider>
            </PageChangingContextProvider>
        </CacheProvider>
    );
}
