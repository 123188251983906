import {action, computed, makeObservable, observable, runInAction} from "mobx";

import {AdminApi} from "../../../api/api";
import {DocumentRevisionAddInput} from "../../../api/values/document-revision-add-input";
import {DocumentRevisionGetInput} from "../../../api/values/document-revision-get-input";
import {DocumentRevisionUserInputOutput} from "../../../api/values/document-revision-user-input-output";
import {Stores} from "../../../stores";
import {SimpleToast} from "../../../stores/toast-store";
import {filterNotNullOrUndefined} from "../../../utils/array-util";
import {getAuthInfo} from "../../../utils/auth-util";
import {BigIntId} from "../../../utils/big-int-id";
import {PageStoreConstructorOptions} from "../../../utils/page-store";
import {PageRouter} from "../../../utils/route-util";
import {validateLength} from "../../../utils/validation/functions/length";
import {createValidator} from "../../../utils/validation/validate";
import {ValidationResultEmpty} from "../../../utils/validation/validation-result";

export class CopyDocumentRevisionStore {
    @observable
    public mediaId: string = "";

    @observable
    public documentRevisionId: BigIntId = new BigIntId("0");

    @observable
    public isOpen = false;

    @observable
    public revisionName: string = "";

    @observable
    public users: DocumentRevisionUserInputOutput[] = [];

    @observable
    public revisionNameValidationResult = ValidationResultEmpty;

    private adminApi: AdminApi;
    private stores: Stores;
    private pageRouter: PageRouter;

    constructor(options: PageStoreConstructorOptions) {
        makeObservable(this);

        this.adminApi = options.adminApi;
        this.stores = options.stores;
        this.pageRouter = options.pageRouter;
    }

    @action.bound
    public async open(mediaId: string, documentRevisionId: BigIntId) {
        this.isOpen = true;
        this.documentRevisionId = documentRevisionId;
        this.mediaId = mediaId;

        const revision = await this.adminApi.getDocumentRevision(
            {id: documentRevisionId},
            new DocumentRevisionGetInput({
                mediaId: this.mediaId,
            }),
        );

        await this.setRevisionName(revision.revisionName + "のコピー");

        const users = filterNotNullOrUndefined([getAuthInfo()]).map(
            (x) => new DocumentRevisionUserInputOutput({name: x.username}),
        );

        runInAction(() => {
            this.users = users;
        });
    }

    @action.bound
    public async close() {
        this.isOpen = false;
    }

    @action.bound
    public async setRevisionName(s: string) {
        this.revisionName = s;
        (await this.revisionNameValidator(s)).run((v) => (this.revisionNameValidationResult = v));
    }

    @computed
    public get validationsAllEmpty() {
        return this.revisionNameValidationResult.isEmpty;
    }

    private revisionNameValidator = createValidator(validateLength(1, 50));

    @action.bound
    public async createCopy() {
        const result = await this.adminApi.postDocumentRevision(
            {mediaId: this.mediaId},
            new DocumentRevisionAddInput({
                referenceDocumentRevisionId: this.documentRevisionId,
                revisionName: this.revisionName,
                users: this.users,
            }),
        );

        this.stores.toastStore.setSimpleToast(SimpleToast.SAVED);
        this.stores.toastStore.keepFirstRouteChange();

        await this.pageRouter.pushRoute("/admin/[mediaId]/revisions/[documentRevisionId]", {
            mediaId: this.mediaId,
            documentRevisionId: result.id.value,
        });
    }

    @action.bound
    public async createCopyAndClose() {
        await this.createCopy();
        this.close();
    }
}
